import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/ZoneList.module.css";
import Plus from "../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import dots from "../../../assets/images/column_dots.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { useHistory } from "react-router";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import { arrayMove } from "react-movable";
import { notification, Dropdown, Menu, Select, Tooltip } from "antd";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import Search from "../../../assets/images/search_icon.svg";
import Pagination from "../../modules/ReusableComponent/Pagination";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import BulkUploadComponent from "../CatalogManagement/ProductManagement/BulkUploadComponent";
import { ExportCSV } from "../../modules/ReusableComponent/ExportCsv";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import moment from "moment";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

const originalHeaders = [
  //  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Zone", value: "zone" },
  { _id: 3, status: 1, name: "Region", value: "region" },
  { _id: 4, status: 1, name: "City", value: "city" },
  { _id: 5, status: 1, name: "Pincode", value: "pincode" },
  { _id: 6, status: 1, name: "Action", value: "Action" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  // { id: 2, isChecked: false, name: "Date", value: "date" },
  // { id: 3, isChecked: false, name: "Rating", value: "rate" },
];
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  // { _id: 8, name: "This Week", value: 2 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: 1 },
  { _id: 2, isChecked: false, name: "Disable", value: 2 },
];
const columns = [
  { id: 1, name: "Zone", value: "zone" },
  { id: 2, name: "Region", value: "region" },
  { id: 3, name: "City", value: "city" },
  { id: 4, name: "Pincode", value: "pincode" },
  { id: 5, name: "Action", value: "Action" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

const original_columns = ["Zone", "Region", "City", "Pincode", "Action"];

const column_value = {
  zone: true,
  region: true,
  city: true,
  pincode: true,
  Action: true,
};

function ZoneList() {
  const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();
  const pdfExportComponent = useRef(null);
  const [regionList, setRegionList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [exportShipping, setExportShipping] = useState([]);
  const [exportRegions, setExportRegions] = useState([]);
  const [exportPincode, setExportPincode] = useState([]);
  const [exportAll, setExportAll] = useState([]);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredResult, setFilteredResult] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");

  const [regions, setRegions] = useState([]);
  const [sortOrder, setSortorder] = useState(0);

  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const fileName = "ZoneFileExported";
  const viewers = exportShipping;
  const [deleteId, setDeleteId] = useState({
    regionId: '',
    zoneId: ''
  });

  const [columns, setColumns] = useState([
    /*  {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Zone",
      visible: false,
      key: "zone",
      render: (text, record, index) => <span>{record.zoneName}</span>,
      sorter: (a, b) => a.zoneName - b.zoneName,
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      render: (text, record, index) => <span>{record.regionName}</span>,
    },
    {
      title: "City",
      visible: false,
      key: "city",
      render: (text, record, index) => (
        <span>
          {record.cities.length === 1 && record.cities[0]}
          {record.cities.length === 2 &&
            record.cities[0] + "," + record.cities[1]}
          {record.cities.length === 3 &&
            record.cities[0] + "," + record.cities[1] + "," + record.cities[2]}
          {record.cities.length > 3 &&
            record.cities[0] +
            "," +
            record.cities[1] +
            "," +
            record.cities[2] +
            ",..."}
        </span>
      ),
    },
    {
      title: "Pincode",
      visible: false,
      key: "pincode",
      render: (text, record, index) => (
        <span>
          {record.allPincodes.length === 1 && record.allPincodes[0]}
          {record.allPincodes.length === 2 &&
            record.allPincodes[0] + "," + record.allPincodes[1]}
          {record.allPincodes.length === 3 &&
            record.allPincodes[0] +
            "," +
            record.allPincodes[1] +
            "," +
            record.allPincodes[2]}
          {record.allPincodes.length > 3 &&
            record.allPincodes[0] +
            "," +
            record.allPincodes[1] +
            "," +
            record.allPincodes[2] +
            ",..."}
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
          permissionsArr.length > 0 &&
          (permissionsArr.find((x) => x === "SiteSettings-Zone-edit") === "SiteSettings-Zone-edit" || 
          permissionsArr.find((x) => x === "SiteSettings-Zone-view") === "SiteSettings-Zone-view") && (
          <span>
            {permissionsArr.find((x) => x === "SiteSettings-Zone-view") === "SiteSettings-Zone-view" && 
              <Tooltip placement="topRight" title="Edit zone">
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-3`}
                  alt="Edit zone"
                  // title="Edit zone"
                  onClick={() => editRegion(record)}
                />
              </Tooltip>
            }
            {permissionsArr.find((x) => x === "SiteSettings-Zone-edit") === "SiteSettings-Zone-edit" && <Tooltip
              placement="topRight"
              title="Delete order"
            >
              <img
                src={adminProductDelete}
                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                onClick={() => deleteFunction(record)}
                alt="Delete order action icon"
              />
            </Tooltip>}
          </span>
        ),
    },
  ]);

  function deleteFunction(record) {
    setDeleteId({
      regionId: record._id,
      zoneId: record.zoneId
    });
  }

  function cancelDelete() {
    setDeleteId({
      regionId: '',
      zoneId: ''
    });
  }

  function removeZone() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
    .post("/admin/zone/deleteZone", deleteId)
    .then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        cancelDelete();
        showNotification({
          type: "success",
          message: "success",
          description: res.data.msg,
        });
        getZoneList(pageSize, nextPage, "");
      }
    })
    .catch((er) => {
      return er;
    })
    .finally(() => {
      // setIsLoading(false);
      setIsLoading(false);
    });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', sorter.order);
    let sortValue = sorter.order === "ascend" ? 0 : 1;
    // console.log(sortValue);
    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortValue,
    };
    getZoneList(pageSize, 1, updatedFilter, true);
  }

  // const [selectedOrder, setSelectedOrder] = useState([]);
  /**
   * @author Prasanthu
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setRegionList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
  * @author Prasanthu
  * @param {event,record}
  * To get state of checked and update each records directly with isChecked parameter
  */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setRegionList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getZoneList(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    };    
    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
  }, []);
  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };
  /**
  * @author Prasanthu
  * @param {pageSize, nextPage, filterarr, type}
  * FETCH REGION LIST
  */
  function getZoneList(pageSize, nextPage, filterarr, type) {
    // setIsLoading(false);
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else {
      filterOptions.search = "";
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder) {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(sortOrder);
      }
    }

    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/admin/zone/getZoneList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          if (res?.data?.data?.length > 0) {
            res?.data?.data.forEach((item) => {
              item["allPincodes"] = [];
              item.cities = Object.values(item.cities);
              Object.keys(item.pincodes).map(function (key, index) {
                // console.log(key,"key");
                item.pincodes[index].forEach((pincode) => {
                  item.allPincodes.push(pincode);
                });
              });
            });
            setIsLoading(false);
          }
          // setIsLoader(false);
          setRegionList(res.data.data);
          // console.log(res.data.data)
          localStorage.setItem("regionList", JSON.stringify(res.data.data));
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  // *** ADD ZONE *** //
  function addZone() {
    history.push(`/site-settings/zone/addzone`);
  }

  useEffect(() => {
    // setTimeout(() => {
    // setIsLoading(true);
    getZoneList(pageSize, nextPage);
    getRegionList();
    getExportZone();

    // }, 500);
  }, [nextPage, pageSize]);
  /**
   * @author Prasanthu
   * @param {pageSize, nextPage, filterarr}
   * To get eport zone
   */
  function getExportZone(pageSize, nextPage, filterarr) {
    const filterOptions = {};

    /* Region export filter */
    if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/admin/zone/exportZone", filterOptions)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          setExportShipping(res.data.data.shipping);
          setExportRegions(res.data.data.regions);
          setExportPincode(res.data.data.pincode);
          setExportAll(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading(false);
      });
  }
  // *** EDIT REGION FUNCTION *** //
  function editRegion(region) {
    // console.log(region);
    history.push({
      pathname: `/site-settings/zone/editzone`,
      state: region,
    });
  }

  function handleInput(e) {
    const { value } = e.target;

    setSearch(value);
    console.log(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setIsLoading(true);
    setNextPage(1);
    getZoneList(pageSize, 1, filter);
    getExportZone(pageSize, 1, filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getZoneList(value, 1);
    } else if (type === "nextPage") {
      setNextPage(value);
      getZoneList(pageSize, value);
    }
  }
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }
  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value;
      setRegionId(value);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value;
      setFilterDays(value);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
      debounceSearch(updatedFilter);
    }
  }
  /**
   * @author Prasanthu
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
    };
    debounceSearch(updatedFilter);
  };

  //bulk
  if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
        region="region"
        handleClose={() => {
          setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          });
          getZoneList(pageSize, nextPage);
        }}
      />
    );
  }
  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortOrder,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        /*  if (item.value === "CategoryId") {
              updatedFilter.CategoryId = "";
              setCategoryId("");
          } */
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        /* if (item.value === "DeliveryStatus") {
              updatedFilter.orderStatus = "";
              setOrderStatus("");
          } */
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                                  className="form_control"
                                  name="regionId"
                                  value={regionId}
                                  onChange={filterOnChange}
                              >
                                  <option value="">All Region</option>
                                  {Array.isArray(regions)
                                      ? regions.map((region) => (
                                          <option key={region._id} value={region._id}>
                                              {region.name}
                                          </option>
                                      ))
                                      : null}
                              </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                        className="form_control"
                        name="filterDays"
                        value={filterDays}
                        onChange={filterOnChange}
                      >
                        <option value="">Date</option>
                        {Array.isArray(filterdayArr)
                          ? filterdayArr.map((day) => (
                              <option key={day._id} value={day.value}>
                                {day.name}
                              </option>
                            ))
                          : null}
                      </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );
  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    // let element = container.current || document.body;
    // savePDF(element, {
    //   paperSize: "auto",
    //   margin: 40,
    //   fileName: `Report for ${new Date().getFullYear()}`,
    // });
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,

      filterDays: filterDays,
    };
    debounceSearch(updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/site-settings`)}
          />
          Shipping Management
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "SiteSettings-Zone-view") ===
          "SiteSettings-Zone-view" && (
            <div>
              <span className={styles.management_header_downloads_text}>
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
              </span>
              <Tooltip placement="topRight" title="Download excel">
                <span>
                  <ExportCSV
                    csvData={viewers}
                    reg={exportRegions}
                    pincode={exportPincode}
                    fileName={fileName}
                  />
                </span>
              </Tooltip>
              <span className={styles.management_header_downloads_text}>
                Export <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
      </div>
      <div className={commonstyles.management_header}>
        <div className={commonstyles.management_contents_upload}>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "SiteSettings-Zone-add") ===
            "SiteSettings-Zone-add" && (
              <ButtonComponent
                className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
                attributes={{
                  onClick: addZone,
                }}
              >
                Add Zone
              </ButtonComponent>
            )}
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "SiteSettings-Zone-view") ===
            "SiteSettings-Zone-view" && (
              <ButtonComponent
                className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
                attributes={{
                  onClick: () => {
                    // setFilter(intialFilterState);
                    setChildComponentVisibility({
                      ...childComponentVisibility,
                      bulkUpload: true,
                    });
                    window.scrollTo(0, 0);
                  },
                }}
              >
                <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
                Bulk Upload
              </ButtonComponent>
            )}
          {/* <span style={{ cursor: "pointer" }} onClick={addZone}>
            <img src={Plus} alt="" />
            Add Zone
          </span> */}
        </div>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Zone-view") ===
        "SiteSettings-Zone-view" && (
          <div className={styles.administrator}>
            <div
              className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
            >
              <div
                className={`${commonstyles.management_contents_search_bar} input-group`}
              >
                <input
                  type="search"
                  className={`${commonstyles.form_control} form-control`}
                  aria-label="Search"
                  placeholder="Search Zone, Region"
                  aria-describedby="search-addon"
                  value={search}
                  onChange={handleInput}
                />
                <img src={Search} alt="" />
              </div>
              <ButtonComponent
                className="theme-btn rnd pl-3 pr-4 ml-2"
                attributes={{
                  onClick: () => {
                    searchClick();
                  },
                }}
              >
                Search
              </ButtonComponent>
              <Dropdown
                overlay={filterMenu}
                onVisibleChange={handleVisibleChange} visible={visible}
                className="usermanage_table_filter_columns ml-3"
              >
                <div className="ant-dropdown-link">
                  Filter <img src={arrowDown} width={10} height={20} />
                </div>
              </Dropdown>
              <Dropdown
                overlay={menu}
                className="usermanage_table_filter_columns ml-3"
              >
                <div
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Columns <img src={arrowDown} width={10} height={20} />
                </div>
              </Dropdown>
            </div>
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {/* {!isLoading && orderList.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                    ></input>
                )} */}
              {IsLoading === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching Zone details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName={`Zone report for ${new Date().getFullYear()}`}
                  author="Damro Team"
                >
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: regionList,
                      pagination: false,
                      // onChange: handleTableChange,
                      onChange: onChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </PDFExport>
              )}
            </div>
          </div>
        )}
      {!IsLoading.list && Array.isArray(regionList) && regionList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Zone-view") ===
        "SiteSettings-Zone-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
      {/* zone delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: deleteId?.regionId? true : false,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeZone}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
      {/* zone delete confirmation modal ends*/}
    </div>
  );
}

export default ZoneList;
