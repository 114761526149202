import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../../styles/PaymentNew.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import DefaultImage from "../../../assets/images/profile_dummy.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  minChar,
  minCharLimit,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Tabs } from "antd";
import { isObject, isString, isEqual, upperFirst } from "lodash";
import validationRules from "../../../utils/validationRules";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import SearchComponent from "../SearchManagement/SearchComponent";
import moment from "moment";
import LandingCategoryComponent from "./ContentWiseCatList";
import LandingStoreComponent from "./StoreWiseList";
import LandingProductComponent from "../CMSManagement/ProductWiseLandingPage/ProductWiseList";
import PromotionalLandingPage from "./PromotionalLandingPage";
import FAQManagementComponent from "../ServiceManagement/FAQManagement";
import TestimonialPage from "./TestimonialPage";

export default function ContentManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const { TabPane } = Tabs;
  const [pageList, setPageList] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [lastViewAndEdit, setLastViewAndEdit] = useState({
    homePage: [],
    listingPage: [],
    ModalPage: [],
  });
  const [selectedTab, setSelectedTab] = useState("1");
  useEffect(() => {
    getPageList("");
    if (history?.isBanner) {
      setSelectedTab("2");
      history.isBanner = false;
    } else if (history?.isProduct) {
      setSelectedTab("5");
      history.isProduct = false;
    } else if (history?.isCategory) {
      setSelectedTab("4");
      history.isCategory = false;
    } else if (history?.isStore) {
      setSelectedTab("6");
    } else if (history?.isFAQ) {
      setSelectedTab("8");
    } else if (history?.isPromotionalPage) {
      setSelectedTab("9");
    }else if (history?.isTestimonialPage) {
      setSelectedTab("10");
    } else {
      setSelectedTab("1");
      history.isRegion = false;
    }
  }, []);
  /**
   * @author Rajkumar
   * @param {id}
   * To get page list
   */
  function getPageList(id) {
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/admin/cms/pageList`)
      .then((res) => {
        if (res.data.status === 200) {
          setPageList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setLoading({ details: false, update: false });
      });
  }

  function callback(key) {
    if (key === "1") {
      getPageList();
    }
    if (key === "2") {
      let data = { page: 0 };
      getBannerList(data);
    }
    if (key === "3" || key === 3) {
      history.push({
        pathname: `/landing`,
      });
    }
    if (key === "4" || key === 4) {
      // history.push({
      //   pathname: `/Category`,
      // });
      <LandingCategoryComponent />;
    }
    if (key === "5" || key === 5) {
      // history.push({
      //   pathname: `/Category`,
      // });
      <LandingProductComponent />;
    }
    if (key === "6" || key === 6) {
      <LandingStoreComponent />;
    }
    if(key === '7') {
      history.push({
        pathname: `/Content/hotDeal`,
      });
    }
    setSelectedTab(key);
  }

  function redirectHandle(id) {
    history.push({
      pathname: `/Content/CMS`,
      state: id,
    });
  }
  /**
   * @author Rajkumar
   * @param {data}
   * To get banner list 
   */
  function getBannerList(data) {
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/banner/getBanner`, data)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          let clonelastViewAndEdit = { ...lastViewAndEdit };
          if (data?.length > 0) {
            data.forEach((item) => {
              if (item._id === 1) {
                clonelastViewAndEdit.homePage = item;
              }
              if (item._id === 2) {
                clonelastViewAndEdit.listingPage = item;
              }
              if (item._id === 3) {
                clonelastViewAndEdit.ModalPage = item;
              }
            });
          }
          setLastViewAndEdit(clonelastViewAndEdit);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {});
  }

  const detailPage = (type) => {
    history.push({
      pathname: "/Content/addBanner",
      state: type,
    });
  };
  return (
    <div className={styles.management_contents}>
      <SearchComponent />
      <div className={styles.management_header}>
        <h1>Content</h1>
      </div>
      <div className="product-tabsbox">
        <Tabs activeKey={selectedTab} onChange={callback} type="card">
          <TabPane tab="CMS Page" key="1">
            {isLoading === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching  CMS details..." />
                  </td>
                </tr>
              </div>
            ) : (
              permissionsArr.length > 0 &&
              permissionsArr.find((x) => x === "Content-CMSPage-view") ===
                "Content-CMSPage-view" && (
                <>
                  {Array.isArray(pageList) &&
                    pageList.map((page, indx) => (
                      <React.Fragment key={indx}>
                        <div className={`${styles.content_card}`}>
                          <div
                            className={`row`}
                            key={page._id}
                            onclick={() => callback(1)}
                          >
                            <div
                              className={` ${styles.content_title} col-6 col-md-4`}
                              onClick={() => redirectHandle(page._id)}
                            >
                              {page.pageTitle}
                            </div>
                            {page?.userName && (
                              <div
                                className={` ${styles.content_text} col-6 col-md-4`}
                              >
                                Last updated by{" "}
                                <span
                                  className={` ${styles.content_text_bold}`}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {page?.userName}
                                </span>
                              </div>
                            )}
                            {page?.updatedAt && (
                              <div
                                className={` ${styles.content_text} col-6 col-md-4`}
                              >
                                Last updated on{" "}
                                <span>
                                  {moment(page?.updatedAt).format(
                                    "DD/MM/YYYY | hh:mm A"
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </>
              )
            )}
          </TabPane>

          <TabPane tab="Banner Management" key="2">
            {isLoading === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching  banner details..." />
                  </td>
                </tr>
              </div>
            ) : (
              permissionsArr.length > 0 &&
              permissionsArr.find((x) => x === "Content-Banner-view") ===
                "Content-Banner-view" && (
                <>
                  <div className={`${styles.content_card}`}>
                    <div className={`row`} onClick={() => detailPage(1)}>
                      <div
                        className={` ${styles.content_title} col-6 col-md-4`}
                      >
                        Home Page
                      </div>
                      {lastViewAndEdit?.homePage?.userName && (
                        <>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated By
                            <span className={` ${styles.content_text_bold}`}>
                              {" "}
                              {upperFirst(lastViewAndEdit?.homePage?.userName)}
                            </span>
                          </div>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated on{" "}
                            <span>
                              {" "}
                              {moment(
                                lastViewAndEdit?.homePage?.updatedAt
                              ).format("DD/MM/YYYY | hh:mm A")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.content_card}`}>
                    <div className={`row`} onClick={() => detailPage(2)}>
                      <div
                        className={` ${styles.content_title} col-6 col-md-4`}
                      >
                        Listing Page
                      </div>
                      {lastViewAndEdit?.listingPage?.userName && (
                        <>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated By
                            <span className={` ${styles.content_text_bold}`}>
                              {" "}
                              {upperFirst(
                                lastViewAndEdit?.listingPage?.userName
                              )}
                            </span>
                          </div>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated on{" "}
                            <span>
                              {" "}
                              {moment(
                                lastViewAndEdit?.listingPage?.updatedAt
                              ).format("DD/MM/YYYY | hh:mm A")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.content_card}`}>
                    <div className={`row`} onClick={() => detailPage(3)}>
                      <div
                        className={` ${styles.content_title} col-6 col-md-4`}
                      >
                        Modal Page
                      </div>
                      {lastViewAndEdit?.ModalPage?.userName && (
                        <>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated By
                            <span className={` ${styles.content_text_bold}`}>
                              {" "}
                              {upperFirst(lastViewAndEdit?.ModalPage?.userName)}
                            </span>
                          </div>
                          <div
                            className={` ${styles.content_text} col-6 col-md-4`}
                          >
                            Last Updated on{" "}
                            <span>
                              {" "}
                              {moment(
                                lastViewAndEdit?.ModalPage?.updatedAt
                              ).format("DD/MM/YYYY | hh:mm A")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )
            )}
          </TabPane>
          {/* <TabPane tab="Region wise Landing Page" key="3"></TabPane> */}
          <TabPane tab="Category-wise Landing Page" key="4">
            {" "}
            <LandingCategoryComponent />;
          </TabPane>
          <TabPane tab="Product-wise Landing Page" key="5">
            {" "}
            <LandingProductComponent />;
          </TabPane>
          <TabPane tab="Store-wise Landing Page" key="6">
            {" "}
            <LandingStoreComponent />;
          </TabPane>
          <TabPane tab="Hot deal Landing Page" key="7"></TabPane>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "FAQ-FAQ-view") === "FAQ-FAQ-view" && (
            <TabPane tab="FAQ Management" key="8">
              {" "}
              <FAQManagementComponent />
            </TabPane>
          )}
          <TabPane tab="Promotional Landing Page" key="9">
            {" "}
            <PromotionalLandingPage />
          </TabPane>
        {/* TestimonialPage */}
          <TabPane tab="Testimonial Page" key="10">
            {" "}
            <TestimonialPage/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
