import React from "react";
import styles from "../../../styles/EditUser.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { notification } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import NotificationManagement from "../NotificationManagement/NotificationManagement";
import ProfileEdit from "./ProfileEdit";

const ProfileDisplay = () => {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [detailsform, setDetailsForm] = useState({});

  let [orginal, setOrginal] = useState({});

  const [loading, setLoading] = useState({ details: false, update: false });
  let [page, setPage] = useState("profile");


  
  /**
   * @author Mithun Prabhu
   * @param {}
   * GET PROFILE DETAILS AND SET TA TO PROFILE
   */
  const setProfile = () => {
    getDetails();
    setPage("profile");
  };

   /**
   * @author Mithun Prabhu
   * @param {}
   * GET PROFILE DETAILS AND SET TA TO NOTIFICATION
   */
  const setNotification = () => {
    getDetails();
    setPage("notification");
  };

   /**
   * @author Mithun Prabhu
   * @param {nowName, nowValue}
   * GET PROFILE DETAILS AND SET NEW FIELD WITH NEW VALUE
   */
  const setDetails = (nowName, nowValue) => {
    setDetailsForm((prevState) => {
      return { ...prevState, [nowName]: nowValue };
    });
  };

   /**
   * @author Mithun Prabhu
   * @param {}
   * GET PROFILE DETAILS
   */
  function getDetails() {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`admin/profileDetails`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDetailsForm(res.data.data);
          setOrginal(res.data.data);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getDetails();
  }, []);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching profile details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={commonstyles.management_contents}>
          <div className={commonstyles.management_header}>
            <h1 className={commonstyles.management_header_heading}>
              <img
                src={BlackLeftArrow}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/site-settings")}
              />
              General Setting
            </h1>
          </div>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "SiteSettings-General-view") ===
              "SiteSettings-General-view" && (
              <div className={commonstyles.tabCollection}>
                {/* <div
                  className={`${commonstyles.tabBtn} ${
                    page == "profile" ? commonstyles.activeTab : ""
                  } `}
                  onClick={setProfile}
                >
                  Profile
                </div> */}
                {/* <div
                  className={`${commonstyles.tabBtn} ${
                    page == "notification" ? commonstyles.activeTab : ""
                  } `}
                  onClick={setNotification}
                >
                  Notification
                </div> */}
              </div>
            )}
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "SiteSettings-General-view") ===
              "SiteSettings-General-view" && (
              <>
                {page === "profile" ? (
                  <ProfileEdit
                    Detail={detailsform}
                    setDetails={setDetails}
                    dispName={orginal?.name}
                    getDetails={getDetails}
                  />
                ) : (
                  <NotificationManagement
                    id={detailsform?._id}
                    notifications={
                      detailsform?.notification ? detailsform?.notification : []
                    }
                  />
                )}
              </>
            )}
        </div>
      )}
    </>
  );
};

export default ProfileDisplay;
