import React , { useState , useEffect } from 'react';
import commonstyles from "../../../styles/Management_Common.module.css";
import Plus from "../../../assets/images/plus_white_icon.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { Link} from "react-router-dom";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import Search from "../../../assets/images/search_icon.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { Switch, Tooltip, notification, Table} from "antd";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { DndContext } from '@dnd-kit/core';
import {
  useHistory
} from "react-router-dom";
import {
  arrayMove as arraMoving,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Name", value: "Name" },
  { _id: 2, status: 1, name: "City", value: "City" },
  { _id: 3, status: 1, name: "Description", value: "Description" },
  { _id: 4, status: 1, name: "Status", value: "Status" },
  { _id: 5, status: 1, name: "Action", value: "action" },
];

function TestimonialPage() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [headers, setHeaders] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);  //change to true
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [sortorder, setSortorder] = useState("");
  const [deleteItem, setDeleteItem] = useState("");
  const [sortActive, setSortActive] = useState(false);
  const [testimonialDetail, setTestimonialDetail] = useState([]);
  const [sortTestimonialDetail, setSortTestimonialDetail] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });


  const [columns, setColumns] = useState([
    {
      title: "Name",
      visible: false,
      key: "Name",
      sorter: (a, b) => a.name - b.name,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.name ? record.name : "-"}</span>
      ),
    },
    {
      title: "City",
      visible: false,
      key: "City",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.designation ? record.designation :"-"}</span>
      ),
    },
    {
      title: "Description",
      visible: false,
      key: "Description",
      className : commonstyles.description_ellipsis,
      render: (text, record, index) =>
      <Tooltip
            placement="top"
            title={record.description ? record.description : "-"}
            className="tooltip_list"
          >
            <span>{record.description ? record.description : "-"}</span>
          </Tooltip>
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },

    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) => (
        <span>
          <Tooltip
            placement="topRight"
            title="Edit Testimonial page"
            className="tooltip_list"
          >
            {/* <Link
              to={{
                pathname: `/Content/testimonial/add`,
                state: { id: record },
              }}
            > */}
              <img
                src={adminProductEdit}
                className={`${commonstyles.admin_table_edit_img} mr-2`}
                alt="Edit action icon"
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => editTestimonial(record)}
              />
            {/* </Link> */}
          </Tooltip>
          <Tooltip
            placement="topRight"
            title="Delete Testimonial page"
            className="tooltip_list"
          >
            {" "}
            <img
              src={adminProductDelete}
              className={commonstyles.admin_table_edit_img}
              onClick={() => deleteTestimonial(record)}
              alt="Delete action icon"
              title="Delete"
              style={{ cursor: "pointer" }}
              disabled={!sortActive}
            />
          </Tooltip>
        </span>
      ),
    },
  ]);


  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    set_new_header(headers);
    setSortorder("");
    getTestimonialPage(pageSize, nextPage, "",sortorder);
    setDeleteItem("")
    history["isTestimonialPage"] = false;

  }, []);


  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setSortTestimonialDetail((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arraMoving(prev, activeIndex, overIndex);
      });
    
    }
  };

  const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform?.toString(
        transform && {
          ...transform,
          scaleY: 1,
        },
      ),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };


  function editTestimonial(record) {
    history.push({
      pathname: `/Content/testimonial/add`,
      state: { id: record },
    });
  }

  function searchHandler(e) {
    setSearch(e.target.value);
    setNextPage(1);
    if (e.target.value) {
      getTestimonialPage(pageSize, 1, e.target.value, sortorder);
    }
  }

  function searchClick(){
    setNextPage(1);
    if (search) {
      getTestimonialPage(pageSize, 1,search, sortorder);
    }
  }

  function toggleStatusOnChange(e, record){

    let data = JSON.parse(JSON.stringify({
      _id: record._id,
      enable: e
    }));
    const formData = new FormData();
    formData.append("editForm", JSON.stringify({...data}) );
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
    .patch(`/testimonial`,formData )
    .then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        showNotification({
          type: "success",
          message: "Success",
          description: "Testimonial updated successfully",
        });
        setSearch("");
        getTestimonialPage(pageSize, 1, search,sortorder);
      } else if ( res.data.status === 400) {
        showNotification({
          type: "error",
          message: "Error",
          description:  res.data.msg,
        });
        setSearch("");
        getTestimonialPage(pageSize, 1, search,sortorder);
      }
    })
    .catch((er) => {
      console.log(er);
    });
  }

  function deleteTestimonial(record){
    setDeleteItem(record._id)
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getTestimonialPage(value, 1, search,sortorder);
    } else if (type === "nextPage") {
      setNextPage(value);
      getTestimonialPage(pageSize, value, search,sortorder);
    }
  }

  function cancelDelete() {
    setDeleteItem("")
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  function deleteCustomer() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .delete(`testimonial/${deleteItem}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Testimonial deleted successfully",
          });
          getTestimonialPage(pageSize, nextPage, "", sortorder);
           setDeleteItem("")
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to remove testiomonial",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  function sortClick(){
    setSearch("");
    setSortActive(true)
    getTestimonialPage(50, 1, "",sortorder);
  }

  function sortCancelClick(e){
    setSortActive(false)
    getTestimonialPage(pageSize, nextPage, "",sortorder);
  }

  function sortSaveClick(e){
    const sortData = sortTestimonialDetail.map((data,index) => {return {_id:data._id, sort:index+1}})
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
    .patch(`/testimonial/sort`, {data:sortData} )
    .then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        setSortActive(false)
        showNotification({
          type: "success",
          message: "Success",
          description: "Testimonial updated successfully",
        });
        setSortorder("")
        getTestimonialPage(pageSize, 1, search, sortorder);
      }
    })
    .catch((e) => {
      console.log(e)
     })
  }
  
  function getTestimonialPage(
    pageSize,
    nextPage,
    search,
    sort
  ) {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get(`/testimonial?pagesize=${pageSize}&nextpage=${nextPage}&search=${search}&sort=${sort}`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          res?.data?.data.forEach((item,indx) => {
            item["key"] = indx + 1;
          });
          setTestimonialDetail(res?.data?.data);
          let sortedData = [];
          res?.data?.data.forEach((item, index) => {
            if (item.enable === true) {
              sortedData.push(item);
            }
          });
          setSortTestimonialDetail(sortedData)
          setPagination(res.data.pagination);
        } else {
          setTestimonialDetail([]);
          setPagination({});
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {});
  }

  function handleTableChange(pagination, filters, sorter, extra) {
    let sortValue = sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : sorter.order == undefined && null;
    setSortorder(sortValue);
    getTestimonialPage(pageSize, nextPage, search, sortValue);
  }

  return (
    <div className={`${commonstyles.management_contents} pl-0 pr-0`}>
      <div className={commonstyles.management_header}>
        <div style={{width:"100%", display:"contents"}}
          className={` ${commonstyles.management_contents_upload} ${commonstyles.customer_add_btn} `  }
        >
          <Link to="/Content/testimonial/add">
            <span style={{ cursor: "pointer" }}>
              <img src={Plus} alt="" />
              Add Testimonial
            </span>
          </Link>
          {!sortActive ? <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              attributes={{
                   style: {justifyContent: "flex-end"},
                    onClick: sortClick,
              }}
            >
            Sort
          </ButtonComponent>
          :
          <div>
          <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              attributes={{
              style: { justifyContent: "flex-end" },
                 onClick: sortCancelClick,
                }}
             >
            Cancel
          </ButtonComponent>
           <ButtonComponent
           className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
           attributes={{
            style: {backgroundColor:"#A39486", color:"#fff" ,justifyContent: "flex-end"},
             onClick: sortSaveClick
           }}
         >
           Save
        </ButtonComponent>
       </div>
         }
        </div>
      </div>
      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale} ${sortActive && commonstyles.read_only_container}`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by Name,City...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: (e) => searchHandler(e),
              ["aria-label"]: "Search customers",
              ["aria-describedby"]: "search-customers",
            }}
          />
          <img src={Search} alt="" />
        </div>
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
      </div>
      <div className={`${commonstyles.management_table} table-responsive`}>
        { IsLoading ? 
          <div
            style={{
              marginLeft: "50vh",
              marginTop: "10vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching testimonial page details..." />
              </td>
            </tr>
          </div>
            :<div className={`${sortActive && commonstyles.hide_sort_time_edit}`}>
             {!sortActive ?
              <AntTableComponent
              className={`order_table ${commonstyles.hide_sort}` }
                tableProps={{
                  columns: columns,
                  dataSource: testimonialDetail,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
             :
             <DndContext onDragEnd={onDragEnd}>
             <SortableContext
               rowKey array
               items={sortTestimonialDetail.map((i) => i.key)}
               strategy={verticalListSortingStrategy}
             >
           <Table
             components={{
               body: {
                 row: Row,
               },
             }}
             rowKey="key"
             columns={columns}
             dataSource={sortTestimonialDetail}
             pagination={false}
           />
           </SortableContext>
           </DndContext>
        }</div>}
      </div>
      {!IsLoading &&
      Array.isArray(testimonialDetail) && !sortActive && 
      testimonialDetail.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        title="Confirm the action"
        confirmText="Delete"
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
      >
        <div className="container-fluid">
          <div className="row">
            <p
              className="theme-txt-detail"
              style={{ textTransform: "capitalize" }}
            >
             Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  )
}

export default TestimonialPage