import LayoutComponent from "../modules/LayoutComponent/LayoutComponent";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import CategoryManagementComponent from "./CatalogManagement/CategoryManagement/CategoryManagementComponent";
import NewCategoryManagementComponent from "./CatalogManagement/CategoryManagement/NewCategoryList";
import ProductManagementComponent from "./CatalogManagement/ProductManagement/ProductManagementComponent";
import CreateDecorComponent from "./CatalogManagement/DecorManagement/CreateDecorComponent";
import ManageColor from "./CatalogManagement/ProductAttributes/ManageColor";

import UserManagementComponent from "./UserManagement/UserManagement";
import UserManagementComponentu from "./UserManagement/SiteSetting";
import SiteSettingComponent from "./SiteSettings/SiteSettingComponent";
import AddUserComponent from "./UserManagement/AddUser";
import AddDiscountComponent from "./DiscountManagement/AddDiscount";
import AddBannerDiscountComponent from "./DiscountManagement/AddBannerDiscount";
import EditDiscountComponent from "./DiscountManagement/EditDiscountNew";

import EditUserComponent from "./UserManagement/EditUser";
import CustomerManagementComponent from "./CustomerManagement/CustomerManagementComponent";
import MapLocator from "./MapLocator/MapLocator";
import DiscountManagementComponent from "./DiscountManagement/DiscountManagementComponent";
import OrderManagementComponent from "./Order Management/OrderManagement";
import SmsTemplateList from "./Marketing/Communications/SmsTemplateList";
import DocumentManagement from "./Order Management/Dcuments/DocumentManagement";
import WareHouseManagementComponent from "./WareHouseManagement/WareHouseManagement";
import OrderManagementDetails from "./Order Management/OrderDetails";
import OrderManagementComplete from "./Order Management/OrderDetailsComplete";
import styles from "../../styles/Dashboard.module.css"; // as Admin_management
import FAQManagementComponent from "./ServiceManagement/FAQManagement";
import TCManagementComponent from "./ServiceManagement/TCManagement";
import ContactUsManagementComponent from "./ServiceManagement/ContactUsManagement";
import BannerManagementComponent from "./BannerManagement/BannerManagement";
import CMSManagementComponent from "./CMSManagement/CMSManagementNew";
import ContentManagementComponent from "./CMSManagement/ContentManagement";
import BannerManagement from "./CMSManagement/BannerManagement";
import DeliveryManagementComponent from "./DeliveryAgentManagement/DeliveryManagementComponent";
import AddDeliveryComponent from "./DeliveryAgentManagement/AddDeliveryComponent";
import { isAuthenticated } from "../../utils";

import ProfileDisplayComponent from "../pages/ProfileManagement/ProfileDisplay";
import EditProfileComponent from "../pages/ProfileManagement/UserProfileManagement";

import DashBoardComponent from "../pages/DashBoardManagement/DashManagementComponent";

import DecorManagementComponent from "./CatalogManagement/DecorManagement/NewDecorList";
import AddDecorComponent from "./CatalogManagement/DecorManagement/CreateDecorComponent";
import TaxManagementComponent from "./Order Management/Tax Management/TaxList.js";
import AddAdditionalTaxManagement from "./Order Management/Tax Management/AddAdditonalTax";
import AddTaxManagement from "./Order Management/Tax Management/AddTax";
import EditTaxManagement from "./Order Management/Tax Management/EditTax";
import TransactionManagementComponent from "./Order Management/Transaction Management/TransactionList";
import RefundManagementComponent from "./Order Management/Refund Management/RefundList";
import RefundDetailComponent from "./Order Management/Refund Management/RefundDetails";
import id from "date-fns/locale/id";
import EditProductComponent from "./CatalogManagement/ProductManagement/EditProductComponent";
import EditSetProductComponent from "./CatalogManagement/ProductManagement/EditSetProductComponent";
import OrderDetails from "./Order Management/OrderDetails";
import AddProductComponent from "./CatalogManagement/ProductManagement/AddProductComponent";
import EditCustomer from "../pages/CustomerManagement/EditCustomerComponent";
import CustomerDetails from "../pages/CustomerManagement/CustomerDetails";
import EditCategoryComponent from "./CatalogManagement/CategoryManagement/EditCategoryComponent";
import AddFAQComponent from "./ServiceManagement/AddFAQComponent";
import AddTCComponent from "./ServiceManagement/AddTCComponent";
import AddContactUsComponent from "./ServiceManagement/AddContactUsComponent";
import AddBannerComponent from "./BannerManagement/AddBannerComponent";
import AnalyticsManagement from "./AnalyticsManagement/AnalyticsList";
import ReportManagement from "./ReportManagement/ReportList";
import Modular from "./Modular/Modular";
import WareHouseMangement from "./StoreManagement/WarehouseManagement";
import AddWareHouse from "./StoreManagement/AddWarehouse";
import EditWareHouse from "./StoreManagement/EditWarehouse";
import AddCustomer from "./CustomerManagement/AddCustomer";
import CancelOrderManagement from "./CancelledOrders/CancelOrderManagement";
import RatingAndReviews from "./Marketing/RatingsReviews/RatingAndReviews";
import CommunicationManagement from "./Marketing/Communications/CommunicationManagement";
import AdandonedDashboard from "./Marketing/Abandoned/AbandonedDashboard";
import AdandonedCheckoutDetails from "./Marketing/Abandoned/AdandonedCheckoutDetails";
import EmailLogs from "./Marketing/Communications/EmailLogs";
import SmsLogs from "./Marketing/Communications/SmsLogs";
import NewsLetterTemp from "./Marketing/Communications/NewLetterTemplate";
import NewLetterSubs from "./Marketing/Communications/NewLetterSubs";
import AddEmailer from "./Marketing/Communications/AddEmailer";
import AddTemplate from "./Marketing/Communications/AddTemplate";
import EditSmsTemplate from "./Marketing/Communications/EditSmsTemplate";
import RatingAndReviewsAdd from "./Marketing/RatingsReviews/AddRatings";
import RatingsAndReviewsEdit from "./Marketing/RatingsReviews/ReviewDetailsList";
import EditOneRaview from "./Marketing/RatingsReviews/EditOneReview";
import DeliveryManagementList from "./DeliveryManagement/DeliveryManagementList";
import ViewDeliveryManagement from "./DeliveryManagement/ViewDeliveryManagement";
import EditDeliveryManagement from "./DeliveryManagement/EditDelivery";
import AddDelivery from "./DeliveryManagement/AddDelivery";
import BannerComponent from "./BannerManagement/BannerNew";
import LandingComponent from "./CMSManagement/LandingComponent";
import AddPromotionalPage from "./CMSManagement/AddPromotionalPage";
import AddTestimonialPage from './CMSManagement/AddTestimonialPage';
import HotDealLandingPage from "./CMSManagement/HotDealLandingPage";
import LandingCategoryComponentList from "./CMSManagement/AddCategoryWiseLanding";
import LandingProductListComponent from "./CMSManagement/ProductWiseLandingPage/ProductWiseUpdate";
import LandingCategoryComponentUpdate from "./CMSManagement/UpdateLandingCategoryComponent";
import LandingCategoryComponentAdd from "./CMSManagement/AddCategoryWiseLanding";
import LandingStoreComponentAdd from "./CMSManagement/AddStoreWiseLanding";
import LandingProductComponentAdd from "./CMSManagement/ProductWiseLandingPage/ProductWiseUpdate";
import DashboardProfile from "./ProfileManagement/UserProfileManagement";
import SalesReportsHome from "./ReportManagement/SalesReports/SalesReportsHome";
import CustomerAnalyticsHome from "./ReportManagement/CustomerAnalytics/CustomerAnalyticsHome";
import ProductReportsHome from "./ReportManagement/ProductReports/ProductReportsHome";
import AddCMS from "./CMSManagement/AddCMS";

export default function PrivateRoutes() {
  const { path } = useRouteMatch();

  if (isAuthenticated()) {
    return (
      <section className={`${styles.admin_management}`}>
        <LayoutComponent>
          <Switch>
            <Route exact path={`/rating/:id/add`}>
              <RatingAndReviewsAdd />
            </Route>
            <Route exact path={`/rating/:id/:indx`}>
              <EditOneRaview />
            </Route>
            <Route exact path={`/rating/:id`}>
              <RatingsAndReviewsEdit />
            </Route>
            <Route exact path={`/rating`}>
              <RatingAndReviews />
            </Route>
            <Route exact path={`/communications/emailogs`}>
              <EmailLogs />
            </Route>
            <Route exact path={`/communications/newstemplate`}>
              <NewsLetterTemp />
            </Route>
            <Route exact path={`/communications/newssubs`}>
              <NewLetterSubs />
            </Route>
            <Route exact path={`/communications/smstemplates`}>
              <SmsTemplateList />
            </Route>
            <Route exact path={`/communications/addemailer`}>
              <AddEmailer />
            </Route>
            <Route exact path={`/communications/addtemplate`}>
              <AddTemplate />
            </Route>
            <Route exact path={`/communications/editsms`}>
              <EditSmsTemplate />
            </Route>
            <Route path={`/communications`}>
              <CommunicationManagement />
            </Route>
            <Route exact path={`/abandonedDashboard/AdandonedCheckoutDetails`}>
              <AdandonedCheckoutDetails />
            </Route>
            <Route path={`/abandonedDashboard`}>
              <AdandonedDashboard />
            </Route>
            <Route path={`/smslogs`}>
              <SmsLogs />
            </Route>
            <Route exact path="/dashboard">
              <DashBoardComponent />
            </Route>
            <Route exact path={`/catalog/products/edit/:_id`}>
              <EditProductComponent />
            </Route>
            <Route exact path={`/catalog/setproducts/edit/:_id`}>
              <EditSetProductComponent />
            </Route>
            <Route path={`/catalog/products`}>
              <ProductManagementComponent />
            </Route>
            <Route exact path={`/catalog/category/:id`}>
              <EditCategoryComponent />
            </Route>
            <Route path={`/catalog/category`}>
              <CategoryManagementComponent />
            </Route>

            <Route path={`/catalog/decors/add`}>
              <CreateDecorComponent />
            </Route>
            <Route path={`/catalog/decors/:id`}>
              <CreateDecorComponent />
            </Route>
            <Route path={`/catalog/decors`}>
              <DecorManagementComponent />
            </Route>

            <Route path={`/catalog/manageColor`}>
              <ManageColor />
            </Route>
            <Route path={`/customer/add`}>
              <AddCustomer />
            </Route>
            <Route exact path={`/customer/:id`}>
              <EditCustomer />
            </Route>
            <Route exact path={`/customer/customerDetails/:id`}>
              <CustomerDetails />
            </Route>

            <Route path={`/customer`}>
              <CustomerManagementComponent />
            </Route>

            <Route path={`/maplocator`}>
              <MapLocator />
            </Route>

            <Route path={`/analytics`}>
              <AnalyticsManagement />
            </Route>
            <Route exact path={`/site-settings/profile`}>
              <ProfileDisplayComponent />
            </Route>
            <Route path={`/site-settings`}>
              <SiteSettingComponent />
            </Route>
            <Route path={`/discount/add`}>
              <AddDiscountComponent />
            </Route>
            <Route path={`/discount/addbanner`}>
              <AddBannerDiscountComponent />
            </Route>
            <Route exact path={`/discount/:id`}>
              <EditDiscountComponent />
            </Route>

            <Route path={`/discount`}>
              <DiscountManagementComponent />
            </Route>
            <Route exact path={`/order/:id`}>
              <OrderDetails />
            </Route>
            <Route path={`/order`}>
              <OrderManagementComponent />
            </Route>
            <Route path={`/Documents`}>
              <DocumentManagement />
            </Route>

            {/* warehouse management */}
            <Route path={`/catalog/warehouse`}>
              <WareHouseManagementComponent />
            </Route>
            <Route path={`/warehouse/add`}>
              <AddWareHouse />
            </Route>
            <Route exact path={`/warehouse/edit/:id`}>
              <EditWareHouse />
            </Route>
            <Route path={`/FAQ`}>
              <FAQManagementComponent />
            </Route>
            <Route path={`/TC/addTC`}>
              <AddTCComponent />
            </Route>
            <Route exact path={`/TC/editTC`}>
              <AddTCComponent />
            </Route>
            <Route path={`/TC`}>
              <TCManagementComponent />
            </Route>
            {/* Contact us */}
            <Route path={`/contactus/add`}>
              <AddContactUsComponent />
            </Route>
            <Route exact path={`/contactus/edit`}>
              <AddContactUsComponent />
            </Route>
            <Route path={`/contactus`}>
              <ContactUsManagementComponent />
            </Route>
            {/* Banner Management */}
            <Route path={`/banner/add`}>
              <AddBannerComponent />
            </Route>
            <Route exact path={`/banner/edit`}>
              <AddBannerComponent />
            </Route>
            <Route path={`/banner`}>
              <BannerManagementComponent />
            </Route>
            {/* Banner new */}
            <Route path={`/bannerNew`}>
              <BannerComponent />
            </Route>

            <Route path={`/catalog/store/add`}>
              <AddWareHouse />
            </Route>
            <Route exact path={`/catalog/store/edit/:id`}>
              <EditWareHouse />
            </Route>

            {/* store Management */}

            <Route path={`/catalog/store`}>
              <WareHouseMangement />
            </Route>
            <Route path={`/Content/addCMS`}>
              <AddCMS />
            </Route>
            <Route exact path={`/Content/CMS`}>
              <CMSManagementComponent />
            </Route>
            <Route exact path={`/Content/addBanner`}>
              <BannerManagement />
            </Route>
            <Route exact path={`/Content/landingCategory/add`}>
              <LandingCategoryComponentAdd />
            </Route>
            <Route exact path={`/Content/landingCategory/:id`}>
              <LandingCategoryComponentAdd />
            </Route>
            <Route exact path={`/Content/landingStore/add`}>
              <LandingStoreComponentAdd />
            </Route>
            <Route exact path={`/Content/landingStore/:id`}>
              <LandingStoreComponentAdd />
            </Route>
            <Route exact path={`/Content/landingProduct/add`}>
              <LandingProductComponentAdd />
            </Route>
            <Route exact path={`/Content/landingProduct/:id`}>
              <LandingProductComponentAdd />
            </Route>
            <Route exact path={`/Content/hotDeal`}>
              <HotDealLandingPage />
            </Route>
            <Route exact path={`/Content/FAQ/addFAQ`}>
              <AddFAQComponent />
            </Route>
            <Route exact path={`/Content/FAQ/editFAQ`}>
              <AddFAQComponent />
            </Route>
            <Route exact path={`/Content/promotional/add`}>
              <AddPromotionalPage />
            </Route>
            {/* testimonial */}
            <Route exact path={`/Content/testimonial/add`}>
              <AddTestimonialPage />
            </Route>
            <Route path={`/Content`}>
              <ContentManagementComponent />
            </Route>
            {/* <Route path={`/Category`}>
              <ContentManagementComponent />
              <CategoryWiseLandingPage />
            </Route> */}
            {/* Reports */}
            <Route exact path={`/reports/salesreports`}>
              <SalesReportsHome />
            </Route>
            <Route exact path={`/reports/CustomerAnalytics`}>
              <CustomerAnalyticsHome />
            </Route>
            <Route exact path={`/reports/ProductReports`}>
              <ProductReportsHome />
            </Route>
            <Route path={`/reports`}>
              <ReportManagement />
            </Route>
            <Route path={`/modular`}>
              <Modular />
            </Route>
            <Route path={`/transactions`}>
              <TransactionManagementComponent />
            </Route>
            {/* Catalog Delivery*/}
            <Route path={`/catalog/deliveryList/viewDelivery/:id`}>
              <ViewDeliveryManagement />
            </Route>
            <Route path={`/editDelivery/:id`}>
              <EditDeliveryManagement />
            </Route>
            {/* Delivery Agent Management */}
            <Route exact path={`/catalog/deliveryList/delivery/add`}>
              <AddDeliveryComponent />
            </Route>
            <Route exact path={`/catalog/deliveryList/delivery/edit`}>
              <AddDeliveryComponent />
            </Route>
            <Route exact path={`/catalog/deliveryList/delivery`}>
              <DeliveryManagementComponent />
            </Route>
            <Route path={`/catalog/deliveryList/add`}>
              <AddDelivery />
            </Route>
            <Route path={`/catalog/deliveryList`}>
              <DeliveryManagementList />
            </Route>
            <Route path={`/cancelledorders`}>
              <CancelOrderManagement />
            </Route>
            <Route path={`/tax/add`}>
              <AddTaxManagement />
            </Route>
            <Route path={`/tax/additionaltax`}>
              <AddAdditionalTaxManagement />
            </Route>
            <Route exact path={`/tax/:id`}>
              <EditTaxManagement />
            </Route>
            <Route path={`/tax`}>
              <TaxManagementComponent />
            </Route>
            <Route exact path={`/refund/:id`}>
              <RefundDetailComponent />
            </Route>
            <Route path={`/refund`}>
              <RefundManagementComponent />
            </Route>

            <Route path={`/orderDetails`}>
              <OrderManagementDetails />
            </Route>
            <Route path={`/orderComplete`}>
              <OrderManagementComplete />
            </Route>
            <Route path={`/orderComplete`}>
              <OrderManagementComplete />
            </Route>
            <Route path={`/editprofile`}>
              <EditProfileComponent />
            </Route>

            <Route path={`/landingCategory`}>
              <LandingCategoryComponentList />
            </Route>

            <Route path={`/landingProduct`}>
              <LandingProductListComponent />
            </Route>
            <Route exact path={`/landing`}>
              <LandingComponent />
            </Route>
            {/* Dashboard Sitesetting profile */}
            <Route path={`/dashboard/profile`}>
              <DashboardProfile />
            </Route>
          </Switch>
        </LayoutComponent>
      </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
