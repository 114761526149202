import { useEffect, useState } from "react";
import { notification, Select } from "antd";
import blackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import markerIcon from "../../../assets/images/map_circle.svg";
import commonstyles from "../../../styles/Management_Common.module.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import ImageMarker from "react-image-marker";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import CloseIconSmall from "../../../assets/images/Close.svg";
import MinimizeIcon from "../../../assets/images/Minimize.svg";
import IndiaMap from "../../../assets/images/india_map.png";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import validationRules from "../../../utils/validationRules";
import RelatedDecorSelectFormComponent from "../../modules/ProductManagement/RelatedDecorSelectFormComponent";
import RegionSearchSelectComponent from "../../modules/ProductManagement/RegionSearchSelectComponent";
import CategorySearchSelectComponent from "../../modules/ProductManagement/CategorySearchSelectComponent";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import {
    formatServerValidtionErrors,
    runValidationChecks,
} from "../../../utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import React from "react";
import {
    alphanumericwithdashHyphen,
    alphanumericwithdashHyphenWithLimit,
    minChar,
} from "../../../utils/RegularExp";

export default function MapLocator(props) {
    function initialState() {
        return {
            data: [],
        };
    }
    const initialFilterState = { search: "", pagesize: 10, nextpage: 1 };
    const [filter, setFilter] = useState(initialFilterState);
    const [newDecor, setnewDecor] = useState(initialState());
    const [regions, setRegions] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [regionId, setproductId] = useState([]);
    const [currentMarker, setcurrentMarker] = useState([]);
    const [files, setFiles] = useState({
        image: null,
        icon: null,
        menuImage: null,
    });
    const [filePreview, setFilePreview] = useState({
        image: null,
        icon: null,
        menuImage: null,
    });
    const [formErrors, setFormErrors] = useState({});
    const rules = {
        files: [(val, data) => (!files.image ? "Required" : true)],
    };
    const [displayStatus, setDisplayStatus] = useState(false);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { state } = history.location;
    let { id } = useParams();

    useEffect(() => {
        const { state } = history.location;
        if (state?._id || id) {
            // getParentProducts();
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, []);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        getRegionList();
        getAllstoreMap();
    }, []);

    function getAllstoreMap() {
        AxiosInstance(PORTS.INVOICE, "application/json")
            .get(`/admin/getAllstoreMap`)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    let allData = [];
                    data?.forEach(item => {
                        item?.map?.forEach(subItem => {
                            allData.push(subItem);
                        })
                    })
                    let regionId = [];
                    let mar_arr = [];
                    let current_arr = [];
                    allData.map((prd, index) => {
                        let val = Number(prd.top) + "," + Number(prd.left);
                        regionId[val] = prd.regionId;
                        let obj = {};
                        let obj1 = {};
                        obj.top = prd.top;
                        obj.left = prd.left;
                        mar_arr.push(obj);
                        obj1.position = prd.top + "," + prd.left;
                        obj1.regionId = prd.regionId;
                        current_arr.push(obj1);
                    });
                    // console.log(mar_arr);
                    setMarkers(mar_arr);
                    setcurrentMarker(current_arr);
                    setproductId(regionId);
                }
            })
            .catch((er) => {
                console.log(er);
            });
    }

    function getRegionList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("/admin/region/getRegionList")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    setProducts(res.data.daaata);
                }
            })
            .catch((er) => {
                console.log(er);
            });
    }

    function AddMarkers(marker) {
        setMarkers([...markers, marker]);
    }

    /**
    * @author Rajkumar
    * @param {}
    * CONVERTING ALL JSON OBJECTS STRINGS
    */
    function createRequestData() {
        const data = JSON.parse(JSON.stringify(newDecor));
        if (state?._id || id) {
            data._id = state?._id || id;
        }
        let arr = [];
        const unique = [
            ...new Map(
                currentMarker.map((item) => [item["position"], item])
            ).values(),
        ];
        markers.forEach((value, key) => {
            unique.forEach((cvalue, ckey) => {
                let datas = cvalue.position.split(",");
                if (
                    String(value["top"]) === datas[0] &&
                    String(value["left"]) === datas[1]
                ) {
                    arr.push({
                        regionId: cvalue.regionId,
                        top: Number(value["top"]),
                        left: Number(value["left"]),
                    });
                }
            });
        });
        data.data = arr;
        return data;
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }


    /**
    * @author Rajkumar
    * @param {}
    * SUBMIT WHOLE DATA INTO API
    */
    function submit() {
        setDisplayStatus(true);
        setFormErrors({});
        const data = createRequestData();

        if (data.data.length === 0) {
            setDisplayStatus(false);
            showNotification({
                type: "warning",
                message: "No warehouse selected",
                description: "Please select warehouse by clicking image",
            });
            return;
        }
        AxiosInstance(PORTS.INVOICE).post(`/admin/addStoreMap`, data)
            .then((res) => {
                // console.log(res.data.msg);
                if (res.data.status === 200) {
                    setDisplayStatus(false);
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: res.data.msg
                    });
                    getAllstoreMap();
                } else if (res.data.status === 400) {
                    // const errors = formatServerValidtionErrors(res.data.error);
                    // setFormErrors(errors);
                    showNotification({
                        type: "error",
                        message: "Adding decor failed",
                        description: res.data?.msg || "Error occured",
                    });
                }
                setDisplayStatus(false);
            })
            .catch((er) => {
                setDisplayStatus(false);
                showNotification({
                    type: "error",
                    message: "Adding decor failed",
                    description: "Error occured",
                });
            });
    }

    const collapse = (id) => {
        document.getElementById(`viewProduct_${id}`).style = "display:block";
        document.getElementById(`hideProduct_${id}`).style = "display:none";
    };

    const expand = (id) => {
        document.getElementById(`viewProduct_${id}`).style = "display:none";
        document.getElementById(`hideProduct_${id}`).style = "display:block";
    };

    const remove = (id) => {
        let data = id.split(",");
        // console.log(data);
        // console.log(id);
        let markerarr = markers;
        markerarr = markerarr.filter((val) => !((parseFloat(val.top) === parseFloat(data[0])) && (parseFloat(val.left) === parseFloat(data[1]))));
        // console.log(markerarr);
        setMarkers(markerarr);
    };

    function handleParentIdUpdate(val, id) {
        regionId[id] = val;
        setproductId(regionId);
        const list = [...currentMarker];
        list[id] = val;
        //setMarkers([...markers, {[id]:value}])
        setcurrentMarker([...currentMarker, { position: id, regionId: val }]);
    }

    /**
   * @author Rajkumar
   * @param {action, decor, formField}
   * UPDATE SELECTED PRODUCTS FOR PARTICULAR DECOR
   */
    const CustomMarker = (props) => {
        let data = props;
        return (
            <>
                <div
                    className={`${commonstyles.management_details_form}`}
                    id={`hideProduct_${data.top + "," + data.left}`}
                    style={{ width: "120%" }}
                >
                    <div className={`${commonstyles.popup_justify}`}>
                        <div>
                            <label htmlFor="" className={commonstyles.secondry_title}>
                                Choose warehouse
                            </label>
                        </div>
                        <div>
                            <img
                                src={MinimizeIcon}
                                className={`${commonstyles.close_icon} mr-1 cursor-pointer`}
                                alt="CloseIcon"
                                title="Minimize"
                                onClick={() => {
                                    collapse(data.top + "," + data.left);
                                }}
                            />
                            <img
                                src={CloseIconSmall}
                                className={`${commonstyles.close_icon} cursor-pointer`}
                                alt="CloseIcon"
                                title="Remove"
                                onClick={() => {
                                    remove(data.top + "," + data.left);
                                }}
                            />
                        </div>
                    </div>
                    <RegionSearchSelectComponent
                        type="decor"
                        isLoading={isLoading}
                        products={products}
                        error={formErrors.parentId}
                        value={regionId[data.top + "," + data.left] || ""}
                        id={props.top ? props.top + "," + props.left : ""}
                        handleParentIdUpdate={handleParentIdUpdate}
                    />
                    <FormErrorText error={formErrors.parentId} />
                </div>
                <div
                    className={` col-lg-12 ${commonstyles.dispNone}`}
                    id={`viewProduct_${data.top + "," + data.left}`}
                >
                    <img
                        src={markerIcon}
                        alt="test"
                        onClick={() => {
                            expand(data.top + "," + data.left);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div className={commonstyles.management_contents}>
            <div className={commonstyles.management_header}>
                <h1 className={commonstyles.management_header_heading}>
                    <img
                        src={blackLeftArrow}
                        onClick={() => history.push(`/dashboard`)}
                        className="cursor-pointer"
                        title="Back"
                        alt="Backactionimage"
                    />
                    Map Locator/Store
                </h1>
                <div>
                    <ButtonComponent
                        className="rnd mr-3"
                        attributes={{
                            // onClick: props.handleClose,
                            onClick: () => history.push(`/dashboard`),
                        }}
                    >
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent
                        className="rnd pl-3 pr-4"
                        attributes={{
                            onClick: submit,
                            disabled: displayStatus,
                        }}
                    >
                        {state?._id || id ? "Edit" : "Save"}
                    </ButtonComponent>
                </div>
            </div>
            {isLoading === true ? (
                <div
                    style={{
                        marginLeft: "70vh",
                        marginTop: "40vh",
                    }}
                >
                    <tr className="bg-transparent">
                        <td colSpan="100%">
                            <LoadingIndicator loadingText="Fetching Map details..." />
                        </td>
                    </tr>
                </div>
            ) : (
                <React.Fragment>
                    <div className={commonstyles.management_details}>
                        <div className={commonstyles.management_details_contents}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{ width: "70%", left: "150px" }} className={commonstyles.decor_banner_img}>
                                        <ImageMarker
                                            src={IndiaMap}
                                            className={commonstyles.imagemarkerWidth}
                                            markers={markers}
                                            onAddMarker={(marker) => AddMarkers(marker)}
                                            markerComponent={CustomMarker}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}