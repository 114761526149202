// import styles from "../../styles/ProductManagement.module.css";
import React, { useEffect, useState, useRef, createRef } from "react";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Switch,
  Tooltip
} from "antd";
import moment from "moment";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import { Link } from "react-router-dom";
import AddProductComponent from "./AddProductComponent";
import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
import EditProductComponent from "./EditProductComponent";
import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import styles from "../../../../styles/CustomerManagement.module.css";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../../utils";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import commonstyles from "../../../../styles/Management_Common.module.css";
import commonstyle from "../../../../styles/ConfirmationBox.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import plusIcon from "../../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../../assets/images/trash.svg";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import excel from "../../../../assets/images/excel.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationProduct";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import dots from "../../../../assets/images/column_dots.svg";
import DefaultImage from "../../../../assets/images/profile_dummy.svg";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import SearchComponents from "../../SearchManagement/SearchComponent";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { upperCase } from "lodash";
import { Tabs } from "antd";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import SetProductManagement from "./SetProductManagement";
import RupeesLogo from "../../../../../src/Indian_Rupee.svg"
import NoImage from "../../../../../src/assets/NoImage.svg"

const updatedFilter = {
  pagesize: 10,
  nextpage: 1,
};

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Product ID", value: "Productid" },
  { _id: 3, status: 1, name: "SKU", value: "sku" },
  { _id: 4, status: 1, name: "Image", value: "image" },
  { _id: 5, status: 1, name: "Product Name", value: "productname" },
  { _id: 6, status: 1, name: "MRP", value: "mrp" },
  { _id: 7, status: 1, name: "Selling Price", value: "sellingprice" },
  { _id: 8, status: 1, name: "Stock Qty", value: "stock" },
  { _id: 9, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 10, status: 1, name: "Action", value: "Action" },
];

const original_columns = [
  "Product ID",
  "SKU",
  "Image",
  "Product Name",
  "MRP",
  "Selling Price",
  "Stock Qty",
  "enable",
  "Action",
];

const column_value = {
  Productid: true,
  sku: true,
  image: true,
  productname: true,
  mrp: true,
  sellingprice: true,
  stock: true,
  enable: true,
  Action: true,
};

const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
  // { id: 3, isChecked: false, name: "Status", value: "Status" },
  { id: 4, isChecked: false, name: "Category", value: "categoryId" },
  { id: 5, isChecked: false, name: "Stock", value: "Stock" },
];

const setProductFilters = [
  { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
  // { id: 3, isChecked: false, name: "Status", value: "Status" },
  { id: 4, isChecked: false, name: "Category", value: "categoryId" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: 1 },
  { _id: 2, isChecked: false, name: "Disable", value: 2 },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function ProductManagementComponent() {
const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [parentCategories, setParentCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const [originalColumn, setOriginalColumn] = useState(original_columns);
  const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  // const [filter, setFilter] = useState(intialFilterState);
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });
  const [visible, setVisible] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [regionId, setRegionId] = useState("");
  const [statuus, setStatuus] = useState("");
  const [IsLoading, setIsLoading] = useState({ delete: false, list: true });
  const [selectedProduct, setSelectedProduct] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [categoryName, setCategoryName] = useState("All Categories");
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [startStock, setStartStock] = useState("");
  const [endStock, setEndStock] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const { TabPane } = Tabs;
  const [filteredResult, setFilteredResult] = useState("");
  const [sortQuantity, setSortQuantity] = useState("");
  const [Status, setStatus] = useState([
    { _id: 1, name: "Enable", value: true },
    { _id: 2, name: "Disable", value: false },
  ]);
  const [sortOrder, setSortorder] = useState(null);

  const [skuOrder, setSortSku] = useState();
  const [productNameOrder, setSortProductName] = useState();
  const [mrpOrder, setSortMrp] = useState();
  const [sellingPriceOrder,setSortSellingPrice] = useState();
  const [actionsSort,setactionsSort] = useState();

  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [draftKey, setDraftKey] = useState(false);
  const [filterDays, setFilterDays] = useState("");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [regionName, setRegionName] = useState("All Region");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [tabSelected, setTabSelected] = useState("1");
  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const normalProductColumn = [
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Product ID",
      visible: false,
      key: "Productid",
      render: (text, record, index) => (
        <td>{record.tallyId ? record.tallyId : "-"}</td>
      ),
      sorter: true,
    },
    {
      title: "SKU",
      visible: false,
      key: "sku",
      sorter:true,
      render: (text, record, index) => <td><Tooltip
      placement="topLeft"
      title={record.sku ? record.sku : "-"}
      // className="tooltip_list"
    >{record.sku ? record.sku : "-"}</Tooltip></td>,
    },
    {
      title: "Image",
      visible: false,
      key: "image",
      render: (text, record, index) => (
        <td>
          {record.image ? (
            <img
              src={record.image}
              className={commonstyles.admin_table_img}
              alt="Product image"
              title={record.name ? record.name : "-"}
            />
          ) : (
            <img
              src={NoImage}
              className={commonstyles.admin_table_img}
              alt="Product image"
            />
          )}
        </td>
      ),
    },
    {
      title: "Product Name",
      visible: false,
      key: "productname",
      sorter:true,
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>
          <Tooltip
        placement="topLeft"
        title={record.name ? record.name : "-"}
        // className="tooltip_list"
      > {record.name ? record.name : "-"}</Tooltip>
        </td>
      ),
    },
    {
      title: "MRP",
      visible: false,
      key: "mrp",
      sorter:true,
      render: (text, record, index) => (
        <td >
          {record.oldPrice
            ? 
            <div>
            <img src={RupeesLogo} alt="rupees" className={`${styles.rupees_logo_sizing}`} />{record.oldPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            </div> 
            : "-"
            }
        </td>
      ),
    },
    {
      title: "Selling Price",
      visible: false,
      sorter:true,
      key: "sellingprice",
      render: (text, record, index) => (
        <td>
{record.newPrice
            ? 
            <div>
            <img src={RupeesLogo} alt="rupees" className={`${styles.rupees_logo_sizing}`}/>{record.newPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            </div> 
            : "-"
            }
        </td>
      ),
    },
    {
      title: "Stock Qty",
      visible: false,
      key: "stock",
      sorter: true,
      render: (text, record, index) => <span>{record.stocks}</span>,
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      sorter: true,
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },

    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Products-edit") ===
        "Catalog-Products-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit product"
            // className="tooltip_list"
            >
              <Link to={`/catalog/products/edit/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                  // onClick={() => editProduct(product)}
                  alt="Edit product action icon"
                  action="Edit product"
                // title="Edit product"
                />
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete product"
            >
              <img
                src={deleteIcon}
                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                onClick={() => showdelete(record)}
                alt="Delete product action icon"
              // title="Delete product"
              />
            </Tooltip>
          </td>
        ),
    },
  ]
  const setProductColumn = [
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Image",
      visible: false,
      key: "image",
      render: (text, record, index) => (
        <td>
          {record.image ? (
            <img
              src={record.image}
              className={commonstyles.admin_table_img}
              alt="Product image"
              title={record.name ? record.name : "-"}
            />
          ) : (
            "-"
          )}
        </td>
      ),
    },
    {
      title: "Set Products Name",
      visible: false,
      key: "productname",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>
          {record.name ? record.name : "-"}
        </td>
      ),
    },
    {
      title: "MRP",
      visible: false,
      key: "mrp",
      render: (text, record, index) => (
        <td>

          {record.oldPrice
            ? record.oldPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
            : "-"}
        </td>
      ),
    },
    {
      title: "Selling Price",
      visible: false,
      key: "sellingprice",
      render: (text, record, index) => (
        <td>

          {record.newPrice
            ? record.newPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
            : "-"}
        </td>
      ),
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Products-edit") ===
        "Catalog-Products-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit product"
            >
              <Link to={`/catalog/setproducts/edit/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                  alt="Edit product action icon"
                  action="Edit product"
                />
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete product"
            >
              <img
                src={deleteIcon}
                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                onClick={() => showdelete(record)}
                alt="Delete product action icon"
              />
            </Tooltip>
          </td>
        ),
    }
  ]
  const [columns, setColumns] = useState(normalProductColumn);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const timeoutId = useRef();


  /**
 * @author Mithun Prabhu
 * @param {event,record}
 * To get state of checked and update each records directly woth isChecked parameter
 */
  const childCheckbox = (e, record) => {
    // console.log(record);
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("productList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("productList", JSON.stringify(cloneOrderList));
    setProductList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };


  /**
  * @author Mithun Prabhu
  * @param {event}
  * To get state of checked and update each records directly woth isChecked parameter
  */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedCustomerr");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("productList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    // console.log(cloneOrderList);
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("productList", JSON.stringify(cloneOrderList));
    setProductList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getProducts(pageSize, nextPage, "");
    localStorage.removeItem("selectedCustomerr");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    };
    return () => {
      localStorage.removeItem("productList");
      localStorage.removeItem("selectedCustomerr");
    };
  }, [tabSelected]);

  const toggleStatusOnChange = (e, record) => {
    if (localStorage.getItem("productList")) {
      let productListClone = JSON.parse(localStorage.getItem("productList"));
      productListClone.forEach((item) => {
        if (record._id === item._id) {
          item.enable = !item.enable;
          sentStatus(item);
        }
      });
      localStorage.setItem("productList", JSON.stringify(productListClone));
      setProductList(productListClone);
    }
  };

  const sentStatus = (showRoom) => {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get(`/admin/product/${showRoom._id}/${showRoom.enable}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  };

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  useState(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
          }
        })
        .catch((er) => {
          const error = {
            type: "error",
            message: "Error",
            description: "Failed to get data",
          };
          showNotification(error);
        });
    }

    function showNotification({ type, message, description }) {
      let options = {
        message: message || "Message",
        description: description,
        duration: 5,
      };
      notification[type](options);
    }

    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            let obj = { name: "All Region", value: "", _id: "" };
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        });
    }
    getParentCategories();
    getRegionList();
  }, []);

  function debounceSearch(filter) {
    getProducts(pageSize, 1, filter,true);
    setFilteredResult(filter);
  }

  function handleTableChange(pagination, filters, sorter, extra) {
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: nextPage,
      pagesize: pageSize,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      startStock: startStock,
      endStock: endStock
    };

    if (sorter?.columnKey === "sellingprice") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sellingPriceOrder = sortValue;
      setSortSellingPrice(sortValue);
      setSortQuantity();
      setSortMrp();
      setSortProductName();
      setSortSku();
      setSortorder();
      setactionsSort()
    }
    else if (sorter?.columnKey === "productname") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.productNameOrder = sortValue;
      setSortProductName(sortValue);
      setSortSellingPrice();
      setSortQuantity();
      setSortMrp();
      setSortorder();
      setSortSku();
      setactionsSort()
    } else if (sorter?.columnKey === "mrp") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.mrpOrder = sortValue;
      setSortMrp(sortValue);
      setSortSellingPrice();
      setSortorder();
      setSortProductName();
      setSortSku();
      setSortQuantity();
      setactionsSort()
    }else if (sorter?.columnKey === "sku") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.skuOrder = sortValue;
      setSortSellingPrice(sortValue);
      setSortMrp();
      setSortProductName();
      setSortSku();
      setSortorder();
      setSortQuantity();
      setactionsSort()
    }
    else if (sorter?.columnKey === "Productid") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
    }else if(sorter?.columnKey === "enable"){
      let sortValue = sorter.order === "ascend" ? 1 : 0;
      updatedFilter.actionsSort = sortValue;
      setactionsSort(sortValue)
      setSortQuantity();
      setSortMrp();
      setSortProductName();
      setSortSku();
      setSortorder();
      setSortSellingPrice();
    } else {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortQuantity = sortValue;
      setSortQuantity(sortValue);
      setSortorder();
      setSortSellingPrice();
      setSortMrp();
      setSortProductName();
      setSortSku();
      setactionsSort();
    }
    // setNextPage(1);
    getProducts(pageSize, nextPage, updatedFilter, true);
  }

  /**
  * @author Mithun Prabhu
  * @param {pageSize, nextPage, filterarr, type}
  * CALLING GET PRODUCT  RECORDS
  */
  function getProducts(pageSize, nextPage, filterarr, type) {
    // console.log(filterarr);
    if (!type) {
      setIsLoading({ ...IsLoading, list: true });
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      recordStatus: +tabSelected,
    };
    let url = "";
    if (+tabSelected === 2) {
      url = "/admin/catalog/setProductsList/";
      delete filterOptions?.recordStatus;
    } else {
      url = "/admin/catalog/productList/";
      filterOptions['recordStatus'] = +tabSelected
    }
    if (filterarr && filterarr.searchKey) {
      filterOptions.searchKey = filterarr.searchKey;
    } else if (searchKey !== "") {
      if (filterarr && filterarr.searchKey !== "") {
        filterOptions.searchKey = searchKey;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.categoryId) {
      filterOptions.categoryId = filterarr.categoryId;
    } else if (categoryId !== "") {
      if (filterarr && filterarr.categoryId !== "") {
        filterOptions.categoryId = filteredDate.categoryId;
      }
    }
    if (filterarr && filterarr.startStock !== "") {
      filterOptions.startStock = filterarr.startStock;
    } else if (startStock !== "") {
      filterOptions.startStock = startStock;
    }
    if (filterarr && filterarr.endStock !== "") {
      filterOptions.endStock = filterarr.endStock;
    } else if (endStock !== "") {
      filterOptions.endStock = endStock;
    }
    if (filterarr && filterarr?.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (sortOrder !== "") {
      if (filterarr && sortOrder !== "" && sortOrder !== null) {
        filterOptions.sortOrder = Number(sortOrder);
      }
    }
    if (filterarr && filterarr?.sortQuantity !== "") {
      filterOptions.sortQuantity = Number(filterarr.sortQuantity);
    } else if (sortQuantity !== "") {
      if (filterarr && filterarr.sortQuantity !== "") {
        filterOptions.sortQuantity = Number(sortQuantity);
      }
    }

    if (filterarr && filterarr.skuOrder !== "") {
      filterOptions.skuOrder = Number(filterarr.skuOrder);
    } else if (filteredDate.skuOrder !== "") {
      if (filterarr && filterarr.skuOrder !== "") {
        filterOptions.skuOrder = Number(
          filteredDate.skuOrder ? filteredDate.skuOrder : 0
        );
      }
    }

    if (filterarr && filterarr.productNameOrder !== "") {
      filterOptions.productNameOrder = Number(filterarr.productNameOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.productNameOrder = Number(sortOrder);
      }
    }
    if (filterarr && filterarr.mrpOrder !== "") {
      filterOptions.mrpOrder = Number(filterarr.mrpOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.mrpOrder = Number(sortOrder);
      }
    }

    if (filterarr && filterarr.sellingPriceOrder !== "") {
      filterOptions.sellingPriceOrder = Number(filterarr.sellingPriceOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.sellingPriceOrder = Number(sortOrder);
      }
    }
    if (filterarr && filterarr.actionsSort !== "") {
      filterOptions.actionsSort = Number(filterarr.actionsSort);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.actionsSort = Number(sortOrder);
      }
    }
    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    if (filterOptions?.sortQuantity === 2) delete filterOptions.sortQuantity;

    if (+tabSelected === 2) {
      if(!filterOptions.mrpOrder&&filterOptions.mrpOrder!==0) delete filterOptions.mrpOrder;
      if(!filterOptions.productNameOrder&&filterOptions.productNameOrder!==0) delete filterOptions.productNameOrder;
      if(!filterOptions.sellingPriceOrder&&filterOptions.sellingPriceOrder!==0) delete filterOptions.sellingPriceOrder;
      if(!filterOptions.skuOrder&&filterOptions.skuOrder!==0) delete filterOptions.skuOrder;
      if(!filterOptions.actionsSort&&filterOptions.actionsSort!==0) delete filterOptions.actionsSort;
    }

    setFilterOptionsGlobal(filterOptions);

    // Object.keys(filterOptions).every(key => {
    //   if (filterOptions[key] === null || filterOptions[key] === "") {
    //     delete filterOptions[key];
    //   }
    // });

    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .post(url, filterOptions)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading({ ...IsLoading, list: false });
          // console.log(res.data?.data?.products);
          if (res.data?.data?.products) {
            res.data.data.products.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem(
              "productList",
              JSON.stringify(res.data.data.products)
            );
            setProductList(res.data.data.products);
            setPagination(res.data.pagination);
            // setPagination({ ...pagination, total: reslt.totalProducts });
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get product data",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, list: false });
      });
  }

  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: 1,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      startStock: startStock,
      endStock: endStock,
      sortOrder: sortOrder,
      sellingPriceOrder:sellingPriceOrder,
      actionsSort:actionsSort,
      productNameOrder:productNameOrder,
      mrpOrder:mrpOrder,
      skuOrder:skuOrder,
      sortQuantity:sortQuantity
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    if (name === "categoryId") {
      updatedFilter.categoryId = value._id;
      setCategoryId(value._id);
      setCategoryName(value.name);
    }

    debounceSearch(updatedFilter);
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: 1,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      startStock: startStock,
      endStock: endStock,
      sortOrder: sortOrder,
      sellingPriceOrder:sellingPriceOrder,
      actionsSort:actionsSort,
      mrpOrder:mrpOrder,
      skuOrder:skuOrder,
      productNameOrder:productNameOrder,
      sortQuantity:sortQuantity
    };
    if (name === "regionId") {
      setRegionId(value);
      updatedFilter.regionId = value;
    }
    if (name === "orderStatus") {
      setStatuus(value);
      updatedFilter.status = value;
    }
    if (name === "categoryId") {
      setCategoryId(value);
      updatedFilter.categoryId = value;
    }
    if (name === "searchKey") {
      // console.log(value);
      setsearchKey(value);
      updatedFilter.searchKey = value;
    }
    if (name === "startStock") {
      // console.log(value);
      const inputtedValue = e.currentTarget.value
      if (isNaN(inputtedValue)) return
      setStartStock(+value);
      updatedFilter.startStock = +value;
    }
    if (name === "endStock") {
      const inputtedValue = e.currentTarget.value
      if (isNaN(inputtedValue)) return
      setEndStock(+value);
      updatedFilter.endStock = +value;
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value;
      setFilterDays(value);
    }
    if (value !== "custom" && name !== "searchKey") {
      const clonefilteredDate = { ...filteredDate };
      /* clonefilteredDate.startDate = "";
        clonefilteredDate.endDate = "";
        updatedFilter.startDate = "";
        updatedFilter.endDate = ""; */
      setFilteredDate(clonefilteredDate);
      debounceSearch(updatedFilter);
    }
    if (name === "searchKey" && value === "") {
      debounceSearch(updatedFilter);
    }


  }

  function searchClick() {
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: 1,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      startStock: startStock,
      endStock: endStock,
      sortOrder: sortOrder,
      sellingPriceOrder:sellingPriceOrder,
      actionsSort:actionsSort,
      mrpOrder:mrpOrder,
      skuOrder:skuOrder,
      sortQuantity:sortQuantity,
      productNameOrder:productNameOrder
    };
    debounceSearch(updatedFilter);
  }

  /**
   * @author Mithun Prabhu
   * @param {data}
   * This is for checking through different action objects and calling API for submit
   */
  const bulkStatusClickHandler = (data) => {
    // console.log(data);
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };


  /**
   * @author Mithun Prabhu
   * @param {item}
   * This is for checking through different action objects and calling API for submit
   */
  const applyBulkActionHandler = (item) => {
    // console.log(item);

    if (localStorage.getItem("selectedCustomerr")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedCustomerr"));
      if (selectedOrder.length > 0) {
        let data = {
          productIds: selectedOrder,
          status: Number(item.value),
        };
        // console.log(item.value);
        // console.log(data);
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the product below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the product below",
      });
    }
  };


  /**
   * @author Mithun Prabhu
   * @param {data}
   * API call for bulk delete and bulk update coupons.
   */
  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/product/bulkUpdateStatus", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedCustomerr");
          const cloneCatagoryList = JSON.parse(
            localStorage.getItem("productList")
          );
          cloneCatagoryList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem(
            "productList",
            JSON.stringify(cloneCatagoryList)
          );
          setProductList(cloneCatagoryList);
          getProducts(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  /**
     * @author Mithun Prabhu
     * @param {date, dateString}
     * DATE RANGE ONCHANGE FUNCTION
     */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: 1,
      regionId: regionId,
      categoryId: categoryId,
      startStock: startStock,
      endStock: endStock,
      sortOrder: sortOrder,

      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
    };
    debounceSearch(updatedFilter);
  };


  /**
  * @author Mithun Prabhu
  * @param {e, data}
  * This is common filter handler for all filters
  */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: 1,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      startStock: startStock,
      endStock: endStock,
      sortOrder: sortOrder,
    };
    // console.log(updatedFilter);
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "categoryId") {
          updatedFilter.categoryId = "";
          setCategoryId("");
          setCategoryName("All Categories");
        }
        if (item.value === "startStock" && item.value === "endStock") {
          updatedFilter.startStock = "";
          updatedFilter.endStock = "";
          setStartStock("");
          setEndStock("");
        }
        if (item.value === "date") {
          setFilterDaysName("Date");
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
        }
        // console.log(item.value);
        if (item.value === "Stock") {
          updatedFilter.startStock = 0;
          updatedFilter.endStock = 0;
          setStartStock("");
          setEndStock("");
        }
        debounceSearch(updatedFilter);
      }
    });
    // console.log((cloneSelectedFilters.find(x => (x === 'region')) === 'region'));
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };


  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="regionId"
                    value={regionId}
                    onChange={filterOnChange}
                  >
                    <option value="">All Region</option>
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <option key={region._id} value={region._id}>
                            {region.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}

            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "categoryId") ===
              "categoryId" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="categoryId"
                    style={{ cursor: "pointer" }}
                    value={categoryId}
                    onChange={filterOnChange}
                  >
                    <option value="">All Categories</option>
                    {Array.isArray(parentCategories) && parentCategories.length
                      ? parentCategories.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {categoryName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(parentCategories)
                      ? parentCategories.map((category) => (
                        <span
                          key={category._id}
                          onClick={() =>
                            filterRegionChange("categoryId", category)
                          }
                        >
                          <label>{category.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Stock") === "Stock" && (
                <div className="card_dropdown show col-md-6">
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    formControl={{
                      placeholder: "Eg, Start stock",
                      name: "startStock",
                      value: startStock,
                      onChange: filterOnChange,
                    }}
                  />
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    formControl={{
                      placeholder: "Eg, End stock",
                      name: "endStock",
                      value: endStock,
                      onChange: filterOnChange,
                    }}
                  />
                </div>
              )}

          </div>
        </div>
      </div>
    </Menu>
  );

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      searchKey: searchKey,
      nextpage: nextPage,
      pagesize: pageSize,
      regionId: regionId,
      categoryId: categoryId,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortQuantity:sortQuantity,
      sortOrder:sortOrder,
      startStock: startStock,
      endStock: endStock,
      mrpOrder:mrpOrder,
      skuOrder:skuOrder,
      actionsSort:actionsSort,
      productNameOrder:productNameOrder,
      sellingPriceOrder:sellingPriceOrder
    };

    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getProducts(value, 1, updatedFilter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getProducts(pageSize, value, updatedFilter,true);
    }
  }

  function closeEditProduct(isEdited) {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
    if (isEdited) {
      getProducts(pageSize, nextPage);
    }
  }

  function showdelete(product) {
    setSelectedProduct(product);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  }

  function cancelDelete() {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }

  function removeProduct() {
    setIsLoading({ ...IsLoading, delete: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteProduct/${selectedProduct._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getProducts(pageSize, nextPage);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete product",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete product",
        });
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, delete: false });
        setChildComponentVisibility({
          ...childComponentVisibility,
          delete: false,
        });
      });
  }

  if (childComponentVisibility.addProduct) {
    return (
      <AddProductComponent
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditProductComponent
        product={selectedProduct}
        handleClose={closeEditProduct}
      />
    );
  } else if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
        product="product"
        handleClose={() => {
          setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          });
          getProducts(pageSize, nextPage, updatedFilter);
        }}
      />
    );
  }

  /**
 * @author Mithun Prabhu
 * @param {props}
 * TO SHOW TABLE HEADERS BASED ON STATUS
 */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
    * @author Mithun Prabhu
    * @param {props}
    * TO SHOW TABLE DATA BASED ON HEADER STATUS
    */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };


  /**
  * @author Mithun Prabhu
  * @param {oldIndex, newIndex}
  * This is for rearranging array of columns items according to checking nd unchecking
  */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };


  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };


  /**
 * @author Mithun Prabhu
 * @param {e, id}
 * This is component for checking and unchecking columns in list page
 */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    // console.log("items", itemClone);
    setItems(itemClone);
    fetchNewOrder(items);
  };

  /* Export here */
  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    if(tabSelected === "2"){
      params = {...params, recordStatus:2}
    }
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.CMS, "application/json")
      .post(`admin/product/exportProducts`, params)
      .then((resp) => {
        // console.log(resp);
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
        
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlxs";
          const ws = XLSX.utils.json_to_sheet(und_res);

          /* ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v); */

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Product-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  };

  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    /* let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    }); */
  };

  /**
  * @author Mithun Prabhu
  * @param {Menu}
  * This is component for removing/adding columns with onClick event
  */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {(value.value !== "select" && ((tabSelected === "2" ? (value.name !== "Product ID" && value.name !== "SKU" && value.name !== "Stock Qty") : true))) && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );
  function callback(key) {
    filters.map((item)=>{
      item.isChecked=false
    })
    setSelectFilter(filters)
    setSelectedFilters([])
    setCategoryName('All Categories')
    setRegionId('');
    setStatuus('')
    setCategoryId('')
    setsearchKey('')
    setStartStock('')
    setEndStock('');
    setFilterDays('');
    setFilteredDate('');
    if(key === "0"){
      setDraftKey(true)
    }else{
      setDraftKey(false)
    }
    if (key === "2") {
      setSelectFilter(setProductFilters)
      setColumns(setProductColumn);
    } else if (key !== "2") {
      setSelectFilter(filters)
      setColumns(normalProductColumn);
    }
    setsearchKey("");
    setPageSize(10);
    setTabSelected(key);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <div className={styles.management_contents}>
        <SearchComponents />
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Products-view") ===
        "Catalog-Products-view" && (
          <div className={commonstyles.management_header}>
            <h1 className={commonstyles.management_header_heading}>
              All Product
            </h1>
            {!draftKey &&
            <div>
              <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img
                  className={commonstyles.export_icon}
                  src={pdf_icon}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => exportToPDF(e)}
                />
              </Tooltip>
              <span
                className={styles.management_header_downloads_text}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              >
                <Tooltip
                  placement="topRight"
                  title="Download excel"
                >
                  <img className={commonstyles.export_icon} src={excel} /> Export{" "}
                </Tooltip>
                <img src={exportIcon} alt="icon" />
              </span>
            </div>}
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Products-view") ===
        "Catalog-Products-view" && (
          <div className={commonstyles.management_header}>
            {/* <ButtonComponent
          className="rnd pl-3 pr-4"
          attributes={{
            onClick: () => {
              setChildComponentVisibility({
                ...childComponentVisibility,
                addProduct: true,
              });
              window.scrollTo(0, 0);
            },
          }}
        >
          //<img src={plusIcon} className="mr-2" />
          Add Product
        </ButtonComponent> */}
            <div>
              {/* Region select*/}
              <ButtonComponent
                className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
                attributes={{
                  onClick: () => {
                    setChildComponentVisibility({
                      ...childComponentVisibility,
                      bulkUpload: true,
                    });
                    window.scrollTo(0, 0);
                  },
                }}
              >
                <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
                Bulk Upload
              </ButtonComponent>
            </div>
          </div>
        )}
      <div className="product-tabsbox">
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Products-view") ===
          "Catalog-Products-view" && (
            <Tabs onChange={callback} defaultActiveKey={tabSelected} type="card">
              <TabPane tab="Product" key="1">
                <div
                  className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
                >
                  <div
                    className={`${commonstyles.management_contents_search_bar} input-group`}
                  >
                    <InputComponent
                      className="with-icon rnd theme-txt-xsm"
                      formControl={{
                        placeholder:
                          "Search by Product ID, SKU, Product Name...",
                        name: "searchKey",
                        style: { height: "38px" },
                        value: searchKey,
                        onChange: filterOnChange,
                        ["aria-label"]: "Search category",
                        ["aria-describedby"]: "search-category",
                      }}
                    />
                    <img src={Search} alt="" />
                  </div>
                  <ButtonComponent
                    className="theme-btn rnd pl-3 pr-4 ml-2"
                    attributes={{
                      onClick: () => {
                        searchClick();
                      },
                    }}
                  >
                    Search
                  </ButtonComponent>
                  <div className="action ml-3">
                    <div className="action_header">
                      Action
                      <img src={arrowDown} alt="image" />
                    </div>
                    <div className="action_contents">
                      {bulkActionFilter.length > 0 &&
                        bulkActionFilter.map((item) => (
                          <div
                            className="action_contents_header"
                            key={item._id}
                            onClick={() => bulkStatusClickHandler(item)}
                          >
                            <h2
                              className={`action_contents_header_h2 ${item.isChecked &&
                                "action_contents_header_h2_active"
                                }`}
                            >
                              {item.name}
                            </h2>
                            {item.isChecked && (
                              <span
                                className="action_contents_btn"
                                onClick={() => applyBulkActionHandler(item)}
                              >
                                Apply
                              </span>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <Dropdown
                    overlay={filterMenu}
                    onVisibleChange={handleVisibleChange} visible={visible}
                    className="usermanage_table_filter_columns ml-3"
                  >
                    <div
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Filter <img src={arrowDown} width={10} height={20} />
                    </div>
                  </Dropdown>
                  <Dropdown
                    overlay={menu}
                    className="usermanage_table_filter_columns ml-3"
                  >
                    <div
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Columns <img src={arrowDown} width={10} height={20} />
                    </div>
                  </Dropdown>

                  {/*<div className={commonstyles.management_contents_upload}>
              <ButtonComponent
                className="theme-btn-outlined rnd px-3 mr-3"
              >
                Export
              </ButtonComponent> 

              
              </div>*/}
                </div>
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName={`Product Report for ${new Date().getFullYear()}`}
                  author="Damro Team"
                >
                  <div
                    className={`${commonstyles.management_table} table-responsive`}
                  >
                    {IsLoading.list === true ? (
                      <div
                        style={{
                          marginLeft: "70vh",
                          marginTop: "10vh",
                        }}
                      >
                        <tr className="bg-transparent">
                          <td colSpan="100%">
                            <LoadingIndicator loadingText="Fetching product details..." />
                          </td>
                        </tr>
                      </div>
                    ) : (
                      <div>
                        <input
                          type="checkbox"
                          className={`${commonstyles.parentCheckbox}`}
                          onChange={parentCheckbox}
                        ></input>
                        <AntTableComponent
                          className="order_table"
                          tableProps={{
                            columns: columns,
                            dataSource: productList,
                            pagination: false,
                            onChange: handleTableChange,
                         
                            components: {
                              header: {
                                row: (props) => renderHeader(props),
                              },
                              body: {
                                row: (props) => renderBody(props),
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!IsLoading.list &&
                    Array.isArray(productList) &&
                    productList.length ? (
                    <Pagination
                      pageSizes={pageSizes}
                      pageSize={pageSize}
                      nextPage={nextPage}
                      data={pagination}
                      handlePaginationChanges={handlePaginationChanges}
                    />
                  ) : null}
                  <ConfirmationBox
                    modal={{
                      show: tabSelected==='1'&&childComponentVisibility.delete,
                      size: "md",
                      centered: true,
                    }}
                    handleCancel={cancelDelete}
                    handleConfirm={removeProduct}
                    title="Confirm the action"
                    confirmText="Delete"
                    loading={IsLoading.delete}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-auto">
                          {selectedProduct.image ? (
                            <img
                              src={selectedProduct.image}
                              className={`${commonstyles.admin_table_img} ${commonstyle.popup_image_size}`}
                              alt="Product image"
                            />) : (<img
                            src={NoImage}
                            className={`${commonstyles.admin_table_img} ${commonstyle.popup_image_size}`}
                            alt="Product image"
                          />
                          )}
                        </div>
                        <div className="col-7">
                          <span className="theme-txt-subtitle">
                          {selectedProduct.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ConfirmationBox>
                </PDFExport>
              </TabPane>
              <TabPane tab="Draft" key="0">
                <div
                  className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
                >
                  <div
                    className={`${commonstyles.management_contents_search_bar} input-group`}
                  >
                    <InputComponent
                      className="with-icon rnd theme-txt-xsm"
                      formControl={{
                        placeholder:
                          "Search by Product ID, SKU, Product Name...",
                        name: "searchKey",
                        style: { height: "38px" },
                        value: searchKey,
                        onChange: filterOnChange,
                        ["aria-label"]: "Search category",
                        ["aria-describedby"]: "search-category",
                      }}
                    />
                    <img src={Search} alt="" />
                  </div>
                  <ButtonComponent
                    className="theme-btn rnd pl-3 pr-4 ml-2"
                    attributes={{
                      onClick: () => {
                        searchClick();
                      },
                    }}
                  >
                    Search
                  </ButtonComponent>
                  <Dropdown
                    overlay={filterMenu}
                    onVisibleChange={handleVisibleChange} visible={visible}
                    className="usermanage_table_filter_columns ml-3"
                  >
                    <div
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Filter <img src={arrowDown} width={10} height={20} />
                    </div>
                  </Dropdown>
                  <Dropdown
                    overlay={menu}
                    className="usermanage_table_filter_columns ml-3"
                  >
                    <div
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Columns <img src={arrowDown} width={10} height={20} />
                    </div>
                  </Dropdown>

                  {/*<div className={commonstyles.management_contents_upload}>
              <ButtonComponent
                className="theme-btn-outlined rnd px-3 mr-3"
              >
                Export
              </ButtonComponent> 

              
              </div>*/}
                </div>
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName={`Product Report for ${new Date().getFullYear()}`}
                  author="Damro Team"
                >
                  <div
                    className={`${commonstyles.management_table} table-responsive`}
                  >
                    {IsLoading.list === true ? (
                      <div
                        style={{
                          marginLeft: "70vh",
                          marginTop: "10vh",
                        }}
                      >
                        <tr className="bg-transparent">
                          <td colSpan="100%">
                            <LoadingIndicator loadingText="Fetching product details..." />
                          </td>
                        </tr>
                      </div>
                    ) : (
                      <div>
                        <input
                          type="checkbox"
                          className={`${commonstyles.parentCheckbox}`}
                          onChange={parentCheckbox}
                        ></input>
                        <AntTableComponent
                          className="order_table"
                          tableProps={{
                            columns: columns,
                            dataSource: productList,
                            pagination: false,
                            onChange: handleTableChange,
                            // onChange: onChange,
                            components: {
                              header: {
                                row: (props) => renderHeader(props),
                              },
                              body: {
                                row: (props) => renderBody(props),
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!IsLoading.list &&
                    Array.isArray(productList) &&
                    productList.length ? (
                    <Pagination
                      pageSizes={pageSizes}
                      pageSize={pageSize}
                      nextPage={nextPage}
                      data={pagination}
                      handlePaginationChanges={handlePaginationChanges}
                    />
                  ) : null}
                  <ConfirmationBox
                    modal={{
                      show: tabSelected==='0'&&childComponentVisibility.delete,
                      size: "md",
                      centered: true,
                    }}
                    handleCancel={cancelDelete}
                    handleConfirm={removeProduct}
                    title="Confirm the action"
                    confirmText="Delete"
                    loading={IsLoading.delete}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-auto">
                          {selectedProduct.image && (
                            <img
                              src={selectedProduct.image}
                              className={`${commonstyles.admin_table_img} ${commonstyle.popup_image_size}`}
                              alt="Product image"
                         
                            />
                          )}
                        </div>
                        <div className="col-7">
                          <span className="theme-txt-subtitle">
                            {selectedProduct.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ConfirmationBox>
                </PDFExport>
              </TabPane>
              <TabPane tab="Set Products" key="2">
                <SetProductManagement
                  searchKey={searchKey}
                  filterOnChange={filterOnChange}
                  searchClick={searchClick}
                  filterMenu={filterMenu}
                  menu={menu}
                  pdfExportComponent={pdfExportComponent}
                  IsLoading={IsLoading}
                  parentCheckbox={parentCheckbox}
                  columns={columns}
                  productList={productList}
                  onChange={handleTableChange}
                  renderHeader={renderHeader}
                  renderBody={renderBody}
                  pageSizes={pageSizes}
                  pageSize={pageSize}
                  nextPage={nextPage}
                  pagination={pagination}
                  handleVisibleChange={handleVisibleChange}
                  visible={visible}
                  handlePaginationChanges={handlePaginationChanges}
                  childComponentVisibility={childComponentVisibility}
                  cancelDelete={cancelDelete}
                  removeProduct={removeProduct}
                  selectedProduct={selectedProduct}
                />
              </TabPane>
            </Tabs>
          )}
      </div>
    </div>
  );
}