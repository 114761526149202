import React, { useState, useEffect, useRef } from "react";
import blackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import exclusionIcon from "../../../../assets/images/exclusion.svg";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/AddCategory.module.css";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import SwitchComponent from "../../../modules/ReusableComponent/SwitchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import validationRules from "../../../../utils/validationRules";
import { notification, Select, Tooltip } from "antd";
import { ExportCSV } from "../../../modules/ReusableComponent/ExportCsv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import { Catalogwithletters } from "../../../../utils/RegularExp";
import is from "date-fns/esm/locale/is/index.js";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { drawGraph } from "../../../DrawGraph/DrawGraph";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import moment from "moment";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { clone, upperCase, upperFirst } from "lodash";

const tabs_ = [
  {
    id: 1,
    name: "Total Items sold",
    isActive: true,
  },
  {
    id: 2,
    name: "Total orders",
    isActive: false,
  },
  {
    id: 3,
    name: "Refund",
    isActive: false,
  },
  {
    id: 4,
    name: "Shipment",
    isActive: false,
  },
  {
    id: 5,
    name: "Invoice",
    isActive: false,
  },
  {
    id: 6,
    name: "Transactions",
    isActive: false,
  },
];

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

export default function ReportManagement(props) {
  const pdfExportComponent = useRef(null);
  const history = useHistory();
  const { state } = history.location;
  const [buttons, setButtons] = useState(tabs_);
  const [activeBtnName, setActiveBtnName] = useState(
    state ? state : "Total Items sold"
  );
  const [barGraph, setBarGraph] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [regions, setRegions] = useState([]);
  const columns_ = [
    {
      title: "Product",
      visible: false,
      key: "Product",
      render: (text, record, index) => <span>{record.productName}</span>,
    },
    {
      title: "Category",
      visible: false,
      key: "Category",
      render: (text, record, index) => (
        <span>{record.categoryNames ? record.categoryNames : "-"}</span>
      ),
    },
    {
      title: "Region",
      visible: false,
      key: "Region",
      render: (text, record, index) => (
        <span>{record.regionNames ? record.regionNames : "-"}</span>
      ),
    },
    {
      title: "Number of items sold",
      visible: false,
      key: "Numberofitemssold",
      render: (text, record, index) => <span>{record.totalItemSold}</span>,
    },
    {
      title: "Amount",
      visible: false,
      key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs.{" "}
          {record.totalEarnings
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const columns1 = [
    {
      title: "Orders ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => <span>{record.orderId}</span>,
    },
    {
      title: "Items",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.items}</span>,
    },
    {
      title: "Shipping",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => <span>{record.shipping}</span>,
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.region ? record.region : "-"}</span>
      ),
    },
    {
      title: "Total",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs. {record.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Refund ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.refundIds ? record.refundIds : "-"}</span>
      ),
    },
    {
      title: "Customers Name",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.customerName}</span>,
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.regionName ? record.regionName : "-"}</span>
      ),
    },
    {
      title: "Refund Type",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => <span>{record.refundType}</span>,
    },
    {
      title: "Refund Amount",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs.{" "}
          {record.refundAmount
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const columns3 = [
    {
      title: "Shipment ID/Order ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.orderId ? record.orderId : "-"}</span>
      ),
    },
    {
      title: "Shipped Date",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => (
        <span>{moment(record.shippedDate).format("DD-MM-YYYY | hh:mm A")}</span>
      ),
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.regionName ? record.regionName : "-"}</span>
      ),
    },
    {
      title: "Order Date",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{moment(record.orderDate).format("DD-MM-YYYY | hh:mm A")}</span>
      ),
    },
  ];
  const columns4 = [
    {
      title: "Order ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.orderId ? record.orderId : "-"}</span>
      ),
    },
    {
      title: "Items",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.items}</span>,
    },
    {
      title: "Refund",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs. {record.refund?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
    {
      title: "Coupon",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs. {record.coupon?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.region ? record.region : "-"}</span>
      ),
    },
    {
      title: "Total Amount",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          Rs. {record.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const columns5 = [
    {
      title: "Order ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.orderId ? record.orderId : "-"}</span>
      ),
    },
    {
      title: "Invoice ID",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.invoiceId}</span>,
    },
    {
      title: "Invoice Date",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{moment(record.invoiceDate).format("DD-MM-YYYY | hh:mm A")}</span>
      ),
    },
    {
      title: "Total Invoice Amount",
      visible: false,
      render: (text, record, index) => (
        <span>
          Rs.{" "}
          {record.totalInvoiceAmount
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const [columns, setColumns] = useState(columns_);
  const [tableList, setTableList] = useState([]);
  const [salesReportData, setSalesReportData] = useState([]);
  const [filterName, setFilterName] = useState("Monthly");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionExportData, setTransactionExportData] = useState([]);

  useEffect(() => {
    getRegionList();
    tabs_?.forEach((item) => {
      if (item.name === (state? state:"Total Items sold")) {
        item.isActive = true;
        selectedBtn(item, true);
      } else {
        item.isActive = false;
      }
    });
    setButtons(tabs_);
    let params = {
      type: filterName,
      activeBtName: state,
    };
    if (state === "Refund") {
      getRefundList(params);
    } else if (state === "Shipment") {
      getShippingList(params);
    } else if (state === "Invoice") {
      getInvoiceList(params);
    } else if (state === "Transactions") {
      getTransactionList(params);
    } else {
      getTotalItemsSold(params);
    }
  }, []);

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function getTotalItemsSold(params) {
    if (params.regionId === "") delete params.regionId;
    let actBtn = params.activeBtName? params.activeBtName:activeBtnName;
    delete params?.activeBtName;
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/report/totalItemsSold", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          setSalesReportData(data);
          params["activeBtName"] = actBtn;
          console.log(actBtn);
          if (actBtn === "Total Items sold") {
            setColumns(columns_);
            setTableList(data?.ProductSoldList);
            grpSplitter(params, data?.totalItemsSold);
          } else {
            setColumns(columns1);
            grpSplitter(params, data?.totalOrders);
            setTableList(data?.totalOrdersListing);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  const selectedBtn = (item, type) => {
    if(!isLoading) {
    setIsLoading(true);
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.id === item.id) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
    });
    setButtons(cloneButtons);
    setFilterName("Monthly");
    setRegionId("");
    setActiveBtnName(item.name);
    let params = {
      type: "Monthly",
      regionId: "",
      activeBtName: item.name,
    };
    if (!type) {
      fetchingData(item, params);
    }
    }
  };

  function fetchingData(item, params) {
    if (item.name === "Total Items sold") {
      setColumns(columns_);
      // setTableList(salesReportData?.ProductSoldList);
      // grpSplitter(params, salesReportData?.totalItemsSold);
      getTotalItemsSold(params);
    } else if (item.name === "Total orders") {
      setColumns(columns1);
      // setTableList(salesReportData?.totalOrdersListing);
      // grpSplitter(params, salesReportData?.totalOrders);
      getTotalItemsSold(params);
    } else if (item.name === "Refund") {
      getRefundList(params);
    } else if (item.name === "Shipment") {
      getShippingList(params);
    } else if (item.name === "Transactions") {
      getTransactionList(params);
    } else if (item.name === "Invoice") {
      getInvoiceList(params);
    }
  }

  function getRefundList(params) {
    if (params.regionId === "") delete params.regionId;
    let activeBtName_ = params.activeBtName;
    delete params.activeBtName;
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/report/refundDetails", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          params["activeBtName"] = activeBtName_;
          setColumns(columns2);
          setTableList(data?.refundsList);
          grpSplitter(params, data?.refundGraph);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function getShippingList(params) {
    if (params.regionId === "") delete params.regionId;
    let activeBtName_ = params.activeBtName;
    delete params.activeBtName;
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/report/shipmentDetails", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          params["activeBtName"] = activeBtName_;
          setColumns(columns3);
          setTableList(data?.shipmentList);
          grpSplitter(params, data?.shipmentGraph);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function getTransactionList(params) {
    if (params.regionId === "") delete params.regionId;
    let activeBtName_ = params.activeBtName;
    delete params.activeBtName;
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/report/transactionDetails", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          params["activeBtName"] = activeBtName_;
          setColumns(columns4);
          setTransactionExportData(data?.exportTransactionList);
          setTableList(data?.transactionListing);
          grpSplitter(params, data?.transactionGraph);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function getInvoiceList(params) {
    if (params.regionId === "") delete params.regionId;
    let activeBtName_ = params.activeBtName;
    delete params.activeBtName;
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/report/invoiceDetails", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          params["activeBtName"] = activeBtName_;
          setColumns(columns5);
          setTransactionExportData(data?.exportInvoice);
          setTableList(data?.invoiceListing);
          grpSplitter(params, data?.invoiceGraph);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    let params = {
      type: filterName,
      regionId: regionId,
    };
    if (name === "regionId") {
      setRegionId(value);
      params.regionId = value;
    } else if (name === "filterName") {
      setFilterName(value);
      params.type = value;
    }
    let item = {};
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.name === activeBtnName) {
        item = x;
      }
    });
    params["activeBtName"] = activeBtnName;
    fetchingData(item, params);
  }

  function grpSplitter(params, data) {
    let xValue = [];
    let yValue = [];
    if (params.type === "Monthly") {
      data?.forEach((item) => {
        if (params?.activeBtName === "Total Items sold") {
          yValue.push(item?.totalItemSold);
        } else if (params?.activeBtName === "Total orders") {
          yValue.push(item?.totalOrders);
        } else if (params?.activeBtName === "Refund") {
          yValue.push(item?.totalRefunds);
        } else if (params?.activeBtName === "Shipment") {
          yValue.push(item?.sum);
          xValue.push(
            moment()
              .month(item?._id?.filter?.month - 1)
              .format("MMM")
          );
        } else if (params?.activeBtName === "Transactions") {
          yValue.push(item?.totalTransaction);
        } else if (params?.activeBtName === "Invoice") {
          yValue.push(item?.totalInvoice);
        }
        if (params?.activeBtName !== "Shipment") {
          xValue.push(
            moment()
              .month(item?._id?.month - 1)
              .format("MMM")
          );
        }
      });
    } else if (params.type === "Weekly") {
      data?.forEach((item, indx) => {
        if (params?.activeBtName === "Total Items sold") {
          yValue.push(item?.totalItemSold);
        } else if (params?.activeBtName === "Total orders") {
          yValue.push(item?.totalOrders);
        } else if (params?.activeBtName === "Refund") {
          yValue.push(item?.totalRefunds);
        } else if (params?.activeBtName === "Shipment") {
          yValue.push(item?.sum);
        } else if (params?.activeBtName === "Transactions") {
          yValue.push(item?.totalTransaction);
        } else if (params?.activeBtName === "Invoice") {
          yValue.push(item?.totalInvoice);
        }
        xValue.push(indx + 1);
      });
    } else {
      data?.forEach((item) => {
        if (params?.activeBtName === "Total Items sold") {
          yValue.push(item?.totalItemSold);
        } else if (params?.activeBtName === "Total orders") {
          yValue.push(item?.totalOrders);
        } else if (params?.activeBtName === "Refund") {
          yValue.push(item?.totalRefunds);
        } else if (params?.activeBtName === "Shipment") {
          yValue.push(item?.sum);
          xValue.push(item?._id?.filter?.day);
        } else if (params?.activeBtName === "Transactions") {
          yValue.push(item?.totalTransaction);
        } else if (params?.activeBtName === "Invoice") {
          yValue.push(item?.totalInvoice);
        }
        if (params?.activeBtName !== "Shipment") {
          xValue.push(item?._id?.day);
        }
      });
    }
    grpRetun(xValue, yValue);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function grpRetun(x, y) {
    let bar = {
      // x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      // y: [10, 12, 15, 5, 46, 23, 54, 50, 40, 56, 32, 19],
      x: x,
      y: y,
      type: "bar",
      width: 0.4,
      name: "Revenue",
      title: "Revenue",
      marker: {
        color: [
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          // "#BE6A4A",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
          "#E7D5CF",
        ],
      },
    };
    let graph = drawGraph(
      [bar],
      null,
      null,
      "grpBar",
      null,
      null,
      950,
      250,
      null,
      null,
      null
    );
    setBarGraph(graph);
  }

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const exportToCSV = async (e) => {
    if (activeBtnName === "Transactions" || activeBtnName === "Invoice") {
      continueToExport(transactionExportData);
      return;
    }
    let params = {
      type: filterName,
      regionId: regionId,
    };
    let url = "";
    if (activeBtnName === "Refund") {
      url = "/report/exportRefundDetails";
    } else if (activeBtnName === "Shipment") {
      url = "/report/exportShipmentDetails";
    } else {
      url = "/report/exportItemsAndOrder";
    }
    if (params.regionId === "") delete params.regionId;
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(url, params)
      .then((resp) => {
        // console.log(resp);
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          continueToExport(response);
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function continueToExport(response) {
    let data = {};
    if (activeBtnName === "Total Items sold") {
      data = [...response?.data?.ProductSoldList];
    } else if (activeBtnName === "Total orders") {
      data = [...response?.data?.totalOrdersListing];
    } else if (activeBtnName === "Refund") {
      data = [...response?.data?.refundsList];
    } else if (activeBtnName === "Shipment") {
      data = [...response?.data?.shipmentList];
    } else if (
      activeBtnName === "Transactions" ||
      activeBtnName === "Invoice"
    ) {
      data = [...response];
    }
    let und_res = data;
    // const fileType =
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(und_res);

    ws["A1"].v = upperCase(ws["A1"].v);
    ws["B1"].v = upperCase(ws["B1"].v);
    ws["C1"].v = upperCase(ws["C1"].v);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, activeBtnName);
    /* generate an XLSX file */
    XLSX.writeFile(
      wb,
      `${activeBtnName}-export-${moment(new Date())
        .format("YYYY-MM-DD")
        .split("-")
        .join("")}.xlsx`
    );
  }

  return (
    <div className={styles.management_contents}>
      <div className={`${styles.management_header}  mb-0 no_bottom_broder`}>
        <h1 className={styles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/reports")}
          />
          Sales Report
        </h1>
      </div>
      <div
        className={`${styles.management_details} d-flex justify-content-between mb-4`}
      >
        <div className="d-flex justify-content-start">
          <div>
            <select
              className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
              style={{ cursor: "pointer" }}
              name="regionId"
              value={regionId}
              onChange={filterOnChange}
            >
              {regions?.length > 1 && <option value="">All Region</option>}
              {Array.isArray(regions)
                ? regions.map((region) => (
                    <option key={region._id} value={region._id}>
                      {region.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div>
            <select
              className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
              style={{ cursor: "pointer" }}
              name="filterName"
              value={filterName}
              onChange={filterOnChange}
            >
              <option selected value="Monthly">
                Monthly
              </option>
              <option selected value="Weekly">
                Weekly
              </option>
              <option selected value="Daily">
                Daily
              </option>
            </select>
          </div>
        </div>
        <div>
          <span className={commonstyles.management_header_downloads_text}>
            <Tooltip placement="topRight" title="Download pdf">
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
                alt=""
                onClick={(e) => exportToPDF(e)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Download excel">
              <img
                src={excelIcon}
                className={commonstyles.management_header_downloads_icon}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              />
            </Tooltip>
          </span>
          <span className={commonstyles.management_header_downloads_text_ex}>
            Export <img src={exportIcon} alt="icon" />
          </span>
        </div>
      </div>

      <div
        className={`${commonstyles.management_header} ${commonstyles.abandone_tab_btn} justify-content-start pb-0`}
      >
        {buttons?.length > 0 &&
          buttons?.map((item) => (
            <button
              key={item.id}
              className={`${commonstyles.sidebarButton} 
                            ${
                              item.isActive && commonstyles.sidebarButton_active
                            } 
                            d-inline-block mb-0 mr-2`}
              onClick={() => selectedBtn(item)}
            >
              {item.name}
            </button>
          ))}
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching report details..." />
            </td>
          </tr>
        </div>
      ) : (
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName={`${activeBtnName} for ${new Date().getFullYear()}`}
          author="Damro Team"
        >
          <div className="row ml-0 mr-0">
            <div className={`${commonstyles.salesreport_card} col-lg-12 mb-4`}>
              <h4>{activeBtnName}</h4>
              {barGraph}
            </div>
            <div className={`${commonstyles.salesreport_card} col-lg-12 mb-4`}>
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: tableList,
                  pagination: false,
                }}
              />
            </div>
          </div>
        </PDFExport>
      )}
    </div>
  );
}
