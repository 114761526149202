import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum } from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import FaqFormComponent from "../../modules/ProductManagement/FaqFormComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import { EditorState } from "draft-js";

const editorOptions = {
    wrapperClassName: `${commonstyles.form_control} form-control-lg p-1 h-auto`,
    toolbarClassName: "bg-light rounded-lg theme-txt-sm",
    editorClassName: "px-2 mt-3 mb-1 ",
    //placeholder: "Type product features",
    toolbar: {
      options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "emoji",
        "link",
        "textAlign",
        "colorPicker",
        // "embedded",
        // "remove",
        "history",
      ],
  
      blockType: {
        inDropdown: true,
        options: [
          "Normal",
          "H1",
          "H2",
          "H3",
          "H4",
          "H5",
          "H6",
          "Blockquote",
          "Code",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },
  
      fontSize: {
        icon: undefined,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },
  
      fontFamily: {
        options: [
          "Arial",
          "Georgia",
          "Impact",
          "Spartan",
          "Tahoma",
          "Times New Roman",
          "Verdana",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: "",
      },
    },
  };
const initialaddFormState = {
 faq: [
  { Question: "", Answer: "" }
 ],
};


const rules = {
 faq: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validateFaqItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
};

function validateFaqItem(accumulator, currentFaq, indx) {
  const error = {};
  ["Question", "Answer"].forEach((field) => {
    if (validationRules.required(currentFaq[field]) !== true) {
      error[field] = "Required";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

export default function AddContactUsComponent() {
  const history = useHistory();
  const [editorState, setEditorState] = useState(null);
  const [addForm, setAddForm] = useState(initialaddFormState);
  const [formErrors, setFormErrors] = useState({});
  // const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setEditorState(() => EditorState.createEmpty());
  }, []);

  function handleEditorUpdate(htmlContent,formField) {
    /* const values = props.form;
    values[formField] = htmlContent;
    props.handleProductFormUpdate(values); */
  }
  /* useEffect(() => {
    const { state } = history.location;
    if (state?.zoneId && state?._id) {
      setIsLoading(true);
      getZoneDetails(state);
      // setIsLoading(false);
    }else{
      setIsLoading(false);
      
    }
    getRegionList();
  }, []); */

  /* useEffect(() => {
    getRegionList();
    getPaymentDetails();
  }, [avenueform?._id, payuform?._id]); */

  /* function getPaymentDetails() {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/payment/paymentConfig`)
      .then((res) => {
        if (res.data.status === 200) {
          //console.log(res.data.data);
          setIsLoading(false);
          setPayuForm(res.data.data.payu);
          setAvenueform(res.data.data.ccavenue);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  } */

  function createRequestData() {
    let data = JSON.parse(JSON.stringify(addForm));
    return data;
  }

  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        // console.log("field");
        // console.log(field);
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    // const errors = {};
    setFormErrors({});
    const data = createRequestData();
    console.log(data);
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    /* e.preventDefault(); */
    /* const apiEndPoint =
      currentTab === "avenue"
        ? "/admin/config/ccPaymentConfig"
        : "/admin/config/paymentConfigPayU";

    //console.log(data);
    AxiosInstance(PORTS.REGION_SERVICE)
      .post(apiEndPoint, { data })
      .then((res) => {
        //console.log(res.data)
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Payment updated successfully",
          });
        }
        history.push(`site-settings`);
      })
      .catch((er) => {
        console.log(er);
      }); */
  }
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /* function addFaq() {
    const newFaq = { Question: "", Answer: "" };
    const faqs = [...addForm.faq, newFaq];
    setAddForm({ ...addForm, faq: faqs });
  }

  function removeFaq(indx) {
    let faqs = addForm.faq;
    if (faqs[indx]) {
      faqs.splice(indx, 1);
      setAddForm({ ...addForm, faq: faqs });
    }
  }

  function updateFaq(val, indx) {
    console.log(val)
    const faqs = addForm.faq;
    faqs[indx] = val;
    setAddForm({ ...addForm, faq: faqs });
  } */
  
  /*return  isLoading === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching payment details..." />
        </td>
      </tr>
    </div>
  ) :  */
  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/contactus")}
          />
          Add Contact
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/contactus`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              onClick: submit,
            }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details}>
        

        {/* {avenueform.map((avenue, indx) => ( */}
          {Array.isArray(addForm.faq) &&
              addForm.faq.map((faq, indx) => (
            <React.Fragment key={indx}>
              <div className={styles.management_details_contents}>
                <div className="row">
                  <div className="col-lg-12 mt-4 mb-5">
                  <label className="theme-form-label">Contact Us</label>
                    {/* <textarea
                    rows="3"
                    name="product_highlights"
                    value={props.form.product_highlights}
                    // onChange={handleChange}
                    disabled
                    className={`${commonstyles.form_control} form-control form-control-lg`}
                    placeholder="Type Product Feature"
                    /> */}
                    <WYSIWYGEditor
                    content={/* props.form.product_highlights  ||*/ ""}
                    options={editorOptions}
                    onEditorStateChange={(content)=>{handleEditorUpdate(content,"product_highlights")}}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          {/* ))} */}
        {/* <div className={styles.payment_btn}>
          <button className={`btn ${styles.btn_filled}`} onClick={addFaq}>
            Add FAQ
          </button>
        </div> */}
      </div>
    </div>
  );
}
