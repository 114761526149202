import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import validationRules from "../../../utils/validationRules";

function AddModularComponent() {
  const initialFormState = {
    name: "",
    companyId: "",
  };
  const rules = {
    name: [validationRules.required],
    companyId: [validationRules.required],
  };
  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const { state } = history.location;

  useEffect(() => {
    const { state } = history.location;
    console.log(state);
  }, []);

  // *** FETCHING REGION DETIALS *** //
  //   function getRegionDetails(id) {
  //     setLoading({ details: true, update: false });
  //     AxiosInstance(PORTS.REGION_SERVICE, "application/json")
  //       .get(`/admin/region/${id}`)
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           const val = res.data.data;
  //           setEditForm(val);
  //         } else {
  //           showNotification({
  //             type: "Error",
  //             message: "Error",
  //             description: "Couldnt fetch required data",
  //           });
  //         }
  //       })
  //       .catch((er) => {
  //         console.log(er);
  //       })
  //       .finally(() => {
  //         setLoading({ details: false, update: false });
  //       });
  //   }


   /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr}
   * INPUT HANDLE CHANGE
   */
  function handleFormInput(e) {
    const { name, value } = e.target;
    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }


     /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr}
   * SHOW NOTIFICATION FUNCTION
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** VALIDATE FUNCTION *** //

  
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // *** SAVE CHANGES *** //
  //   function onSubmite() {
  //     console.log(editForm);
  //     let data = JSON.parse(JSON.stringify(editForm));
  //     const result = validate(undefined, data);
  //     if (Object.keys(result).length) {
  //       setFormErrors(result);
  //       showNotification({
  //         type: "warning",
  //         message: "There were few errors",
  //         description: "Resolve the errors and try again",
  //       });
  //       return;
  //     }
  //     // delete data.__v;
  //     // delete data.createdAt;
  //     // delete data.updatedAt;
  //     setLoading({ ...loading, update: true });
  //     AxiosInstance(PORTS.REGION_SERVICE, "application/json")
  //       .post(`/admin/region/${state?._id ? "updateRegion" : "addRegion"}/`, data)
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setEditForm(initialFormState);
  //           showNotification({
  //             type: "success",
  //             message: "Success",
  //             description: state?._id
  //               ? "Region updated successfully"
  //               : "Region added successfully",
  //           });
  //           setTimeout(() => {
  //             history.push("/site-settings/region");
  //           }, 1000);
  //         } else {
  //           showNotification({
  //             type: "Error",
  //             message: "Error",
  //             description: "Couldnt fetch required data",
  //           });
  //         }
  //       })
  //       .catch((er) => {
  //         console.log(er);
  //       })
  //       .finally(() => {
  //         setLoading({ details: false, update: false });
  //       });
  //   }

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/site-settings/modular")}
          />
          {state?._id ? "Edit Enquiry" : "Warehouse Managemant"}
        </h1>
        <div className={commonstyles.management_contents_upload}>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              //   onClick: onSubmite,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      <div className={commonstyles.management_details}>
        <div className={commonstyles.management_details_contents}>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">Name</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.name}
                  formControl={{
                    placeholder: "Eg, Karnataka",
                    name: "name",
                    value: state?.name,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.name} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">Email</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.email}
                  formControl={{
                    placeholder: "Eg, 1234535",
                    name: "companyId",
                    value: state?.email,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.email} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">Mobile</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.mobile}
                  formControl={{
                    placeholder: "Eg, Karnataka",
                    name: "name",
                    value: state?.mobile,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.mobile} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">State</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.state}
                  formControl={{
                    placeholder: "Eg, Karnataka",
                    name: "name",
                    value: state?.state,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.state} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">Comment</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.comment}
                  formControl={{
                    placeholder: "Eg, Karnataka",
                    name: "name",
                    value: state?.comment,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.comment} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">Type</label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.type}
                  formControl={{
                    placeholder: "Eg, Karnataka",
                    name: "name",
                    value: state?.type,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.type} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModularComponent;
