import React, { useState, useEffect } from "react";
import styless from "../../../../styles/AddProduct.module.css";
import blackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import exclusionIcon from "../../../../assets/images/exclusion.svg";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/AddCategory.module.css";
import stylees from "../../../../styles/PaymentNew.module.css";
import redstyles from "../../../../styles/AddProduct.module.css";
import SwitchComponent from "../../../modules/ReusableComponent/SwitchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import { notification } from "antd";
import {
  noSpace,
  alphaNum,
  alphaNumwithSpace,
  alphanumericwithdashHyphen,
  onlyAlphabets,
  minCharWithNoMaxLimit
} from "../../../../utils/RegularExp";
import {
  onlyAlphabetsWithSpace,
  phoneRegx,
  passwordReg,
  minChar,
} from "../../../../utils/RegularExp";
import { useHistory } from "react-router";
// import Compressor from "compressorjs";

function initialState() {
  return {
    enable: true,
    name: "",
    slug: "",
    description: "",
    level: "",
    parentId: "" /* not required if level 0, */,
    ourcollection: false,
    trending: false,
    sortValue: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    seoMarkup: "",
  };
}
// Bind body in data of formdata;
// files -
// icon or image required if ourcollection true;
// menuImage - optional

export default function AddCategoryComponent(props) {
  const [newCategory, setNewCategory] = useState(initialState());
  const [parentCategories, setParentCategories] = useState([]);
  const [parentCategoriesALl, setParentCategoriesAll] = useState([]);
  const [isLoading, setIsLoading] = useState({
    parentCategory: false,
    update: false,
  });
  const [files, setFiles] = useState({
    image: null,
    icon: null,
    menuImage: null,
  });
  const [filePreview, setFilePreview] = useState({
    image: null,
    icon: null,
    menuImage: null,
  });
  const [formErrors, setFormErrors] = useState({});
  // const [compressedFile, setCompressedFile] = useState(null);
  //

  //   const filteredCategories = newCategory.level !== 0 ? parentCategories.filter((c) => c.level < newCategory.level) : [];
  const history = useHistory();
  const rules = {
    name: [(val, data) => (!val ? "Name " : true)],
    slug: [(val, data) => (!val ? "Slug " : true)],
    level: [(val, data) => (data.level >= 0 ? true : "Level ")],
    parentId: [(val, data) => (data.level > 0 && !val ? "Parent " : true)],
    sortValue: [
      (val, data) => (!val && val !== 0? "Please select a sort value" : true),
    ],
    files: [
      (val, data) =>
        data.ourcollection &&
          data.trending &&
          files.image == null &&
          files.icon == null
          ? "Icon and category image when category is set for our collection and trending"
          : true,
    ],
  };

  useEffect(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
            setParentCategoriesAll(res.data.data);
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getParentCategories();
  }, []);

  function handleInput(e) {
    const { name, value, checked } = e.target;
    let arr = [],
      relvel = value === "2" ? 1 : 0;

    if (name === "level") {
      parentCategoriesALl.map((x) => {
        if (x.level === relvel) {
          arr.push(x);
        }
      });
      setParentCategories(arr);
    }
    // if (name === "metaTitle") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaTitle = "Enter only alphabet";
    //   } else {
    //     formErrors.metaTitle = "";
    //   }
    // }
    // if (name === "metaDescription") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaDescription = "Enter only alphabet";
    //   } else {
    //     formErrors.metaDescription = "";
    //   }
    // }
    // if (name === "metaKeywords") {
    //   if (!e.target.value.match(onlyAlphabets) || !e.target.value === "") {
    //     formErrors.metaKeywords = "Enter only alphabet";
    //   } else {
    //     formErrors.metaKeywords = "";
    //   }
    // }
    if (["level"].includes(name)) {
      const val = parseInt(value);
      setNewCategory({
        ...newCategory,
        parentId: "",
        [name]: !isNaN(val) ? val : value,
      });
    } else if (["trending", "ourcollection", "enable"].includes(name)) {
      setNewCategory({ ...newCategory, [name]: checked });
    } else if (["name"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.name = "Enter only alphabet and number";
      }
      if (!alphaNumwithSpace.test(value)) {
        formErrors.name = "Enter only alphabet and number";
      }
      setNewCategory({ ...newCategory, [name]: value });
    } else {
      setNewCategory({ ...newCategory, [name]: value });
    }
  }

  function handleInputSlug(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) {
      return false;
    } else {
      if (!alphanumericwithdashHyphen.test(event.target.value)) {
        formErrors.slug = "Enter only alphabet and number";
      }
      setNewCategory({
        ...newCategory,
        slug: event.target.value.toLowerCase().split(" ").join(""),
      });
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {event}
   * change image input and update state
   */
  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase();
    //31427
    // new Compressor(inputFiles[0], {
    //   quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {
    //     console.log(compressedResult);
    //     // compressedResult has the compressed file.
    //     // Use the compressed file to upload the images to your server.
    //     setCompressedFile(compressedResult);
    //   },
    // });
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (filePreview[name]) {
          URL.revokeObjectURL(filePreview[name]);
        }
        const url = URL.createObjectURL(inputFiles[0]);
        setFiles({ ...files, [name]: inputFiles[0] });
        setFilePreview({ ...filePreview, [name]: url });
      }
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {name}
   * REMOVE FILE/IMAGE
   */
  function removeFile(name) {
    if (filePreview[name]) {
      URL.revokeObjectURL(filePreview[name]);
    }
    setFiles({ ...files, [name]: null });
    setFilePreview({ ...filePreview, [name]: null });
  }

  function createRequestData() {
    const data = JSON.parse(JSON.stringify(newCategory));
    data.level = parseInt(data.level);
    data.sortValue = parseInt(data.sortValue);
    if (data.level === 0) {
      delete data.parentId;
    }
    newCategory.seoMarkup == "" && delete data.seoMarkup;
    data.metaTitle =
      newCategory.metaTitle === "" ? null : newCategory.metaTitle;
    data.metaKeywords =
      newCategory.metaKeywords === "" ? null : newCategory.metaKeywords;
    data.metaDescription =
      newCategory.metaDescription === "" ? null : newCategory.metaDescription;
    return data;
  }

  function submit() {
    setFormErrors({});
    const data = createRequestData();
    const result = runValidationChecks(rules, undefined, { ...data, files });

    if (Object.keys(result).length > 0) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    if (noSpace.test(newCategory.name)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number for category name",
      });
      return;
    }

    if (
      !alphanumericwithdashHyphen.test(newCategory.slug) ||
      !alphaNumwithSpace.test(newCategory.name)
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number",
      });
      return;
    }

    let filesData = {};

    ["image", "icon", "menuImage"].forEach((field) => {
      if (files[field]) {
        filesData[field] = files[field];
      }
    });

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));

    // if (Object.keys(filesData).length) {
    //   formData.append("files", filesData);
    // }
    ["image", "icon", "menuImage"].forEach((field) => {
      if (files[field]) {
        formData.append(field, files[field]);
      }
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // accept: "*/*",
      },
    };

    // if (
    //   files.image.size > 1024 * 1024 * 3 ||
    //   files.menuImage.size > 1024 * 1024 * 3 ||
    //   files.menuImage.size > 1024 * 1024 * 3
    // ) {
    //   showNotification({
    //     type: "error",
    //     message: "There were few errors",
    //     description: "Please upload file less than 3MB size",
    //   });
    //   return;
    // }
    setIsLoading({ ...isLoading, update: true });
    AxiosInstance(PORTS.CATALOG_SERVICE)
      .post("/admin/catalog/addCategory", formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Category details added successfully",
          });
          props.handleClose(true);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
          showNotification({
            type: "error",
            message: "Adding category failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding category failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading({ ...isLoading, update: false });
      });
  }

  /**
 * @author Mithun Prabhu
 * @param {type, message, description}
 * SHOW NOTIFICATION USING ANTD
 */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={styles.management_header_heading}>
          <img
            src={blackLeftArrow}
            onClick={props.handleClose}
            className="cursor-pointer"
            title="Back"
            alt="Back action image"
          />
          Add Category
        </h1>
        <div className={`${styless.onoffswitchbtn}`}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={handleInput}
              checked={newCategory.enable}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: props.handleClose,
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: isLoading.update,
              onClick: submit,
            }}
          >
            {isLoading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/*  */}
      <div className={styles.management_details}>
        <div className="row mb-4">
          {/* Category name  */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>
                Category name
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.name}
                formControl={{
                  placeholder: "Type category name",
                  name: "name",
                  value: newCategory.name,
                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.name) {
                        formErrors.name = {};
                      }
                      formErrors.name = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.name) {
                        formErrors.name = "";
                      }
                    }

                    handleInput(e);
                  },
                }}
              />
              <FormErrorText error={formErrors.name} />
            </div>
          </div>
          {/* Category name ends */}

          {/* Slug name start */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>
                Slug
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.slug}
                formControl={{
                  placeholder: "Type slug name",
                  name: "slug",
                  value: newCategory.slug,
                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.slug) {
                        formErrors.slug = {};
                      }
                      formErrors.slug = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.slug) {
                        formErrors.slug = "";
                      }
                    }
                    handleInputSlug(e);
                  },
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
          </div>
          {/* Slug name ends */}

          {/* Description  */}
          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>Description</label>
              <textarea
                className="form-control theme-form-control form-control-lg"
                name="description"
                rows="3"
                value={newCategory.description}
                onChange={handleInput}
                placeholder="Type description"
              />
            </div>
          </div>
          {/* Description ends */}

          <div className="col-lg-12">
            <div className={styles.management_details_contents}>
              <label>Schema markup</label>
              <textarea
                className="form-control theme-form-control form-control-lg"
                name="seoMarkup"
                rows="3"
                // value={newCategory.seoMarkup}
                onChange={handleInput}
                placeholder="Type schema markup"
              />
            </div>
          </div>
          {/* Level  */}
          <div className="col-lg-6">
            <div className={styles.management_details_contents}>
              <label>
                Category level
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <SelectComponent
                error={formErrors.level}
                className="form-control-md"
                attributes={{
                  name: "level",
                  value: newCategory.level,
                  onChange: handleInput,
                }}
              >
                <option value="" disabled>
                  Select a level
                </option>
                <option value="0">Parent category</option>
                <option value="1">Sub category</option>
                <option value="2">Second sub category</option>
              </SelectComponent>
              <FormErrorText error={formErrors.level} />
            </div>
          </div>
          {/* Level ends */}
          {/* ParentId  */}
          <div
            className={`${newCategory.level >= 1 || newCategory.level >= "1" ? "" : "fade"
              } col-lg-6`}
          >
            <div className={styles.management_details_contents}>
              <label>
                Choose Parent
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <SelectComponent
                error={formErrors.parentId}
                className="form-control-md"
                attributes={{
                  name: "parentId",
                  value: newCategory.parentId,
                  onChange: handleInput,
                }}
              >
                <option value="" disabled>
                  Select parent category
                </option>
                {Array.isArray(parentCategories) && parentCategories.length
                  ? parentCategories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))
                  : null}
              </SelectComponent>
              <FormErrorText error={formErrors.parentId} />
            </div>
          </div>
          {/* ParentId ends */}
          {/* Sort value  */}
          <div className="col-lg-6">
            <div className={styles.management_details_contents}>
              <label>
                Sort value
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <SelectComponent
                error={formErrors.sortValue}
                className="form-control-md"
                attributes={{
                  name: "sortValue",
                  value: newCategory.sortValue,
                  onChange: handleInput,
                }}
              >
                <option value="" disabled>
                  Select an option
                </option>

                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </SelectComponent>
              <FormErrorText error={formErrors.sortValue} />
            </div>
          </div>
          {/* Sort value ends */}
          <div className="col-lg-6 ">
            <div className="row mt-4 pt-2">
              <div className="col-lg-6">
                <div
                  className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                >
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Trending
                  </label>
                  <div
                    className={`${redstyles.onoffswitch} ${redstyles.yes_no}`}
                  >
                    <input
                      type="checkbox"
                      name="trending"
                      className={redstyles.onoffswitch_checkbox}
                      id="trending"
                      onChange={handleInput}
                      checked={newCategory.trending}
                    />

                    <label
                      className={redstyles.onoffswitch_label}
                      htmlFor="trending"
                    >
                      <span className={redstyles.onoffswitch_inner}></span>
                      <span className={redstyles.onoffswitch_switch}></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                >
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Our collection
                  </label>
                  <div
                    className={`${redstyles.onoffswitch} ${redstyles.yes_no}`}
                  >
                    <input
                      type="checkbox"
                      name="ourcollection"
                      className={redstyles.onoffswitch_checkbox}
                      id="ourcollection"
                      onChange={handleInput}
                      checked={newCategory.ourcollection}
                    />

                    <label
                      className={redstyles.onoffswitch_label}
                      htmlFor="ourcollection"
                    >
                      <span className={redstyles.onoffswitch_inner}></span>
                      <span className={redstyles.onoffswitch_switch}></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {formErrors.files && (
            <div className="col-lg-12 theme-txt-sm theme-error-txt mt-3">
              {formErrors.files}
            </div>
          )}

          <div className="col-lg-12 mt-4">
            <div className="row">
              <div className="col-lg-4 ">
                <label className={commonstyles.secondry_title} htmlFor="">
                  Category Image
                </label>

                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.add_image_card
                      } ${formErrors.files ? "theme-error-blk" : ""
                      } d-flex align-items-center justify-content-center`}
                    style={{ overflow: "hidden" }}
                  >
                    {filePreview.image ? (
                      <ImagePreviewChip
                        url={filePreview.image}
                        handleClose={() => removeFile("image")}
                      />
                    ) : (
                      <div className={`${styles.upload_img} text-center`}>
                        <img src={exclusionIcon} alt="" />
                        <div>
                          <label
                            htmlFor="imageInput"
                            className={`${commonstyles.btn} px-2 cursor-pointer`}
                          >
                            Add image
                          </label>
                          <input
                            name="image"
                            className="d-none"
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml"
                            id="imageInput"
                            onInput={handleImageInput}
                          />
                        </div>
                        <span>or drop a file to Upload</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 ">
                <label className={commonstyles.secondry_title} htmlFor="">
                  Icon
                </label>

                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.add_image_card
                      } ${formErrors.files ? "theme-error-blk" : ""
                      } d-flex align-items-center justify-content-center`}
                    style={{ overflow: "hidden" }}
                  >
                    {filePreview.icon ? (
                      <ImagePreviewChip
                        url={filePreview.icon}
                        handleClose={() => removeFile("icon")}
                      />
                    ) : (
                      <div className={`${styles.upload_img} text-center`}>
                        <img src={exclusionIcon} alt="" />
                        <div>
                          <label
                            htmlFor="iconInput"
                            className={`${commonstyles.btn} px-2 cursor-pointer`}
                          >
                            Add image
                          </label>
                          <input
                            name="icon"
                            className="d-none"
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml"
                            id="iconInput"
                            onInput={handleImageInput}
                          />
                        </div>
                        <span>or drop a file to Upload</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 ">
                <label className={commonstyles.secondry_title}>
                  Menu Image
                </label>

                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.add_image_card}  d-flex align-items-center justify-content-center`}
                    style={{ overflow: "hidden" }}
                  >
                    {filePreview.menuImage ? (
                      <ImagePreviewChip
                        url={filePreview.menuImage}
                        handleClose={() => removeFile("menuImage")}
                      />
                    ) : (
                      <div className={`${styles.upload_img} text-center`}>
                        <img src={exclusionIcon} alt="" />
                        <div>
                          <label
                            htmlFor="menuImageInput"
                            className={`${commonstyles.btn} px-2 cursor-pointer`}
                          >
                            Add image
                          </label>
                          <input
                            name="menuImage"
                            className="d-none"
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml"
                            id="menuImageInput"
                            onInput={handleImageInput}
                          />
                        </div>
                        <span>or drop a file to Upload</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row mb-4"> */}
          <div className="col-lg-3">{/* </div> */}</div>
        </div>
        <div className={styles.management_details_contents}>
          <h4 className="theme-form-section-title">
            Search Engine Optimization
          </h4>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Title</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaTitle}
                  formControl={{
                    placeholder: "",
                    name: "metaTitle",
                    value: newCategory.metaTitle,
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={formErrors.metaTitle} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Keywords</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaKeywords}
                  formControl={{
                    placeholder: "",
                    name: "metaKeywords",
                    value: newCategory.metaKeywords,
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={formErrors.metaKeywords} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Description</label>
                <textarea
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.metaDescription}
                  // formControl={{
                  rows="10"
                  placeholder=""
                  name="metaDescription"
                  value={newCategory.metaDescription}
                  onChange={handleInput}
                // }}
                />
                <FormErrorText error={formErrors.metaDescription} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div
                className={`${styles.management_details_form} rich_text_hidden`}
              >
                <label for="">
                  Top Content

                </label>

                {/* {page?._id && editForm.content && ( */}
                <CKEditor
                  error={formErrors.headerContent}
                  editor={ClassicEditor}
                  // data={newCategory.headerContent || ""}
                  config={{ extraPlugins: [uploadPlugin] }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...newCategory };
                    console.log(data);
                    // console.log(values);
                    values.topContent = data;
                    if (!minCharWithNoMaxLimit.test(data)) {
                      if (!formErrors.headerContent) {
                        formErrors.headerContent = {};
                      }
                      formErrors.headerContent = "Header content required";
                    } else {
                      if (formErrors.headerContent) {
                        formErrors.headerContent = "";
                      }
                    }
                    setNewCategory(values);
                  }}
                />
                {/* )} */}
                {/*  {!(page?._id && editForm.content) && (
                                        <CKEditor
                                        editor={ ClassicEditor }
                                        data={editForm?.content || ""}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            const values = editForm;
                                            values.content = data;
                                            setEditForm(values);
                                        } }
                                    />
                                    )} */}

                <FormErrorText error={formErrors.headerContent} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div
                className={`${styles.management_details_form} rich_text_hidden`}
              >
                <label for="">
                  Bottom Content

                </label>
                <CKEditor
                  error={formErrors.footerContent}
                  editor={ClassicEditor}
                  config={{ extraPlugins: [uploadPlugin] }}
                  // data={newCategory?.footerContent || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...newCategory };
                    // console.log(data);
                    // console.log(values);
                    values.bottomContent = data;
                    if (!minCharWithNoMaxLimit.test(data)) {
                      if (!formErrors.footerContent) {
                        formErrors.footerContent = {};
                      }
                      formErrors.footerContent = "Footer content required";
                    } else {
                      if (formErrors.footerContent) {
                        formErrors.footerContent = "";
                      }
                    }
                    setNewCategory(values);
                  }}
                />
                <FormErrorText error={formErrors.footerContent} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
