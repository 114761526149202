// import { Editor } from "react-draft-wysiwyg";
import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
import { stateFromHTML } from "draft-js-import-html";
import commonstyles from "../../../styles/Management_Common.module.css";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default class WYSIWYGEditor extends React.Component {
  constructor(props) {
    super(props);
    //set editorState
    let editorState;
    if (props.content) {
      const html = props.content;

      // const contentBlock = htmlToDraft(html);
      // console.log("contentBlock");
      // console.log(contentBlock);
      // if (contentBlock) {
      //   const contentState = ContentState.createFromBlockArray(
      //     contentBlock.contentBlocks
      //   );
      //   console.log("contentState");
      //   console.log(contentState);
      //   editorState = EditorState.createWithContent(contentState);
      // }
      let contentState = stateFromHTML(html);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    //

    this.state = { editorState };
  }

  onEditorStateChange = (editorState) => {
    const htmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState });
    this.props.onEditorStateChange(htmlData);
  };
  // return null;
  render() {
    const { editorState } = this.state;
    const editorOptions = this.props.options || {};
    return (
      <Editor
        editorState={editorState}
        // toolbarClassName="toolbarClassName"
        // wrapperClassName="wrapperClassName"
        // editorClassName="editorClassName"
        {...editorOptions}
        onEditorStateChange={this.onEditorStateChange}
      />
    );
  }
}
