import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import moment from "moment";
import { Select } from 'antd';
import { upperFirst } from "lodash";

const { Option } = Select;



export default function ModularHeader(props) {
    return (
        <>
            <div className={styles.management_header}>
                <h1>
                    {/* <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                    /> */}
                    Modular Furniture
                </h1>
            </div>
            <div className={`${styles.management_header} ${styles.banner_city_list}`}>
                <div className={`${styles.banner_citys}`}>
                    <div className={`${styles.banner_citys_dropdown_btn} row d-flex pl-3`}>
                        <div className=" mb-3 mr-2">

                            <Select 
                                defaultValue="All Region" 
                                style={{ width: 140 }}
                                name={"regionId"}
                                value={props.regionId}
                                disabled={props.filterRegions?.length === 1}
                                onChange={(value) => props.filterOnChangeforRegion(value, "regionId")}
                            >
                                {props.filterRegions?.length > 1 && <Option value="">All Warehouse</Option>}
                                {Array.isArray(props.filterRegions)
                                    ? props.filterRegions.map((region) => (
                                        <Option key={region._id} value={region._id}>
                                            {region.name}
                                        </Option>
                                    ))
                                    : null}
                            </Select>

                            {/* <SelectComponent
                                className="form-control-md"
                                attributes={{
                                    name: "regionId",
                                    value: props.regionId,
                                    onChange: props.filterOnChangeforRegion,
                                }}
                            >
                                <option value="">All Region</option>
                                {Array.isArray(props.filterRegions)
                                    ? props.filterRegions.map((region) => (
                                        <option key={region._id} value={region._id}>
                                            {region.name}
                                        </option>
                                    ))
                                    : null}
                            </SelectComponent> */}
                        </div>
                        <div className=" mb-3 mr-2">
                            <Select 
                                style={{ width: 140 }}
                                name= {"modularType"}
                                value={props.modularType}
                                onChange={(value) => props.filterOnChangeforRegion(value, "modularType")}
                            >
                                {Array.isArray(props.modularTypes)
                                    ? props.modularTypes.map((modularType) => (
                                        <Option key={modularType.id} value={modularType.value}>
                                            {modularType.name}
                                        </Option>
                                    ))
                                    : null}
                            </Select>
                            {/* <SelectComponent
                                className="form-control-md"
                                attributes={{
                                    name: "modularType",
                                    value: props.modularType,
                                    onChange: props.filterOnChangeforRegion,
                                }}
                            >
                                {Array.isArray(props.modularTypes)
                                    ? props.modularTypes.map((modularType) => (
                                        <option key={modularType.id} value={modularType.value}>
                                            {modularType.name}
                                        </option>
                                    ))
                                    : null}
                            </SelectComponent> */}
                        </div>
                        <div className=" mb-3 mr-2">
                            {props.shape!==7&&<Select 
                                style={{ width: 140 }}
                                name={"shapes"}
                                value={props.shape}
                                onChange={(value) => props.filterOnChangeforRegion(value, "shapes")}
                            >
                                {Array.isArray(props.shapes)
                                    ? props.shapes.map((shape) => (
                                        <Option key={shape.id} value={shape.value}>
                                            {shape.name}
                                        </Option>
                                    ))
                                    : null}
                            </Select>}
                            {/* <SelectComponent
                                className="form-control-md"
                                attributes={{
                                    name: "shapes",
                                    value: props.shape,
                                    onChange: props.filterOnChangeforRegion,
                                }}
                            >
                                {Array.isArray(props.shapes)
                                    ? props.shapes.map((shape) => (
                                        <option key={shape.id} value={shape.value}>
                                            {shape.name}
                                        </option>
                                    ))
                                    : null}
                            </SelectComponent> */}
                        </div>
                    </div>
                    <div className={`${styles.banner_citys_update}`}>
                        {props?.lastUpdateUserDetails?.userName && <p>
                            Last update by: <span>{upperFirst(props?.lastUpdateUserDetails?.userName)}</span>
                        </p>}
                        {props?.lastUpdateUserDetails?.updatedAt && <p>
                            Last update on:{" "}
                            <span>
                                {moment(props?.lastUpdateUserDetails?.updatedAt).format(
                                    "DD/MM/YYYY | hh:mm A"
                                )}
                            </span>
                        </p>}
                    </div>
                </div>

                <div className={styles.management_contents_upload}>
                    <ButtonComponent
                        className="rnd"
                        attributes={{
                            disabled: props.isUpdate,
                            onClick: props.submit,
                        }}
                    >
                        {props.isUpdate ? "Saving" : "Save"}
                    </ButtonComponent>
                </div>
            </div>
        </>
    )
}