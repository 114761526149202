import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import styles from "../../../styles/DashBoa.module.css";
import excelIcon from "../../../assets/images/excel_icon.svg";


export const ExportDash = ({most,topCata,topSearch,latestSearch,RecentReport,notificationReport,totalSal,statOverview,DetailReport, fileName}) => {

// console.log(statOverview,DetailReport);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportCsv = ( most,topCata,topSearch,latestSearch,RecentReport,totalSal,notificationReport,statOverview,DetailReport, fileName) => {

        const ws2 = XLSX.utils.json_to_sheet(most);
        const ws3 = XLSX.utils.json_to_sheet(topCata);
        const ws4 = XLSX.utils.json_to_sheet(topSearch);
        const ws5 = XLSX.utils.json_to_sheet(latestSearch);
        const ws6 = XLSX.utils.json_to_sheet(RecentReport);
        const ws7 = XLSX.utils.json_to_sheet(totalSal);
        const ws8 = XLSX.utils.json_to_sheet(notificationReport);
        const ws9 = XLSX.utils.json_to_sheet(statOverview);
        const ws10 = XLSX.utils.json_to_sheet(DetailReport);

        const wb = { Sheets: {'Most viewed':ws2, 'Top category':ws3,'Top search':ws4, 'Latest search':ws5, 'Recent Activity':ws6,'Total sales':ws7,'Notification':ws8,"Statistics overview":ws9,"Quick details":ws10 }, SheetNames: ['Most viewed','Top category','Top search','Latest search','Recent Activity','Total sales','Notification','Statistics overview','Quick details'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);

    }

    return (

        <img src={excelIcon} className={styles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportCsv(most,topCata,topSearch,latestSearch,RecentReport,totalSal,notificationReport,statOverview,DetailReport,fileName)}/>
    )

}