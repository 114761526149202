import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { Link } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useRef, useState, createRef } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import AntSelectComponent from "../../modules/ReusableComponent/AntSelectComponent";
import AddDiscount from "../DiscountManagement/AddDiscount";
import deliveryStyle from "../../../styles/Delivery.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../Order Management/OrderDetails";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import { Checkbox } from "antd";
import { set } from "date-fns";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  message as massages,
  Select,
  notification,
  Empty,
} from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import RegionDropDown from "../../modules/ReusableComponent/RegionDropDown";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Order Id", value: "order_id" },
  { _id: 3, status: 1, name: "Region", value: "region" },
  { _id: 4, status: 1, name: "Customer", value: "customer" },
  { _id: 5, status: 1, name: "No. Of Items", value: "noOfItems" },
  { _id: 6, status: 1, name: "Amount", value: "amount" },
  { _id: 7, status: 1, name: "Tax", value: "Tax" },
  { _id: 8, status: 1, name: "Purchase Date", value: "purchase_date" },
  { _id: 9, status: 1, name: "Payment Method", value: "payment_method" },
  { _id: 10, status: 1, name: "Status", value: "status" },
  { _id: 11, status: 1, name: "Action", value: "action" },
];

const filters = [
  { id: 2, isChecked: false, name: "Order Date", value: "date" },
  { id: 3, isChecked: false, name: "Payment Method", value: "PaymentMethod" },
  // { id: 4, isChecked: false, name: "Delivery Status", value: "DeliveryStatus" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const bulkAction = [
  // { _id: 1, isChecked: true, name: 'Order placed successfully', value: 1 },
  { _id: 2, isChecked: true, name: "Order under processing", value: 1 },
  { _id: 3, isChecked: false, name: "On-Hold", value: 6 },
  { _id: 4, isChecked: false, name: "Out for delivery", value: 7 },
  { _id: 5, isChecked: false, name: "Delivered successfully", value: 3 },
  { _id: 6, isChecked: false, name: "Delivery attempted", value: 8 },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

const columns = [
  // { id: 1, name: "All", value: "all" },
  { id: 2, name: "Order Id", value: "order_id" },
  { id: 3, name: "Region", value: "region" },
  { id: 4, name: "Amount", value: "amount" },
  { id: 5, name: "Customer", value: "customer" },
  { id: 6, name: "Payment Method", value: "payment_method" },
  { id: 7, name: "Purchase Date", value: "purchase_date" },
  { id: 8, name: "Status", value: "status" },
  { id: 9, name: "Action", value: "action" },
];

const original_columns = [
  "Order Id",
  "Region",
  "Amount",
  "Customer",
  "Payment Method",
  "Purchase Date",
  "Status",
  "Action",
];

const column_value = {
  order_id: true,
  region: true,
  grand_total: true,
  ship_to_name: true,
  payment_method: true,
  purchase_date: true,
  status: true,
  operate: true,
};

export default function OrderManagementComponent() {
  const accessregion = localStorage.getItem('accessregion')
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const pdfExportComponent = useRef(null);
  const [orderList, setOrderList] = useState([]);
  const [originalColumn, setOriginalColumn] = useState(original_columns);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  // const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentName, setPaymentName] = useState("All Payment")
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const timeoutId = useRef();
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [payMethods, setPayMethods] = useState([
    { _id: 1, name: "CC Avenue", value: "CCAvenue" },
    { _id: 2, name: "payU", value: "payU" },
  ]);
  
  // const [deliveryStatus, setDeliveryStatus] = useState([
  //   { _id: 1, name: "Pending", value: 0 },
  //   { _id: 2, name: "Completed", value: 1 },
  // ]);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [sortorder, setSortorder] = useState();
  const [amountOrder, setSortAmount] = useState();
  const [customerOrder, setSortCustomer] = useState();
  const [regionOrder,setSortRegion] = useState();
  const [taxOrder,setSortTax] = useState();
  const [purchaseOrder,setSortPurchase] = useState();
  const [itemOrder, setSortItem] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [visible, setVisible] = useState(false);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Order Id",
      visible: false,
      key: "order_id",
      render: (text, record, index) => <span>{record.orderId}</span>,
      sorter: true,
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      render: (text, record, index) => <span>{record.regionDetail.name}</span>,
      sorter:true
    },
    {
      title: "No. of Items",
      visible: false,
      key: "noOfItems",
      render: (text, record, index) => (
        <span>
          {record.products?.length}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Amount",
      visible: false,
      key: "amount",
      render: (text, record, index) => (
        <span>
          {record.grandTotal.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Tax",
      visible: false,
      key: "Tax",
      sorter:true,
      render: (text, record, index) => (
        <span>
          {record.AddTax.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "Customer",
      visible: false,
      key: "customer",
      render: (text, record, index) => (
        <span>{record.shippingAddress.name.charAt(0).toUpperCase() + record.shippingAddress.name.substring(1)}</span>
      ),
      sorter: true,
    },
    {
      title: "Payment Method",
      visible: false,
      key: "payment_method",
      render: (text, record, index) => <span>{record.paymentMethod}</span>,
    },
    {
      title: "Purchase Date",
      visible: false,
      key: "purchase_date",
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format("MMM Do YYYY, h:mm:ss A")}</span>
      ),
      sorter:true
    },
    {
      title: "Status",
      visible: false,
      key: "status",
      render: (text, record, index) => <span
        class={
          record.status == "Pending"
            ? commonstyles.status_pending
            : record.status === "Partially completed"
              ? commonstyles.status_pending
              : record.status === "Completed"
                ? commonstyles.status_active
                : commonstyles.status_inactive
        }
      >
        {record.status}
      </span>,
    },
    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) => (
        permissionsArr.length > 0 &&
        (permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" || 
        permissionsArr.find((x) => x === "Sales-Orders-edit") === "Sales-Orders-edit") && (
          <span>
            {permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" && <Tooltip
              placement="topRight"
              title="Edit order"
            >
              <Link to={`/order/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={commonstyles.admin_table_edit_img}
                  // onClick={() => editCustomer(record)}
                  alt="Edit action icon"
                  // title="Edit order"
                  style={{ cursor: "pointer" }}
                />{" "}
              </Link>
            </Tooltip>}
            {permissionsArr.find((x) => x === "Sales-Orders-edit") === "Sales-Orders-edit" && <Tooltip
              placement="topRight"
              title="Delete order"
            >
              <img
                src={adminProductDelete}
                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                onClick={() => showdelete(record)}
                alt="Delete order action icon"
              // title="Delete order"
              />
            </Tooltip>}
          </span>
        )
      ),
    },
  ]);
  const [selectedOrder, setSelectedOrder] = useState({});
  function showdelete(order) {
    setSelectedOrder(order);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  }
  // const [selectedOrder, setSelectedOrder] = useState([]);
  /**
   * @author Rajkumar
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
   * @author Rajkumar
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getOrderDetails(pageSize, nextPage, "");
    localStorage.removeItem("selectedOrder");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    }
    return () => {
      localStorage.removeItem("orderList");
      localStorage.removeItem("selectedOrder");
      filters?.forEach(item => {
        item.isChecked = false;
      })
      setSelectFilter(filters);
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getOrderDetails(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }

    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.paymentMethod) {
      filterOptions.paymentMethod = filterarr.paymentMethod;
    } else if (paymentMethod !== "") {
      if (filterarr && filterarr.paymentMethod !== "") {
        filterOptions.paymentMethod = paymentMethod;
      }
    }
    
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterDays !== "" && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filterDays);
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.amountOrder !== "") {
      filterOptions.amountOrder = Number(filterarr.amountOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.amountOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.customerOrder !== "") {
      filterOptions.customerOrder = Number(filterarr.customerOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.customerOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.itemOrder !== "") {
      filterOptions.itemOrder = Number(filterarr.itemOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.itemOrder = Number(sortorder);
      }
    }

    if (filterarr && filterarr.regionOrder !== "") {
      filterOptions.regionOrder = Number(filterarr.regionOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.regionOrder = Number(sortorder);
      }
    }

    if (filterarr && filterarr.purchaseOrder !== "") {
      filterOptions.purchaseOrder = Number(filterarr.purchaseOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.purchaseOrder = Number(sortorder);
      }
    }

    if (filterarr && filterarr.taxOrder !== "") {
      filterOptions.taxOrder = Number(filterarr.taxOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.taxOrder = Number(sortorder);
      }
    }

    if (filterarr && filterarr.paymentMethod) {
      filterOptions.paymentMethod = filterarr.paymentMethod;
    } else if (filteredDate.paymentMethod !== "") {
      if (filterarr && filterarr.paymentMethod !== "") {
        filterOptions.paymentMethod = paymentMethod;
      }
    }

    if (!filterOptions?.filterDays) delete filterOptions.filterDays;
    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    if (!filterOptions?.paymentMethod) delete filterOptions.paymentMethod;

    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/orderList", filterOptions)
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data.data.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
              let totalPrdTaxAmount = item?.products?.reduce(function (prev, current) {
                return prev + +current.taxAmount
              }, 0);
              item["AddTax"] = parseFloat(totalPrdTaxAmount.toFixed()) + parseFloat(item?.additionalTaxAmount.toFixed());
            });
            localStorage.setItem("orderList", JSON.stringify(res.data.data));
            setOrderList(res.data.data);
            setPagination(res.data.pagination);
          } else {
            localStorage.removeItem('orderList');
            setOrderList([]);
            setPagination({});
          }
        }
        else {
          setOrderList([]);
          setPagination({});
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      itemOrder: itemOrder,
      regionOrder:regionOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOrderDetails(value, 1, updatedFilter, true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOrderDetails(pageSize, value, updatedFilter, true);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function editCustomer(user) {
    setSelectedCustomer(user);
    // console.log(user);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  }

  function closeEditProduct() {
    getOrderDetails(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  if (childComponentVisibility.addProduct) {
    // console.log(childComponentVisibility.addProduct);
    return (
      <AddDiscount
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditOrderComponent
        customer={selectedCustomer}
        handleClose={closeEditProduct}
      />
    );
  }

  function handleInput(e, type) {
    const { value } = e.target;


    setSearch(value);

    if (!value) {
      
    let updatedFilter = {
      search: "",
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      regionId : regionId,
      paymentMethod:paymentMethod,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      itemOrder: itemOrder,
      regionOrder:regionOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    clearTimeout(timeoutId.current);
    setNextPage(1);
    timeoutId.current = setTimeout(() => {
      getOrderDetails(pageSize, 1, filter, false);
    }, 1000);
  }
  
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      regionId : regionId,
      paymentMethod:paymentMethod,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      itemOrder: itemOrder,
      regionOrder:regionOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    if (name === "paymentMethod") {
      updatedFilter.paymentMethod = value?.value;
      setPaymentMethod(value?.value);
      setPaymentName(value?.name);
    }
    debounceSearch(updatedFilter);
  }

  /**
   * @author Rajkumar
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      regionOrder:regionOrder,
      itemOrder: itemOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };
    debounceSearch(updatedFilter);
  };
  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  }

  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;

    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/orderExport", params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach(item => {
            if (item['CREATED AT']) {
              item['CREATED AT'] = moment(item['CREATED AT'], "DD-MM-YYYY HH:mm:ss").format("MMM Do YYYY, h:mm:ss A")
            }
          })

          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);

          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `order-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const onMultiSelectChangeHandler = (value) => {
    let name = [];
    value.forEach((item) => {
      name.push(item.split("*&^")[1]);
    });
    if (name.length > 0) {
      setOriginalColumn(name);
      orderList.forEach((item) => {
        item.isShow = [];
        value.forEach((column) => {
          item.isShow[column.split("*&^")[0]] = true;
        });
      });
      setOrderList(orderList);
    } else {
      orderList.forEach((item) => {
        item.isShow = { ...column_value };
      });
      setOrderList(orderList);
      setOriginalColumn(original_columns);
    }
  };
  /**
  * @author Rajkumar
  * @param {event,record}
  * TO SHOW TABLE HEADERS BASED ON STATUS
  */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };
  /**
   * @author Rajkumar
   * @param {event,record}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
   
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      regionOrder:regionOrder,
      itemOrder: itemOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "PaymentMethod") {
          updatedFilter.paymentMethod = "";
          setPaymentMethod("");
          setPaymentName("All Payment");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        // if (item.value === "DeliveryStatus") {
        //   updatedFilter.orderStatus = "";
        //   setOrderStatus("");
        // }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && !accessregion && (
                <div className="card_dropdown show col-md-6">
                  
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {regionName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    <RegionDropDown 
                      filterRegionChange = {filterRegionChange}
                    />
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "PaymentMethod") ===
              "PaymentMethod" && (
                <div className="card_dropdown show col-md-6">
                 
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {paymentName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(payMethods)
                      ? payMethods.map((pay) => (
                        <span key={pay._id} onClick={() => filterRegionChange("paymentMethod", pay)}>
                          <label>{pay.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {filterDaysName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span key={day._id} onClick={() => filterRegionChange("filterDays", day)}>
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {/* <div className={`${commonstyles.card_dropdown} ${commonstyles.show} col-md-6`}>
                    <div className={`${commonstyles.filter_heading} ${commonstyles.card_dropdown_heading}`}>
                      Select Filter <img src={arrowDown} width={10} height={20} />
                    </div>
                    <div className={`${commonstyles.card_dropdown_contents}`}>
                      <span>Region</span>
                      <span>Date</span>
                      <span>Payment Status</span>
                      <span>Delivery Status</span>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
    </Menu>
  );
  /**
   * @author Rajkumar
   * @param {data}
   * BUILD STATUS CLICK HANDLER
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedOrder")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (selectedOrder.length > 0) {
        let data = {
          orderIds: selectedOrder,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedOrder");
          const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
          setOrderList(cloneOrderList);
          getOrderDetails(pageSize, nextPage, "");
        } else if (res && (res.data.status === "400" || res.data.status === 400)) {
          setIsLoading(false);
          showNotification({
            type: "error",
            message: "Error",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedOrder");
          const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
          setOrderList(cloneOrderList);
          getOrderDetails(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function cancelDelete() {
    setSelectedOrder({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      filterDays: filterDays,
      regionId : regionId,
      paymentMethod:paymentMethod,
      sortOrder: sortorder,
      amountOrder: amountOrder,
      customerOrder: customerOrder,
      itemOrder: itemOrder,
      regionOrder:regionOrder,
      taxOrder:taxOrder,
      purchaseOrder:purchaseOrder
    };
    debounceSearch(updatedFilter);
  }

  const removeOrder = () => {
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/deleteOrder/${selectedOrder._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "success",
            description: res.data.msg,
          });
          getOrderDetails(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => { });
  };

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {

    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
    };
    if (sorter?.columnKey === "order_id") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
      setSortRegion();
      setSortCustomer();
      setSortItem();
      setSortAmount();
      setSortTax();
      setSortPurchase();
    } else if (sorter?.columnKey === "amount") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.amountOrder = sortValue;
      setSortAmount(sortValue);
      setSortorder();
      setSortRegion();
      setSortCustomer();
      setSortItem();
      setSortTax();
      setSortPurchase();
    } else if (sorter?.columnKey === "customer") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.customerOrder = sortValue;
      setSortCustomer(sortValue);
      setSortorder();
      setSortRegion();
      setSortItem();
      setSortAmount();
      setSortTax();
      setSortPurchase();
    } else if (sorter?.columnKey === "noOfItems") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.itemOrder = sortValue;
      setSortItem(sortValue);
      setSortorder();
      setSortRegion();
      setSortCustomer();
      setSortAmount();
      setSortTax();
      setSortPurchase();
    }else if (sorter?.columnKey === "region") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.regionOrder = sortValue;
      setSortRegion(sortValue);
      setSortorder();
      setSortCustomer();
      setSortItem();
      setSortAmount();
      setSortTax();
      setSortPurchase();
    }else if (sorter?.columnKey === "Tax") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.taxOrder = sortValue;
      setSortTax(sortValue);
      setSortorder();
      setSortRegion();
      setSortCustomer();
      setSortItem();
      setSortAmount();
      setSortPurchase();
    }else if (sorter?.columnKey === "purchase_date") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.purchaseOrder = sortValue;
      setSortPurchase(sortValue);
      setSortorder();
      setSortRegion();
      setSortCustomer();
      setSortItem();
      setSortAmount();
      setSortTax();
    }
    getOrderDetails(pageSize, nextPage, updatedFilter, true);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Orders
        </h1>
        
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
          <div >
            <span className={commonstyles.management_header_downloads_text}>
              <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img className={commonstyles.export_icon} src={pdf_icon} style={{ cursor: "pointer" }} onClick={(e) => exportToPDF(e)} />
              </Tooltip>
              <Tooltip
                placement="topRight"
                title="Download excel"
              >
                <img src={excelIcon} className={commonstyles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportToCSV(e)} />
              </Tooltip>
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
        }
      </div>


      <div className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}>

        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
          <div
            className={`${commonstyles.management_contents_search_bar} input-group`}
          >
            <InputComponent
              className="with-icon rnd theme-txt-xsm"
              formControl={{
                placeholder: "Search by order ID, Customer...",
                name: "search",
                style: { height: "38px" },
                value: search,
                onChange: handleInput,
                ["aria-label"]: "Search category",
                ["aria-describedby"]: "search-category",
              }}
            />

            <img src={Search} alt="" />

          </div>
        }
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
          <div className="action ml-3">
            <div className="action_header">
              Action
              <img src={arrowDown} alt="image" />
            </div>
            <div className="action_contents">
              {bulkActionFilter.length > 0 &&
                bulkActionFilter.map((item) => (
                  <div
                    className="action_contents_header"
                    key={item._id}
                    onClick={() => bulkStatusClickHandler(item)}
                  >
                    <h2
                      className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                        }`}
                    >
                      {item.name}
                    </h2>
                    {item.isChecked && (
                      <span
                        className="action_contents_btn"
                        onClick={() => applyBulkActionHandler(item)}
                      >
                        Apply
                      </span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        }
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
          <Dropdown
            overlay={filterMenu}
            onVisibleChange={handleVisibleChange} visible={visible}
            className="usermanage_table_filter_columns ml-3"
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
        }
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
          <Dropdown
            overlay={menu}
            className="usermanage_table_filter_columns  ml-3"
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Columns <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
        }

      </div>

      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
        <div className={`${commonstyles.management_table} table-responsive`}>
          {!isLoading && orderList.length > 0 && (
            <input
              type="checkbox"
              className={`${commonstyles.parentCheckbox}`}
              onChange={parentCheckbox}
            ></input>
          )}
          {isLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching order details..." />
                </td>
              </tr>
            </div>
          ) : (
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={40}
              fileName={`OrderList for ${new Date().getFullYear()}`}
              author="Damro Team"
            >

              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: orderList,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </PDFExport>

          )}
        </div>
      }
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Orders-view") === "Sales-Orders-view" &&
        <div>
          {!isLoading && Array.isArray(orderList) && orderList.length ? (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          ) : null}
        </div>
      }
      {/* Order delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.delete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeOrder}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
      {/* Order delete confirmation modal ends*/}
    </div>
  );
}
