import React, { useState, useEffect, useRef } from "react";
import blackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import exclusionIcon from "../../../../assets/images/exclusion.svg";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/AddCategory.module.css";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import SwitchComponent from "../../../modules/ReusableComponent/SwitchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import validationRules from "../../../../utils/validationRules";
import { notification, Select, Tooltip } from "antd";
import { ExportCSV } from "../../../modules/ReusableComponent/ExportCsv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import { Catalogwithletters } from "../../../../utils/RegularExp";
import is from "date-fns/esm/locale/is/index.js";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { drawGraph } from "../../../DrawGraph/DrawGraph";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import moment from "moment";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { clone, upperCase, upperFirst } from "lodash";
import MaskGraph from "../../../../assets/images/mask_group_126.png";

const tabs_ = [
  {
    id: 1,
    name: "Most Viewed Products",
    isActive: true,
  },
  {
    id: 2,
    name: "Most Sold",
    isActive: false,
  },
  {
    id: 3,
    name: "Least Sold",
    isActive: false,
  },
  {
    id: 4,
    name: "Popular Color",
    isActive: false,
  },
  {
    id: 5,
    name: "Most Searched Keywords",
    isActive: false,
  },
  {
    id: 6,
    name: "Latest Searched Keyword",
    isActive: false,
  },
];

const defaultPageSize = 10;

export default function ProductReportsHome(props) {
  const pdfExportComponent = useRef(null);
  const history = useHistory();
  const { state } = history.location;
  const [buttons, setButtons] = useState(tabs_);
  const [activeBtnName, setActiveBtnName] = useState(
    state ? state : "Most Viewed Products"
  );
  const [barGraph, setBarGraph] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [regions, setRegions] = useState([]);
  const columns_ = [
    {
      title: "Product",
      visible: false,
      render: (text, record, index) => <span>{record.name}</span>,
    },
    {
      title: "Category",
      visible: false,
      render: (text, record, index) => (
        <span>{record.categoryNames ? record.categoryNames : "-"}</span>
      ),
    },
    {
      title: "Region",
      visible: false,
      render: (text, record, index) => <span>{record.regionNames}</span>,
    },
    {
      title: "Products Viewed",
      visible: false,
      render: (text, record, index) => <span>{record?.viewCount}</span>,
    },
    {
      title: "Products Sold",
      visible: false,
      render: (text, record, index) => <span>{record?.soldCount}</span>,
    },
  ];
  const columns1 = [
    {
      title: "Product",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => <span>{record._id}</span>,
    },
    // {
    //   title: "Category",
    //   visible: false,
    //   //   key: "Category",
    //   render: (text, record, index) => <span>{record.categoryNames}</span>,
    // },
    {
      title: "Category / Sub Category",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => <span>{record.categoryNames}</span>,
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.regionNames ? record.regionNames : "-"}</span>
      ),
    },
    {
      title: "Product Viewed",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.viewCount ? record.viewCount : "-"}</span>
      ),
    },
    {
      title: "Most Sold",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>{record.most_sold ? record.most_sold : "-"}</span>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Product",
      visible: false,
      render: (text, record, index) => <span>{record._id}</span>,
    },
    {
      title: "Category / Sub Category",
      visible: false,
      render: (text, record, index) => <span>{record.categoryNames}</span>,
    },
    {
      title: "Region",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.regionNames ? record.regionNames : "-"}</span>
      ),
    },
    {
      title: "Product Viewed",
      visible: false,
      render: (text, record, index) => (
        <span>{record['viewCount'] ? record['viewCount'] : "-"}</span>
      ),
    },
    {
      title: "Least Sold",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>{record['least_sold'] ? record['least_sold'] : "-"}</span>
      ),
    },
  ];
  const columns3 = [
    {
      title: "Keywords",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{upperFirst(record.searchKey)}</span>
      ),
    },
    {
      title: "Result",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.result}</span>,
    },
    {
      title: "No. Clicks",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => <span>{record.clicks}</span>,
    }
  ];
  const columns4 = [
    {
      title: "Category / Sub Category",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.categoryNames ? record.categoryNames : "-"}</span>
      ),
    },
    {
      title: "Product",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record?._id? record?._id : "-"}</span>,
    },
    {
      title: "Region",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          {record?.regionNames? record?.regionNames : "-"}
        </span>
      ),
    },
    {
      title: "Color",
      visible: false,
      //   key: "totalItemSold",
      render: (text, record, index) => (
        <span>
          {record?.color? record?.color : "-"}
        </span>
      ),
    },
    {
      title: "Product Sold",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{record.productSold ? record.productSold : "-"}</span>
      ),
    }
  ];
  const columns5 = [
    {
      title: "Order ID",
      visible: false,
      //   key: "Product",
      render: (text, record, index) => (
        <span>{record.orderId ? record.orderId : "-"}</span>
      ),
    },
    {
      title: "Invoice ID",
      visible: false,
      //   key: "Category",
      render: (text, record, index) => <span>{record.invoiceId}</span>,
    },
    {
      title: "Invoice Date",
      visible: false,
      //   key: "Numberofitemssold",
      render: (text, record, index) => (
        <span>{moment(record.invoiceDate).format("DD-MM-YYYY | hh:mm A")}</span>
      ),
    },
    {
      title: "Total Invoice Amount",
      visible: false,
      render: (text, record, index) => (
        <span>
          Rs.{" "}
          {record.totalInvoiceAmount
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      ),
    },
  ];
  const [columns, setColumns] = useState(columns_);
  const [tableList, setTableList] = useState([]);
  const [mostViewedPrdList, setMostViewedPrdList] = useState([]);
  const [filterName, setFilterName] = useState("Monthly");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionExportData, setTransactionExportData] = useState([]);
  const [topSelling, setTopSelling] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  // const [topSellingTotal, setTopSellingTotal] = useState("");
  const [topCategoryBack, setTopCategoryBack] = useState([]);
  const [selectedTopId, setSelectedTopId] = useState("");
  const [categoryTitle, setCategoryTitle] = useState(["Top Selling"]);

  useEffect(() => {
    // selectedBtn(tabs_[0], true);/
    tabs_?.forEach((item) => {
      if (item.name === (state? state:"Most Viewed Products")) {
        item.isActive = true;
        selectedBtn(item, true);
      } else {
        item.isActive = false;
      }
    });
    setButtons(tabs_);
    getRegionList();
    let params = {
      type: filterName,
      activeBtName: state,
    };
    getMostViewedProductGraph(params);
    getMostViewedProductList(params);
  }, []);

  useEffect(() => {
    let arr = [];
    if(topSelling?.activeBtnName === "Popular Color") {
      arr = topSelling.grpData;
    } else {
      arr = topSelling;
    }
    let pieTopCategoryValue = arr?.map(function (item) {
      if(arr?.activeBtnName === "Most Sold" || arr?.activeBtnName === "Least Sold") {
        return item["name"];
      } else if(arr?.activeBtnName === "Popular Color") {
        return item["name"];
      } else {
        return item["categoryName"];
      }
    });
    let pieTotal = arr?.map(function (item) {
      if(arr?.activeBtnName === "Most Sold" || arr?.activeBtnName === "Least Sold" ) {
        return item["totalProducts"];
      } else if(arr?.activeBtnName === "Popular Color") {
        return item["value"];
      } else {
        return item["productViewCounts"];
      }
    });
    // let totalTop = Math.abs(topSellingTotal) > 999 ? Math.sign(topSellingTotal) * ((Math.abs(topSellingTotal) / 1000).toFixed(1)) + 'k' : Math.sign(topSellingTotal) * Math.abs(topSellingTotal);

    let pieTrace;
    pieTrace = {
      values: pieTotal,
      labels: pieTopCategoryValue,
      hoverinfo: "label<br />values",
      //   insidetextorientation: "radial",
      type: "pie",
      textinfo: "none",
      marker: {
        colors: ["orange", "#A0522D"],
      },
    };
    let chart = drawGraph(
      [pieTrace],
      null,
      null,
      "pie",
      null,
      null,
      550,
      400,
      null,
      null,
      true
    );
    setPieChart(chart);
  }, [topSelling]);

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function getMostViewedProductGraph(params) {
    setIsLoading(true);
    let filterOptions = {
      categoryId: topCategoryBack[topCategoryBack.length - 1]
        ? topCategoryBack[topCategoryBack.length - 1]
        : null,
    };
    if (params?.regionId) {
      filterOptions.regionId = params.regionId;
    }
    let url = "";
    if (params?.activeBtName === "Most Sold") {
      url = "/report/product/mostSoldProductsGraph";
    } else if (params?.activeBtName === "Least Sold") {
      url = "/report/product/leastSoldProductsGraph";
    } else if (params?.activeBtName === "Popular Color") {
      url = "/report/product/popularColorGraph";
    } 
    // else if (params?.activeBtName === "Most Searched Keywords") {
    //   url = "/getProductAnalytics";
    //   filterOptions.type = 0;
    //   filterOptions.nextpage = 1;
    //   filterOptions.pagesize = defaultPageSize;
    // } else if (params?.activeBtName === "Latest Searched Keyword") {
    //   url = "/getProductAnalytics";
    //   filterOptions.type = 1;
    //   filterOptions.nextpage = 1;
    //   filterOptions.pagesize = defaultPageSize;
    // } 
    else {
      url = "/report/product/mostViewedProductGraph";
    }
    // if(params?.activeBtName !== "Most Searched Keywords" && params?.activeBtName !== "Latest Searched Keyword") {
    //   if(filterOptions.type!=="") {
    //     delete filterOptions.type;
    //   }
    // }
    // if(!filterOptions.categoryId) {
    //   delete filterOptions.categoryId;
    // }
    continueToGetGraphData(filterOptions, url, params);
  }

  const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

  function continueToGetGraphData(filterOptions, url, params_) {
    let activeBtn = params_?.activeBtName
    if (!filterOptions.regionId) delete filterOptions.regionId;
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(url, filterOptions)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          data.forEach(item => {
            item.totalProducts = parseFloat(item.totalProducts)
          })
          data['activeBtnName'] = activeBtn;
          if(activeBtn === "Popular Color") {
            const groupByColor = groupBy('color');
            const groupByCategory = groupBy('category');
            let dataByColor = groupByColor(data);
            let dataByCategory = groupByCategory(data);
            let grpData = [];
            let grpData1 = [];
            Object.keys(dataByColor).forEach(function(key, index) {
              let obj = {};
              obj.name = key;
              if(dataByColor[key]?.length > 1) {
                obj.value = dataByColor[key].reduce((prev, current) => {
                  return prev + +current.totalProducts
                }, 0)
              } else if(dataByColor[key]?.length === 1) {
                obj.value = dataByColor[key][0].totalProducts
              }
              grpData.push(obj);
            });
            Object.keys(dataByCategory).forEach(function(key, index) {
              let obj1 = {};
              obj1.categoryName = key;
              if(dataByCategory[key]?.length > 1) {
                obj1.totalProducts = dataByCategory[key].reduce((prev, current) => {
                  return prev + +current.totalProducts
                }, 0);
              } else if(dataByCategory[key]?.length === 1) {
                obj1.totalProducts = dataByCategory[key][0]?.totalProducts
              }
              obj1.id = dataByCategory[key][0]?.categoryId
              grpData1.push(obj1);
            });
            grpData1['activeBtnName'] = activeBtn;
            grpData['activeBtnName'] = activeBtn;
            grpData1['grpData'] = grpData;
            setTopSelling(grpData1);
          } else {
            setTopSelling(data);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function handleTopCategory(id, name) {
    setSelectedTopId(id);
    let title = categoryTitle;
    title.push(name);
    setCategoryTitle(title);
    let category = topCategoryBack;
    category.push(id);
    setTopCategoryBack(category);
    let params = {
      regionId: regionId,
      activeBtName: activeBtnName,
    };
    getMostViewedProductGraph(params);
  }

  function getMostViewedProductList(params) {
    if (params.regionId === "") delete params.regionId;
    delete params.type;
    setIsLoading(true);
    let url = "";
    if (params?.activeBtName === "Most Sold") {
      url = "/report/product/mostSoldList";
    } else if (params?.activeBtName === "Least Sold") {
      url = "/report/product/leastSoldList";
    } else if (params?.activeBtName === "Popular Color") {
      url = "/report/product/popularColorList";
    } else {
      url = "/report/product/getMostViewedProductList";
    }
    // if (params?.activeBtName === "" || params?.activeBtName)
    //   delete params.activeBtName;
    continueToFetch(params, url);
  }

  function continueToFetch(params, url) {
    let aBtn = params.activeBtName;
    delete params?.activeBtName;
    if (!params.regionId) delete params.regionId;
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(url, params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          if(aBtn === "Most Viewed Products") {
            setColumns(columns_)
            setMostViewedPrdList(data);
            setTableList(data?.mostViewedProductList);
            setTransactionExportData(data?.exportMostViewdProductList);
          } else if(aBtn === "Most Sold") {
            setColumns(columns1)
            setTableList(data?.mostSoldProductList);
            setTransactionExportData(data?.exportSoldProductList);
          } else if (aBtn === "Least Sold") {
            setColumns(columns2)
            setTableList(data?.leastSoldProductList);
            setTransactionExportData(data?.exportleastSoldProductList)
          } else if (aBtn === "Popular Color") {
            setColumns(columns4)
            setTableList(data?.popularColorList);
            setTransactionExportData(data?.exportColorList);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  const selectedBtn = (item, type) => {
    if(!isLoading) {
    setIsLoading(true);
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.id === item.id) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
    });
    setButtons(cloneButtons);
    setFilterName("Monthly");
    setRegionId("");
    setActiveBtnName(item.name);
    let params = {
      type: "Monthly",
      regionId: "",
      activeBtName: item.name,
    };
    if(item?.name !== "Most Searched Keywords" && item?.name !== "Latest Searched Keyword") {
      getMostViewedProductGraph(params);
      getMostViewedProductList(params);
    }
    if (item?.name === "Most Searched Keywords") {
      params.type = 0;
      params.nextpage = 1;
      params.pagesize = defaultPageSize;
      getProductAnalyticsList(params);
      exportSearchAnalysis(params);
    } else if (item?.name === "Latest Searched Keyword") {
      params.type = 1;
      params.nextpage = 1;
      params.pagesize = defaultPageSize;
      getProductAnalyticsList(params);
      exportSearchAnalysis(params);
    } 
    } 
  };

  function getProductAnalyticsList(params) {
    let aBtn = params.activeBtName;
    delete params?.activeBtName;
    delete params?.regionId;
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getProductAnalytics", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          setColumns(columns3)
          setTableList(data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function exportSearchAnalysis(params) {
    let aBtn = params.activeBtName;
    delete params?.activeBtName;
    delete params?.nextpage;
    delete params?.pagesize;
    delete params?.regionId;
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/exportSearchAnalysis", params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          setTransactionExportData(data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    let params = {
      // type: filterName,
      regionId: regionId,
    };
    if (name === "regionId") {
      setRegionId(value);
      params.regionId = value;
    }
    let item = {};
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.name === activeBtnName) {
        item = x;
      }
    });
    let activebttn = {activeBtName: activeBtnName};
    let url = "";
    let url1 = "";
    if (activeBtnName === "Most Viewed Products") {
      url = "/report/product/mostViewedProductGraph";
      url1 = "/report/product/getMostViewedProductList"
    } else if(activeBtnName === "Most Sold") {
      url = "/report/product/mostSoldProductsGraph";
      url1 = "/report/product/mostSoldList"
    } else if(activeBtnName === "Least Sold") {
      url = "/report/product/leastSoldProductsGraph";
      url1 = "/report/product/leastSoldList"
    } else if(activeBtnName === "Popular Color") {
      url = "/report/product/popularColorGraph";
      url1 = "/report/product/popularColorList"
    }
    continueToGetGraphData(params, url, activebttn);
    let paramss = {...params, activeBtName: activeBtnName}
    continueToFetch(paramss, url1);
  }

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const exportToCSV = async (e) => {
    continueToExport(transactionExportData);
  };

  function continueToExport(response) {
    let data = [...response];
    let und_res = data;
    // const fileType =
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(und_res);

    ws["A1"].v = upperCase(ws["A1"].v);
    ws["B1"].v = upperCase(ws["B1"].v);
    ws["C1"].v = upperCase(ws["C1"].v);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    /* generate an XLSX file */
    XLSX.writeFile(
      wb,
      `${activeBtnName}-export-${moment(new Date())
        .format("YYYY-MM-DD")
        .split("-")
        .join("")}.xlsx`
    );
  }

  function handleBack() {
    // setIsLoadingDash(true);
    //title pop(-1)
    let title = categoryTitle;
    title.pop();
    setCategoryTitle(title);
    //categoryId pop(-1) to an array
    let category = topCategoryBack;
    category.pop();
    setTopCategoryBack(category);
    // console.log(topCategoryBack)
    let params = {
      regionId: regionId,
      activeBtName: activeBtnName,
    };
    getMostViewedProductGraph(params);
  }

  function handleTop(e) {
    if(activeBtnName === "Most Searched Keywords") {
      history.push({
        pathname: `/analytics`,
        page: "dashboardTop",
      });
    } else {
      history.push({
        pathname: `/analytics`,
        page: "dashboard",
      });
    }
  }

  return (
    <div className={styles.management_contents}>
      <div className={`${styles.management_header}  mb-0 no_bottom_broder`}>
        <h1 className={styles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/reports")}
          />
          Product Reports
        </h1>
      </div>
      <div
        className={`${styles.management_details} d-flex justify-content-between mb-4`}
      >
        <div className="d-flex justify-content-start">
          {activeBtnName !== "Most Searched Keywords" && activeBtnName !== "Latest Searched Keyword" && (
            <div>
              <select
                className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
                style={{ cursor: "pointer" }}
                name="regionId"
                value={regionId}
                onChange={filterOnChange}
              >
                {regions?.length > 1 &&  <option value="">All Region</option>}
                {Array.isArray(regions)
                  ? regions.map((region) => (
                      <option key={region._id} value={region._id}>
                        {region.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          )}
          {/* {activeBtnName !== "Most Viewed Products" && (
            <div>
              <select
                className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
                style={{ cursor: "pointer" }}
                name="filterName"
                value={filterName}
                onChange={filterOnChange}
              >
                <option selected value="Monthly">
                  Monthly
                </option>
                <option selected value="Weekly">
                  Weekly
                </option>
                <option selected value="Daily">
                  Daily
                </option>
              </select>
            </div>
          )} */}
        </div>
        <div>
          <span className={commonstyles.management_header_downloads_text}>
            <Tooltip placement="topRight" title="Download pdf">
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
                alt=""
                onClick={(e) => exportToPDF(e)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Download excel">
              <img
                src={excelIcon}
                className={commonstyles.management_header_downloads_icon}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              />
            </Tooltip>
          </span>
          <span className={commonstyles.management_header_downloads_text_ex}>
            Export <img src={exportIcon} alt="icon" />
          </span>
        </div>
      </div>

      <div
        className={`${commonstyles.management_header} ${commonstyles.abandone_tab_btn} justify-content-start pb-0`}
      >
        {buttons?.length > 0 &&
          buttons?.map((item) => (
            <button
              key={item.id}
              className={`${commonstyles.sidebarButton} 
                            ${
                              item.isActive && commonstyles.sidebarButton_active
                            } 
                            d-inline-block mb-0 mr-2`}
              onClick={() => selectedBtn(item)}
            >
              {item.name}
            </button>
          ))}
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching report details..." />
            </td>
          </tr>
        </div>
      ) : (
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName={`${activeBtnName} for ${new Date().getFullYear()}`}
          author="Damro Team"
        >
          <div className="row ml-0 mr-0">
            {activeBtnName !== "Most Searched Keywords" && activeBtnName !== "Latest Searched Keyword"&&<div className={`${commonstyles.salesreport_card} col-lg-12 mb-4`}>
              <h4>
                {topCategoryBack.length > 0 && (
                  <img
                    src={BlackLeftArrow}
                    alt=""
                    className="cursor-pointer"
                    onClick={handleBack}
                  />
                )}{" "}
                {activeBtnName}
              </h4>
              <div className={`${styles.dashboard_card} mb-4`}>
                {/* <h1 style={{fontWeight:'bold'}} className={`${styles.dashboard_card_title} mb-3`}>
                                    {categoryTitle[categoryTitle.length - 1]} Categories
                                </h1> */}
                {categoryTitle[1]!="Offers"?
                <div className={`row`}>
                  <div className={`col-md-4 ${styles.top_selling_category}`}>
                    {Array.isArray(topSelling) &&
                      topSelling.length > 0 &&
                      topSelling.map((topSell, indx, arr) => (
                        <div
                          className={`media ${styles.top_selling_category_item}`}
                          key={topSell.id? topSell.id:topSell._id}
                        >
                          <img className="mr-3" src={MaskGraph} alt="" />
                          <div
                            className={`${styles.top_selling_category_content}`}
                          >
                            <a
                              style={
                                topSell.totalProducts === 0 ||
                                topSell.level != 0
                                  ? { cursor: "default", pointerEvents: "none" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() =>
                                topSell.totalProducts > 0 && topSell.level ===0 && 
                                handleTopCategory(
                                  topSell.id? topSell.id:topSell._id,
                                  topSell.categoryName? topSell.categoryName : topSell.name
                                )
                              }
                            >
                              <h4>{topSell.categoryName? topSell.categoryName : topSell.name}</h4>
                            </a>
                            <p>{topSell.totalProducts} Products</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className={`col-md-8`}>{pieChart}</div>
                </div>:
                <div style={{textAlign:"center"}}>
                  <h1>No child category found</h1>
                  </div>}
              </div>
            </div>}
            <div className={`${commonstyles.salesreport_card} col-lg-12`}>
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: tableList,
                  pagination: false,
                }}
              />
              {(activeBtnName === "Most Searched Keywords" || activeBtnName === "Latest Searched Keyword")&&
                <div className="col-md-12 text-center mt-4">
                  <button onClick={handleTop} className="btn btn-link text-color-brown">see more</button>
                </div>
              }
            </div>
          </div>
        </PDFExport>
      )}
    </div>
  );
}
