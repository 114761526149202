import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum, alphanumericwithdashHyphen, alphanumericwithdashHyphenWithLimit, userNameWithSpace } from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import FaqFormComponent from "../../modules/ProductManagement/FaqFormComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
const { Option } = Select;

/* const initialaddFormState = {
 faq: [
  { Question: "", Answer: "" }
 ],
};


const rules = {
 faq: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validateFaqItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
};

function validateFaqItem(accumulator, currentFaq, indx) {
  const error = {};
  ["Question", "Answer"].forEach((field) => {
    if (validationRules.required(currentFaq[field]) !== true) {
      error[field] = "Required";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
} */


export default function AddBannerComponent() {

    const initialFormState = {
        status: true,
        name: "",
        slug: "",
        image: "",
        discount:"",
        regionId: [],
        categoryId: [],
        type:"",
        sort:"",
      };
      const rules = {
        name: [validationRules.required],
        slug: [validationRules.required],
        // image: [(val, data) => !file && "Required"],
        image: [(val, data) => (!url && !file ? "Banner Image Required" : true)],
        discount: [validationRules.required],
        // regionId: [validationRules.required],
        type:[validationRules.required],
        sort:[validationRules.required],
      };

  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [categoryNames,setCategoryNames] = useState([])
  const filterCategoryIds=Array.isArray(editForm.categoryId) ? editForm.categoryId.filter((item, index) =>        editForm.categoryId.indexOf(item) === index) : []; 
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);

  //file
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  /* category */
  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName/")
      .then((res) => {
        //console.log(res.data)
        if (res && res.data && res.status == "200") {
          // console.log(res.data.daaata);
          // const data = transformData(res.data.daaata);
          //setTreeData(data);
          setCategoryNames(res.data.daaata);
          //   setCategoryData(res.data.data);
          //   setPagination(res.data.pagination);
          
        }
      })
      .catch((er) => {
        console.log("Failed to get data on Category tree select");
        console.log(er);
      });
  }

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            // res.data.daaata.unshift({
            //   name: "All",
            //   _id: "All",
            // });
            setRegions(res.data.daaata);
          } /* else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);

            // setFormErrors(errors);
          } */
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);

  function handleRegionChange(e) {
    editForm.regionId = e;
    setEditForm({ ...editForm });
  }

  function handleImageInput(e) {
    const { name, files } = e.target;
    // console.log(e.target.files[0]);
    if (files[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
    }else{
        setFile(e.target.files[0]);
        if (url) {
          URL.revokeObjectURL(url);
        }
        const generatedUrl = URL.createObjectURL(e.target.files[0]);
        setUrl(generatedUrl);
        editForm.image = generatedUrl;
        // console.log(file);

    }
    
  }

  function removeFile() {
    if (url) {
      editForm.image = "";
      setFile(null);
      setUrl(null);
    }
  }

  useEffect(() => {
    const { state } = history.location;
    // console.log(state);
    if (state?._id) {
      getBannerDetails(state._id);
    }else{
      setIsLoading(false);
    }
    /* getRegionList();  */
  }, []);

  

   const [editZoneId, setEditZoneId] = useState('');

   function getBannerDetails(id) {
    //  console.log(id);
   setLoading({ details: true, update: false });
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/banner/bannerDetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          // console.log(val)
          setEditForm(val);
          setUrl(val.banner);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

   /**
   * @author Mithun Prabhu
   * @param {Event}
   * INPUT HANDLE CHANGE 
   */
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    // console.log(name, value);
      if(name==="slug"){
        value=value.trim();
        if (!e.target.value.match(alphanumericwithdashHyphen) || !e.target.value === "") {
          formErrors.slug = "Enter only alphabet and number";
        }else{
          formErrors.slug = "";
        }
      }
      if(name==="name"){
        if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
          formErrors.name = "Enter only alphabet and minimum 3";
        }else{
          formErrors.name = "";
        }
      } 
      if(name==="type"){
        if (!e.target.value === "") {
          setFormErrors({...formErrors,type : "Required"})
        }else{
          setFormErrors({...formErrors,type : ""})
        }
      }if(name==="discount"){
        if (!e.target.value === "") {
          setFormErrors({...formErrors,discount : "Required"})
        }else{
          setFormErrors({...formErrors,discount : ""})
        }
      }if(name==="sort"){
        if (!e.target.value === "") {
          setFormErrors({...formErrors,sort : "Required"})
        }else{
          setFormErrors({...formErrors,sort : ""})
        }
      }
    const updatedValues = { ...editForm };
    if (["status"].includes(name)) {
        updatedValues[name] = checked;
      } else {
        updatedValues[name] = value;
      }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }
  
  /**
   * @author Mithun Prabhu
   * @param {value}
   * SUBMIT ALL FIELD VALUES AND SET STATE AND CALL API FOR SUBMISSION
   */
  function submit(e) {
    // let data = JSON.parse(JSON.stringify(editForm));

    /* CATEGORY */
    const selectedCategoryIdsType=Array.isArray(editForm.categoryId) ? typeof editForm.categoryId[0]==="string" ? "string" : typeof editForm.categoryId[0]==="object" ? "object" : null : null;

    const selectedCategoryIds=selectedCategoryIdsType==="string" ? editForm.categoryId :selectedCategoryIdsType==="object" ? editForm.categoryId.map((c)=>c.value) : [];
    //console.log(selectedCategoryIds);
    if(selectedCategoryIds.length>0)
    {
     const selectedCategories=categoryNames.filter((category)=>selectedCategoryIds.includes(category._id));
      //console.log(selectedCategories);
     selectedCategories.forEach((category)=>{
        if(category.level===1){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          }
        }else if(category.level===2){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          //console.log(selectedCategoryIds);
          }
          const parentCategory=categoryNames.find(item=>item._id===category.parentId);
          //console.log(parentCategory)
          if(parentCategory){
            if(!selectedCategoryIds.includes(parentCategory.parentId)){
              selectedCategoryIds.push(parentCategory.parentId);
            }
          }
        }
      })
    }
    let data = {
        name: editForm.name.trim(),
        // banner_image: editForm.image,
        status: parseInt(editForm.status ? 1 : 0),
        slug: editForm.slug.trim(),
        type: parseInt(editForm.type),
        discount: parseInt(editForm.discount),
        sort: parseInt(editForm.sort),
        categoryId: selectedCategoryIds,
        regionId : editForm.regionId,
      };
    // console.log(data);

    //validation
    if(data.name){
        if (!userNameWithSpace.test(data.name) || !data.name === "") {
          setFormErrors({...formErrors,name : "Enter only alphabet and minimum 3"})
          return false
        }else{
          setFormErrors({...formErrors,name : ""})
        }
      }
    //   console.log(data.slug.length);
      if (data.slug) {
        if (!alphanumericwithdashHyphen.test(data.slug) || !data.slug === "") {
          setFormErrors({ ...formErrors, slug: "Incorrect match" });
          return false;
        } else if (!alphanumericwithdashHyphenWithLimit.test(data.slug) || !data.slug === "") {
          setFormErrors({ ...formErrors, slug: "Enter min 3 letters" });
          return false;
        } else {
          setFormErrors({ ...formErrors, slug: "" });
        }
      }
    data._id=state?._id
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("banner_image", file);
    // console.log(formData);

    // const result = validate(undefined, data);
    const result = runValidationChecks(rules, undefined, { ...data, file });

    // console.log(result);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
    //    .post("/banner/createBanner", formData)
        .post(`/banner/${state?._id ? "updateBanner" : "createBanner"}`, formData)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: state?._id
            ? "Banner updated successfully"
            : "Banner added successfully",
          });
          //setFilter(intialState());
          history.push(`/banner`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding banner failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding banner failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      }); 
      
  }

   /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  const { state } = history.location;

  
  return ( 
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/banner")}
          />
          {state?._id ? "Edit Banner" : "Add Banner"}
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="status"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.status}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/banner`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching banner details..." />
          </td>
        </tr>
      ) : (
      <div className={styles.management_details}>
        {/* {avenueform.map((avenue, indx) => ( */}
         {/*  {Array.isArray(editForm.faq) &&
              editForm.faq.map((faq, indx) => (
            <React.Fragment key={indx}> */}
              <div className={styles.management_details_contents}>
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <label className={commonstyles.secondry_title}>
                            Name{" "}
                            <span className="text-danger" style={{ fontSize: "20px" }}>
                            *
                            </span>
                        </label>

                        <InputComponent
                            error={formErrors.name}
                            className="form-control-lg mb-3"
                            formControl={{
                            placeholder: "Type banner name",
                            name: "name",
                            value: editForm?.name,
                            onChange: handleFormInput,
                            }}
                        />
                        {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
                        <FormErrorText error={formErrors.name}/>
                    </div>
                    
                    {/* Region start */}
                    <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Warehouse
                    </label>
                        <div className={styles.management_details_form}>
                            <Select
                            mode="multiple"
                            name="region"
                            style={{ width: "100%" }}
                            placeholder="Select Warehouse"
                            onChange={(e) => handleRegionChange(e)}
                            optionLabelProp="label"
                            value={editForm?.regionId}
                            >
                            {regions.map((region) => (
                                <Option value={region._id} label={region.name}>
                                <div className="demo-option-label-item">
                                    {region.name}
                                </div>
                                </Option>
                            ))}
                            </Select>
                        </div>
                    </div>
                    {/* region End */}
                    {/* Slug */}
                    <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Slug
                        <span className="text-danger" style={{ fontSize: "20px" }}>
                        *
                        </span>
                    </label>
                    <InputComponent
                        error={formErrors.slug}
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "Type slug",
                        name: "slug",
                        value: editForm?.slug,
                        onChange: handleFormInput,
                        }}
                    />
                    <FormErrorText error={formErrors.slug} />
                    </div>
                    {/* Slug ends */}
                    {/* type */}
                    <div className="col-lg-6 mb-4">
                        <label className="theme-form-label">
                            Type <span className="text-danger" style={{fontSize: "20px"}}>*</span>
                        </label>
                        <select
                            name="type"
                            className={`form-control form-control-lg mb-3
                                ${formErrors.type && "theme-error-input"} ${
                                commonstyles.form_control
                            }`}
                            value={editForm?.type}
                            style={{ cursor: "pointer" }}
                            onChange={handleFormInput}
                            >
                            <option value="" disabled>Select a type</option>
                            <option value="8">Category</option>
                            <option value="3">Forgot password</option>
                            <option value="5">Home page</option>
                            <option value="7">Home page single banner</option>
                            <option value="2">Login</option>
                            <option value="4">Login with otp</option>
                            <option value="6">Offer banner</option>
                            <option value="1">Signup</option>

                        </select>
                        <FormErrorText error={formErrors.type} />
                    </div>
                    {/* type end */}
                    {/* discount */}
                    <div className="col-lg-6 mb-4">
                        <label className="theme-form-label">
                        Discount  <span className="text-danger" style={{fontSize: "20px"}}>*</span>
                        </label>
                        <select
                            name="discount"
                            className={`form-control form-control-lg mb-3
                                ${formErrors.discount && "theme-error-input"} ${
                                commonstyles.form_control
                            }`}
                            value={editForm?.discount}
                            style={{ cursor: "pointer" }}
                            onChange={handleFormInput}
                            >
                            <option value=""></option>
                            <option value="10">10%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                            <option value="60">60%</option>
                            <option value="70">70%</option>
                            <option value="80">80%</option>
                            <option value="90">90%</option>
                            <option value="100">100%</option>
                        </select>
                        {/* <InputComponent
                        error={formErrors.discount}
                        className="form-control-lg"
                        formControl={{
                            placeholder: "Type Ship in",
                            type: "number",
                            min: 0,
                            step: 1,
                            name: "discount",
                            value: editForm?.discount,
                            onChange: handleFormInput,
                        }}
                        /> */}
                        <FormErrorText error={formErrors.discount} />
                    </div>
                    {/* discount end */}
                    {/* Sort value  */}
                    <div className="col-lg-6 mb-4">
                        <label>
                            Sort 
                            <span className="text-danger" style={{ fontSize: "20px" }}>
                            *
                            </span>
                        </label>
                        <SelectComponent
                            error={formErrors.sort}
                            className="form-control-md mb-3"
                            attributes={{
                            name: "sort",
                            value: editForm.sort,
                            onChange: handleFormInput,
                            }}
                            >
                            <option value="" disabled>
                            Select an option
                            </option>

                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </SelectComponent>
                        <FormErrorText error={formErrors.sort} />
                    </div>
                    {/* Sort value ends */}
                    {/* category */}
                    <div className="col-lg-6 mb-4">
                            <label className="theme-form-label">
                            Categories
                            </label>
                            <ProductCategoryTreeSelectComponent
                            //value={addForm.categoryId}
                            value={filterCategoryIds}
                            category={categoryNames}
                            handleChange={(v) => {
                                setEditForm({ ...editForm, categoryId: v });
                            }}
                            />
                    </div>
                    {/* category end */}
                    {/* image upload */}
                    <div className="col-lg-6 mb-4">
                        <div className={commonstyles.management_details_contents}>
                        <div className={styless.upload_img_header}>
                            <label className={commonstyles.secondry_title}>
                            Image
                            <span className="text-danger" style={{ fontSize: "20px" }}>
                                *
                            </span>
                            </label>
                        </div>

                        <div className={commonstyles.management_details_contents}>
                            <div
                            // className={`${commonstyles.management_details_form} ${styless.add_featureImage_card}`}
                            >
                            {url ? (
                                <>
                                <ImagePreviewChip
                                    url={url}
                                    className={styless.added_image}
                                    width="60px"
                                    height="60px"
                                    handleClose={removeFile}
                                />
                                </>
                            ) : (
                                <div className={` ${styless.upload_img} text-center w-100`}>
                                <div>
                                    <label
                                    htmlFor="categoryFileInput"
                                    className={`${styless.btn} px-2 `}
                                    >
                                    Add file
                                    <InputComponent
                                        error={formErrors.image}
                                        className="form-control-lg mb-3"
                                        formControl={{
                                            type: "file",
                                            name: "url",
                                            style:{cursor: "pointer" },
                                            accept: "image/jpeg, image/png, image/svg+xml",
                                            id: "categoryFileInput",
                                            onChange: (e) => handleImageInput(e),
                                        }}
                                    />
                                    <FormErrorText error={formErrors.image} />
                                    </label>
                                </div>
                                {/* <span>or drop a file to Upload</span> */}
                                </div>
                            )}
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* image upload end */}
                </div>
              </div>
            {/* </React.Fragment>
          ))}
          ))} */}
        {/* <div className={styles.payment_btn}>
          <button className={`btn ${styles.btn_filled}`} onClick={addFaq}>
            Add FAQ
          </button>
        </div> */}
      </div>
      )}
    </div>
  );
}
