import React, { useEffect, useState, useRef, createRef } from "react";
import styles from "../../../styles/DashBoa.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Earnings from "../../../assets/media_earnings.svg";
import MaskGraph from "../../../assets/images/mask_group_126.png";
import Products from "../../../assets/images/products_order_value.svg";
import productTotalOrder from "../../../assets/images/products_total_orders.svg";
import Arrow from "../../../assets/images/sidebar/right_sidebar_arrow.svg";
import ArrowActive from "../../../assets/images/sidebar/right_sidebar_arrow_active.svg";
import Logout from "../../../assets/images/sidebar/iconly_bulk_logout.svg";
import Notification from "../../../assets/images/sidebar/iconly_bulk_notification.svg";
import Profile from "../../../assets/images/sidebar/iconly_bulk_profile.svg";
import Activites from "../../../assets/images/sidebar/activity_icon.svg";
import Setting from "../../../assets/images/sidebar/iconly_bulk_setting.svg";
import Send_active from "../../../assets/images/sidebar/send_active.svg";
import Setting_active from "../../../assets/images/sidebar/setting_active.svg";
// import Setting_active from "../../../assets/images/sidebar/Iconly-Bulk-Location.svg";
import Location from "../../../assets/images/sidebar/Iconly-Bulk-Location.svg";
import Profile_active from "../../../assets/images/sidebar/profile_active.svg";
import Notification_active from "../../../assets/images/sidebar/notification_active.svg";
import Logout_active from "../../../assets/images/sidebar/logout_active.svg";
import activitySucessfully from "../../../assets/images/sidebar/activity_sucessfully.svg";
import activityOrderStatus from "../../../assets/images/sidebar/activity_order_status.svg";
import activityTicketStar from "../../../assets/images/sidebar/activity_ticket_star.svg";
import hold from "../../../assets/images/sidebar/right_sidebar_hold.svg";
import checkout from "../../../assets/images/sidebar/right_sidebar_checkout.svg";
import customer from "../../../assets/images/sidebar/right_sidebar_customer.svg";
import lowStack from "../../../assets/images/sidebar/right_sidebar_low_stack.svg";
import outOfStack from "../../../assets/images/sidebar/right_sidebar_out_of_stack.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import arrowGreen from "../../../assets/images/bar_chart_arrow_green.svg";
import arrowRed from "../../../assets/images/bar_chart_arrow_red.svg";
import barChartGreen from "../../../assets/images/bar_chart_green.svg";
import barChartRed from "../../../assets/images/bar_chart_red.svg";
import searchIcon from "../../../assets/images/admin_search_icon.svg";
import searchRightArrow from "../../../assets/images/top_searched_right_arrow.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import profileimg from "../../../assets/images/profile_dummy.svg";
import usericon from "../../../assets/images/user.png";
import logouticon from "../../../assets/images/logouticon.png";
import permissionicon from "../../../assets/images/permissionicon.png";
import passwordicon from "../../../assets/images/passwordicon.png";
import sgenaralicon from "../../../assets/images/sgenaralicon.svg";
import snotificationicon from "../../../assets/images/snotificationicon.svg";
import spaymentsicon from "../../../assets/images/spaymentsicon.svg";
import sregionsicon from "../../../assets/images/sregionsicon.svg";
import sshippingicon from "../../../assets/images/sshippingicon.svg";
import susersicon from "../../../assets/images/susersicon.svg";
import productImage from "../../../assets/images/product_notification.png";
import { ExportDash } from "../../modules/ReusableComponent/ExportDash";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import moment from "moment";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import { drawGraph } from "../../DrawGraph/DrawGraph";
import { Dropdown } from "react-bootstrap";
import { Select, notification, Tooltip } from "antd";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { upperCase } from "lodash";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import AnalyticsList from "../AnalyticsManagement/AnalyticsList";
import { useHistory } from "react-router";
import { Modal, Button } from "antd";
import { DatePicker, Space, Menu } from "antd";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
const defaultPageSize = 5;
const initialFormState = {
  // status: true,
  date: "",
};
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 8, name: "This Week", value: "" },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 12 },
  { _id: 7, name: "Custom", value: "custom" },
];

export default function DashBoardComponent(props) {
  // console.log(props);
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const [region, setRegion] = useState("");
  const [regionlist, setRegionList] = useState([]);
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDash, setIsLoadingDash] = useState(true);
  const [isLoadingTop, setIsLoadingTop] = useState(true);
  const [barGrapg, setBarGrapg] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  const [show, setShow] = useState(false);
  const [reportMostSold, setReportMostSold] = useState([]);
  const [reportMostViewed, setReportMostViewed] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [analyticsTop, setAnalyticsTop] = useState([]);
  const [analyticsLatest, setAnalyticsLatest] = useState([]);
  const [topSelling, setTopSelling] = useState([]);
  const [selectedTopId, setSelectedTopId] = useState("");
  const [topSellingTotal, setTopSellingTotal] = useState("");
  const [topCategoryBack, setTopCategoryBack] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState(["Top Selling"]);
  const history = useHistory();
  const [dashboardTotSalesBar, setDashboardTotSalesBar] = useState([]);
  const [totalDash, setTotalDash] = useState([]);
  const [totCouponDiscount, setTotCouponDiscount] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statsOverview, setstatsOverview] = useState([]);
  const [selectDate, setselectDate] = useState(initialFormState);
  const [recentActivity, setRecentActivity] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [dashGraph, setDashGraph] = useState([]);
  const [graphX, setGraphX] = useState([]);
  const [graphY, setGraphY] = useState([]);

  const [quickDetails, setQuickDetails] = useState([]);
  const [selectMenu, setSelectMenu] = useState("");
  const [notificationDetails, setNotificationDetails] = useState([]);
  const { RangePicker } = DatePicker;
  /* REPORT */
  const [dashResponce, setDashResponce] = useState([]);
  const [mostSold, setMostSold] = useState([]);
  const [mostView, setMostView] = useState([]);
  const [top, setTop] = useState([]);
  const [topSearch, setTopSearch] = useState([]);
  const [latestSearch, setLatestSearch] = useState([]);
  const [RecentReport, setRecentReport] = useState([]);
  const [notificationReport, setNotificationReport] = useState([]);
  const [detailsForm, setDetailsForm] = useState({});

  const fileName = "Dashboard Report";
  const viewers = mostSold;

  function filterOnChange(e) {
    setFilterDays(e);
    // setIsLoadingDash(true);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  const onLogoutHandler = (e) => {
    // alert("123")
    // e.preventDefault();
    localStorage.clear();
    showNotification({
      type: "success",
      message: "Success.",
      description: "Logged out successfully.",
    });
    // history.push('/');
    window.location.reload();
  };


    /**
   * @author Mithun Prabhu
   * @param {}
   * To get profile details and set in state
   */
  function getDetails() {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`admin/profileDetails`)
      .then((res) => {
        if (res.data.status === 200) {
          setDetailsForm(res.data.data);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

    /**
   * @author Mithun Prabhu
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    // console.log(date, dateString);
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
  };
  let diffDays =
    (new Date(filteredDate.endDate) - new Date(filteredDate.startDate)) /
    (1000 * 60 * 60 * 24);
  // console.log(filteredDate);
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  let sales = [];
  let statisticsOverview = [];
  let quickDetailReport = [];

  let dayDiffPer = `${
    filterDays === ""
      ? `% Last week`
      : filterDays === "custom"
      ? `% Last ${diffDays} Days`
      : `% Last ${filterDays === "12" ? "12 Months" : `${filterDays} Days`}`
  }`;

  let dayDiff = `${
    filterDays === ""
      ? `Last week`
      : filterDays === "custom"
      ? `Last ${diffDays} Days`
      : `Last ${filterDays === "12" ? "12 Months" : `${filterDays} Days`}`
  }`;

  dashboardTotSalesBar?.totalSales?.length > 0 &&
    dashboardTotSalesBar?.totalSales?.map((item) => {
      sales.push({
        [`Total sales of ${dayDiff}`]: item.totalSales,
        [`Total no.of order of ${dayDiff}`]: item.orderId,
        [`Average order value of ${dayDiff}`]: item.avgValueOfSales,
        [`Total sales ${dayDiffPer}`]:
          dashboardTotSalesBar.totalSalesOfLastMonthPercentage,
        [`Order ${dayDiffPer}`]: dashboardTotSalesBar.ordersLastMonthPercentage,
        [`Average order ${dayDiffPer}`]:
          dashboardTotSalesBar.averageSalesOfLastMonthPercentage,
      });
    });
  /* dashboardTotSalesBar?.totalSales?.length > 0 && dashboardTotSalesBar?.totalSales?.map(item => {
    sales.push({[`Total sales of ${dayDiff}`]: totalDash, [`Total no.of order of ${dayDiff}`]: item.orderId,[`Average order value of ${dayDiff}`]: (item.avgValueOfSales - (totCouponDiscount/item.orderId)),[`Total sales ${dayDiffPer}`]: dashboardTotSalesBar.totalSalesOfLastMonthPercentage, [`Order ${dayDiffPer}`]: dashboardTotSalesBar.ordersLastMonthPercentage,[`Average order ${dayDiffPer}`]: dashboardTotSalesBar.averageSalesOfLastMonthPercentage})
  }); */

  statisticsOverview.push({
    [`   ${dayDiff}`]: dashResponce.avgItemsPerOrder,
    [`Total Tax Collected of ${dayDiff}`]: dashResponce.totalTaxCollected,
    "New Users": dashResponce.newUsersCount,
    [`Abandoned Checkout of ${dayDiff}`]: dashResponce.abandonedCheckout,
    [`Avg Items per order ${dayDiffPer}`]: dashResponce.avgItemsPerOrder,
    [`Tax ${dayDiffPer}`]: dashResponce.taxPercentage,
    "New Users %": dashResponce.userPercentageCount,
    [`Abandoned Checkout ${dayDiffPer}`]: dashResponce.abondenedPercentage,
  });

  quickDetailReport.push({
    "New customer last 24hrs": dashResponce.newCustomerCount,
    "Low in stocks count": dashResponce.lowInStocksCount,
    "Abandoned checkout orders": dashResponce.abandonedCheckoutOrders,
    "Out of stocks count": dashResponce.outOfStocksCount,
    "On hold orders": dashResponce.onHoldOrders,
  });

  useEffect(() => {
    getTopSelllingCategoryList();
    getReport();
    // getReportDetails();
    getAnalyticsTopList(pageSize);
    getAnalyticsLatestList(pageSize);
    getDashboardDetails();
    getStatsDetails();
    getActivityDetails();
    getQuickDetails();
    getNotificationDetails();
    getGraphDetails();
    getExport();
    getDetails();
  }, [region, selectedTopId, filteredDate, filterDays, topCategoryBack]);

   /**
   * @author Mithun Prabhu
   * @param {}
   * TO GET TOP SELLING CATEGORYDETAILS
   */
  function getTopSelllingCategoryList() {
    // setIsLoading(false);
    const filterOptions = {
      categoryId: topCategoryBack[topCategoryBack.length - 1]
        ? topCategoryBack[topCategoryBack.length - 1]
        : null,
      regionId: region === "" ? null : region,
    };
    // console.log(filterOptions);
    // setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/topSellingCetegory", filterOptions)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading({ delete: false, list: false });
          setIsLoadingDash(false);
          setTopSelling(res.data.data);
          setTopSellingTotal(res.data.totalAmount);
        }
      })
      .catch((er) => {
        setIsLoadingDash(false);
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        // setIsLoading({ delete: false, list: false });
        setIsLoadingDash(false);
      });
  }

  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET EXPORT DETAILS OF DASHBOARD RESPONSE, MOST SOLD, MOST VIEWED, TOP SEARCH,LATEST SEARCH,RECENT REPORT
   */
  function getExport() {
    // setIsLoading(false);
    const data = {
      categoryId: topCategoryBack[topCategoryBack.length - 1]
        ? topCategoryBack[topCategoryBack.length - 1]
        : null,
      regionId: region === "" ? null : region,
      filterDays: filterDays === "" ? null : +filterDays,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);
    setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/ExportDashboardData ", data)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading({ delete: false, list: false });
          let data = res.data;
          setDashResponce(res.data);
          setMostSold(data.mostSoldProduct);
          setMostView(data.mostViewedProduct);
          setTop(data.topSellingCategory);
          setTopSearch(data.topSearchedKeywords);
          setLatestSearch(data.latestSearchedKeyword);
          setRecentReport(data.recentActivity);
          setNotificationReport(data.notifications);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

    /**
   * @author RAJKUMAR
   * @param {id, name}
   * TO GET TOP CATEGORIES ACCORDING ID AND NAME WE ARE PASSING AND CALL TOP SELLING CATEGORY LIST
   */
  function handleTopCategory(id, name) {
    setIsLoadingDash(true);
    setTimeout(() => {
      setIsLoadingDash(false);
    }, 1000);

    setSelectedTopId(id);
    //title
    let title = categoryTitle;
    title.push(name);
    setCategoryTitle(title);
    //categoryId push to an array
    let category = topCategoryBack;
    category.push(id);
    setTopCategoryBack(category);
    getTopSelllingCategoryList();
    // console.log(topCategoryBack)
  }


  /**
   * @author RAJKUMAR
   * @param {}
   * TO SET HANDLEBACK FOR MULTIPLE IDS AND REDUCE/POP ID OR CATEGORY AS YOU HANDLEBACK
   */
  function handleBack() {
    setIsLoadingDash(true);
    //title pop(-1)
    let title = categoryTitle;
    title.pop();
    setCategoryTitle(title);
    //categoryId pop(-1) to an array
    let category = topCategoryBack;
    category.pop();
    setTopCategoryBack(category);
    // console.log(topCategoryBack)
    getTopSelllingCategoryList();
  }


   /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL NOTIFICATION DETAILS
   */
  function getNotificationDetails() {
    let data = {
      regionId: region === "" ? null : region,
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get("/dashboardNotifications", data)
      .then((res) => {
        //  console.log(res.data.notifications);
        if (res.status === 200 && res.data.status === 200) {
          setNotificationDetails(res.data.notifications);
        }
      })
      .catch((er) => {
        return er;
      });
  }


  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET QUICK DETAILS
   */
  function getQuickDetails() {
    let data = {
      regionId: region === "" ? null : region,
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/quickDetails", data)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setQuickDetails(res.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL ACTIVITY DETAILS
   */
  function getActivityDetails() {
    let data = {
      regionId: region === "" ? null : region,
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get("/getRecentActivity", data)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let data = res.data.data;
          data.length = 3;
          setRecentActivity(data);
        }
      })
      .catch((er) => {
        return er;
      });
  }
  let tot, discountTot;

  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL DASHBOARD DETAILS
   */
  function getDashboardDetails() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      /* startDate:filteredDate.startDate ==="" ? null: filteredDate.startDate,
      endDate:filteredDate.endDate ==="" ? null :filteredDate.endDate, */
      regionId: region === "" ? null : region,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getDashboardAnalytics", data)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          setIsLoadingDash(false);
          setDashboardTotSalesBar(res.data);

          const totalDis = res?.data?.totalCouponDiscount?.map((item) => {
            // console.log(item.totalCouponDiscount);
            return item?.totalCouponDiscount;
          });
          const totalsal = res?.data?.totalSales?.map((item) => {
            return item?.totalSales;
          });
          setTotCouponDiscount(totalDis);
          // console.log(totalsal-totalDis);
          // setTotalDash(totalsal-totalDis)
        }
      })
      .catch((er) => {
        setIsLoadingDash(false);
        return er;
      });
  }
  // console.log(totalDash);

  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL STATS DETAILS
   */
  function getStatsDetails() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      /* startDate:filteredDate.startDate ==="" ? null: filteredDate.startDate,
      endDate:filteredDate.endDate ==="" ? null :filteredDate.endDate, */
      regionId: region === "" ? null : region,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getStatsOverview", data)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          setstatsOverview(res.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }


  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL GRAPH DETAILS
   */
  function getGraphDetails() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      /* startDate:filteredDate.startDate ==="" ? null: filteredDate.startDate,
      endDate:filteredDate.endDate ==="" ? null :filteredDate.endDate, */
      regionId: region === "" ? null : region,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getRevenueGraph", data)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setDashGraph(res.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }

/**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL REPORT DETAILS
   */
  // function getReportDetails() {
  //   let data = {
  //     type: 1,
  //     regionId: region === "" ? null : region,
  //   };
  //   AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
  //     .post("/admin/reports/getReportList", data)
  //     .then((res) => {
  //       if (res.status === 200 && res.data.status === 200) {
  //         let data = res.data.data;
  //         data.length = 8;
  //         setReportMostSold(data);
  //       }
  //     })
  //     .catch((er) => {
  //       return er;
  //     });
  // }
  function getReport() {
    let data = {
      type: 2,
      regionId: region === "" ? null : region,
    };
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/admin/reports/getReportList", data)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let data = res.data.data;
          data.length = 8;
          setReportMostViewed(data);
        }
      })
      .catch((er) => {
        return er;
      });
  }


  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL ANALYTICS TOP LIST DETAILS
   */
  function getAnalyticsTopList(pageSize) {
    // setIsLoading(false);
    let nextpage;
    const filterOptions = {
      nextpage: 1,
      pagesize: pageSize,
      type: 0,
    };
    setIsLoading({ delete: false, list: true });

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getProductAnalytics", filterOptions)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading({ delete: false, list: false });
          setAnalyticsTop(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

  /**
   * @author RAJKUMAR
   * @param {}
   * TO GET ALL ANALYTICS LATEST  DETAILS
   */
  function getAnalyticsLatestList(pageSize) {
    // setIsLoading(false);
    let nextpage;
    const filterOptions = {
      nextpage: 1,
      pagesize: pageSize,
      type: 1,
    };
    setIsLoading({ delete: false, list: true });

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getProductAnalytics", filterOptions)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          setIsLoading({ delete: false, list: false });
          setAnalyticsLatest(res.data.data);
          // setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }
  function handleLatest(e) {
    history.push({
      pathname: `/analytics`,
      page: "dashboard",
    });
  }
  function handleTop(e) {
    history.push({
      pathname: `/analytics`,
      page: "dashboardTop",
    });
  }

  function nth(d) {
    return (
      d +
      (31 == d || 21 == d || 1 == d
        ? "st"
        : 22 == d || 2 == d
        ? "nd"
        : 23 == d || 3 == d
        ? "rd"
        : "th")
    );
  }


  /**
   * @author RAJKUMAR
   * @param {year, week, day}
   * week day to date calculation part
   */
  function weekDateToDate(year, week, day) {
    var d = (1 + (week - 1) * 7); // 1st of January + 7 days for each week

    return new Date(year, 0, d + (day-1)).getDate();
  }
  // console.log(weekDateToDate(2022,7,(7 -1))); //Fri feb 18 2022

  
   /**
   * @author RAJKUMAR
   * @param {year, week, day}
   * MONTH WISE AND DAY AND YEAR WISE PUSHING DATA FOR GRAPH IN X-AXIS AND Y-AXIS AND GIVING IT TO GRAPH PLUGIN
   */
  useEffect(() => {
    let graphData = dashGraph?.chartOrders?.map(function (item) {
      // console.log(item);

      /* Month,week and yealy wise */

      //define a date object variable that will take the current system date
      let todaydate = new Date(
        filteredDate.startDate === "" ? null : filteredDate.startDate
      );

      //find the year of the current date
      var oneJan = new Date(todaydate.getFullYear(), 0, 1);

      // calculating number of days in given year before a given date
      var numberOfDays = Math.floor(
        (todaydate - oneJan) / (24 * 60 * 60 * 1000)
      );

      // adding 1 since to current date and returns value starting from 0
      var result = Math.ceil((todaydate.getDay() + 1 + numberOfDays) / 7);
      // console.log(result);
      graphY.push(item.total);

      // console.log(nth(7));
      if (item._id.month === 1 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Jan(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Jan(${item._id.year})`);
        }
      } else if (item._id.month === 2 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Feb(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Feb(${item._id.year})`);
        }
      } else if (item._id.month === 3 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Mar(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Mar(${item._id.year})`);
        }
      } else if (item._id.month === 4 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Apr(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Apr(${item._id.year})`);
        }
      } else if (item._id.month === 5 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `May(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`May(${item._id.year})`);
        }
      } else if (item._id.month === 6 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Jun(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Jun(${item._id.year})`);
        }
      } else if (item._id.month === 7 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push( 
            `Jul(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Jul(${item._id.year})`);
        }
      } else if (item._id.month === 8 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Aug(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Aug(${item._id.year})`);
        }
      } else if (item._id.month === 9 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Sep(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Sep(${item._id.year})`);
        }
      } else if (item._id.month === 10 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Oct(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Oct(${item._id.year})`);
        }
      } else if (item._id.month === 11 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Nov(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Nov(${item._id.year})`);
        }
      } else if (item._id.month === 12 && !item._id.day) {
        if (item._id.week >= result) {
          graphX.push(
            `Dec(${item._id.week}<sup>th</sup> week ${item._id.year})`
          );
        } else {
          graphX.push(`Dec(${item._id.year})`);
        }
      }

      /* day wise */
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      var monthName = months[item._id.month - 1];

      if (item._id.day === 1 && !item._id.week) {
        graphX.push(`Sun (${monthName} ${item._id.year})`);
      } else if (item._id.day === 2 && !item._id.week) {
        graphX.push(`Mon (${monthName} ${item._id.year})`);
      } else if (item._id.day === 3 && !item._id.week) {
        graphX.push(`Tue (${monthName} ${item._id.year})`);
      } else if (item._id.day === 4 && !item._id.week) {
        graphX.push(`Wed (${monthName} ${item._id.year})`);
      } else if (item._id.day === 5 && !item._id.week) {
        graphX.push(`Thu (${monthName} ${item._id.year})`);
      } else if (item._id.day === 6 && !item._id.week) {
        graphX.push(`Fri (${monthName} ${item._id.year})`);
      } else if (item._id.day === 7 && !item._id.week) {
        graphX.push(`Sat (${monthName} ${item._id.year})`);
      }
      /* Date wise filter under Last 7 days */
      // var dayWeek=`${monthName} ${weekDateToDate(item._id.year,item._id.week,item._id.day)}${nth(weekDateToDate(item._id.year,item._id.week,item._id.day))} ${item._id.year}`
      var dayWeek = `${monthName} ${nth(
        weekDateToDate(item._id.year, item._id.week, item._id.day)
      )} ${item._id.year}`;
      if (item._id.day === 1 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 2 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 3 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 4 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 5 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 6 && item._id.week) {
        graphX.push(dayWeek);
      } else if (item._id.day === 7 && item._id.week) {
        graphX.push(dayWeek);
      }
    });

    let bar = {
      x: graphX,
      y: graphY,
      type: "bar",
      title: "Revenue",
      width: 0.4,
      name: "Revenue",
      marker: {
        color: [
          "#A0522D",
          "rgba(222,45,38,0.8)",
          "rgba(204,204,204,1)",
          "#A0522D",
          "rgba(204,204,204,1)",
          "grey",
          "#A0522D",
        ],
      },
    };
    let graph = drawGraph(
      [bar],
      null,
      null,
      "bar",
      null,
      null,
      640,
      250,
      null,
      null,
      null
    );
    setBarGrapg(graph);

    let pieTopCategoryValue = topSelling.map(function (item) {
      return item["name"];
    });
    let pieTotal = topSelling.map(function (item) {
      return item["totalAmount"];
    });
    let totalTop =
      Math.abs(topSellingTotal) > 999
        ? Math.sign(topSellingTotal) *
            (Math.abs(topSellingTotal) / 1000).toFixed(1) +
          "k"
        : Math.sign(topSellingTotal) * Math.abs(topSellingTotal);

    let pieTrace;
    pieTrace = {
      values: pieTotal,
      labels: pieTopCategoryValue,
      total: `${
        totalTop === 0
          ? "No orders are available"
          : `<b>${totalTop}</b> <br /> Total Earning`
      }`,
      text: "Total Earning",
      hoverinfo: "label+percent",
      insidetextorientation: "radial",
      type: "pie",
      hole: 0.4,
      textinfo: "none",
      marker: {
        colors: ["orange", "#A0522D"],
      },
    };
    let chart = drawGraph(
      [pieTrace],
      null,
      null,
      "pie",
      null,
      null,
      550,
      400,
      null,
      null,
      null
    );
    setPieChart(chart);
    setGraphX([]);
    setGraphY([]);
  }, [dashGraph, topSelling, topSellingTotal]);

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setIsLoadingDash(false);
            if(res.data.daaata?.length > 1) {
              // let obj = { name: "All Warehouse", value: "", _id: "" };
              // res.data?.daaata?.unshift(obj);
              setRegionList(res.data.daaata);
            } 
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getRegionList();
  }, []);

  function handleSelect(e) {
    setRegion(e);
    // setIsLoadingDash(true);
  }

  const today = new moment();

  const container = React.useRef(null);
  const pdfExportComponent = useRef(null);
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return isLoadingDash === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching dashboard details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={styles.management_contents}>
      {/* <SearchComponent/> */}
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Report for ${new Date().getFullYear()}`}
        author="Damro Team"
      >
        <div className={styles.managenet_card}>
         
          <SearchComponent />
          <div className={styles.management_header_date}>
            {today.format("DD-MMMM-YYYY").split("-").join(" ")}
          </div>

          <div className={styles.export_card_box}>
            <div className={`media ${styles.managenet_card_media1}`}>
              {/*  <img
                              className="mr-3"
                              src={Earnings}
                              alt="Generic placeholder image"
                            /> */}
              <span className={styles.management_header_downloads_text}>
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
              </span>

              <Tooltip placement="topRight" title="Download excel">
                <span>
                  <ExportDash
                    // csvData={viewers}
                    most={mostView}
                    topCata={top}
                    topSearch={topSearch}
                    latestSearch={latestSearch}
                    RecentReport={RecentReport}
                    totalSal={sales}
                    notificationReport={notificationReport}
                    statOverview={statisticsOverview}
                    DetailReport={quickDetailReport}
                    fileName={fileName}
                  />
                </span>
              </Tooltip>

              {/* <img src={excelIcon} className={styles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) =>exportToCSV(e)}/> */}
              <span className={styles.management_header_downloads_text}>
                Export{" "}
                <img
                  src={exportIcon}
                  className={commonstyles.export_icon_small}
                  alt="icon"
                />
              </span>
              
            </div>
          </div>

          <div className={styles.management_header}>
            <h1>
              <span className={styles.management_header_hi}>Hi</span>
              {dashboardTotSalesBar.userName
                ? dashboardTotSalesBar.userName.charAt(0).toUpperCase() +
                  dashboardTotSalesBar.userName.slice(1)
                : "-"}{" "}
              !
              <span className={styles.management_header_welcome}>
                Welcome back
              </span>
            </h1>

            <div className={` filter_width dashboard_filter_font ${styles.management_header}`}>
              <Menu className="filter_dropdown datefilter">
                <div className="card">
                  <div className="card_contents">
                    <div className="row no-gutters">
                      <div className="card_dropdown show col-md-6">
                      
                        <Select
                          style={{ width: 130 }}
                          name="filterDays"
                          value={filterDays}
                          onChange={filterOnChange}
                        >
                          {Array.isArray(filterdayArr)
                            ? filterdayArr.map((date) => (
                                <option key={date._id} value={date.value}>
                                  {date.name}
                                </option>
                              ))
                            : null}
                        </Select>
                       
                      </div>
                      {filterDays === "custom" && (
                        <div className="card_dropdown show col-md-12">
                          <AntDateRangeSelectComponent
                            selectProps={{
                              placeholder: placeholder,
                              onChange: dateRangeOnchangeHandler,
                              name: "sessionDateRange",
                              format: dateFormatList,
                              disabledDate: disabledDate,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Menu>

              {regionlist?.length > 1 && <div>
                <Select
                  className="mr-2"
                  style={{ width: 120 }}
                  onSelect={(e) => handleSelect(e)}
                  name="region"
                  value={region}
                  // optionLabelProp="label"
                >
                  {regionlist?.length > 1 && <Option value="">All Warehouse</Option>}
                  {Array.isArray(regionlist)
                    ? regionlist.map((region) => (
                        <Option key={region._id} value={region._id}>
                          {region.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </div>}
              {/* <div>
                <select
                  name="region"
                  value={region}
                  onChange={(e) => handleInput(e, "region")}
                  className={`form-control  ${styles.form_control}`}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">All Region</option>
                  {Array.isArray(regionlist)
                    ? regionlist.map((region) => (
                        <option key={region._id} value={region._id}>
                          {region.name}
                        </option>
                      ))
                    : null}
                </select>
              </div> */}
            </div>
          </div>

          <div className={`row mb-4 ${styles.dashboard_revenue_card}`}>
            <div className="col-md-4">
              <div className="row">
              {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-TotalSales-view") ===
            "Dashboard-TotalSales-view" && (
                <div
                  className={`col-md-12 ${styles.managenet_card_media_contents}`}
                >
                  <div className={`media ${styles.managenet_card_media}`}>
                    <img
                      className={`mr-3 ${styles.media_img}`}
                      src={Earnings}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      Total Sales
                      {dashboardTotSalesBar?.totalSales?.length > 0 ? (
                        dashboardTotSalesBar?.totalSales?.map((item) => (
                          <h5 className="mt-0">
                            Rs.
                            {item.totalSales.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </h5>
                        ))
                      ) : (
                        <h5 className="mt-0">Rs. 0</h5>
                      )}
                      {/* <h5 className="mt-0">Rs.{totalDash ? totalDash.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</h5> */}
                      <div
                        className={`${styles.last_week_report}`}
                        style={
                          dashboardTotSalesBar?.totalSalesOfLastMonthPercentage &&
                          dashboardTotSalesBar?.totalSalesOfLastMonthPercentage <
                            0
                            ? { color: "red" }
                            : { color: "#379E7A" }
                        }
                      >
                        <div className={`${styles.last_week_report_text}`}>
                          <img
                            src={
                              dashboardTotSalesBar.totalSalesOfLastMonthPercentage &&
                              dashboardTotSalesBar.totalSalesOfLastMonthPercentage <
                                0
                                ? arrowRed
                                : arrowGreen
                            }
                            alt="arrow"
                            className={`${styles.bar_chart_arrow}`}
                          />
                          {/* {(dashboardTotSalesBar.totalSalesOfLastMonthPercentage) ?(dashboardTotSalesBar.totalSalesOfLastMonthPercentage).toLocaleString(undefined, {maximumFractionDigits:2}) : "0"} % Last week */}
                          {filterDays === ""
                            ? `${
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last week`
                            : filterDays === "custom"
                            ? `${
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                diffDays
                                  ? diffDays + " Days"
                                  : filterDays === "custom"
                                  ? "Week"
                                  : "Days"
                              }`
                            : `${
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.totalSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                filterDays === "12"
                                  ? "12 Months"
                                  : `${filterDays} Days`
                              } `}
                        </div>
                        <img
                          src={
                            dashboardTotSalesBar.totalSalesOfLastMonthPercentage &&
                            dashboardTotSalesBar.totalSalesOfLastMonthPercentage <
                              0
                              ? barChartRed
                              : barChartGreen
                          }
                          alt="arrow"
                          className={`${styles.bar_chart}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>)}
                {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-AverageOrderValue-view") ===
          "Dashboard-AverageOrderValue-view"&& (
                <div
                  className={`col-md-12 ${styles.managenet_card_media_contents}`}
                >
                  <div className={`media ${styles.managenet_card_media}`}>
                    <img
                      className={`mr-3 ${styles.media_img}`}
                      src={Products}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      Average Order Value
                      {dashboardTotSalesBar?.totalSales?.length > 0 ? (
                        dashboardTotSalesBar?.totalSales?.map((item) => (
                          <h5 className="mt-0">
                            Rs.{" "}
                            {item.avgValueOfSales.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </h5>
                        ))
                      ) : (
                        <h5 className="mt-0">Rs. 0</h5>
                      )}
                      <div
                        className={`${styles.last_week_report}`}
                        style={
                          dashboardTotSalesBar?.averageSalesOfLastMonthPercentage &&
                          dashboardTotSalesBar?.averageSalesOfLastMonthPercentage <
                            0
                            ? { color: "red" }
                            : { color: "#379E7A" }
                        }
                      >
                        <div className={`${styles.last_week_report_text}`}>
                          <img
                            src={
                              dashboardTotSalesBar.averageSalesOfLastMonthPercentage &&
                              dashboardTotSalesBar.averageSalesOfLastMonthPercentage <
                                0
                                ? arrowRed
                                : arrowGreen
                            }
                            alt="arrow"
                            className={`${styles.bar_chart_arrow}`}
                          />
                          {/* {(dashboardTotSalesBar.averageSalesOfLastMonthPercentage) ? (dashboardTotSalesBar.averageSalesOfLastMonthPercentage).toLocaleString(undefined, {maximumFractionDigits:2}) : "0"} % Last week */}
                          {filterDays === ""
                            ? `${
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last week`
                            : filterDays === "custom"
                            ? `${
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                diffDays
                                  ? diffDays + " Days"
                                  : filterDays === "custom"
                                  ? "Week"
                                  : "Days"
                              }`
                            : `${
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage &&
                                dashboardTotSalesBar.averageSalesOfLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                filterDays === "12"
                                  ? "12 Months"
                                  : `${filterDays} Days`
                              }`}
                        </div>
                        <img
                          src={
                            dashboardTotSalesBar.averageSalesOfLastMonthPercentage &&
                            dashboardTotSalesBar.averageSalesOfLastMonthPercentage <
                              0
                              ? barChartRed
                              : barChartGreen
                          }
                          alt="arrow"
                          className={`${styles.bar_chart}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>)}
                {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-TotalNumberofOrders-view") ===
          "Dashboard-TotalNumberofOrders-view" && (
                <div
                  className={`col-md-12 ${styles.managenet_card_media_contents}`}
                >
                  <div className={`media ${styles.managenet_card_media}`}>
                    <img
                      className={`mr-3 ${styles.media_img}`}
                      src={productTotalOrder}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      Total Number of Orders
                      {dashboardTotSalesBar?.totalSales?.length > 0 ? (
                        dashboardTotSalesBar?.totalSales?.map((item) => (
                          <h5 className="mt-0">
                            {item.orderId?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </h5>
                        ))
                      ) : (
                        <h5 className="mt-0">0</h5>
                      )}
                      <div
                        className={`${styles.last_week_report}`}
                        style={
                          dashboardTotSalesBar?.ordersLastMonthPercentage &&
                          dashboardTotSalesBar?.ordersLastMonthPercentage < 0
                            ? { color: "red" }
                            : { color: "#379E7A" }
                        }
                      >
                        <div className={`${styles.last_week_report_text}`}>
                          {" "}
                          <img
                            src={
                              dashboardTotSalesBar?.ordersLastMonthPercentage &&
                              dashboardTotSalesBar?.ordersLastMonthPercentage <
                                0
                                ? arrowRed
                                : arrowGreen
                            }
                            alt="arrow"
                            className={`${styles.bar_chart_arrow}`}
                          />
                          {/* {(dashboardTotSalesBar.ordersLastMonthPercentage) ? (dashboardTotSalesBar.ordersLastMonthPercentage).toLocaleString(undefined, {maximumFractionDigits:2}) : "0"} % Last week */}
                          {/* <div style={(dashboardTotSalesBar?.ordersLastMonthPercentage) && (dashboardTotSalesBar?.ordersLastMonthPercentage) < 0 && {color:"red"}}> */}
                          {filterDays === ""
                            ? `${
                                dashboardTotSalesBar.ordersLastMonthPercentage &&
                                dashboardTotSalesBar.ordersLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last week`
                            : filterDays === "custom"
                            ? `${
                                dashboardTotSalesBar.ordersLastMonthPercentage &&
                                dashboardTotSalesBar.ordersLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                diffDays
                                  ? diffDays + " Days"
                                  : filterDays === "custom"
                                  ? "Week"
                                  : "Days"
                              } `
                            : `${
                                dashboardTotSalesBar.ordersLastMonthPercentage &&
                                dashboardTotSalesBar.ordersLastMonthPercentage.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )
                              } % Last ${
                                filterDays === "12"
                                  ? "12 Months"
                                  : `${filterDays} Days`
                              }`}
                        </div>
                        <img
                          src={
                            dashboardTotSalesBar.ordersLastMonthPercentage &&
                            dashboardTotSalesBar.ordersLastMonthPercentage < 0
                              ? barChartRed
                              : barChartGreen
                          }
                          alt="arrow"
                          className={`${styles.bar_chart}`}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
            {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === 
          "Dashboard-Revenue-view") ===
          
"Dashboard-Revenue-view" && (
            <div className="col-md-8">
              <div className={`${styles.revenue}`}>
                <h3 className={`${styles.revenue_title}`}>Revenue</h3>
                <div className={`${styles.revenue_value}`}>
                  Rs.{" "}
                  {dashGraph.totalRevenue?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </div>
                {barGrapg}
              </div>
            </div>)}
          </div>
          {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === 
          "Dashboard-StatsOverview-view") ===
          
"Dashboard-StatsOverview-view"&& (
          <div className={`${styles.dashboard_card} mb-4`}>
            <h1
              style={{ fontWeight: "bold" }}
              className={`${styles.dashboard_card_title}`}
            >
              Statistics Overview
            </h1>
            <div className={`row no-gutters`}>
              <div className="col-md-3">
                <div
                  className={`${styles.overview_card} ${styles.grayesh_orange}`}
                >
                  <div className={`${styles.overview_card_header}`}>
                    <h4 className={`${styles.overview_card_title}`}>
                      Avg Items per order
                    </h4>
                    <div
                      className={`${styles.overview_card_percentage} dashboard-progressbar firstprogress-state-overview`}
                    >
                      <CircularProgressbar
                        value={statsOverview.itemsPerOrderPercentage?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}
                        text={`${statsOverview.itemsPerOrderPercentage?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}%`}
                        styles={buildStyles({
                          textSize: "22px",
                          fontWeight: "600",
                        })}
                      />
                    </div>
                  </div>
                  <div className={`${styles.overview_card_contents}`}>
                    {statsOverview?.avgItemsPerOrder?.length > 0 ? (
                      statsOverview?.avgItemsPerOrder?.map((item) => (
                        <h5 className="mt-0">
                          {item.avgOfItemsCount ? item.avgOfItemsCount : 0}
                        </h5>
                      ))
                    ) : (
                      <h5 className="mt-0">0</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className={`${styles.overview_card} ${styles.grayesh_orange_dark} dashboard-progressbar secondprogress-state-overview`}
                >
                  <div className={`${styles.overview_card_header}`}>
                    <h4 className={`${styles.overview_card_title}`}>
                      Total Tax Collected
                    </h4>
                    <div className={`${styles.overview_card_percentage}`}>
                      <CircularProgressbar
                        value={
                          statsOverview?.taxPercentage === null
                            ? "-100"
                            : statsOverview?.taxPercentage?.toLocaleString(
                                undefined,
                                { maximumFractionDigits: 2 }
                              )
                        }
                        text={`${
                          statsOverview?.taxPercentage === null
                            ? "-100"
                            : statsOverview?.taxPercentage?.toLocaleString(
                                undefined,
                                { maximumFractionDigits: 2 }
                              )
                        }%`}
                        styles={buildStyles({
                          textSize: "22px",
                          fontWeight: "600",
                        })}
                      />
                    </div>
                  </div>
                  <div className={`${styles.overview_card_contents}`}>
                    {statsOverview?.totalTaxCollected?.length > 0 ? (
                      statsOverview?.totalTaxCollected?.map((item) => (
                        <h5 className="mt-0">
                          Rs.{" "}
                          {item.taxCollected?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </h5>
                      ))
                    ) : (
                      <h5 className="mt-0">Rs. 0</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className={`${styles.overview_card} ${styles.gray_dark} dashboard-progressbar thirdprogress-state-overview`}
                >
                  <div className={`${styles.overview_card_header}`}>
                    <h4 className={`${styles.overview_card_title}`}>
                      New Users
                    </h4>
                    <div className={`${styles.overview_card_percentage}`}>
                      <CircularProgressbar
                        value={statsOverview.userPercentageCount?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}
                        text={`${statsOverview.userPercentageCount?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}%`}
                        styles={buildStyles({
                          textSize: "22px",
                          fontWeight: "600",
                        })}
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.overview_card_contents} dashboard-progressbar forthprogress-state-overview`}
                  >
                    {statsOverview.newUsersCount === ""
                      ? "0"
                      : statsOverview.newUsersCount}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className={`${styles.overview_card} ${styles.gray_lite}`}>
                  <div className={`${styles.overview_card_header}`}>
                    <h4 className={`${styles.overview_card_title}`}>
                      Abandoned Checkout
                    </h4>
                    <div className={`${styles.overview_card_percentage}`}>
                      <CircularProgressbar
                        value={statsOverview.abondenedPercentage?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}
                        text={`${statsOverview.abondenedPercentage?.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}%`}
                        styles={buildStyles({
                          textSize: "22px",
                          fontWeight: "600",
                        })}
                      />
                    </div>
                  </div>
                  <div className={`${styles.overview_card_contents}`}>
                    {/* {statsOverview.abandonedCheckout} */}
                    {statsOverview?.abandonedCheckout?.length > 0 ? (
                      statsOverview?.abandonedCheckout?.map((item) => (
                        <h5 className="mt-0">{item.sum}</h5>
                      ))
                    ) : (
                      <h5 className="mt-0">0</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
 {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === 
          
"Dashboard-TopSellingCategories-view"
) ===
          

"Dashboard-TopSellingCategories-view"
&& (

          <div className={`${styles.dashboard_card} mb-4`}>
            <h1
              style={{ fontWeight: "bold" }}
              className={`${styles.dashboard_card_title} mb-3`}
            >
              {topCategoryBack.length > 0 && (
                <img
                  src={BlackLeftArrow}
                  alt=""
                  className="cursor-pointer"
                  onClick={handleBack}
                  style={{marginRight:"20px"}}
                />
              )}{" "}
              {categoryTitle[categoryTitle.length - 1]} Categories
            </h1>
            <div className={`row`}>
              <div className={`col-md-4 ${styles.top_selling_category}`}>
                {Array.isArray(topSelling) &&
                  topSelling.length > 0 &&
                  topSelling.map((topSell, indx, arr) => (
                    <div
                      className={`media ${styles.top_selling_category_item}`}
                      key={topSell._id}
                    >
                      <img
                        className="mr-3"
                        src={topSell.image ? topSell.image : MaskGraph}
                        alt="Category image"
                      />
                      <div className={`${styles.top_selling_category_content}`}>
                        <a
                          style={
                            topSell.totalProducts === 0 || topSell.level === 2
                              ? { cursor: "default", pointerEvents: "none" }
                              : { cursor: "pointer" }
                          }
                          onClick={() =>
                            topSell.totalProducts > 0 &&
                            handleTopCategory(topSell._id, topSell.name)
                          }
                        >
                          <h4>{topSell.name}</h4>
                        </a>
                        <p>{topSell.totalProducts} Products</p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={`col-md-8`}>
                <div>{pieChart}</div>
              </div>
            </div>
          </div>)}
          {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-MostViewedProducts-view") ===
          "Dashboard-MostViewedProducts-view"&& (
          <div className={`${styles.dashboard_card} mb-4`}>
            <h1
              style={{ fontWeight: "bold" }}
              className={`${styles.dashboard_card_title} mb-3`}
            >
              Most Viewed Products
            </h1>
            <div className={`row ${styles.most_viewed}`}>
              {Array.isArray(reportMostViewed) &&
              reportMostViewed.length > 0 ? (
                reportMostViewed.map((viewed, indx, arr) => (
                  <div className={`col-md-3 ${styles.most_viewed_products}`}>
                    <div
                      className={`media ${styles.most_viewed_products_item}`}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/catalog/products/edit/${viewed._id}`)
                      }
                    >
                      <img
                        className="mr-3"
                        src={
                          viewed.feature_image
                            ? viewed.feature_image
                            : MaskGraph
                        }
                        alt="Image"
                        title={viewed.name ? viewed.name : "-"}
                      />
                      <div className={`${styles.top_selling_category_content}`}>
                        <h4>{viewed.name ? viewed.name : "-"}</h4>
                        <p>
                          {viewed.viewCount ? viewed.viewCount : 0}{" "}
                          {viewed.viewCount == 0 || viewed.viewCount == 1
                            ? "View"
                            : "Views"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h5 style={{ padding: "0px", textAlign: "left" }}>
                  No results found!
                </h5>
              )}
            </div>
          </div>)}
          {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-MostSoldProducts-view") ===
          "Dashboard-MostSoldProducts-view"&& (
          <div className={`${styles.dashboard_card} mb-4`}>
            <h1
              style={{ fontWeight: "bold" }}
              className={`${styles.dashboard_card_title} mb-3`}
            >
              Most Sold Products
            </h1>
            <div className={`row ${styles.most_sold}`}>
              {Array.isArray(reportMostSold) && reportMostSold.length > 0 ? (
                reportMostSold.map((sold, indx, arr) => (
                  <div
                    className={`col-md-3 ${styles.most_sold_products}`}
                    key={sold._id}
                  >
                    <div
                      className={`media ${styles.most_sold_products_item}`}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/catalog/products/edit/${sold._id}`)
                      }
                    >
                      <img
                        className="mr-3"
                        src={
                          sold.feature_image ? sold.feature_image : MaskGraph
                        }
                        alt="Image"
                        title={sold.name ? sold.name : "-"}
                      />
                      <div className={`${styles.top_selling_category_content}`}>
                        <h4>{sold.name ? sold.name : "-"}</h4>
                        <p>
                          {sold.totalOrdered ? sold.totalOrdered : 0}{" "}
                          {sold.totalOrdered == 0 || sold.totalOrdered == 1
                            ? "Item sold"
                            : "Items sold"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h5 style={{ padding: "0px", textAlign: "left" }}>
                  No results found!
                </h5>
              )}
            </div>
          </div>)}
          <div className={"row"}>
          {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Dashboard-TopSearched-view") ===
          "Dashboard-TopSearched-view" && (
            <div className="col-md-6">
            
              <div className={`${styles.dashboard_card}`}>
                <div className={`${styles.searched_header}`}>
                  <h1
                    style={{ fontWeight: "bold" }}
                    className={`${styles.dashboard_card_title} mb-3`}
                  >
                    Top Searched
                  </h1>
                  <div>
                    <a onClick={handleTop} className={`${styles.see_more}`}>
                      See more
                    </a>
                    <img src={searchRightArrow} alt="arrow" />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className={` ${styles.table} table table-borderless `}>
                    <tbody>
                      <tr>
                        <td>Keywords</td>
                        <td>Result</td>
                        <td>No of clicks</td>
                      </tr>
                      {Array.isArray(analyticsTop) &&
                      analyticsTop.length > 0 ? (
                        analyticsTop.map((top, indx, arr) => (
                          <tr key={top._id}>
                            <td style={{ textTransform: "capitalize" }}>
                              <span className="truncate">{top.searchKey}</span>
                            </td>
                            <td>{top.result}</td>
                            <td>{top.clicks}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-transparent">
                          <td></td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            No results found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>)}
            {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === 
          "Dashboard-LatestSearched-view") ===
          
"Dashboard-LatestSearched-view" && (
            <div className="col-md-6">
              <div className={`${styles.dashboard_card}`}>
                <div className={`${styles.searched_header}`}>
                  <h1
                    style={{ fontWeight: "bold" }}
                    className={`${styles.dashboard_card_title} mb-3`}
                  >
                    Latest Searched
                  </h1>
                  <div>
                    <a onClick={handleLatest} className={`${styles.see_more}`}>
                      See more
                    </a>
                    <img src={searchRightArrow} alt="arrow" />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className={` ${styles.table} table table-borderless `}>
                    <tbody>
                      <tr>
                        <td>Keywords</td>
                        <td>Result</td>
                        <td>No of clicks</td>
                      </tr>
                      {Array.isArray(analyticsLatest) &&
                      analyticsLatest.length > 0 ? (
                        analyticsLatest.map((latest, indx, arr) => (
                          <tr key={latest._id}>
                            <td style={{ textTransform: "capitalize" }}>
                              <span className="truncate">
                                {latest.searchKey}
                              </span>
                            </td>
                            <td>{latest.result}</td>
                            <td>{latest.clicks}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-transparent">
                          <td></td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            No results found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>)}
          
          </div>
        </div>
      </PDFExport>

      <div className={styles.management_contents_right_slide_bar}>
        <nav id="sidebar" className={show ? "active" : ""}>
          <div className={styles.right_slide_bar_sticky}>
            <button
              onClick={() => setShow(!show)}
              type="button"
              id="sidebarCollapse"
              className="btn"
            >
              <img src={Arrow} alt="arrow" className="arrow" />
              <img src={ArrowActive} className="arrow_active" alt="arrow" />
            </button>
            <section className="activites">
              <Dropdown onSelect={(key) => setSelectMenu(key)}>
                <Dropdown.Toggle id="dropdown-basic">
                  {selectMenu === "Activites" && (
                    <img src={Activites} alt="image" />
                  )}
                  {selectMenu === "Profile" && (
                    <img src={Profile} alt="image" />
                  )}
                  {selectMenu === "Notification" && (
                    <img src={Notification} alt="image" />
                  )}
                  {selectMenu === "Settings" && (
                    <img src={Setting} alt="image" />
                  )}
                  <span className="text_hide">
                    {selectMenu ? selectMenu : setSelectMenu("Activites")}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="Activites"
                    active={selectMenu === "Activites"}
                  >
                    <img
                      className="inactive_img"
                      src={Activites}
                      alt="Activites"
                    />
                    <img
                      className="active_img"
                      src={Send_active}
                      alt="Activites"
                    />
                    <span className="text_hide">Activites</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Profile"
                    active={selectMenu === "Profile"}
                  >
                    <img className="inactive_img" src={Profile} alt="profile" />
                    <img
                      className="active_img"
                      src={Profile_active}
                      alt="profile"
                    />
                    <span className="text_hide">Profile</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Notification"
                    active={selectMenu === "Notification"}
                  >
                    <img
                      className="inactive_img"
                      src={Notification}
                      alt="Notification"
                    />
                    <img
                      className="active_img"
                      src={Notification_active}
                      alt="Notification"
                    />
                    <span className="text_hide">Notification</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Settings"
                    active={selectMenu === "Settings"}
                  >
                    <img
                      className="inactive_img"
                      src={Setting}
                      alt="Settings"
                    />
                    <img
                      className="active_img"
                      src={Setting_active}
                      alt="Settings"
                    />
                    <span className="text_hide">Site settings</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="MapLocator"
                    active={selectMenu === "MapLocator"}
                    onClick={() => history.push("/maplocator")}
                  >
                    <img
                      className="inactive_img"
                      src={Location}
                      alt="MapLocator"
                    />
                    <img
                      className="active_img"
                      src={Setting_active}
                      alt="MapLocator"
                    />
                    <span className="text_hide">Map Locator</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    /* eventKey="Logout" active={selectMenu === "Logout"} */ onClick={
                      onLogoutHandler
                    }
                  >
                    <img className="inactive_img" src={Logout} alt="profile" />
                    <img
                      className="active_img"
                      src={Logout_active}
                      alt="profile"
                    />
                    <span className="text_hide">Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </section>

            <div>
              {/* selectMenu === "Profile" && <span>profile</span> */}
              {selectMenu === "Settings" && (
                <section
                  className={`${styles.profile_details} ${styles.setting_details}`}
                >
                  <div className={`${styles.profie_user_list}`}>
                    <ul>
                      <li>
                        <a onClick={() => history.push("/site-settings")}>
                          <img src={sgenaralicon} alt="image" />
                          <span className="text_hide">General</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => history.push("/site-settings/payment")}
                        >
                          <img src={spaymentsicon} alt="image" />
                          <span className="text_hide">Payments</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/site-settings/zone")}>
                          <img src={sshippingicon} alt="image" />
                          <span className="text_hide">Shipping</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => history.push("/site-settings/region")}
                        >
                          <img src={sregionsicon} alt="image" />
                          <span className="text_hide">Regions</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            history.push("/site-settings/notification")
                          }
                        >
                          <img src={snotificationicon} alt="image" />
                          <span className="text_hide">Notification</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/site-settings/user")}>
                          <img src={susersicon} alt="image" />
                          <span className="text_hide">
                            Users and permissions
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              )}
            </div>

            <div>
              {/* selectMenu === "Profile" && <span>profile</span> */}
              {selectMenu === "Profile" && (
                <section className={`${styles.profile_details}`}>
                  <div className={`${styles.profie_pic} text_hide`}>
                    {detailsForm?.profileImg ? (
                      <img src={detailsForm?.profileImg} alt="image" />
                    ) : (
                      <img src={profileimg} alt="image" />
                    )}
                  </div>
                  <div className={`${styles.profie_user_list}`}>
                    <ul>
                      <li>
                        <a onClick={() => history.push("/site-settings/profile")}>
                          <img src={permissionicon} alt="image" />
                          <span className="text_hide">User Details</span>{" "}
                        </a>
                      </li>
                      {/* <li><a onClick={() => history.push("/profile")}><img src={passwordicon} alt="image" /><span className="text_hide" > Password</span></a></li> */}
                      <li>
                        <a onClick={() => history.push("/site-settings/user")}>
                          <img src={usericon} alt="image" />
                          <span className="text_hide">
                            {" "}
                            User and permissions
                          </span>
                        </a>
                      </li>
                      <li>
                        <a onClick={onLogoutHandler}>
                          <img src={logouticon} alt="image" />
                          <span className="text_hide"> Logout</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              )}
            </div>

            <div>
              {/* selectMenu === "Profile" && <span>profile</span> */}
              {selectMenu === "Notification" && (
                <section className={`${styles.notification_box} quick_details`}>
                  {Array.isArray(notificationDetails) &&
                  notificationDetails.length > 0 ? (
                    notificationDetails.map((item, indx, arr) => (
                      <div className="quick_details_contents" key={item._id}>
                        <div className={`${styles.notification_img_box}`}>
                          <img src={productImage} alt="image" />
                        </div>
                        <div className="quick_details_contents_details text_hide">
                          <div
                            className={`${styles.notifidetails_contents_title}`}
                          >
                            {item.alert}
                          </div>
                          <div
                            className={`${styles.notifidetails_contents_description}`}
                          >
                            {item.product}
                          </div>
                          <div
                            className={`${styles.notifidetails_contents_message}`}
                          >
                            {item.message}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 style={{ padding: "0px", textAlign: "left" }}>
                      No results found!
                    </h5>
                  )}
                </section>
              )}
            </div>
            {selectMenu === "Activites" && (
              <>
                <section className="recent_activites">
                  <div className="recent_activites_header">
                    <h4 style={{ fontWeight: "bold" }} className="text_hide">
                      Recent Activity
                    </h4>
                    {/* <div className="dots text_hide">...</div> */}
                  </div>
                  {Array.isArray(recentActivity) &&
                    recentActivity.length > 0 &&
                    recentActivity.map((recent, indx, arr) => (
                      <div className="recent_activites_values" key={recent._id}>
                        <div className="recent_activites_details">
                          <div className="recent_activites_img">
                            <img src={activitySucessfully} alt="" />
                          </div>
                          <div className="recent_activites_content text_hide">
                            <div
                              className="recent_activites_name"
                              style={{ textTransform: "capitalize" }}
                            >
                              {recent.message}
                            </div>
                            {/*  <div class="recent_activites_status">
                              as been added Successfully
                            </div> */}
                            <div
                              className="recent_activites_date"
                              style={{ textTransform: "uppercase" }}
                            >
                              {new Date(Date.now()).getDate() ==
                              new Date(recent.updatedAt).getDate()
                                ? `Today  ${new Date(
                                    recent.updatedAt
                                  ).toLocaleTimeString("en-IN", {
                                    timeZone: "Asia/Kolkata",
                                  })}`
                                : new Date(recent.updatedAt).toLocaleString(
                                    "en-IN",
                                    { timeZone: "Asia/Kolkata" }
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </section>
                {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === 
          "Dashboard-QuickDetails-view"
) ===
          
"Dashboard-QuickDetails-view"
 && (
                <section className="quick_details">
                  <div className="text_hide">
                    <h4
                      style={{ fontWeight: "bold" }}
                      className="quick_details_title"
                    >
                      Quick Details
                    </h4>
                  </div>
                  <div className="quick_details_contents">
                    <img src={customer} alt="image" />
                    <div className="quick_details_contents_details text_hide">
                      <div className="quick_details_contents_title">
                        {quickDetails?.newCustomerCount?.length > 0
                          ? quickDetails?.newCustomerCount?.map(
                              (item) =>
                                `${item.createdAt} ${
                                  item.createdAt === 1 || item.createdAt === 0
                                    ? "New Customer"
                                    : "New Customers"
                                }`
                            )
                          : "0 New Customer"}
                      </div>
                      <div className="quick_details_contents_description">
                        Last 24 Hours
                      </div>
                    </div>
                  </div>

                  <div className="quick_details_contents">
                    <img src={checkout} alt="image" />
                    <div className="quick_details_contents_details text_hide">
                      <div className="quick_details_contents_title">
                        {quickDetails?.abandonedCheckoutOrders?.length > 0
                          ? quickDetails?.abandonedCheckoutOrders?.map(
                              (item) =>
                                `${item.abandonedStatus} ${
                                  item.abandonedStatus === 1 ||
                                  item.abandonedStatus === 0
                                    ? "Order"
                                    : "Orders"
                                }`
                            )
                          : "0 Order"}
                      </div>
                      <div className="quick_details_contents_description">
                        Abandoned Checkout
                      </div>
                    </div>
                  </div>
                  <div className="quick_details_contents">
                    <img src={hold} alt="image" />
                    <div className="quick_details_contents_details text_hide">
                      <div className="quick_details_contents_title">
                        {quickDetails?.onHoldOrders?.length > 0
                          ? quickDetails?.onHoldOrders?.map(
                              (item) =>
                                `${item.count} ${
                                  item.count === 1 || item.count === 0
                                    ? "Order"
                                    : "Orders"
                                }`
                            )
                          : "0 Order"}
                      </div>
                      <div className="quick_details_contents_description">
                        On Hold
                      </div>
                    </div>
                  </div>
                  <div className="quick_details_contents">
                    <img src={lowStack} alt="image" />
                    <div className="quick_details_contents_details text_hide">
                      <div className="quick_details_contents_title">
                        {quickDetails?.lowInStocksCount?.length > 0
                          ? quickDetails?.lowInStocksCount?.map(
                              (item) =>
                                `${item.lowInStocks} ${
                                  item.lowInStocks === 1 ||
                                  item.lowInStocks === 0
                                    ? "Product"
                                    : "Products"
                                }`
                            )
                          : "0 Product"}
                      </div>
                      <div className="quick_details_contents_description">
                        Low in Stock
                      </div>
                    </div>
                  </div>
                  <div className="quick_details_contents">
                    <img src={outOfStack} alt="image" />
                    <div className="quick_details_contents_details text_hide">
                      <div className="quick_details_contents_title">
                        {quickDetails?.outOfStocksCount?.length > 0
                          ? quickDetails?.outOfStocksCount?.map(
                              (item) =>
                                `${item.outofstock} ${
                                  item.outofstock === 1 || item.outofstock === 0
                                    ? "Item"
                                    : "Items"
                                } `
                            )
                          : "0 Item"}
                      </div>
                      <div className="quick_details_contents_description">
                        Out of stock
                      </div>
                    </div>
                  </div>
                </section>)}
              </>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}
