import React from "react";
// import SiteSettings from "../Site Settings/SiteSetting";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import UserManagement from "../UserManagement/UserManagement";
import AddUserComponent from "../UserManagement/AddUser";
import AddRoleComponent from "../UserManagement/AddRole";
import EditUserComponent from "../UserManagement/EditUser";
import GeneralComponent from "../GeneralSettiings/General";
import SiteSettingsMenu from "./SiteSettingsMenuComponent";
import RegionManagement from "../RegionManagement/RegionManagement";
import ModularComponent from "../ModularManagement/modularlist";
import AddModularComponent from "../ModularManagement/addModular";
import AddRegion from "../RegionManagement/AddRegion";
import AddPaymentComponent from "../PaymentManagement/PaymentManagementNew";
import ZoneListComponent from "../UserManagement/ZoneList";
import AddZoneComponent from "../UserManagement/AddZone";
import NotificationComponent from "../MailManagement/ListMail";
import AddNotificationComponent from "../MailManagement/AddSaveMail";
import EditNotificationComponent from "../MailManagement/EditSaveMail";
import RefundComponent from "../RefundChargeManagement/RefundChargeList";
import RefundUpdateComponent from "../RefundChargeManagement/RefundAddAndUpdate";
import EditModularComponent from "../ModularManagement/EditModularComponent"
import AddCity from "../RegionManagement/AddCity";
export default function SiteSettingComponent() {
  return (
    <Switch>
      {" "}
      <Route path={`/site-settings/refund`}>
        <RefundUpdateComponent />
      </Route>
      <Route path={`/site-settings/notification/add`}>
        <AddNotificationComponent />
      </Route>
      <Route path={`/site-settings/notification/:id`}>
        <EditNotificationComponent />
      </Route>
      <Route path={`/site-settings/notification`}>
        <NotificationComponent />
      </Route>
      <Route path={`/site-settings/user/add`}>
        <AddUserComponent />
      </Route>
      <Route path={`/site-settings/role/add`}>
        <AddRoleComponent />
      </Route>
      <Route exact path={`/site-settings/user/:id`}>
        <EditUserComponent />
      </Route>
      <Route path={`/site-settings/user`}>
        <UserManagement />
      </Route>
      <Route path={`/site-settings/region/add`}>
        <AddRegion />
      </Route>
      <Route path={`/site-settings/city/add`}>
        <AddCity />
      </Route>
      <Route path={`/site-settings/region`}>
        <RegionManagement />
      </Route>
      <Route path={`/site-settings/general`}>
        <GeneralComponent />
      </Route>
      <Route path={`/site-settings/zone/addzone`}>
        <AddZoneComponent />
      </Route>
      <Route exact path={`/site-settings/zone/editzone`}>
        <AddZoneComponent />
      </Route>
      <Route path={`/site-settings/zone`}>
        <ZoneListComponent />
      </Route>
      <Route exact path={`/site-settings/modular/:id`}>
        <EditModularComponent />
      </Route>
      <Route path={`/site-settings/modular/add`}>
        <AddModularComponent />
      </Route>
      <Route path={`/site-settings/modular`}>
        <ModularComponent />
      </Route>
      <Route path={`/site-settings/payment`}>
        <AddPaymentComponent />
      </Route>{" "}
      {/* <Route path={`/site-settings/payment`}>
        <PaymentComponent />
     
      </Route> */}
      <Route path={`/`}>
        <SiteSettingsMenu />
      </Route>
    </Switch>
  );
}
