import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import styles from "../../../styles/NewPassword.module.css";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import banner from "../../../assets/login_banner_img.png";
import mainLogo from "../../../assets/main_logo.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { passwordReg } from "../../../utils/RegularExp";
import Spinner from 'react-bootstrap/Spinner'
// import Alert from "react-bootstrap/Alert";
import "antd/dist/antd.css";
import { Alert } from "antd";
import { useState } from "react";
import { Router } from "react-router";
import { notification } from "antd";
import { useHistory } from "react-router";

const NewPasswordComponent = () => {

  let [newPassword, setNewPassword] = useState("");

  let [confirmPassword, setConfirmPassword] = useState("");

  let [newPassword_error, setNewpassword_error] = useState("");

  let [confirmPassword_error, setConfirmpassword_error] = useState("");

  const [visible, setVisible] = useState(false);

  const [Newvisible, setNewVisible] = useState(false);

  let [clickable, setClickable] = useState(false);

  // const [userName , setUserName] = useState("");

  // const [Secret , setSecret] = useState("")

  const [error, setError] = useState({
    message: null,
    type: null,
  });
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const user_name = queryParams.get('user_name');
  const secret = queryParams.get('secret');
  // setUserName(user_name);
  // setSecret(secret);
  console.log(user_name);
  console.log(secret);
  const HandleChange = (e) => {
    let nowName = e.target.name;

    let nowValue = e.target.value;

    if (nowName == "newPassword") {
      if (nowValue) {
        if (passwordReg.test(nowValue)) {
          setNewpassword_error("");
        } else {
          setNewpassword_error(
            "At least 1 number, upper & lower case and special character and minimum 8 characters"
          );
          setClickable(false)
        }
      }
      else {
        setNewpassword_error("");
      }

      setNewPassword(nowValue);
    } else if (nowName == "confirmPassword") {
      if (nowValue) {
        if (nowValue == newPassword) {
          setConfirmpassword_error("");
        } else {
          setConfirmpassword_error("Password not matching");
          setClickable(false)
        }
      } else {
        setConfirmpassword_error("");
      }

      setConfirmPassword(nowValue);
    }
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    console.log(options);
    notification[type](options);
  }
  // const otpHandle = (value) => {
  //   if (value.length != 0 && value.length < 6) {
  //     setOtpError("Please enter valid 6 digit OTP");
  //     setClickable(false)
  //   } else {
  //     setOtpError("");
  //   }
  //   setOtp(value);
  // };
  const onPasswordVisible = () => {
    setVisible(!visible);
  };
  const onPasswordnewVisible = () => {
    setNewVisible(!Newvisible);
  };

  const resetPassword = (e) => {
    e.preventDefault();
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword_error == "" && confirmPassword_error == "") {
        setClickable(true)
        let data = {
          user_name: user_name,
          otp: secret,
          newPassword: newPassword,
        };

        AxiosInstance(PORTS.USER_SERVICE, "application/json")
          .post(`verifyOTP`, data)
          .then((res) => {
            console.log(res.data.status);
            if (res.data.status == 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
              setClickable(false)
              history.push("/");

            } else if (res.data.status == 400) {
              showNotification({
                type: "error",
                message: res.data.msg,
                description: "something went wrong",
              });
              // console.log(res.data.msg);
              setClickable(false)
            }
          })
          .catch((er) => {
            return er;
          });
      }
    } else {
      setClickable(false)
      if (newPassword == "") {
        setNewpassword_error("Password required");
      }
      if (confirmPassword == "") {
        setConfirmpassword_error("Password required");
      }

    }
  };

  return (

    <div className={`${styles.login_bg} row m-0`}>
      <div className="col-lg-4 p-0">
        <div className={styles.login}>
          <div className={styles.logo_img}>
            <img src={mainLogo} alt="logo" />
          </div>
          {/* <h1 className={styles.title_text}>Log In</h1> */}
          <p className={styles.welcome_text_welcome}>Add Password</p>
          <p className={styles.welcome_text_pass}>Add your New Password</p>
          <div>

            <form onSubmit={resetPassword}>
              <div className={`form-group ${styles.form_group} ${styles.password_icon}`}>
                <div>
                  <input
                    name="newPassword"
                    placeholder="New Password*"
                    type={Newvisible ? "text" : "password"}
                    data-test="password"
                    onChange={HandleChange}
                    value={newPassword}
                    className={`form-control ${styles.form_control}`}
                  />
                  <span id="password" onClick={onPasswordnewVisible}>
                    <img
                      src={Newvisible ? eyeClose : eyeOpen}
                      alt="visible"
                      onClick={onPasswordnewVisible}
                      className={styles.eyeIcon}
                    />
                  </span>
                  <span className={styles.error} >
                    {newPassword_error ? newPassword_error : null}
                  </span>
                </div>
              </div>
              <div className={`form-group ${styles.form_group} ${styles.password_icon}`} >
                <div>
                  <input
                    name="confirmPassword"
                    placeholder="Confirm Password*"
                    type={visible ? "text" : "password"}
                    data-test="password"
                    onChange={HandleChange}
                    value={confirmPassword}
                    className={`form-control ${styles.form_control}`}
                  />
                  <span id="password" onClick={onPasswordVisible}>
                    <img
                      src={visible ? eyeClose : eyeOpen}
                      alt="visible"
                      onClick={onPasswordVisible}
                      className={styles.eyeIcon}
                    />
                  </span>
                  <span className={styles.error} >
                    {confirmPassword_error ? confirmPassword_error : null}
                  </span>
                </div>
              </div>
              <button className={`${styles.btn} ${styles.btn_login}`}>
                {clickable ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="spinner_btn"
                  />
                ) : null}
                CREATE
              </button>
            </form>
          </div>

        </div>
      </div>

    </div>
    // <div className={`container-fluid ${style.admin_login_container}`}>
    //   <div className="row">
    //     <div className={`col-12 col-md-6 ${style.admin_login_banner}`}>
    //       <img src={banner} alt="" />
    //     </div>
    //     <div className={`col-12 col-md-6 ${style.admin_login_content}`}>
    //       <h1 className={style.main_logo}>
    //         {" "}
    //         <a href="">
    //           {" "}
    //           <img src={mainLogo} alt="" />{" "}
    //         </a>{" "}
    //       </h1>
    //       <form data-test="login_form" onSubmit={resetPassword}>
    //         <div>
    //           <h4 className={style.admin_forgot_heading}>Reset Password</h4>
    //         </div>

    //         <div className={style.otp_input_section}>
    //           <OTPScreen
    //             className={`otp_input_form ${style.otp_input_field} ${style.otp_input_field_admin}`}
    //             numInputs={6}
    //             value={otp}
    //             onChange={otpHandle}
    //             separator={<span>-</span>}
    //           />
    //           {otpError && (
    //             <span className={style.error} data-test="otp_error">
    //               {otpError}
    //             </span>
    //           )}
    //         </div>

    //         {/* <h5 className={`${style.otp_msg} ${style.otp_msg_admin}`}>
    //           OTP valid for 30 mins. Didn’t Receive OTP?
    //           <span onClick={resend_otp_handler} data-test="resend_otp">Resend OTP</span>
    //         </h5> */}
    //         <div className={style.input_row}>
    //           <div>
    //             <InputComponent
    //               formControl={{
    //                 name: "newPassword",
    //                 placeholder: "Password",
    //                 type: "text",
    //                 "data-test": "password",
    //                 onChange: HandleChange,
    //                 value: newPassword,
    //               }}
    //             />
    //           </div>
    //           <span className={style.error} data-test="email_error">
    //             {newPassword_error ? newPassword_error : null}
    //           </span>
    //         </div>
    //         <div className={style.input_row}>
    //           <div>
    //             <InputComponent
    //               formControl={{
    //                 name: "confirmPassword",
    //                 placeholder: "Confirm password",
    //                 type: "text",
    //                 "data-test": "password",
    //                 onChange: HandleChange,
    //                 value: confirmPassword,
    //               }}
    //             />
    //           </div>
    //           <span className={style.error} data-test="email_error">
    //             {confirmPassword_error ? confirmPassword_error : null}
    //           </span>
    //         </div>

    //         <ButtonComponent
    //           data={{
    //             type: "submit",
    //             "data-test": "sign_in_btn",
    //             className: "login_btn login_btn_admin",
    //           }}
    //           onClick={resetPassword}
    //         >
    //           {clickable ?
    //           <Spinner
    //               as="span"
    //               animation="border"
    //               size="sm"
    //               role="status"
    //               aria-hidden="true"
    //               className="spinner_btn"
    //               /> : null }
    //           Reset Password
    //         </ButtonComponent>
    //       </form>
    //     </div>
    //   </div>
    // </div>
  );
};

export default NewPasswordComponent;
