import React, { useState, useEffect } from "react";
import styles from "../../../../styles/AddDiscount.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styless from "../../../../styles/AddProduct.module.css";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import { notification, Space, Spin, Select } from "antd";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";

import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import { useHistory } from "react-router";

import {
  onlyNumbers,
  onlyFiveNumbers,
  minCharwithnospacecount,
  onlyAlphabetsWithSpace,
  phoneRegx,
  decimalValue
} from "../../../../utils/RegularExp";

const { Option } = Select;

const initialState = {
  ruleStatus: true,
  taxName: "",
  regionId: [],
  startDate: null,
  endDate: null,
  percentage: "",
  taxType: 0,
};

export default function OfferManagement() {
  const history = useHistory();

  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [taxDetails, setTaxDetails] = useState(initialState);
  const [currentMinAm, setCurrentMinAm] = useState(0);
  const [formErrors, setFormErrors] = useState({
    taxName: false,
    startDate: false,
    endDate: false,
    percentage: false,
    regionId: false,
  });

  const rules = {
    taxName: [(val, data) => (!val ? "Tax name " : true)],
    percentage: [(val, data) => (!val ? "Percentage " : true)],
    startDate: [(val, data) => (!val ? "Start date " : true)],
    endDate: [(val, data) => (!val ? "End date " : true)],
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            setRegions(res.data.daaata);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
    return (() => {
      history['isAdd'] = true;
    })
  }, []);
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function discountList() {
    history.push("/tax");
  }

  function handleRegionChange(e) {
    taxDetails.regionId = e;
    setTaxDetails({ ...taxDetails });
  }

  function handleOfferChange(e) {
    const { name, value, checked } = e.target;

    if (checked && (name == "none" || name == "taxType")) {
      if (value == 0) {
        setCurrentMinAm(parseInt(value));
        taxDetails.taxType = parseInt(value);
      } else if (checked == "1") {
        setCurrentMinAm(parseInt(value));
        taxDetails.taxType = parseInt(value);
      }

      setTaxDetails({ ...taxDetails });
    }

    if (["taxName", "percentage"].includes(name)) {
      taxDetails[name] = value;

      setTaxDetails({ ...taxDetails });
    }
    if (["ruleStatus"].includes(name)) {
      taxDetails[name] = checked;

      setTaxDetails({ ...taxDetails });
    }
  }

  function handlefirstdate(e) {
    taxDetails.startDate = moment(e.target.value).format("YYYY-MM-DD");
    setSdate(moment(e.target.value).format("YYYY-MM-DD"));
  }

  function handlelastdate(e) {
    taxDetails.endDate = moment(e.target.value).format("YYYY-MM-DD");
    setEdate(moment(e.target.value).format("YYYY-MM-DD"));
  }
  /**
   * @author Rajkumar
   * TO ADD DISCOUNT
   */
  function addDiscount() {
    const data = {
      taxName: taxDetails.taxName.trim(),
      enable: JSON.parse(taxDetails.ruleStatus),
      percentage: parseFloat(taxDetails.percentage),
      startDate: moment(taxDetails.startDate).format("YYYY-MM-DD"),
      endDate: moment(taxDetails.endDate).format("YYYY-MM-DD"),
      regionId: taxDetails.regionId,
      taxType: taxDetails.taxType || currentMinAm,
    };

    if (data.startDate == "Invalid date") {
      data.startDate = "";
    }
    if (data.endDate == "Invalid date") {
      data.endDate = "";
    }
    if (data.taxName.length < 3) {
      data.taxName = "";
    }

    const result = runValidationChecks(rules, undefined, { ...data });

    console.log({ data: data, result: result });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }
    if (
      taxDetails.taxName.trim().length < 3 ||
      taxDetails.taxName.trim().length > 30
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description:
          "Code/Name must be more than 1 characters and less than 30 characters",
      });
      return;
    }

    if (edate >= sdate) {
      setLoading({ details: false, update: true });
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .post("admin/tax/createtax", data)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Tax details added successfully",
            });
            history.push("/tax");
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });
            const errors = formatServerValidtionErrors(res.data.error);
            return;

            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      alert("End date should be greater than start date.");
    }
  }

  function handleSingleCategory(value) {
    taxDetails.applicableCategory = value;
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={discountList}
          />
          Add Additional Tax
        </h1>
        <div className={`${styless.onoffswitchbtn}`}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleOfferChange(e)}
              checked={taxDetails.ruleStatus}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>

        </div>
      </div>

      <div className={`${styles.management_details} ${styles.management_details_card_box}`}>
        <div className="row">
          <div className="col-lg-6">
            <div className="card-box">
              <div className={styles.management_details_contents}>
                <div className={styles.management_details_contents_header}>
                  <h4>
                    Tax name
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                </div>

                <div className={styles.management_details_form}>
                  <InputComponent
                    className="form-control-lg"
                    error={formErrors.taxName}
                    formControl={{
                      name: "taxName",
                      onChange: (e) => {
                        if (!minCharwithnospacecount.test(e.target.value)) {
                          if (!formErrors.taxName) {
                            formErrors.taxName = {};
                          }
                          formErrors.taxName = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.taxName) {
                            formErrors.taxName = "";
                          }
                        }

                        handleOfferChange(e);
                      },
                      maxLength: "30",
                    }}
                  />
                  <FormErrorText error={formErrors.taxName} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card-box">
              <div className={styles.management_details_contents}>
                <div className={styles.management_details_contents}>
                  <h4>
                    Percentage
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>

                  <div className={styles.management_details_form}>
                    <InputComponent
                      className="form-control-lg"
                      formControl={{
                        maxlength: 5,
                        type: "text",
                        name: "percentage",
                        onChange: (e) => {
                          if (!decimalValue.test(e.target.value)) {
                            if (!formErrors.percentage) {
                              formErrors.percentage = {};
                            }
                            formErrors.percentage = "Enter valid percentage";
                          } else {
                            if (formErrors.percentage) {
                              formErrors.percentage = "";
                            }
                          }
                          if (!onlyFiveNumbers.test(e.target.value)) {
                            formErrors.percentage =
                              "Enter only 5 numbers within 100";
                          }

                          handleOfferChange(e);
                        },
                      }}
                      error={formErrors.percentage}
                    />
                    <FormErrorText error={formErrors.percentage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card-box">
              <div className={styles.management_details_contents}>
                <h4>Warehouse</h4>
                <div className={styles.management_details_form}>
                  <Select
                    mode="multiple"
                    name="regionId"
                    style={{ width: "100%" }}
                    placeholder="Select one or multiple warehouse(s)"
                    onChange={(e) => handleRegionChange(e)}
                    optionLabelProp="label"
                  >
                    {regions.map((region) => (
                      <Option value={region._id} label={region.name}>
                        <div className="demo-option-label-item">
                          {region.name}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className={`${styles.tax_gst_btn} tax_gst_btn`} >
              <div className={styles.management_details_contents}>
                <h4>
                  GST
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </h4>
                <div className={styles.management_details_form}>
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                  >
                    <input
                      type="radio"
                      id="none"
                      name="none"
                      className="custom-control-input"
                      checked={currentMinAm == 0}
                      value={0}
                      onChange={(e) => handleOfferChange(e)}
                    />
                    <label className="custom-control-label" for="none">
                      <span>Exclusive</span>
                    </label>
                  </div>
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                  >
                    <input
                      type="radio"
                      id="taxType"
                      name="taxType"
                      className="custom-control-input"
                      checked={currentMinAm > 0}
                      value="1"
                      onChange={(e) => handleOfferChange(e)}
                    />
                    <label className="custom-control-label" for="taxType">
                      <span>Inclusive</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card-box">
              <div className={styles.management_details_contents}>
                <h4>
                  Tax validity
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </h4>
                <div className={styles.management_details_form}>
                  <div className="row mb-2">
                    <div className={`col-lg-6 ${styles.input_img}`}>
                      <label>Start date</label>

                      <InputComponent
                        className="form-control-sm"
                        error={formErrors.startDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(new Date())
                          .add(1, "years")
                          .format("YYYY-MM-DD")}
                        formControl={{
                          name: "startDate",
                          // value: newCategory.name,
                          type: "date",
                          style: {
                            cursor: "pointer",
                            padding: "5px",
                            textTransform: "uppercase",
                          },
                          onChange: (e) => handlefirstdate(e),
                        }}
                      />

                      <FormErrorText error={formErrors.startDate} />
                    </div>
                    <div className={`col-lg-6 ${styles.input_img}`}>
                      <label>End date</label>

                      <InputComponent
                        className="form-control-sm"
                        error={formErrors.endDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(new Date())
                          .add(1, "years")
                          .format("YYYY-MM-DD")}
                        formControl={{
                          name: "endDate",
                          type: "date",
                          // value: newCategory.name,
                          style: {
                            cursor: "pointer",
                            padding: "5px",
                            textTransform: "uppercase",
                          },
                          onChange: (e) => handlelastdate(e),
                        }}
                      />
                      <FormErrorText error={formErrors.endDate} />

                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {/* {errorMessage} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-right">
            <div className={styles.taxsave_btn}>
              <ButtonComponent
                className="rnd outline_cancel_btn mr-3 "
                attributes={{
                  onClick: () => history.push(`/tax`),
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className="rnd"
                attributes={{ onClick: addDiscount, disabled: loading.update }}
                style={{ cursor: "pointer" }}
              >
                {loading.update ? "Saving" : "Save"}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
