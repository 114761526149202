// import styles from "../../styles/ProductManagement.module.css";
import React, { useEffect, useState, useRef, createRef } from "react";
import {
    notification,
    Space,
    Spin,
    Dropdown,
    Menu,
    message as massages,
    Select,
    Switch,
    Tooltip
} from "antd";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../../utils";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";

export default function setProductManagement(props) {
    // console.log(props);

    const renderHeader = (_props) => {
        return (
            <tr>
                {props.columns.map((item, idx) => {
                    if (item.visible && item.title!=="Product ID" && item.title!=="SKU" && item.title!=="Stock Qty") return _props.children[idx];
                })}
            </tr>
        );
    };

    const renderBody = (_props) => {
        if (_props.children.length > 0) {
            return (
                <tr className={_props.className}>
                    {props.columns.map((item, idx) => {
                        if (item.visible && item.title!=="Product ID" && item.title!=="SKU" && item.title!=="Stock Qty") {
                            return _props.children[idx];
                        }
                    })}
                </tr>
            );
        } else {
            return (
                <tr className="bg-transparent text-info">
                    <td colSpan="3">No data found!</td>
                </tr>
            );
        }
    };

    return (
        <>
            <div
                className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
            >
                <div
                    className={`${commonstyles.management_contents_search_bar} input-group`}
                >
                    <InputComponent
                        className="with-icon rnd theme-txt-xsm"
                        formControl={{
                            placeholder:
                                "Search by Product Name...",
                            name: "searchKey",
                            style: { height: "38px" },
                            value: props.searchKey,
                            onChange: props.filterOnChange,
                            ["aria-label"]: "Search category",
                            ["aria-describedby"]: "search-category",
                        }}
                    />
                    <img src={Search} alt="" />
                </div>
                <ButtonComponent
                    className="theme-btn rnd pl-3 pr-4 ml-2"
                    attributes={{
                        onClick: () => {
                            props.searchClick();
                        },
                    }}
                >
                    Search
                </ButtonComponent>
                <Dropdown
                    overlay={props.filterMenu}
                    onVisibleChange={props.handleVisibleChange} visible={props.visible}
                    className="usermanage_table_filter_columns ml-3"
                >
                    <div
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                        Filter <img src={arrowDown} width={10} height={20} />
                    </div>
                </Dropdown>
                <Dropdown
                    overlay={props.menu}
                    className="usermanage_table_filter_columns ml-3"
                >
                    <div
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                        Columns <img src={arrowDown} width={10} height={20} />
                    </div>
                </Dropdown>
            </div>
            <PDFExport
                ref={props.pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`Product Report for ${new Date().getFullYear()}`}
                author="Damro Team"
            >
                <div
                    className={`${commonstyles.management_table} table-responsive`}
                >
                    {props.IsLoading.list === true ? (
                        <div
                            style={{
                                marginLeft: "70vh",
                                marginTop: "10vh",
                            }}
                        >
                            <tr className="bg-transparent">
                                <td colSpan="100%">
                                    <LoadingIndicator loadingText="Fetching product details..." />
                                </td>
                            </tr>
                        </div>
                    ) : (
                        <div>
                            <input
                                type="checkbox"
                                className={`${commonstyles.parentCheckbox}`}
                                onChange={props.parentCheckbox}
                            ></input>
                            <AntTableComponent
                                className="order_table"
                                tableProps={{
                                    columns: props.columns,
                                    dataSource: props.productList,
                                    pagination: false,
                                    onChange: props.onChange,
                                    components: {
                                        header: {
                                            row: (_prop) => renderHeader(_prop),
                                        },
                                        body: {
                                            row: (_prop) => renderBody(_prop),
                                        },
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
                {!props.IsLoading.list &&
                    Array.isArray(props.productList) &&
                    props.productList.length ? (
                    <Pagination
                        pageSizes={props.pageSizes}
                        pageSize={props.pageSize}
                        nextPage={props.nextPage}
                        data={props.pagination}
                        handlePaginationChanges={props.handlePaginationChanges}
                    />
                ) : null}
                {/* Product delete confirmation modal  */}
                <ConfirmationBox
                    modal={{
                        show: props.childComponentVisibility.delete,
                        size: "md",
                        centered: true,
                    }}
                    handleCancel={props.cancelDelete}
                    handleConfirm={props.removeProduct}
                    title="Confirm the action"
                    confirmText="Delete"
                    loading={props.IsLoading.delete}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto">
                                {props.selectedProduct.image && (
                                    <img
                                        src={props.selectedProduct.image}
                                        className={commonstyles.admin_table_img}
                                        alt="ProductImg"
                                        style={{ width: "80px", height: "auto" }}
                                    />
                                )}
                            </div>
                            <div className="col-7">
                                <span className="theme-txt-subtitle">
                                    {props.selectedProduct.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </ConfirmationBox>
                {/* Product delete confirmation modal ends*/}
            </PDFExport>
        </>
    )
}