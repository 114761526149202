import DashboardIcon from "../../../assets/images/sidebar/admin_product_dashboard.svg";
import DashboardIconActive from "../../../assets/images/sidebar/admin_product_dashboard_active.svg";
import CatalogIcon from "../../../assets/images/sidebar/catalogicon.svg";
import CatalogIconActive from "../../../assets/images/sidebar/catalogiconactive.svg";
import SalesIcon from "../../../assets/images/sidebar/admin_product_sales.svg";
import SalesIconActive from "../../../assets/images/sidebar/admin_product_sales_active.svg";
import DiscountIcon from "../../../assets/images/sidebar/admin_product_discount.svg";
import DiscountIconActive from "../../../assets/images/sidebar/admin_product_discount_active.svg";
import MarketingIcon from "../../../assets/images/sidebar/admin_marketing.svg";
import MarketingIconActive from "../../../assets/images/sidebar/admin_marketing_active.svg";
import CustomerIcon from "../../../assets/images/sidebar/admin_product_customer.svg";
import CustomerIconActive from "../../../assets/images/sidebar/admin_product_customer_active.svg";
import ReportsIcon from "../../../assets/images/sidebar/admin_product_reports.svg";
import ReportsIconActive from "../../../assets/images/sidebar/admin_product_reports_active.svg";
import ContentIcon from "../../../assets/images/sidebar/admin_product_content.svg";
import ContentIconActive from "../../../assets/images/sidebar/admin_product_content_active.svg";
import AnalyticsActiveIcon from "../../../assets/images/Iconly-Bold-Graph.svg";
import AnalyticsIcon from "../../../assets/images/Iconly-Bulk-Graph.svg";
// import AnalyticsIcon from "../../../assets/images/Iconly-Bold-Graph";
import SettingIcon from "../../../assets/images/sidebar/admin_product_setting.svg";
import SettingIconActive from "../../../assets/images/sidebar/admin_product_setting_active.svg";
import styles from "../../../styles/Sidebar.module.css";
import CacheIcon from "../../../assets/images/sidebar/Cache.svg";
import CacheIconActive from "../../../assets/images/sidebar/CacheAcitve.svg";
import Modular from "../../../assets/images/sidebar/Modular.svg";
import ModularActive from "../../../assets/images/sidebar/ModularActive.svg";

export const menus = [
  {
    id: "Dashboard",
    title: "Dashboard",
    route: "/dashboard",
    img: DashboardIcon,
    imgActive: DashboardIconActive,
    imgAlt: "Dashboard icon",
    className: `${styles.side_menu_img}`,
  },
  {
    id: "Sales",
    title: "Sales",
    route: "/sales",
    img: SalesIcon,
    imgActive: SalesIconActive,
    imgAlt: "SalesIcon",
    number: 1,
    isActive: false,
    child: [
      { title: "Orders", route: `/order` },
      { title: "Transactions", route: "/transactions" },
      { title: "Refunds", route: "/refund" },
      { title: "CancelledOrders", route: "/cancelledorders" },
      { title: "Tax", route: "/tax" },
      { title: "Documents", route: "/Documents" },
    ],
  },
  {
    id: "Marketing",
    title: "Marketing",
    route: "/marketing",
    img: MarketingIcon,
    imgActive: MarketingIconActive,
    imgAlt: "MarketingIcon",
    isActive: false,
    child: [
      { title: "Abandoned", route: `/abandonedDashboard` },
      { title: "Communication", route: `/communications` },
      { title: "Rating", route: `/rating` },
    ],
  },
  {
    id: "Discount",
    title: "Discount",
    route: "/discount",
    img: DiscountIcon,
    imgActive: DiscountIconActive,
    imgAlt: "DiscountIcon",
  },
  {
    id: "Customer",
    title: "Customer",
    route: "/customer",
    img: CustomerIcon,
    imgActive: CustomerIconActive,
    imgAlt: "CustomerIcon",
  },
  {
    id: "Catalog",
    title: "Catalog",
    route: "/catalog",
    img: CatalogIcon,
    imgActive: CatalogIconActive,
    imgAlt: "Catalog",
    number: 3,
    isActive: false,
    child: [
      { title: "Products", route: `/catalog/products` },
      // { title: "Product Attributes", route: "/catalog/product-attributes" },
      { title: "Categories", route: "/catalog/category" },
      { title: "Collection", route: "/catalog/decors" },
      // { title: "Warehouse", route: "/catalog/warehouse" },
      { title: "Store", route: "/catalog/store" },
      { title: "Delivery", route: "/catalog/deliveryList" },
      { title: "Manage Colour", route: "/catalog/manageColor" },
    ],
  },
  {
    id: "Reports",
    title: "Reports",
    route: "/reports",
    img: ReportsIcon,
    imgActive: ReportsIconActive,
    imgAlt: "ReportsIcon",
  },
  {
    id: "Modular",
    title: "Modular",
    route: "/modular",
    img: Modular,
    imgActive: ModularActive,
    imgAlt: "DiscountIcon",
  },
  // {
  //   id: "null",
  //   title: "Store",
  //   route: "/store",
  //   img: SalesIcon,
  //   imgActive: SalesIconActive,
  //   imgAlt: "SalesIcon",
  //   number: 7,
  //   child: [{ title: "Warehouse", route: `/warehouse` }],
  // },
  {
    id: "Analytics",
    title: "Analytics",
    route: "/analytics",
    img: AnalyticsIcon,
    imgActive: AnalyticsActiveIcon,
    imgAlt: "ContentIcon",
  },
  /* {
    id: "Offer",
    title: "CMS page",
    route: "/CMS",
    img: DiscountIcon,
    imgActive: DiscountIconActive,
    imgAlt: "DiscountIcon",
  }, */
  // {
  //   id: "FAQ",
  //   title: "FAQ",
  //   route: "/FAQ",
  //   img: DiscountIcon,
  //   imgActive: DiscountIconActive,
  //   imgAlt: "DiscountIcon",
  // },
  {
    id: "Content",
    title: "Content",
    route: "/Content",
    img: ContentIcon,
    imgActive: ContentIconActive,
    imgAlt: "DiscountIcon",
  },
  /* {
    id: "Order",
    title: "CMS",
    route: "/service",
    img: SalesIcon,
    imgAlt: "SalesIcon",
    child: [
      { title: "FAQ", route: `/FAQ` },
      { title: "T&C", route: `/TC` },
      { title: "ContactUs", route: `/ContactUs` },
    ],
  }, 
  {
    id: "Offer",
    title: "Banner",
    route: "/banner",
    img: CatalogIcon,
    imgActive: CatalogIcon,
    imgAlt: "BannerIcon",
  },
  {
    id: "Offer",
    title: "Banner New",
    route: "/bannerNew",
    img: CatalogIcon,
    imgActive: CatalogIcon,
    imgAlt: "BannerIcon",
  },*/
  // {
  //   id: "Delivery",
  //   title: "Delivery",
  //   route: "/delivery",
  //   img: CatalogIcon,
  //   imgActive: CatalogIcon,
  //   imgAlt: "BannerIcon",
  // },
  {
    id: "SiteSettings",
    title: "Site Settings",
    route: "/site-settings",
    img: SettingIcon,
    imgActive: SettingIconActive,
    imgAlt: "Site settingsIcon",
    // child: [
    //   { title: "Zone", route: `/zonelist` },
    // ],
  },
  {
    id: "clearCache",
    title: "Clear Cache",
    route: "/",
    img: CacheIcon,
    imgActive: CacheIconActive,
    imgAlt: "SalesIcon",
    // child: [
    //   { title: "Zone", route: `/zonelist` },
    // ],
  }
];