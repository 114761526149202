// import styles from "../../styles/ProductManagement.module.css";
import { useState, useRef, useEffect } from "react";
import { notification , Tooltip } from "antd";
import { Link } from "react-router-dom";
import AddDeliveryComponent from "./AddDeliveryComponent";
//import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
// import EditProductComponent from "./EditProductComponent";
// import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import Search from "../../../assets/images/search_icon.svg";
import plusIcon from "../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import SearchComponent from "../SearchManagement/SearchComponent";
import { useHistory } from "react-router";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

export default function DeliveryManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [deliveryList, setDeliveryList] = useState([]);
  /* const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState); */
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [selectedProduct, setSelectedProduct] = useState({});
  const timeoutId = useRef();
  const [choosenId, setChoosenId] = useState('');

  const history = useHistory();

  useEffect(() => {
    getDeliveryList(pageSize, nextPage);
  }, []);


   /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * CALLING GET DELIVERY RECORDS
   */
  function getDeliveryList(pageSize,nextPage,filter) {
    // setIsLoading(false);
    setIsLoading({ delete: false, list: true });
    const filterOptions = {
      pagesize: pageSize,
      nextpage: filter?.nextpage? filter?.nextpage : nextPage,
      search:filter?.search || "",
    };

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/deliveryAgent/listAgent", filterOptions)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading(false);
          setIsLoader(false);
          setIsLoading({ delete: false, list: false });
          setDeliveryList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

  function handleSeach(e) {
    const { value } = e.target;

    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: value,
        nextpage: 1,
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getDeliveryList(pageSize, 1, filter);
    // setFilteredResult(filter);
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getDeliveryList(value, 1);
    } else if (type === "nextPage") {
      setNextPage(value);
      getDeliveryList(pageSize,value);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function editDelivery(id) {
    history.push({
      pathname: `/catalog/deliveryList/viewDelivery/${id}`,
    });
  }

  function cancelHandler() {
    setChoosenId('');
  }

  function deleteHandler() {
    AxiosInstance(PORTS.USER_SERVICE, "multipart/form-data")
    .get(`/deleteDeliveryAgent/${choosenId}`)
    .then((res) => {
      if (res.data.status === 200) {
        showNotification({
          type: "success",
          message: "Success",
          description: "Delivery agent deleted successfully",
        });
        getDeliveryList(pageSize, nextPage);
        cancelHandler();
      } else if (res.data.status === 400) {
        showNotification({
          type: "error",
          message: "Deleting delivery agent failed",
          description: res.data?.msg || "Error occured",
        });
        cancelHandler();
      }
    })
    .catch((er) => {
      showNotification({
        type: "error",
        message: "Deleting delivery agent failed",
        description: "Error occured",
      });
      cancelHandler();
    });
  }

  // *** DELETE DELIVERY FUNCTION *** //
  function deleteFAQ(delivery) {
      setChoosenId(delivery?._id)
  }

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
     
    };
    getDeliveryList(pageSize,nextPage,updatedFilter);
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/catalog/deliveryList")}
          />
          Delivery Agent Management
        </h1>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Delivery-view") ===
          "Catalog-Delivery-view" && (
          <div className={commonstyles.management_contents_search}>
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search by First name,last name...."
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => handleSeach(e)}
              />
              <img src={Search} alt="Search icon" />
              <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
            </div>
            
            <div className={commonstyles.management_contents_upload}>
             
            </div>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Delivery-view") ===
          "Catalog-Delivery-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email ID</th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>

                  <th scope="col">Operate</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {IsLoading.list === true ? (
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching delivery details" />
                    </td>
                  </tr>
                ) : Array.isArray(deliveryList) && deliveryList.length > 0 ? (
                  deliveryList.map((delivery, indx, arr) => (
                    <tr key={delivery._id}>
                      <td style={{ textTransform: "capitalize" }}>
                        {delivery?.name?.split("__")[0]
                          ? delivery?.name?.split("__")[0]
                          : "-"}{" "}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {delivery?.name?.split("__")[1]
                          ? delivery?.name?.split("__")[1]
                          : "-"}{" "}
                      </td>
                      <td>+91{delivery.phone ? delivery.phone : "-"}</td>
                      <td>{delivery.email ? delivery.email : "-"}</td>
                      <td>
                        {delivery?.address?.split("__")[0]
                          ? delivery?.address?.split("__")[0]
                          : "-"}{" "}
                      </td>
                      <td
                        className={
                          !delivery.enable === true ? "theme-error-txt" : ""
                        }
                      >
                        {delivery.enable === true ? "Enabled" : "Disabled"}
                      </td>
                      {permissionsArr.length > 0 &&
                        permissionsArr.find(
                          (x) => x === "Catalog-Delivery-edit"
                        ) === "Catalog-Delivery-edit" && (
                          <td>
                            <Tooltip
                              placement="topRight"
                              title="Edit delivery agent"
                               // className="tooltip_list"
                            >
                            <img
                              src={adminProductEdit}
                              className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                              onClick={() => editDelivery(delivery._id)}
                              alt="Edit action icon"
                              // title="Edit delivery"
                            />
                           </Tooltip>
                            <img
                              src={deleteIcon}
                              className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                              onClick={() => deleteFAQ(delivery)}
                              alt="Delete delivery action icon"
                              title="Delete delivery"
                            />
                          </td>
                        )}
                      {/* Edit button ends*/}
                    </tr>
                  ))
                ) : (
                  <tr className="bg-transparent">
                    <td style={{ padding: "0px", textAlign: "left" }}>
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      {!IsLoading.list && Array.isArray(deliveryList) && deliveryList.length
        ? permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Delivery-view") ===
            "Catalog-Delivery-view" && (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          )
        : null}

        {/* Delivery Management delete confirmation modal  */}
        <ConfirmationBox
          modal={{
            show: choosenId ? true : false,
            size: "md",
            centered: true,
          }}
          handleCancel={cancelHandler}
          handleConfirm={deleteHandler}
          title="Confirm the action"
          confirmText="Delete"
        >
          <div className="container-fluid">
            <div className="row">
              <p className="theme-txt-detail">
              Are you sure want to delete?
              </p>
            </div>
          </div>
        </ConfirmationBox>
        {/* Delivery Management delete confirmation modal ends*/}
    </div>
  );
}
