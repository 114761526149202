import styles from "../../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
// import pdfIcon from "../../../../assets/images/excel_icon.svg";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
// import adminProductDelete from "../../../assets/images/admin_delete.svg";
import React, { useEffect, useRef, useState } from "react";
// import AddCustomer from "./AddCustomer";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Tooltip,
} from "antd";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import { formatServerValidtionErrors } from "../../../../utils";
import Plus from "../../../../assets/images/plus_white_icon.svg";
import { useHistory } from "react-router-dom";
import discountstyles from "../../../../styles/AdminDiscount.module.css";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import EditOrderComponent from "../../Order Management/OrderDetails";
import moment from "moment";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { Link } from "react-router-dom";
import { set } from "date-fns";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Order Id", value: "Order Id" },
  { _id: 3, status: 1, name: "Transaction Id", value: "Transaction Id" },
  { _id: 4, status: 1, name: "Amount", value: "Amount" },
  { _id: 5, status: 1, name: "Created date", value: "Created date" },
  { _id: 6, status: 1, name: "Payment Method", value: "Payment Method" },
  // { _id: 7, status: 1, name: "Mode", value: "Mode" },
  {
    _id: 8,
    status: 1,
    name: "Transaction Status",
    value: "Transaction Status",
  },
  { _id: 9, status: 1, name: "Action", value: "Action" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
  { id: 3, isChecked: false, name: "Payment Method", value: "PaymentMethod" },
  {
    id: 4,
    isChecked: false,
    name: "Transaction Status",
    value: "DeliveryStatus",
  },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function TransactionManagementComponent() {
  const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [transactionList, setTransactionList] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [status, setStatus] = useState("");
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const timeoutId = useRef();
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [orderName, setrderName] = useState("All Transaction Status");
  const [payMethods, setPayMethods] = useState([
    { _id: 1, name: "CC Avenue", value: "CCAvenue" },
    { _id: 2, name: "PayU", value: "PayU" },
  ]);
  const [deliveryStatus, setDeliveryStatus] = useState([
    { _id: 1, name: "Success", value: 1 },
    { _id: 2, name: "Failure", value: 2 },
  ]);
  const [visible, setVisible] = useState(false);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentName, setPaymentName] = useState("All Payment");
  const [selectFilter, setSelectFilter] = useState(filters);

  const [sortorder, setSortorder] = useState();
  const [transactionIdOrder, setTransactionIdOrder] = useState();
  const [amountOrder, setAmountOrder] = useState();
  const [createdAtOrder, setCreatedAtOrder] = useState();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const pdfExportComponent = useRef(null);
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Order Id",
      visible: false,
      key: "Order Id",
      render: (text, record, index) => <td>{record.orderId}</td>,
      sorter: true,
    },
    {
      title: "Transaction Id",
      visible: false,
      key: "Transaction Id",
      sorter: true,
      render: (text, record, index) => (
        <td>
          {
            <Tooltip placement="topRight" title={record.transactionId}>
              {record.transactionId}
            </Tooltip>
          }
        </td>
      ),
    },
    {
      title: "Amount",
      visible: false,
      key: "Amount",
      sorter: true,
      render: (text, record, index) => (
        <td>
          {record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </td>
      ),
    },
    {
      title: "Created date",
      visible: false,
      key: "Created date",
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format("MMM Do YYYY, h:mm:ss A")}</span>
      ),
      sorter:true
    },
    {
      title: "Payment Method",
      visible: false,
      key: "Payment Method",
      render: (text, record, index) => <td>{record.paymentMethod}</td>,
    },
    {
      title: "Mode",
      visible: false,
      key: "Mode",
      render: (text, record, index) => (
        <td>{record.mode ? record.mode : "-"}</td>
      ),
    },

    {
      title: " Transaction Status",
      visible: false,
      key: "Transaction Status",
      render: (text, record, index) => (
        <td>
          <span
            className={
              record.paymentStatus == 1
                ? discountstyles.status_active
                : discountstyles.status_inactive
            }
          >
            {record.paymentStatus == 1 ? "Success" : "Failure"}
          </span>
        </td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Transactions-edit") ===
        "Sales-Transactions-edit" && (
          <span>
            <Tooltip
              placement="topRight"
              title="Edit transaction"
            // className="tooltip_list"
            >
              <Link to={`/order/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={commonstyles.admin_table_edit_img}
                  alt="Edit action icon"
                  style={{ cursor: "pointer" }}
                />{" "}
              </Link>
            </Tooltip>
          </span>
        ),
    },
  ]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  /**
   * @author Rajkumar
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      status: parseInt(orderStatus),
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      sortOrder: sortorder,
      transactionIdOrder: transactionIdOrder,
      amountOrder: amountOrder,
      filterDays: ""
    };
    debounceSearch(updatedFilter);
  };
  /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly   with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("transactionList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedtransaction")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedtransaction")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedtransaction",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("transactionList", JSON.stringify(cloneOrderList));
    setTransactionList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
   * @author Rajkumar
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("transactionList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    localStorage.setItem("transactionList", JSON.stringify(cloneOrderList));
    setTransactionList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getTransactionDetails(pageSize, nextPage);
    localStorage.removeItem("selectedtransaction");
    return () => {
      localStorage.removeItem("transactionList");
      localStorage.removeItem("selectedtransaction");
      filters?.forEach(item => {
        item.isChecked = false;
      })
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  // TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  //TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewTransaction(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };
  /**
   * @author Rajkumar
   * @param {items}
   * To fetch new transaction
   */
  const fetchNewTransaction = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewTransaction(items);
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            // let obj = { name: "All Region", value: "", _id: "" };
            // res.data?.daaata?.unshift(obj);
            if(res.data.daaata?.length > 1) {
              let obj = { name: "All Region", value: "", _id: "" };
              res.data?.daaata?.unshift(obj);
              setRegions(res.data.daaata);
              if (!accessregion) {
                let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
                if (!checkDuplicates) {
                  filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
                }
              };
            }
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getRegionList();
  }, []);

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );
  /**
   * @author Rajkumar
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;

    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
    };
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
        if (!item.isChecked && item.id === data.id) {
          if (item.value === "region") {
            updatedFilter.regionId = "";
            setRegionId("");
            setRegionName("All Region");
          }
          if (item.value === "Date"||item.value === "date") {
            const clonefilteredDate = { ...filteredDate };
            clonefilteredDate.startDate = "";
            clonefilteredDate.endDate = "";
            updatedFilter.startDate = "";
            updatedFilter.filterDays = "";
            updatedFilter.endDate = "";
            setFilteredDate(clonefilteredDate);
            setFilterDays("");
          }
          if (item.value === "PaymentMethod") {
            updatedFilter.paymentMethod = "";
            setPaymentMethod("");
            setPaymentName("All Payment");
          }
          if (item.value === "DeliveryStatus") {
            updatedFilter.status = "";
            setOrderStatus("");
            setrderName("All Transaction Status");
          }
          debounceSearch(updatedFilter);
        }
      }
    });

    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      status: parseInt(orderStatus),
      filterDays: filterDays,
      sortOrder: sortorder,
      transactionIdOrder: transactionIdOrder,
      amountOrder: amountOrder,
      createdAtOrder:createdAtOrder
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "paymentMethod") {
      updatedFilter.paymentMethod = value?.value;
      setPaymentMethod(value?.value);
      setPaymentName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (name === "orderStatus") {
      updatedFilter.status = parseInt(value.value);
      setOrderStatus(value.value);
      setrderName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "PaymentMethod") ===
              "PaymentMethod" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={filterOnChange}
                  >
                    <option value="">All Payment</option>
                    {Array.isArray(payMethods)
                      ? payMethods.map((pay) => (
                          <option key={pay._id} value={pay.value}>
                            {pay.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {paymentName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(payMethods)
                      ? payMethods.map((pay) => (
                        <span
                          key={pay._id}
                          onClick={() =>
                            filterRegionChange("paymentMethod", pay)
                          }
                        >
                          <label>{pay.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "DeliveryStatus") ===
              "DeliveryStatus" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="orderStatus"
                    value={orderStatus}
                    onChange={filterOnChange}
                  >
                    <option value="">All Delivery Status</option>
                    {Array.isArray(deliveryStatus)
                      ? deliveryStatus.map((delivery) => (
                          <option key={delivery._id} value={delivery.value}>
                            {delivery.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {orderName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(deliveryStatus)
                      ? deliveryStatus.map((delivery) => (
                        <span
                          key={delivery._id}
                          onClick={() =>
                            filterRegionChange("orderStatus", delivery)
                          }
                        >
                          <label>{delivery.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="filterDays"
                    value={filterDays}
                    onChange={filterOnChange}
                  >
                    <option value="">Order Date</option>
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <option key={day._id} value={day.value}>
                            {day.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {filterDaysName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span key={day._id} onClick={() => filterRegionChange("filterDays", day)}>
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {/* <div className={`${commonstyles.card_dropdown} ${commonstyles.show} col-md-6`}>
                    <div className={`${commonstyles.filter_heading} ${commonstyles.card_dropdown_heading}`}>
                      Select Filter <img src={arrowDown} width={10} height={20} />
                    </div>
                    <div className={`${commonstyles.card_dropdown_contents}`}>
                      <span>Region</span>
                      <span>Date</span>
                      <span>Payment Status</span>
                      <span>Delivery Status</span>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
    </Menu>
  );
  /**
   * @author Rajkumar
   * @param {pageSize, nextPage, filterarr, type}
   * To get transaction details
   */
  function getTransactionDetails(pageSize, nextPage, filterarr, type) {
    // setIsLoading({ ...IsLoading, list: true });
    if (!type) {
      setIsLoading({ ...IsLoading, list: true });
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };

    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.status) {
      filterOptions.status = filterarr.status;
    } else if (status !== "") {
      if (filterarr && filterarr.status !== "") {
        filterOptions.status = status;
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.paymentMethod) {
      filterOptions.paymentMethod = filterarr.paymentMethod;
    } else if (filteredDate.paymentMethod !== "") {
      if (filterarr && filterarr.paymentMethod !== "") {
        filterOptions.paymentMethod = filteredDate.paymentMethod;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterDays !== ""  && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filterDays);
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && sortorder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.amountOrder !== "") {
      filterOptions.amountOrder = Number(filterarr.amountOrder);
    } else if (amountOrder !== "" && amountOrder !== null) {
      if (filterarr && amountOrder !== "") {
        filterOptions.amountOrder = Number(amountOrder);
      }
    }
    if (filterarr && filterarr.transactionIdOrder !== "") {
      filterOptions.transactionIdOrder = Number(filterarr.transactionIdOrder);
    } else if (transactionIdOrder !== "" && transactionIdOrder !== null) {
      if (filterarr && transactionIdOrder !== "") {
        filterOptions.transactionIdOrder = Number(transactionIdOrder);
      }
    }
    if (filterarr && filterarr.createdAtOrder !== "") {
      filterOptions.createdAtOrder = Number(filterarr.createdAtOrder);
    } else if (sortorder !== "" && sortorder !== null) {
      if (filterarr && sortorder !== "") {
        filterOptions.createdAtOrder = Number(sortorder);
      }
    }

    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;

    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/getTransactionList", filterOptions)
      .then((res) => {
        setIsLoading(false);
        if (res && res.data && res.status == "200") {
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem(
            "transactionList",
            JSON.stringify(res.data.data)
          );
          setIsLoading(false);
          setTransactionList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      status: parseInt(orderStatus),
      filterDays: filterDays,
      sortOrder: sortorder,
      transactionIdOrder: transactionIdOrder,
      amountOrder: amountOrder,
      createdAtOrder:createdAtOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getTransactionDetails(value, 1, updatedFilter, type);
    } else if (type === "nextPage") {
      setNextPage(value);
      getTransactionDetails(pageSize, value, updatedFilter, type);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function closeEditProduct() {
    getTransactionDetails(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  function deleteCustomer(user) {
    const sureDelete = window.confirm("Are you sure want to delete???");
    if (sureDelete === true) {
      setSelectedCustomer(user);
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .get(`offers/coupon/${user._id}`)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Offer Deleted Successfully",
            });
            getTransactionDetails(pageSize, nextPage);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);
            setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    } else {
      getTransactionDetails(pageSize, nextPage);
    }
  }

  function handleInput(e, type) {

    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        regionId: regionId,
        paymentMethod: paymentMethod,
        status: parseInt(orderStatus),
        filterDays: filterDays,
        sortOrder: sortorder,
        transactionIdOrder: transactionIdOrder,
        amountOrder: amountOrder,
        createdAtOrder:createdAtOrder
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {

    setNextPage(1);
 
    getTransactionDetails(pageSize, 1, filter,true);

  }
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
   
  };
  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/getTransactionExport",params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach(item => {
            if (item['Transaction Time']) {
              item['Transaction Time'] = moment(item['Transaction Time'], "DD-MM-YYYY HH:mm:ss").format("MMM Do YYYY, h:mm:ss A")
            }
          })
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);

          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          // / generate an XLSX file /
          XLSX.writeFile(
            wb,
            `transaction-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter);
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      paymentMethod: paymentMethod,
      status: parseInt(orderStatus),
      // sortOrder: sorter.order === "descend" ? 1 : 0,
    };

    if (sorter?.columnKey === "Order Id") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
      setAmountOrder();
      setTransactionIdOrder();
      setCreatedAtOrder();
    } else if (sorter?.columnKey === "Amount") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.amountOrder = sortValue;
      setAmountOrder(sortValue);
      setSortorder();
      setTransactionIdOrder();
      setCreatedAtOrder();
    } else if (sorter?.columnKey === "Transaction Id") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.transactionIdOrder = sortValue;
      setTransactionIdOrder(sortValue);
      setSortorder();
      setAmountOrder();
      setCreatedAtOrder();
    }else if (sorter?.columnKey === "Created date") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.createdAtOrder = sortValue;
      setCreatedAtOrder(sortValue);
      setSortorder();
      setAmountOrder();
      setTransactionIdOrder();
    }

    getTransactionDetails(pageSize, nextPage, updatedFilter, true);
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      paymentMethod: paymentMethod,
      status: parseInt(orderStatus),
      filterDays: filterDays,
      sortOrder: sortorder,
      transactionIdOrder: transactionIdOrder,
      amountOrder: amountOrder,
      createdAtOrder:createdAtOrder,
      
    };
    debounceSearch(updatedFilter);
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent region={regionId} />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Transactions</h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Transactions-view") ===
          "Sales-Transactions-view" && (
            <div>
              <span
                className={commonstyles.management_header_downloads_text}
              >
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
                <Tooltip placement="topRight" title="Download excel">
                  <img
                    src={excelIcon}
                    className={commonstyles.management_header_downloads_icon}
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToCSV(e)}
                  />
                </Tooltip>
              </span>
              <span
                className={commonstyles.management_header_downloads_text_ex}
              >
                Export <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
        {/* <div style={{ maxWidth: "300px" }}></div> */}
      </div>
      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
      >
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Transactions-view") ===
          "Sales-Transactions-view" && (
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Order Id,Payment Method...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: handleInput,
                  ["aria-label"]: "Search category",
                  ["aria-describedby"]: "search-category",
                }}
              />
              <img src={Search} alt="" />
            </div>
          )}
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Transactions-view") ===
          "Sales-Transactions-view" && (
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          )}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Transactions-view") ===
          "Sales-Transactions-view" && (
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          )}
        {/* <ButtonComponent
          className="theme-btn-outlined rnd px-3 ml-3"
          attributes={{
            onClick: (e) => exportToCSV(e),
          }}
        >
          Export
        </ButtonComponent> */}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Transactions-view") ===
        "Sales-Transactions-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading.list === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching transaction details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`TransactionList for ${new Date().getFullYear()}`}
                author="Damro Team"
              >
                <div>
                  <input
                    type="checkbox"
                    className={`${commonstyles.parentCheckbox}`}
                    onChange={parentCheckbox}
                  ></input>
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: transactionList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </div>
              </PDFExport>
            )}
          </div>
        )}

      {!IsLoading.list &&
        Array.isArray(transactionList) &&
        transactionList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Transactions-view") ===
        "Sales-Transactions-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
    </div>
  );
}
