import { Alert, notification } from "antd";
import { isNumber } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/AddProduct.module.css";
import { formatServerValidtionErrors } from "../../../../utils";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import validationRules from "../../../../utils/validationRules";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import ProductMediaUploadForm from "../../../modules/ProductManagement/ProductMediaUploadForm";
import ProductDetailsFormComponent from "../../../modules/ProductManagement/ProductDetailsFormComponent";
import ProductRegionDetailFormComponent from "../../../modules/ProductManagement/ProductRegionDetailFormComponent";
import FaqFormComponent from "../../../modules/ProductManagement/FaqFormComponent";
import ProductCategoryTreeSelectComponent from "../../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import RelatedProductSelectFormComponent from "../../../modules/ProductManagement/RelatedProductSelectFormComponent";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import ProductOptionsFormComponent from "../../../modules/ProductManagement/ProductOptionsFormComponent";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import { addressRegx, alphaNum, alphanumericwithdashHyphen, checkForHexRegExp, gst, onlyNumbers, userName } from "../../../../utils/RegularExp";
import { useHistory, useParams } from "react-router";


const initialFormState = {
  enable: false,
  name: "",
  classification: "",
  slug: "",
  most_desirable: false,
  handpicked: false,
  trending: false,
  description: "",
  seoMarkup:"",
  youmaylike: [],
  similar: [],
  boughtTogether: [],
  tallyId: "",
  tag: "",
  categoryId: [],
  productOption: [],
  feature_image: "",
  // staticProductOption: [],
  // dynamicProductOption: [],
  // productType "",
  hsn: "",
  lineTax: "",
  uom: "",
  faq: [],
  manufacturer:"",
  packedby:"",
  marketedby:"",
  manufacturerAddress: "",
  packedbyAddress: "",
  marketedbyAddress: "",
  weight:"",
  numberofpacks:"",
  countryoforigin:"",  
  lastUpdatedBy:""
};

const stock = {
  quantity: 0,
  oldPrice: "",
  newPrice: "",
  shipin: "",
  // offer: "",
  notificationQuantity:"",
  region: {
    id: "",
  },
};

export default function EditProductComponent(props) {
  const { _id } = useParams();
  const history = useHistory();

  const [editForm, setEditForm] = useState(initialFormState);
  // const [details, setDetails] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [localFileData, setLocalFileData] = useState({
    files: [],
    filesPreview: [],
  });
  const [componentVisibility, setComponentVisibility] = useState({
    youmaylike: false,
    similar: false,
    boughtTogether: false,
  });
  const [regions, setRegions] = useState([]);
  const [regionalProducts, setRegionalProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [categoryNames,setCategoryNames] = useState([])
  let filterCategoryIds=Array.isArray(editForm.categoryId) ? editForm.categoryId.filter((item, index) => editForm.categoryId.indexOf(item) === index) : [];
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null); 
  // console.log(editForm.categoryId); 
  // console.log(filterCategoryIds)
  // const filterCategory=editForm.categoryId.toString();

  /* Removing the deleted id  */
  /* // console.log(filterCategoryIds)
  let validCategoryIds=categoryNames?.map((item)=>item._id);
  // console.log(validCategoryIds);
  let validFilterCategoryIds=filterCategoryIds.filter((id)=> {
    // console.log(id); 
    return validCategoryIds.includes(id)
  });
  // console.log(validFilterCategoryIds); */

  const { product } = props;
  const selectedRegionIds = Array.isArray(editForm.stocks)
    ? editForm.stocks.map((stock) => stock.region.id)
    : [];
    /* console.log(editForm.stocks);
    console.log(selectedRegionIds); */
  const ServicesRef = useRef(null);
  const ServicesRefDim = useRef(null);

  const rules = {
    // name: [validationRules.required],
    slug: [validationRules.required],
    //description: [validationRules.required],
    // tallyId: [validationRules.required],
    // classification: [validationRules.required],
    productType: [validationRules.required],
    slug: [validationRules.required],
    // sku: [validationRules.required],
    feature_image: [validationRules.required],
    //tag: [validationRules.required],
    // lineTax: [validationRules.required],
    // staticProductOption: [(val) => Array.isArray(val) || "Required"],
    // dynamicProductOption: [(val) => Array.isArray(val) || "Required"],
    stocks: [
      (val) => {
        if (Array.isArray(val)) {
          const errors = val.reduce(validateStockItem, {});
          if (Object.keys(errors).length) {
            return errors;
          }
          return true;
        }
      },
    ],
    faq: [
      (val) => {
        if (Array.isArray(val)) {
          const errors = val.reduce(validateFaqItem, {});
          if (Object.keys(errors).length) {
            return errors;
          }
          return true;
        }
      },
    ],
    /* parentId: [(val, data) => (!data.isParent && !val ? "Required" : true)],
    productOption: [
      (val) => {
        if (Array.isArray(val)) {
          if (val.length === 0) {
            return { general: "Required at least one product option" };
          }
        }
      },
    ], */
   /*  tag: [
      (val) =>
        ["New", "Best buy"].includes(val) || "Required to select a value",
    ], */
    // faq: [(val) => (Array.isArray(val) && val.length) || "Required"],
  };
  useEffect(() => {
    //Receives product details via props.product
    //Request API to get complete product details;
    // const data = { prodSlug: product.slug, regionId: "" };

    // const data = { prodSlug: slug, regionId: "" };
    const data = { productId: _id, regionId: "" };

  /**
   * @author Mithun Prabhu
   * @param {}
   * GET ALL PRODUCT DETAILS AND SETTING STATE SEPERATELY
   */
    function getProductDetails() {
      setLoading({ details: true, update: false });
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .post("/admin/catalog/productDetails/", data)
        .then((res) => {
          if (res.data.status === 200) {
            const val = res.data.data[0];
            // console.log("val");
            // console.log(typeof val);
            //console.log(val.categoryId);
            if (
              val !== null &&
              val !== undefined &&
              Object.keys(val).length > 0
            ) {
              setEditForm(val);
              setUrl(val.feature_image);
              //Show youmaylike, similar and bought together
              const visibility = {};
              ["youmaylike", "similar", "boughtTogether"].forEach(
                (field) =>
                  (visibility[field] = Array.isArray(val[field])
                    ? !!val[field].length
                    : false)
              );
              setComponentVisibility(visibility);
              // get related products based on region
              const regionIds = Array.isArray(val.stocks)
                ? val.stocks.map((s) => s.region?.id)
                : [];

              getProductListByRegionId(regionIds, true, val);
            } else {
              // No data found
              setEditForm({});
            }
          } else {
            showNotification({
              type: "Error",
              message: "Error",
              description: "Couldnt fetch required data",
            });
            history.push("/catalog/products")
          }
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
    //Get region list to seed data to select region in stocks
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }

    function getProductionOptions() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .post("/productOptionFilter")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setProductOptions(res.data.data);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }

    getRegionList();
    getProductionOptions();
    getProductDetails();
  }, []);

  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase()
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png|ico|svg)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg and png format images",
        });
      } else if (inputFiles[0].size > 1024*1024*3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
      } else {
        setFile(e.target.files[0]);
        if (url) {
          URL.revokeObjectURL(url);
        }
        const generatedUrl = URL.createObjectURL(e.target.files[0]);
        setUrl(generatedUrl);
        editForm.feature_image = generatedUrl;
      }
    }
  }

  function removeFile() {
    if (url) {
      editForm.feature_image = "";
      setFile(null);
      setUrl(null);
    }
  }

  
  async function getProductListByRegionId(
    ids = [],
    shouldSetRelatedProducts,
    productDetails
  ) {
    //This api will provies data to youmaylike, similar, boughtTogether
    //based on regions ids selected.
    // WIll trigger  when regionid exists, changed
    let regionalProductData;

    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getProductsList/", { regionId: ids })
      .then((res) => {
        //console.log(res.data)
        if (res.status === 200 && res.data.status === 200) {
          regionalProductData = res.data.data;
          setRegionalProducts(regionalProductData);

          if (shouldSetRelatedProducts) {
            const details = JSON.parse(JSON.stringify(productDetails));
            //Setting product data for youmaylike similar and boughtTogether
            ["youmaylike", "similar", "boughtTogether"].forEach((field) => {
              if (Array.isArray(details[field])) {
                details[field] = regionalProductData.filter((p) =>
                  details[field].includes(p._id)
                );
              }
            });

            setEditForm(details);
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  useEffect(() => {
    getCategories();
  }, []);

   /**
   * @author Mithun Prabhu
   * @param {value}
   * GET CATEGORY DETAILS
   */
  function getCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName/")
      .then((res) => {
        //console.log(res.data)
        if (res && res.data && res.status == "200") {
          // console.log(res.data.daaata);
          // const data = transformData(res.data.daaata);
          //setTreeData(data);
          setCategoryNames(res.data.daaata);
          //   setCategoryData(res.data.data);
          //   setPagination(res.data.pagination);
          
        }
      })
      .catch((er) => {
        console.log("Failed to get data on Category tree select");
        console.log(er);
      });
  }
 

  function validateStockItem(accumulator, currentStock, indx) {
    const error = {};
    if (!currentStock?.region?.id) {
      error["region"] = "Requried";
    }
    ["oldPrice", "shipin", "newPrice","notificationQuantity"].forEach((field) => {
      if (!currentStock[field] && currentStock[field] !== 0) {
        error[field] = "Required";
      } /* else if (currentStock.oldPrice <= 0 && field !== "quantity") {
        error[field] = "Value should be greater than 0";
      } */
    });

    ["oldPrice", "newPrice"].forEach((field) => {
      if (field < 0) {
        error[field] = "Value should be greater than 0";
      }
    });

    if (
      currentStock.oldPrice &&
      currentStock.newPrice &&
      currentStock.oldPrice < currentStock.newPrice
    ) {
      error.oldPrice = "Value should be greater than selling price";
    }

    if (Object.keys(error).length) {
      accumulator[indx] = error;
    }
    return accumulator;
  }

   /**
   * @author Mithun Prabhu
   * @param {value}
   * VALIDATE FAQ ITEMS
   */
  function validateFaqItem(accumulator, currentFaq, indx) {
    const error = {};
    ["Question", "Answer"].forEach((field) => {
      if (validationRules.required(currentFaq[field]) !== true) {
        error[field] = "Required";
      }
    });
    if (Object.keys(error).length) {
      accumulator[indx] = error;
    }
    return accumulator;
  }

  function handleFormInput(e) {
    // const { name, value, checked } = e.target;
    let { name, value, checked } = e.target;
    if(name==="name"){
      if (!e.target.value.match(addressRegx) || !e.target.value === "") {
        formErrors.name = "required";
      }else{
        formErrors.name = "";
      }
    }
    if(name==="slug"){
      value=value.trim();
      if (!e.target.value.match(alphanumericwithdashHyphen) || !e.target.value === "") {
        formErrors.slug = "Enter only alphabet and number";
      }else{
        formErrors.slug = "";
      }
    }
    if(name==="uom"){
      if (!e.target.value.match(gst) || !e.target.value === "") {
        formErrors.uom = "Enter only number";
      }else{
        formErrors.uom = "";
      }
    }
    if(name==="tallyId"){
      value=value.trim();
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        formErrors.tallyId = "Enter only alphanumeric, min 3 & max 50";
      }else{
        formErrors.tallyId = "";
      }
    }
    if(name==="lineTax"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.lineTax = "Enter only number";
      }else if(e.target.value.length>3){
        formErrors.lineTax = "GST out of range";
      }
      else{
        formErrors.lineTax = "";
      }
    }

    if(name==="countryoforigin"){
      if (!e.target.value.match(userName) || !e.target.value === "") {
        formErrors.countryoforigin = "Enter only alphabets and minimum 3 letters";
      }else{
        formErrors.countryoforigin = "";
      }
    }
    if(name==="weight"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.weight = "Enter only number";
      }else{
        formErrors.weight = "";
      }
    }
    if(name==="numberofpacks"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.numberofpacks = "Enter only number";
      }else{
        formErrors.numberofpacks = "";
      }
    }

    const updatedValues = { ...editForm };
    if (["enable", "trending", "most_desirable", "handpicked"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
  }

  function handleMediaUpdate(fileDetails, form) {
    if (form === "gallery") {
      setEditForm({ ...editForm, gallery: fileDetails });
    } else if (form === "files") {
      setLocalFileData(fileDetails);
    }
  }

  function updateProductDetails(productDetails) {
    // console.log("productDetails");
    // console.log(productDetails);
    const updatedValues = { ...editForm, ...productDetails };
    setEditForm(updatedValues);
  }


   /**
   * @author Mithun Prabhu
   * @param {stock, indx}
   * UPDATE STOCKS
   */
  function updateStock(stock, indx) {
    // console.log(`${stock} ${indx}`);
    const updatedValues = JSON.parse(JSON.stringify(editForm.stocks));
    updatedValues[indx] = stock;
    // console.log(`updatedValues : ${updatedValues}`);
    // console.log(updatedValues);
    //Check changes in region id
    const oldRegionIds = Array.isArray(editForm.stocks)
      ? editForm.stocks
          .filter((s) => s?.region?.id !== "")
          .map((s) => s.region.id)
      : [];

    const updatedRegionIds = Array.isArray(updatedValues)
      ? updatedValues
          .filter((s) => s?.region?.id !== "")
          .map((s) => s.region.id)
      : [];

    if (!isEqual(oldRegionIds, updatedRegionIds)) {
      //Trigger product API list based on region id
      getProductListByRegionId(updatedRegionIds);
    }
    setEditForm({ ...editForm, stocks: [...updatedValues] });
  }

  function addNewRegion() {
    const updatedValue = editForm.stocks;
    const newStock = JSON.parse(JSON.stringify(stock));
    updatedValue.push(newStock);
    // window.scrollTo(window.scrollX, window.scrollY + 220)
    setEditForm({ ...editForm, stocks: [...updatedValue] });
  }


   /**
   * @author Mithun Prabhu
   * @param {index}
   * REMOVE REGION
   */
  function removeRegion(index) {
    // console.log(index);
    const updatedValue = editForm.stocks;
    updatedValue.splice(index, 1);
    // if(updatedValue.length > 1){
    //   window.scrollTo(window.scrollX, window.scrollY - 220)
    // }
    setEditForm({ ...editForm, stocks: [...updatedValue] });
  }

  function updateComponentVisibility(e) {
    const { name, value, checked } = e.target;
    const updatedValues = { ...componentVisibility };
    if (["youmaylike", "similar", "boughtTogether"].includes(name)) {
      updatedValues[name] = checked;
      // if (!checked) {
      //   setAddForm({ ...addForm, [name]: [] });
      // }
    }

    setComponentVisibility(updatedValues);
  }

  /**
   * @author Mithun Prabhu
   * @param {value}
   * UPDATE SELECTED PRODUCTS
   */
  function updateSelectedProducts(action, product, formField) {
    //const maxLength = { youmaylike: 4, similar: 4, boughtTogether: 2 };

    let updatedValues = editForm[formField];

    // to resolve null values issues
    if (!Array.isArray(updatedValues)) {
      updatedValues = [];
    }

    if (["add", "remove"].includes(action)) {
      const index = updatedValues.findIndex((v) => v._id === product._id);
      if (action === "add" /* && updatedValues.length < maxLength[formField] */) {
        if (index === -1) {
          updatedValues.push(product);
          setEditForm({ ...editForm, [formField]: updatedValues });
        }
      } else {
        if (index > -1) {
          updatedValues.splice(index, 1);
          setEditForm({ ...editForm, [formField]: updatedValues });
        }
      }
    }
  }

  function addFaq() {
    const newFaq = { Question: "", Answer: "" };
    const faqs = [...editForm.faq, newFaq];
    setEditForm({ ...editForm, faq: faqs });
  }

  function removeFaq(indx) {
    let faqs = editForm.faq;
    if (faqs[indx]) {
      faqs.splice(indx, 1);
      setEditForm({ ...editForm, faq: faqs });
    }
  }

  function updateFaq(val, indx) {
    const faqs = editForm.faq;
    faqs[indx] = val;
    setEditForm({ ...editForm, faq: faqs });
  }

  function updateProductOption(productOptionsArray) {
    // console.log(productOptionsArray);
    editForm.productOption = productOptionsArray;
    setEditForm({ ...editForm });
  }

  function createRequestData() {
    let data = JSON.parse(JSON.stringify(editForm));
    // console.log(data)
    data.isParent=data.isParent ? true : false; 
    /* if(!data.tag){
      delete data.tag
    } */
    data.tag=data.tag ? data.tag : ""
    data.name=data.name.trim();
    /* if(data.description!=""){
      data.description=data.description.trim();
    } */
    data.youmaylike = Array.isArray(data.youmaylike) && componentVisibility.youmaylike
      ? data.youmaylike.map((prdct) => prdct._id)
      : [];
    data.similar = Array.isArray(data.similar) && componentVisibility.similar
      ? data.similar.map((prdct) => prdct._id)
      : [];
    data.boughtTogether = Array.isArray(data.boughtTogether) && componentVisibility.boughtTogether
      ? data.boughtTogether.map((prdct) => prdct._id)
      : [];

    data.slug = data?.slug?.trim();
    data.tallyId = data?.tallyId?.trim();

    // const parsedlineTax = parseInt(data.lineTax);
    // data.lineTax = !isNaN(parsedlineTax) ? parsedlineTax : data.lineTax;
    if (data.lineTax) {
      const parsedlineTax = parseInt(data.lineTax);
      data.lineTax = !isNaN(parsedlineTax) ? parsedlineTax : data.lineTax;
    } else {
      delete data.lineTax;
    }
    if (data.weight) {
      const parsedweight = parseInt(data.weight);
      data.weight = !isNaN(parsedweight) ? parsedweight : data.weight;
    } else {
      delete data.weight;
    }
    if (data.numberofpacks) {
      const parsedPack = parseInt(data.numberofpacks);
      data.numberofpacks = !isNaN(parsedPack) ? parsedPack : data.numberofpacks;
    } else {
      delete data.numberofpacks;
    }

    if (!Array.isArray(data.faq)) {
      data.faq = [];
    }

    data.most_desirable = !!data.most_desirable;
    data.handpicked = !!data.handpicked;
    data.trending = !!data.trending;

    if (data.isParent) {
      data.parentId = _id;
    }

    if (data?.gallery?.length === 0) {
      delete data.gallery;
    }

    data?.stocks?.forEach((stock) => {
      delete stock.offer;
    });

    delete data.reviews;

    data.productType = data.isParent
      ? "Configurable Product"
      : "Simple Product";

    data.seoMarkup=JSON.stringify(editForm.seoMarkup)
    delete data.uom;

    /* New changes */
    delete data.sku;
    delete data.tallyId;
    // delete data.name;
    delete data.hsn;
    delete data.lineTax;
    const selectedCategoryIdsType=Array.isArray(editForm.categoryId) ? typeof editForm.categoryId[0]==="string" ? "string" : typeof editForm.categoryId[0]==="object" ? "object" : null : null;

    const selectedCategoryIds=selectedCategoryIdsType==="string" ? editForm.categoryId :selectedCategoryIdsType==="object" ? editForm.categoryId.map((c)=>c.value) : [];
    //console.log(selectedCategoryIds);
    if(selectedCategoryIds.length>0)
    {
     const selectedCategories=categoryNames.filter((category)=>selectedCategoryIds.includes(category._id));
      //console.log(selectedCategories);
     selectedCategories.forEach((category)=>{
        if(category.level===1){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          }
        }else if(category.level===2){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          //console.log(selectedCategoryIds);
          }
          const parentCategory=categoryNames.find(item=>item._id===category.parentId);
          //console.log(parentCategory)
          if(parentCategory){
            if(!selectedCategoryIds.includes(parentCategory.parentId)){
              selectedCategoryIds.push(parentCategory.parentId);
            }
          }
        }
      })
    }
    
    data.categoryId=selectedCategoryIds
    console.log(data);
    return data;
  }


  /**
   * @author Mithun Prabhu
   * @param {value}
   * VALIDATE ALL FIELDS IN PRODUCT FORM
   */
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }

    return result;
  }
  const gotoServices = () =>{
  // console.log(ServicesRef.current);
  ServicesRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
}
  const gotoServicesDim = () =>{
    // console.log(ServicesRefDim.current);
    ServicesRefDim.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }


  /**
   * @author Mithun Prabhu
   * @param {value}
   * UPDATE PRODUCT
   */
  function updateProduct() {
    const data = createRequestData();
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      //console.log(result);
      return;
    } else {
      delete data.feature_image
    }
    let hasError = false;
    let hasErrorDime= false;
    data.productOption?.forEach(option => {
    let foundedOption = productOptions?.find(o => o._id === option?.attributeId);
    // console.log(foundedOption);
      if(foundedOption?.name === "Warranty"){
        // console.log(option);
        if(!checkForHexRegExp.test(option.optionId)){
          // console.log("error");
          // console.log(option);
          let datas=option.optionId.split(" ")
          if(datas[0]==="" || datas[1]===""){
            hasError=true;
          }
        }
     
      }
      if(foundedOption?.name === "Dimensions"){
        // console.log(option);
        if(!checkForHexRegExp.test(option.optionId)){
          // console.log("error");
          // console.log(option);
          let datas=option.optionId;
          // console.log(datas);
          // console.log(datas.length);
          if(!datas){
            hasErrorDime=true;
          }
        }
      }
    })
    if(hasError){
      showNotification({
        type: "error",
        message: "Failed to update data",
        description: "Please fill the warranty",
      });
      // setFormErrors({...formErrors});
      gotoServices();
      return 
    }
    if(hasErrorDime){
      showNotification({
        type: "error",
        message: "Failed to update data",
        description: "Please fill the dimensions field",
      });
      // setFormErrors({...formErrors});
      gotoServicesDim();
      return 
    }

    if(data.parentId===""){
      delete data.parentId
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        accept: "*/*",
      },
    };
    if(data?.stocks?.length > 0) {
      data?.stocks?.forEach(stock => {
        stock.quantity = stock.quantity? stock.quantity : 0
      })
    }

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("feature_image", file);

    for (let i = 0; i < localFileData.files.length; i++) {
      formData.append("files", localFileData.files[i]);
    }
    setLoading({ ...loading, update: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "multipart/form-data")
      .post("/admin/catalog/editProduct", formData, config)
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {

          const cookieString = document.cookie || '';
          let cookieArr = cookieString.split(';')
          cookieArr.forEach(item => {
            let cookieName = item.split('=')
            document.cookie = `${cookieName[0]} = `
          })

          showNotification({
            type: "success",
            message: "Success",
            description: "Product updated successfully",
          });
          history.push("/catalog/products")
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
          showNotification({
            type: "error",
            message: "Failed to update data",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed to update data",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ ...loading, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleTagChanges(target){
    //console.log(target);
    const {name,checked}=target;
    if(checked && editForm.tag !==name){
      setEditForm({ ...editForm, tag: name})
    }else{
      setEditForm({ ...editForm, tag: ""})
    }
    
  }

  if (loading.details || Object.keys(editForm).length < 1) {
    return (
      <div className={commonstyles.management_contents}>
        {/* Header */}
        <div className={commonstyles.management_header}>
          <h1 className={commonstyles.management_header_heading}>
            <img
              src={BlackLeftArrow}
              onClick={() => history.push("/catalog/products")}
              alt=""
              className="cursor-pointer"
              title="back"
            />
            Edit Product
          </h1>
        </div>
        {/* Header */}
        <div className={`${styles.loading} d-flex justify-content-center`}>
          {loading.details ? (
            <LoadingIndicator loadingText="Fetching product details" />
          ) : (
            <Alert
              className="alert_message"
              message="No data received"
              type="warning"
            />
          )}
        </div>
      </div>
    );
  }

  if (Object.keys(editForm).length < 1) {
    return <div></div>;
  }

  return (
    <div className={commonstyles.management_contents}>
      {/* Header */}
      {/* <button onClick={gotoServices}>click </button> */}
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            onClick={() => history.push("/catalog/products")}
            alt=""
            className="cursor-pointer"
            title="back"
          />
          Edit Product
        </h1>
        <div className={`${styles.edit_category_update_user_date}`}>
            {editForm?.lastUpdatedBy && (
              <p>
                Last update by: <span>{editForm?.lastUpdatedBy}</span>
              </p>
            )}
            {editForm?.updatedAt && (
              <p>
                Last update on:{" "}
                <span>
                  {moment(editForm?.updatedAt).format(
                    "DD/MM/YYYY | hh:mm A"
                  )}
                </span>
              </p>
            )}
          </div>
        <div className="management_contents_upload">
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push("/catalog/products"),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: updateProduct,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/* Header */}

      {/* Fields */}
      <div className={commonstyles.management_details}>
        <div className={commonstyles.management_details_contents}>
          <div className="row">
            {/* Name */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Product Name
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.name}
                formControl={{
                  placeholder: "Type product name",
                  name: "name",
                  disabled: true,
                  value: editForm?.name,
                  onChange: handleFormInput,
             
                }}
              />
              <FormErrorText error={formErrors.name} />
            </div>
            {/* Name ends */}
            {/* Slug */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Slug
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.slug}
                formControl={{
                  placeholder: "Type product slug",
                  name: "slug",
                  disabled: true,
                  value: editForm?.slug,
                  onChange: handleFormInput,
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
            {/* Slug ends */}

            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>
                Custom Product Name
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.productTitle}
                formControl={{
                  placeholder: "Type product name",
                  name: "productTitle",
                  value: editForm?.productTitle,
                  onChange: handleFormInput,
                }}
              />
            </div>

            {/* Description */}
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>Description</label>
              <textarea
                className={`${commonstyles.form_control} 
                 
                form-control form-control-lg `}
                placeholder="Type product description"
                rows="4"
                autoComplete="off"
                name="description"
                value={editForm?.description}
                onChange={handleFormInput}
              />
             {/*  <FormErrorText error={formErrors.description} /> */}
            </div>
            {/* Description ends */}
            {/* Schema Markup */}
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>Schema Markup</label>
              <textarea
                className={`${commonstyles.form_control} 
                form-control form-control-lg `}
                placeholder="Type product seo"
                rows="4"
                autoComplete="off"
                name="seoMarkup"
                value={editForm?.seoMarkup}
                onChange={handleFormInput}
              />
             {/*  <FormErrorText error={formErrors.description} /> */}
            </div>
            {/* Schema Markup ends */}
            {/* Classification */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Classification
              </label>
              <InputComponent
                className="form-control-lg"
                error={formErrors.classification}
                formControl={{
                  placeholder: "Type product classification",
                  name: "classification",
                  value: editForm?.classification,
                  onChange: handleFormInput,
                }}
              />
              <FormErrorText error={formErrors.classification} />
            </div>
            {/* Classification ends */}
            {/* Feature Image */}
            <div className="col-lg-6 mb-4">
            <div className={commonstyles.management_details_contents}>
              <div className={styles.upload_img_header}>
                <label className={commonstyles.secondry_title}>
                  Feature Image
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
              </div>

              <div className={commonstyles.management_details_contents}>
                <div className={styles.upload_img_header}></div>
                <div
                  className={`${commonstyles.management_details_form} ${styles.add_featureImage_card}`}
                >
                  {url ? (
                    <>
                      <ImagePreviewChip
                        url={url}
                        className={styles.added_image}
                        width="60px"
                        height="60px"
                        handleClose={removeFile}
                      />
                    </>
                  ) : (
                    <div className={` ${styles.upload_img} text-center`}>
                      <div>
                        <label
                          htmlFor="categoryFileInput"
                          className={`${styles.btn} px-2 `}
                        >
                          Add file
                          <InputComponent
                            className="form-control-lg"
                            formControl={{
                              type: "file",
                              name: "url",
                              accept: "image/jpeg, image/png, image/svg+xml",
                              id: "categoryFileInput",
                              onChange: (e) => handleImageInput(e),
                            }}
                            error={formErrors.feature_image}
                          />
                          <FormErrorText
                            error={formErrors.feature_image ? "Required" : ""}
                          />
                        </label>
                      </div>

                      <span>or drop a file to Upload</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>

            {/* Image upload */}
            <ProductMediaUploadForm
              localFileData={localFileData}
              gallery={editForm?.gallery}
              updateMediaForm={handleMediaUpdate}
            />
            {/* Image upload ends */}

            {/*  */}

            <div className={`${styles.mt_32} col-lg-6`}>
              <div className="row">
                {/* Tally ID */}
                <div className="col-lg-6">
                  <div className={`${commonstyles.management_details_form} mb-4`}>
                    <label htmlFor="" className={commonstyles.secondry_title}>
                      Tally ID
                    </label>
                    <InputComponent
                      error={formErrors.tallyId}
                      className="form-control-lg"
                      formControl={{
                        placeholder: "Type tally Id",
                        name: "tallyId",
                        value: editForm?.tallyId,
                        onChange: handleFormInput,
                        disabled: true,
                      }}
                    />
                    <FormErrorText error={formErrors.tallyId} />
                  </div>
                </div>
                {/* Tally ID ends*/}

                {/* Most desirable */}
                <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label htmlFor="" className={commonstyles.secondry_title}>
                      Most Desirable
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="most_desirable"
                        className={styles.onoffswitch_checkbox}
                        id="switchMostDesirable"
                        onChange={handleFormInput}
                        checked={editForm?.most_desirable}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchMostDesirable"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Most desirable ends*/}
                {/* Handpicked*/}
                <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label className={commonstyles.secondry_title} htmlFor="">
                      Handpicked
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="handpicked"
                        className={styles.onoffswitch_checkbox}
                        id="switchHandpicked"
                        onChange={handleFormInput}
                        checked={editForm?.handpicked}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchHandpicked"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Handpicked ends*/}
                {/* Trending*/}
                <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label className={commonstyles.secondry_title} htmlFor="">
                      Trending
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="trending"
                        className={styles.onoffswitch_checkbox}
                        id="switchTrending"
                        onChange={handleFormInput}
                        checked={editForm?.trending}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchTrending"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Trending ends*/}
              </div>
            </div>

            {/* Stocks */}
            <div className="col-lg-12 mb-4">
              <h4 className="theme-form-section-title">Inventory</h4>
              <div className={`${commonstyles.management_details_form} ml-0`}>
                {Array.isArray(editForm.stocks) &&
                  editForm.stocks.map((stock, indx, arr) => (
                    <ProductRegionDetailFormComponent
                      key={indx}
                      regions={regions}
                      selectedRegions={selectedRegionIds}
                      form={stock}
                      formErrors={formErrors.stocks?.[indx] || {}}
                      handleFormUpdate={(stock) => updateStock(stock, indx)}
                      isLastItem={arr.length === 1}
                      showAddNewForm={indx === arr.length - 1}
                      handleNewForm={() => addNewRegion()}
                      handleRemoveForm={() => removeRegion(indx)}
                    />
                  ))}
                <div className="col-lg-12 text-right add_text">
                  <span className="theme-btn-link" onClick={addNewRegion}>
                    Add Warehouse
                  </span>
                </div>
              </div>
            </div>

            {/* Stocks ends*/}

            {/* Product detail starts */}
            <ProductDetailsFormComponent
              _id={_id}
              form={editForm}
              formErrors={formErrors}
              handleProductFormUpdate={updateProductDetails}
            />
            {/* Product detail ends */}

            {/* Product options */}
            <ProductOptionsFormComponent
              refsDim={ServicesRefDim}
              refs={ServicesRef}
              options={productOptions}
              selected={editForm.productOption}
              formErrors={/* formErrors.productOption  ||*/ {}}
              handleUpdate={updateProductOption}
            />
            {/* Product options ends */}

            {/* Categories */}
            <div className="col-lg-6 mb-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">
                  Categories
                  {/* <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span> */}
                </label>
                <ProductCategoryTreeSelectComponent
                  //  value={validFilterCategoryIds} //=>[12,23,43] removed id's, if not in category list
                   category={categoryNames}
                  // value={editForm.categoryId} //[12,12,23,23,43,43]
                  //value={['6102bc5de1e8042ab4d3cf7b']} //bbcd
                  value={filterCategoryIds} // editForm.categoryId filtered (not remove id's, if not in category list )
                  handleChange={(v) => {
                    // console.log(v)
                    setEditForm({ ...editForm, categoryId: v });
                  }}
                />
              </div>
            </div>
            {/* Categories ends */}

            {/* Tag */}
            <div className="col-lg-6 mb-4">
              <div
                className={`${commonstyles.management_details_form} ${
                  formErrors.tag ? "theme-error-blk" : ""
                } pt-5  inline_radio h-100 ml-0`}
              >
                <label className="ml-3 mr-3 theme-form-label">
                  Tag:{" "}
                 {/*  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span> */}
                </label>
                <div className="custom-control custom-radio custom-control-inline mr-4">
                  <input
                    type="radio"
                    id="rd_1"
                    name="New"
                    className={`custom-control-input`}
                    value="New"
                    checked={editForm.tag === "New"}
                    /* onChange={(e) =>
                      e.target.checked &&
                      setEditForm({ ...editForm, tag: "New" })
                    } */
                    onClick={(e)=>{handleTagChanges(e.target)}}
                  />
                  <label
                    className={`custom-control-label theme-form-label pt-1`}
                    htmlFor="rd_1"
                  >
                    <span>New</span>
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline mr-4">
                  <input
                    type="radio"
                    id="rd_2"
                    name="Best buy"
                    className={`custom-control-input`}
                    value="Best Bug"
                    checked={editForm.tag === "Best buy"}
                    /* onChange={(e) =>
                      e.target.checked &&
                      setEditForm({ ...editForm, tag: "Best buy" })
                    } */
                    onClick={(e)=>{handleTagChanges(e.target)}}
                  />
                  <label
                    className={`custom-control-label theme-form-label pt-1`}
                    htmlFor="rd_2"
                  >
                    <span>Best Buy</span>
                  </label>
                </div>
                <FormErrorText error={formErrors.tag} />
              </div>
            </div>
            {/* Tag ends */}
            
            {/* Manufacturer */}
            <div className="col-lg-6">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Manufactured by</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.manufacturer}
                  formControl={{
                    placeholder: "Type Manufacturer",
                    name: "manufacturer",
                    value: editForm?.manufacturer,
                    onChange: handleFormInput,
                  }}
                />
                {<FormErrorText error={formErrors.manufacturer} />}
              </div>
            </div>
            {/* Manufacturer ends */}

            {/* manufacturerAddress (GST) */}
            <div className="col-lg-6">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Manufacturer Address</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.manufacturerAddress}
                  formControl={{
                    //type:"number",
                    placeholder: "Manufacturer address",
                    name: "manufacturerAddress",
                    value: editForm?.manufacturerAddress,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.manufacturerAddress} />
              </div>
            </div>
            {/* manufacturerAddress ends */}

            {/* Packedby (GST) */}
            <div className="col-lg-6">
            <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Packed by</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.packedby}
                  formControl={{
                    //type:"number",
                    placeholder: "Type Packed",
                    name: "packedby",
                    value: editForm?.packedby,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.packedby} />
              </div>
            </div>
            {/* Packedby ends */}

            {/* packedbyAddress (GST) */}
            <div className="col-lg-6">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Packedby Address</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.packedbyAddress}
                  formControl={{
                    //type:"number",
                    placeholder: "Packedby address",
                    name: "packedbyAddress",
                    value: editForm?.packedbyAddress,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.packedbyAddress} />
              </div>
            </div>
            {/* packedbyAddress ends */}

            {/* marketedby (GST) */}
            <div className="col-lg-6">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Marketed by</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.marketedby}
                  formControl={{
                    //type:"number",
                    placeholder: "Type Marketed",
                    name: "marketedby",
                    value: editForm?.marketedby,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.marketedby} />
              </div>
            </div>
            {/* marketedby ends */}

            {/* marketedbyAddress (GST) */}
            <div className="col-lg-6">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Marketedby Address</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.marketedbyAddress}
                  formControl={{
                    //type:"number",
                    placeholder: "Marketedby address",
                    name: "marketedbyAddress",
                    value: editForm?.marketedbyAddress,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.marketedbyAddress} />
              </div>
            </div>
            {/* marketedbyAddress ends */}

            {/* weight */}
            <div className="col-lg-6 mt-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Weight</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.weight}
                  formControl={{
                    placeholder: "Type Weight",
                    name: "weight",
                    value: editForm?.weight,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.weight} />
              </div>
            </div>
            {/* weight ends */}
            {/* Number of packs (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Number of packs</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.numberofpacks}
                  formControl={{
                    //type:"number",
                    placeholder: "Type Number of packs",
                    name: "numberofpacks",
                    value: editForm?.numberofpacks,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.numberofpacks} />
              </div>
            </div>
            {/* Number of packs ends */}
            
            {/* Country of origin (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">Country of origin</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.countryoforigin}
                  formControl={{
                    //type:"number",
                    placeholder: "Type country",
                    name: "countryoforigin",
                    value: editForm?.countryoforigin,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.countryoforigin} />
              </div>
            </div>
            {/* Country of origin ends */}

            {/* HSN */}
            <div className="col-lg-6 mt-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">HSN</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.hsn}
                  formControl={{
                    placeholder: "Type HSN",
                    name: "hsn",
                    value: editForm?.hsn,
                    onChange: handleFormInput,
                    disabled: true,
                  }}
                />
                {/* <FormErrorText error={formErrors.hsn} /> */}
              </div>
            </div>
            {/* HSN ends */}
            {/* LineTax (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={`${commonstyles.management_details_form} ml-0`}>
                <label className="theme-form-label">GST
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.lineTax}
                  formControl={{
                    type:"number",
                    placeholder: "Type GST",
                    name: "lineTax",
                    value: editForm?.lineTax,
                    onChange: handleFormInput,
                    disabled: true,
                  }}
                />
                <FormErrorText error={formErrors.lineTax} />
              </div>
            </div>
            {/* LineTax (GST) ends */}
            {/* unit of measure */}
            {/* <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Unit of measure</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.uom}
                  formControl={{
                    placeholder: "Type unit of measure",
                    name: "uom",
                    value: editForm?.uom,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.uom} />
              </div>
            </div> */}
            {/* unit of measure ends */}

            {/* Faq  */}
            <div className="col-lg-12 mt-4 mb-5">
              <h4 className="theme-form-section-title">FAQ</h4>
              <div className={`${commonstyles.management_details_form} ml-0`}>
                {Array.isArray(editForm.faq) &&
                  editForm.faq.map((faq, indx) => (
                    <FaqFormComponent
                      form={faq}
                      key={indx}
                      formErrors={formErrors.faq?.[indx] || {}}
                      number={indx + 1}
                      handleRemove={() => removeFaq(indx)}
                      handleUpdate={(val) => updateFaq(val, indx)}
                    />
                  ))}
                <div className="col-lg-12 text-right add_text theme-btn-link">
                  <span onClick={addFaq}>Add</span>
                </div>
              </div>
            </div>
            {/* Faq ends */}

            {/* You may like */}
            <RelatedProductSelectFormComponent
              title="You may also like"
              formField="youmaylike"
              isVisible={componentVisibility.youmaylike}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={editForm.youmaylike}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* You may like ends*/}

            {/* Similar Products */}
            <RelatedProductSelectFormComponent
              title="Similar Products"
              formField="similar"
              isVisible={componentVisibility.similar}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={editForm.similar}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* Similar Products ends*/}

            {/* Bought together */}

            <RelatedProductSelectFormComponent
              title="Bought together"
              formField="boughtTogether"
              isVisible={componentVisibility.boughtTogether}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={editForm.boughtTogether}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* Bought together ends*/}

            {/*  */}
          </div>
        </div>
      </div>
      {/* Fields ends */}
    </div>
  );
}
