import commonstyles from "../../../styles/Management_Common.module.css";
import { onlyNumbers, price, userName } from "../../../utils/RegularExp";
import InputComponent from "../ReusableComponent/InputComponent";
import FormErrorText from "./FormErrorText";
export default function ProductRegionDetailFormComponent(props) {
  // console.log(props);
  const { formErrors, isLastItem } = props;
  function handleChange(e) {
    const { name, value } = e.target;
    // console.log(`${name} : ${value}`);
    // if(name==="quantity"){
    //   if(!formErrors.quantity){
    //     formErrors.quantity={};
    //   }
    //   if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
    //     formErrors.quantity = "Enter number";
    //   }else{
    //     formErrors.quantity = "";
    //   }
    // }
    if(name==="shipin"){
      if(!formErrors.shipin){
        formErrors.shipin={};
      }
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.shipin = "Enter number";
      }else{
        formErrors.shipin = "";
      }
    }
    if(name==="notificationQuantity"){
      if(!formErrors.notificationQuantity){
        formErrors.notificationQuantity={};
      }
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.notificationQuantity = "Enter number";
      }else{
        formErrors.notificationQuantity = "";
      }
    }
      if(name==="newPrice"){
        if(!formErrors.newPrice){
          formErrors.newPrice={};
        }
        if (!e.target.value.match(price) || !e.target.value === "") {
          formErrors.newPrice = "Enter minimun 3 & maximum 6 digit";
        }else{
          formErrors.newPrice = "";
        }
      } 
     if(name==="oldPrice"){
      if(!formErrors.oldPrice){
        formErrors.oldPrice={};
      }
      if (!e.target.value.match(price) || !e.target.value === "") {
        formErrors.oldPrice = "Enter minimun 3 & maximum 6 digit";
      }else{
        formErrors.oldPrice = "";
      }
    }
    
    /*if(!price.test(value) ){
      if(!formErrors.oldPrice){
        formErrors.oldPrice={};
      }
      formErrors.oldPrice = "Enter maximum 6 digit";
      }else{
      if (
        formErrors.oldPrice
      ) {
        formErrors.oldPrice= "";
      }
    }
     if(!price.test(value) ){
      if(!formErrors.newPrice){
        formErrors.newPrice={};
      }
      formErrors.newPrice = "Enter maxiunum 6 digit's";
      }else{
      if (
        formErrors.newPrice
      ) {
        formErrors.newPrice= "";
      }
    } */
    const updated = JSON.parse(JSON.stringify(props.form));
    if(name==="region"){
      if (!e.target.value.match(userName) || !e.target.value === "") {
        formErrors.region = "Required";
      }else{
        formErrors.region = "";
      }
    }
    if (name === "regionId") {
      updated.region.id = value;
    } else if (name === "quantity") {
      const parsed = Number.parseInt(value);
      // if (!isNaN(parsed)) {
        updated[name] = parsed;
      // }
    } else if (["oldPrice", "newPrice", "notificationQuantity"].includes(name)) {
      const parsed = Number.parseFloat(value);
      // if (!isNaN(parsed)) {
        updated[name] = parsed;
      // }
    } else {
      updated[name] = value;
    }
    props.handleFormUpdate(updated);
  }

  const selectedRegionID = props.form.region.id;
  // console.log(selectedRegionID);
  return (
    <div className="row">
      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          Warehouse <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>
        <select
          name="regionId"
          className={`form-control form-control-lg
            ${formErrors.region && "theme-error-input"} ${
            commonstyles.form_control
          }`}
          value={selectedRegionID}
          style={{ cursor: "pointer" }}
          onChange={handleChange}
          disabled={props?.isSetProduct ? true : false}
        >
          <option value="" disabled>Choose a region</option>
          {props.regions.map((region) => (
            <option
              key={region._id}
              value={region._id}
              disabled={props.selectedRegions.includes(region._id)}
            >
              {region.name}
            </option>
          ))}
          {/* <option>Bangalore</option> */}
        </select>
        <FormErrorText error={formErrors.region} />
      </div>
      {/* <div className="col-lg-1 mb-4">
          <label  className="theme-form-label">Stocks</label>
          <select
            className={`form-control form-control-lg ${commonstyles.form_control} w-auto`}
          >
            <option>1</option>
          </select>
        </div> */}
      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          Stocks <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>
        <InputComponent
          className="form-control-lg"
          // error={formErrors.quantity}
          formControl={{
            placeholder: "Enter stocks",
            name: "quantity",
            type: "number",
            min: 0,
            step: 1,
            value: props.form.quantity,
            onChange: handleChange,
            disabled: true,
          }}
        />
        {/* <FormErrorText error={formErrors.quantity} /> */}
        {/* 
        <input
          type="number"
          name="quantity"
          className={`form-control form-control-lg ${
            commonstyles.form_control
          }  ${formErrors.region && "theme-error-input"}`}
          placeholder="Enter quantity"
          value={props.form.quantity}
          min={1}
          step={1}
          onChange={handleChange}
        />
        <FormErrorText error={formErrors.quantity} /> */}
      </div>
      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          MRP <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>
        <InputComponent
          error={formErrors.oldPrice}
          className="form-control-lg"
          formControl={{
            placeholder: "Enter MRP",
            name: "oldPrice",
            type: "number",
            min: 0,
            step: 0.01,
            value: props.form.oldPrice,
            onChange: handleChange,
            disabled: props?.isSetProduct ? true : false,
          }}
        />
        <FormErrorText error={formErrors.oldPrice} />
      </div>

      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          Selling price
          <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>

        <InputComponent
          error={formErrors.newPrice}
          className="form-control-lg"
          formControl={{
            placeholder: "Enter selling price",
            name: "newPrice",
            type: "number",
            min: 1,
            step: 0.01,
            value: props.form.newPrice,
            onChange: handleChange,
            disabled: props?.isSetProduct ? true : false,
          }}
        />
        <FormErrorText error={formErrors.newPrice} />
      </div>

      {/* <div className="col-lg-3 mb-4">
          <label  className="theme-form-label">Offers</label>
          <input
            type="text"
            name="offer"
            className={`form-control form-control-lg ${commonstyles.form_control}`}
            placeholder="Offers"
            // value={props.form.offer}
            // onChange={handleChange}
            disabled
          />
        </div> */}

      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          Ship in <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>
        {/* <input
          type="text"
          name="shipin"
          className={`form-control form-control-lg ${
            commonstyles.form_control
          } ${formErrors.region && "theme-error-input"}`}
          placeholder=""
          value={props.form.shipin}
          onChange={handleChange}
        /> */}

        <InputComponent
          error={formErrors.shipin}
          className="form-control-lg"
          formControl={{
            placeholder: "Type Ship in",
            type: "number",
            min: 0,
            step: 1,
            name: "shipin",
            value: props.form.shipin,
            onChange: handleChange,
            disabled: props?.isSetProduct ? true : false,
          }}
        />
        <FormErrorText error={formErrors.shipin} />
      </div>
      <div className="col-lg-3 mb-4">
        <label className="theme-form-label">
          Notify if Product Low in stock <span className="text-danger" style={{fontSize: "20px"}}>*</span>
        </label>
        <InputComponent
          className="form-control-lg"
          error={formErrors.notificationQuantity}
          formControl={{
            placeholder: "Enter notification quantity",
            name: "notificationQuantity",
            type: "number",
            min: 0,
            step: 1,
            value: props.form.notificationQuantity,
            onChange: handleChange,
            disabled: props?.isSetProduct ? true : false,
          }}
        />
        <FormErrorText error={formErrors.notificationQuantity} />
      </div>

      {!isLastItem ? (
        <div className="col-lg-4 ml-auto align-self-end mb-2 text-right add_text theme-btn-link text-danger">
          <span onClick={props.handleRemoveForm}>Remove Warehouse</span>
        </div>
      ) : null}
      {/* {props.showAddNewForm && (
          // <div className="col-lg-12 text-right add_text">
          //   <span className="theme-btn-link" onClick={props.handleNewForm}>
          //     Add Region
          //   </span>
          // </div>
        )} */}
    </div>
  );
}
