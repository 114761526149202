import React, { useEffect, useState } from "react";
import styles from "../../../styles/OrderDetails.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import CloseIconSmall from "../../../assets/images/close_icon_small.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import BrownSofa from "../../../assets/images/brown_sofa.png";
import { notification, Select } from "antd";
import { formatServerValidtionErrors } from "../../../utils";
import commonstyles from "../../../styles/Management_Common.module.css";
import moment from "moment";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import validationRules from "../../../utils/validationRules";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { useHistory, useParams } from "react-router";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Menu, Dropdown, DatePicker, Tooltip } from "antd";
import arrowDropDown from "../../../assets/images/drop_down_arrow.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import ModalComponent from "../../modules/ReusableComponent/ModalComponent";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { emailRegx, otpRegx } from "../../../utils/RegularExp";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";

const { Option } = Select;

export default function OrderDetails(props) {
  const history = useHistory();
  const { id } = useParams();
  const orderProps = props.customer;
  const [order, setOrder] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(0);
  const [isShowRefundBtn, setIsShowRefundBtn] = useState(true);
  const [refundSubmit, setResundSubmit] = useState(false);
  const [markAsPaid, setmarkAsPaid] = useState(false);
  const [refund, setRefund] = useState({
    amount: "",
    reason: "",
  });
  const [refundErr, setRefundErr] = useState({
    amount: false,
    reason: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [orderEventLists, setOrderEventLists] = useState([]);
  const [editAddressForm, setEditAddressForm] = useState({
    name: "",
    email: "",
    phone: "",
    pincode: "",
    address: "",
    state: "",
    city: "",
  });
  const [regions, setRegions] = useState([]);
  const [addError, setAddError] = useState({});
  const [actionStatus, setActionStatus] = useState("");
  const [orderEventName, setOrderEventName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [vehiclesData, setVehiclesData] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [ChoosenPrdId, setChoosenPrdId] = useState('')
  const [markAsPaidInput, setMarkAsPaidInput] = useState({
    amount: "",
    method: "",
    bankRefNum:"",
    orderId:id
  })
  const [markAsPaidInputError, setMarkAsPaidInputError] = useState({
    amount: "",
    method: "",
  })


  const [statusValue,setStatusValues] = useState({
    status : null,
    product : null
  })
  const transparentButton = {
    backgroundColor:"transparent",
    border:"1px solid #A39486"
  }

  useEffect(() => {
    OrderDetails();
    getRegionList();
  }, []);
  /**
   * @author Rajkumar
   * ORDER DETAILS
   */
  function OrderDetails(type) {
    if (!type) {
      setIsLoading(true);
    }
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/orderDetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setOrder(res.data.data);
          setStatusValues({product:res.data.data.products[0]})
          getAgentList(res.data.data.regionId);
          getVehicleList(res.data.data.regionId);
          OrderEvents(res.data.data.products[0]._id);
          setOrderEventName(res.data.data.products[0].productName);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const [selectedProductId, setSelectedProductId] = useState("");
  const [selected_prdId, setSelected_prdId] = useState("");
  function OrderEvents(prd_id) {
    if (prd_id?.productName) {
      setOrderEventName(prd_id?.productName);
    }
    let product_id = prd_id?._id ? prd_id._id : prd_id;
    setSelectedProductId(product_id);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/getorderEvents/${id}/${product_id}`)
      .then((res) => {
        if (res.data.status === 200) {
          // setIsLoading(false);
          setSelected_prdId(product_id);
          if (res.data?.data[0]?.events?.length > 0) {
            res.data.data[0].events.forEach((item) => {
              item.converted_date = moment(item.createdAt).format(
                "DD MMM YYYY"
              );
              let originalDate = new Date(item.createdAt);
              item.converted_time = moment(originalDate).format("hh:mm A");
            });
            setOrderEventLists(res.data.data[0].events);
          }
        } else if (res.data.status === 400) {
        }
      })
      .catch((er) => {
        // setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }

  const [agentList, setAgentList] = useState([]);
  /**
   * @author Rajkumar
   * @param {id}
   * To get agent list
   */
  function getAgentList(id) {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`/deliveryAgent/getAgent/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data?.data?.length > 0) {
            res.data?.data?.forEach(item => {
              if (item?.name) {
                item.name = item.name.replace('__', ' ');
              }
            });
            setAgentList(res.data.data);
          }
        } else if (res.data.status === 400) {
        }
      })
      .catch((er) => {
        // setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }

  /**
 * @author Rajkumar
 * @param {id}
 * To get vehicle list
 */
  function getVehicleList(id) {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`/deliveryVehicle/getVehicle/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setVehiclesData(res?.data?.data[0]?.vehicle);
        } else if (res.data.status === 400) {
        }
      })
      .catch((er) => {
        // setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }

  const productList = (
    <Menu className={styles.order_action_dropdown_menu}>
      {order?.products?.length > 0 &&
        order?.products?.map((product) => (
          <Menu.Item key={product._id} onClick={() => OrderEvents(product)}>
            {/* <a target="_blank" rel="noopener noreferrer" href="/"> */}
            {product.productName}
            {/* </a> */}
          </Menu.Item>
        ))}
    </Menu>
  );

  const menu = (
    <Menu className={styles.order_action_dropdown_menu}>
      <Menu.Item key="0" onClick={() => setAction(statusValue.product, 3)}>
        Delivered
      </Menu.Item>
      <Menu.Item key="1" onClick={() => setAction(statusValue.product, 8)}>
        Delivery attempted
      </Menu.Item>
    </Menu>
  );

  function updateOrder() {
    // setIsUpdateOrder(true);
    
    if (ChoosenPrdId) {
      setIsFetching(true);
      let object = {
        _id: id,
        productId: ChoosenPrdId,
        status: Number(statusValue.status),
      };
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post("orders/orderStatusUpdate", object)
        .then((res) => {
          if (res.data.status === 200) {
            OrderDetails();
            // showNotification({
            //   type: "success",
            //   message: "Success",
            //   description: "User Comment Added Successfully",
            // });
            setIsFetching(false);      
          } else if (res.data.status === 400) {
            showNotification({
              type: "error",
              message: "Failed",
              description: res.data.msg,
            });
            setIsFetching(false);
          }
      //    setIsUpdateOrder(false);
            setChoosenPrdId('');
        })
        .catch((er) => {
          setIsFetching(false);
      //    setIsUpdateOrder(false);
           setChoosenPrdId('');
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
  }

  function sentComment() {
    let object = {
      _id: id,
      comments: comment,
    };
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/orderCommentUpdate", object)
      .then((res) => {
        if (res.data.status === 200) {
          OrderDetails();
          setComment("");
          showNotification({
            type: "success",
            message: "Success",
            description: "User comment added successfully",
          });
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function statusBtnClick(e, prd) {
    let sts = "";
    if (prd.status === 1) {
      sts = 2;
    } else if (prd.status === 2 || prd.status === 8) {
      sts = 7;
    } else if (prd.status === 7) {
      sts = 3;
    } else {
      sts = 1;
    }
    setChoosenPrdId(prd._id);
    setStatusValues({status:sts,product:prd})
    // setIsUpdateOrder(true);
  }

  function setAction(prd, status) {
    setStatusValues({product:actionStatus,status:status})
    // updateOrder();
    setChoosenPrdId(actionStatus._id);
    // setIsUpdateOrder(true);
  }

  const refundRules = {
    amount: [validationRules.required],
    reason: [validationRules.required],
  };

  const rules = {
    name: [validationRules.required],
    email: [validationRules.required],
    phone: [validationRules.required],
    pincode: [validationRules.required],
    address: [validationRules.required],
    state: [validationRules.required],
    city: [validationRules.required],
  };

  function markAsPaidInputOnchange(name, e){
    if(name === "amount"){
      if(e.target.value === ""){
        setMarkAsPaidInputError({...markAsPaidInputError,amount:"Please enter the amount"})
      }else{
        setMarkAsPaidInputError({...markAsPaidInputError,amount:""})
      }
      setMarkAsPaidInput({...markAsPaidInput,amount:e.target.value})
    }
    if(name === "paymentMethod"){
      if(e.target.value === ""){
        setMarkAsPaidInputError({...markAsPaidInputError,method:"Please select the payment method"})
      }else{
        setMarkAsPaidInputError({...markAsPaidInputError,method:""})
      }
      setMarkAsPaidInput({...markAsPaidInput, method:e.target.value})
    }
    if(name === "bankReference"){
      setMarkAsPaidInput({...markAsPaidInput, bankRefNum:e.target.value})
    }
  }

  function submitMarkAsPaid(){
    if(markAsPaidInput.amount === ""){
      return setMarkAsPaidInputError({...markAsPaidInputError,amount:"Required" })
    }
    if(markAsPaidInput.method === ""){
      return setMarkAsPaidInputError({...markAsPaidInputError,method:"Required" })
    }
    let data = markAsPaidInput
    if(data.bankRefNum === ""){
      delete data.bankRefNum
    }
    AxiosInstance(PORTS.INVOICE_DOWNLOAD, "application/json")
          .post("orders/payuCCAvenueTransactionStatusUpdate", data)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              showNotification({
                type: "success",
                message: "Success",
                description: "Updated Successfully",
              });
              setmarkAsPaid(false)
            } else if (res.data.status === 400 || res.data.status === "400") {
              showNotification({
                type: "error",
                message: "error",
                description: "Something went wrong",
              });
            }
          })
          .catch((er) => {
            // console.log(er);
            return showNotification({
              type: "error",
              message: "Failed",
              description: "Error occured",
            });
          });
  }

  function handleInput(e) {
    // const { name, value } = e.target;
    let { name, value } = e.target;
    value = value.trim();
    if (name === "reason") {
      // console.log(value.trim());
      let cloneRefund = { ...refund };
      cloneRefund[name] = value.trim();
      refundErr[name] = false;
      setRefund(cloneRefund);
    }
    let cloneRefund = { ...refund };
    cloneRefund[name] = value;
    refundErr[name] = false;
    setRefund(cloneRefund);
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current < moment().startOf("day");
  }

  function submitRefund() {
    if (refund.amount > 0) {
      if (refund.reason) {
        setResundSubmit(true);
        let params = {
          _id: id,
          amount: Number(refund.amount),
          reason: refund.reason,
        };
        AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
          .post("orders/refund", params)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              OrderDetails();
              setIsShowRefundBtn(false);
              showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
              setRefund({ amount: "", reason: "" });
              setResundSubmit(false);
            } else if (res.data.status === 400 || res.data.status === "400") {
              const errors = formatServerValidtionErrors(res.data.error);
              setFormErrors(errors);
              showNotification({
                type: "error",
                message: "error",
                description: res.data.msg,
              });
              setResundSubmit(false);
            }
          })
          .catch((er) => {
            // console.log(er);
            return er;
            setResundSubmit(false);
            showNotification({
              type: "error",
              message: "Failed",
              description: "Error occured",
            });
          });
      } else {
        if (!refund.amount) {
          setRefundErr({ ...refundErr, amount: true, reason: true });
        } else {
          setRefundErr({ ...refundErr, reason: true });
        }
      }
    } else {
      if (!refund.reason) {
        setRefundErr({ ...refundErr, reason: true, amount: true });
      } else {
        setRefundErr({ ...refundErr, amount: true });
      }
    }
  }

  //Button disabled if order product status is 3 or 4
  let orderStatus = "";
  if (Array.isArray(order.products)) {
    orderStatus = order.products.every(
      (product) => product.status === 3 || product.status === 4
    );
  }

  const [isEnabled, setIsEnabled] = useState(false);
  const refundStatus = () => {
    setIsEnabled(true);
    let type = order.refundPermision === 0 ? 1 : 0;
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/refundPermision/${order._id}/${type}`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsEnabled(false);
          OrderDetails();
        }
      })
      .catch((er) => {
        setIsEnabled(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsEnabled(false);
      });
  };

  // const [isUpdateOrder, setIsUpdateOrder] = useState(false);
  const deleteOrder = () => {
    // setIsUpdateOrder(true);
  };

  function cancelDelete() {
    // setIsUpdateOrder(false);
    setChoosenPrdId('');
  }

  const removeOrder = () => {
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/deleteOrder/${order._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          history.push("/order");
        }
      })
      .catch((er) => {
        setIsEnabled(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => { });
  };

  const downloadInvoice = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL_AWS}:${PORTS.INVOICE_DOWNLOAD}/api/v1/orders/invoice/${order._id}/1`,
      "_self"
    );
    setTimeout(() => {
      OrderDetails(true);
    }, 4000)
  };

  const downloadEway = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL_AWS}:${PORTS.INVOICE_DOWNLOAD}/api/v1/orders/ewayBill/${order._id}/1`,
      "_self"
    );
    // AxiosInstance(PORTS.INVOICE_DOWNLOAD, "application/json")
    // .get(`orders/ewayBill/${order._id}/1`)
    // .then((res) => {
    // })
    // .catch((er) => {
    //   setIsEnabled(false);
    //   showNotification({
    //     type: "error",
    //     message: "Failed",
    //     description: "Error occured",
    //   });
    // })
    // .finally(() => { });
  }

  const postComments = (item) => {
    let comments = "";
    let cloneOrderEventLists = [...orderEventLists];
    cloneOrderEventLists.forEach((data) => {
      if (item._id === data._id) {
        comments = data.updatedComment;
      }
    });
    if (!comments) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please enter the post",
      });
    }
    if (comments && !isBtnAction) {
      setIsBtnAction(true);
      let params = {
        orderId: id,
        productId: selectedProductId,
        eventId: item._id,
        comment: comments,
      };
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post(`orders/events/comment`, params)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Comment Added Successfully",
            });
            OrderEvents(selected_prdId);
            setIsBtnAction(false);
          } else {
            setIsBtnAction(false);
          }
        })
        .catch((er) => {
          setIsEnabled(false);
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
          setIsBtnAction(false);
        })
        .finally(() => {
          setIsBtnAction(false);
        });
    }
  };

  const [isBtnAction, setIsBtnAction] = useState(false);
  const retryHandler = (item) => {
    setIsBtnAction(true);
    let params = {
      orderId: id,
      productId: selectedProductId,
      eventId: item._id,
    };
    if (!isBtnAction) {
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post(`orders/events/retryRefund `, params)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: res.data.msg,
            });
            OrderEvents(selected_prdId);
            setIsBtnAction(false);
          } else {
            setIsBtnAction(false);
          }
        })
        .catch((er) => {
          setIsEnabled(false);
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
          setIsBtnAction(false);
        })
        .finally(() => {
          setIsBtnAction(false);
        });
    }
  };

  const onchangeInputComment = (e, item) => {
    const { name, value } = e.target;
    let cloneOrderEventLists = [...orderEventLists];
    cloneOrderEventLists.forEach((data) => {
      if (item._id === data._id) {
        data.updatedComment = value;
      }
    });
    setOrderEventLists(cloneOrderEventLists);
  };

  const [scheduleDate, setScheduleDate] = useState("");
  const rescheduleDate = (dateString, item) => {
    let converted_Date = moment(dateString).format("YYYY-MM-DD");
    setScheduleDate(converted_Date);
  };
  /**
   * @author Rajkumar
   * @param {item}
   * To handle reschedule
   */
  const rescheduleHandler = (item) => {
    if (!scheduleDate) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please choose the reschedule date",
      });
    }
    if (scheduleDate && !isBtnAction) {
      setIsBtnAction(true);
      let params = {
        orderId: id,
        productId: selectedProductId,
        eventId: item._id,
        shipin: scheduleDate,
      };
      if (!isBtnAction) {
        AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
          .post(`orders/events/reschedule `, params)
          .then((res) => {
            if (res.data.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
              setScheduleDate("");
              OrderEvents(selected_prdId);
              setIsBtnAction(false);
            } else {
              setIsBtnAction(false);
            }
          })
          .catch((er) => {
            setIsEnabled(false);
            showNotification({
              type: "error",
              message: "Failed",
              description: "Error occured",
            });
            setIsBtnAction(false);
          })
          .finally(() => {
            setIsBtnAction(false);
          });
      }
    }
  };

  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedAgentDetail, setSelectedAgentDetail] = useState("");
  const agentSelectOnchange = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "" && name !== "vehicle") {
      setSelectedAgent("");
      setSelectedAgentDetail([]);
      let ele = document.getElementById("inputPassword4");
      if (ele?.value) {
        ele.value = "";
      }
    } else {
      if (name === "vehicle") {
        setVehicle(e);
      } else {
        setSelectedAgent(value);
        agentList.forEach((item) => {
          if (item._id === value) {
            setSelectedAgentDetail(item);
          }
        });
      }
    }
  };

  function vehicleSelectOnchange(e) {
    setVehicle(e);
  }

  const sentAgent = (item) => {
    if (selectedAgentDetail?._id && !isBtnAction && vehicle?.length > 0) {
      setIsBtnAction(true);
      let params = {
        orderId: id,
        productId: selectedProductId,
        eventId: item._id,
        agentId: selectedAgentDetail._id,
        vehicle
      };
      if (!isBtnAction) {
        AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
          .post(`orders/events/setAgent`, params)
          .then((res) => {
            if (res.data.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
              OrderEvents(selected_prdId);
              setIsBtnAction(false);
            } else {
              setIsBtnAction(false);
            }
          })
          .catch((er) => {
            setIsEnabled(false);
            showNotification({
              type: "error",
              message: "Failed",
              description: "Error occured",
            });
            setIsBtnAction(false);
          })
          .finally(() => {
            setIsBtnAction(false);
          });
      }
    } else if (vehicle.length === 0) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the Agent/Vehicle details",
      });
    }
  };

  const [isEditAddress, setIsEditAddress] = useState(false);
  const editShippingAddress = () => {
    setIsEditAddress(true);
    setEditAddressForm({ ...order?.shippingAddress });
  };

  const hideAddressPopup = () => {
    setIsEditAddress(false);
    setAddError([]);
  };

  const addressHandleInput = (e) => {
    const { name, value } = e.target;
    const cloneEditAddressForm = { ...editAddressForm };
    if (value.trim()) {
      cloneEditAddressForm[name] = value;
    } else {
      cloneEditAddressForm[name] = "";
    }
    setEditAddressForm(cloneEditAddressForm);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const submitshippingAddress = () => {
    const result = validate(undefined, editAddressForm);
    if (Object.keys(result).length) {
      setAddError(result);
      // showNotification({
      //   type: "warning",
      //   message: "There were few errors",
      //   description: "Resolve the errors and try again",
      // });
      return;
    }
    if (
      editAddressForm.email ||
      editAddressForm.phone ||
      editAddressForm.pincode
    ) {
      if (!emailRegx.test(editAddressForm.email.trim())) {
        result.email = "Please enter a valid email address";
      } else {
        result.email = "";
      }
      if (
        editAddressForm?.phone?.length > 10 ||
        editAddressForm?.phone?.length < 10
      ) {
        result.phone = "Please enter a valid phone number";
      } else {
        result.phone = "";
      }
      if (
        editAddressForm?.pincode?.length > 6 ||
        editAddressForm?.pincode?.length < 6
      ) {
        result.pincode = "Please enter a valid pincode";
      } else {
        result.pincode = "";
      }
      setAddError(result);
    }
    setIsBtnAction(true);
    let params = {
      _id: id,
      shippingAddress: editAddressForm,
    };
    if (!result.email && !result.phone && !result.pincode) {
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post(`orders/updateAddress`, params)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: res.data.msg,
            });
            let cloneOrder = { ...order };
            cloneOrder.shippingAddress = editAddressForm;
            setOrder(cloneOrder);
            OrderEvents(selected_prdId);
            setIsBtnAction(false);
            hideAddressPopup();
            OrderDetails();
          } else if (res.data.status === 400) {
            setIsBtnAction(false);
            hideAddressPopup();
            showNotification({
              type: "error",
              message: "error",
              description: res.data.msg,
            });
          } else {
            setIsBtnAction(false);
          }
        })
        .catch((er) => {
          setIsEnabled(false);
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
          setIsBtnAction(false);
        })
        .finally(() => {
          setIsBtnAction(false);
        });
    }
  };

  function downloadCreditMemo(id) {
    window.open(
      `${process.env.REACT_APP_BASE_URL_AWS}:${PORTS.INVOICE_DOWNLOAD}/api/v1//orders/creditMemo/${id}`,
      "_self"
    );
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setAddError({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setAddError(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  return isLoading === true ? (
    <div style={{ marginLeft: "40%", marginTop: "10%" }}>
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching order details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <div className={styles.management_header_title}>
          <h1>
            <img
              src={BlackLeftArrow}
              alt=""
              // onClick={() => history.push("/order")}
              onClick={() => history?.goBack()}
              style={{ cursor: "pointer" }}
            />
            Order details
          </h1>
          <span className={styles.management_header_info}>
            Last updated,
            {moment(order?.updatedAt).format("MMM Do, h:mm:ss A")}
          </span>
        </div>
        <div className={styles.management_header_btn}>
          {(order?.refundPermision === 0 || order?.refundPermision === 1) &&
            order?.refundEnablePermision && (
              <button
                className={`${styles.btn}  d-inline-block`}
                onClick={!isEnabled && refundStatus}
              >
                {!isEnabled
                  ? order?.refundPermision
                    ? "Disable Refund"
                    : "Enable Refund"
                  : "Loading..."}
              </button>
            )}
          {/* <button
            onClick={deleteOrder}
            className={`${styles.btn} d-inline-block`}
          >
            Delete
          </button> */}
          <div className={styles.management_header_order_id}>
            Order Id: # {order?.orderId}
          </div>
          {order.paymentStatus !== 1 &&
          <div
            className={`${styles.order_mark_as_paid_payment_done}`}
            onClick={() => setmarkAsPaid(true)}
          >
            Mark as Paid
          </div>}
          
        </div>
        <ModalComponent
              data={{
                show: markAsPaid,
                size: "md",
                centered: true,
              }}
              handleCancel={()=>setmarkAsPaid(false)}
              
              // handleConfirm={removeOrder}
              title="Mark As Paid"
              confirmText="Mark As Paid"
            >
              <div>
                <label className={styles.Shipping_label}>
                  Amount
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={markAsPaidInputError.amount}
                  formControl={{
                    type: "number",
                    placeholder: "000000",
                    name: "amount",
                    value: markAsPaidInput.amount,
                    onChange: (e)=>markAsPaidInputOnchange("amount",e),
                  }}
                />
                <FormErrorText error={markAsPaidInputError.amount} />
                <label className={styles.Shipping_label}>
                  Payment Method
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <select
                    className={"form-control " + styles.ccavenue_payu_dropdown}
                    onChange={(e)=>markAsPaidInputOnchange("paymentMethod",e)}
                    value={markAsPaidInput.method}
                    style={{ cursor: "pointer" }}
                  >
                        {markAsPaidInput.method === "" &&
                        <option key={1} value={''} label={'Select Method'}>
                          {"Select Method"}
                        </option>}
                        <option key={2} value={'CCAvenue'} label={'CCAvenue'}>
                          {"CCAvenue"}
                        </option>
                        <option key={3} value={'payU'} label={'PayU'}>
                          {"PayU"}
                        </option>
                </select>
                <FormErrorText error={markAsPaidInputError.method} />
                <label className={styles.Shipping_label}>
                  Bank Reference Number
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  formControl={{
                    type: "number",
                    placeholder: "********",
                    name: "phone",
                    value: markAsPaidInput.bankRefNum,
                    onChange: (e)=>markAsPaidInputOnchange("bankReference",e),
                  }}
                />
                <div style={{ marginTop: "10px",marginBottom:"20px" }} className="text-center">
                  <button
                    className={`btn ${styles.btn_filled_close}`}
                    onClick={()=>setmarkAsPaid(false)}
                  >
                    Close
                  </button>
                  <button
                    className={`btn ${styles.btn_filled}`}
                    onClick={submitMarkAsPaid}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </ModalComponent>
      </div>
      <div className={styles.management_details}>
        <div className={styles.management_details_contents}>
          <div className="row">
            <div className="col-lg-12">
              <h4>Products</h4>
              {order?.products?.length > 0 &&
                order.products.map((product) => (
                  <div
                    className={`${styles.management_details_form} ${styles.product_details} mb-4`}
                  >
                    <div>
                      <h3 className={styles.secondry_title}>Product</h3>
                      {/* <a href="/catalog/products"> */}{" "}
                      <img
                        src={product?.feature_image}
                        alt=""
                        className={styles.product_img}
                      />{" "}
                      {/* </a> */}
                      <div className={styles.product_name}>
                        <div className={styles.product_name_value}>
                          <div className={styles.product_title}>
                            {product.productName}
                          </div>
                          <span>
                            Rs.{" "}
                            {product.sellPrice.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        {product.dynamicProductOptions.map((prdDetail) => (
                          <div
                            key={prdDetail._id}
                            className={styles.product_name_value}
                          >
                            <span>{prdDetail.name}:</span>
                            <span>{prdDetail.options.name}</span>
                          </div>
                        ))}
                        <span>
                          Delivery on (Expected):{" "}
                          {moment(product?.shipin).format("MMM Do YYYY")}
                        </span>
                        {/* <div className={styles.product_name_value}>
                        <span>Size:</span>
                        <span>{product.size ? product.size : "Medium"}</span>
                      </div> */}
                      </div>
                    </div>
                    <div>
                      <h3 className={styles.secondry_title}>Quantity</h3>
                      <span className={styles.product_value}>
                        {product.quantity}{" "}
                        {product.cancelledQuantity > 0 &&
                          "( " + product.cancelledQuantity}{" "}
                        {product.cancelledQuantity > 0 && " - Cancelled )"}
                      </span>
                    </div>
                    <div>
                      <h3 className={styles.secondry_title}>Total</h3>
                      <span className={styles.product_value}>
                        Rs.{" "}
                        {product.grandTotal.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                    <div>
                      <h3 className={styles.secondry_title}>Delivery Status</h3>
                      <span className={styles.product_value}>
                        {product.status === 1
                          ? "Processing"
                          : product.status === 2
                            ? "Shipped"
                            : product.status === 3
                              ? "Delivered"
                              : product.status === 4
                                ? "Cancelled"
                                : product.status === 5
                                  ? "Failed"
                                  : product.status === 6
                                    ? "On-Hold"
                                    : product.status === 7
                                      ? "Out For Delivery"
                                      : "Delivery attempted"}
                      </span>
                    </div>
                    <div>
                      {(product.status === 1 ||
                        product.status === 2 ||
                        product.status === 6 ||
                        product.status === 7 ||
                        product.status === 8) && (
                          <div>
                            <h3 className={styles.secondry_title}>Action</h3>
                            {/* <span className={styles.product_value}>Ship</span> */}

                            {product.status === 7 && (
                              <Dropdown
                                overlay={menu}
                                className={`${styles.order_action_dropdown}`}
                              >
                                <div
                                  className="ant-dropdown-link"
                                  onClick={() => {
                                    setActionStatus(product);
                                  }}
                                >
                                  Out For Delivery
                                  <img src={arrowDropDown} alt="image" />
                                </div>
                              </Dropdown>
                            )}
                            {product.status !== 7 && (
                              <Tooltip
                                disabled={!order?.actionPermision}
                                placement="topRight"
                                title={
                                  !order?.actionPermision &&
                                  "Please perform action after 24 hours."
                                }
                              >
                                <button
                                  className={`${commonstyles.btn_ordr} ${commonstyles.btn_primary} ${commonstyles.transparent_button} d-inline-block`}
                                  style={{backgroundColor:"transparent",border:"none"}}
                                  disabled={!order?.actionPermision}
                                  onClick={(e) => statusBtnClick(e, product)}
                                >
                                  {product.status === 1
                                    ? isFetching &&
                                      selectedProduct === product._id
                                      ? "Shipping..."
                                      : "Ship"
                                    : product.status === 2 || product.status === 8
                                      ? isFetching &&
                                        selectedProduct === product._id
                                        ? "Loading..."
                                        : "Out For Delivery"
                                      : product.status === 6
                                        ? isFetching &&
                                          selectedProduct === product._id
                                          ? "Processing..."
                                          : "Process"
                                        : isFetching &&
                                          selectedProduct === product._id
                                          ? "Loading..."
                                          : "Out For Delivery"}
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                ))}
            </div>
            <div class="col-lg-6">
              <h4>Payment Summary</h4>
              <div className={`${styles.management_details_form} mb-5`}>
                <div className={styles.admin_payment}>
                  <span>Sub Total</span>
                  <span>
                    Rs.{" "}
                    {order?.subtotalAmount?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                {order?.totalDiscount !== "" && order?.totalDiscount !== 0 && (
                  <div className={styles.admin_payment}>
                    <span>Discount</span>
                    <span>
                      Rs.{" "}
                      {order?.totalDiscount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                )}
                {order?.additionalTax?.length > 0 &&
                  order?.additionalTax.map((item) => (
                    <div key={item._id} className={styles.admin_payment}>
                      <span>{item.name}</span>
                      <span>
                        Rs.{" "}
                        {item?.taxAmount?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  ))}
                {order?.couponCode !== "" && order?.couponCode > 0 && (
                  <div className={styles.admin_payment}>
                    <span>Coupon Discount ({order?.couponCode})</span>
                    <span>
                      Rs.{" "}
                      {order?.couponDiscount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                )}
                {order?.totalDiscount !== "" && order?.totalDiscount > 0 && (
                  <div className={styles.admin_payment}>
                    <span>Total Saving</span>
                    <span>
                      Rs.{" "}
                      {order?.totalDiscount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                )}
                <div className={styles.admin_payment}>
                  <span>Shipping and Handling</span>
                  <span>
                    Rs.{" "}
                    {order?.shippingAmount?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Total</span>
                  <span>
                    Rs.{" "}
                    {order?.grandTotal?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>

              {/* {order.actionPermision && (
                <>
                  <h4>Refund</h4>
                  <div className={`${styles.management_details_form} mb-4`}>
                    {isShowRefundBtn ? (
                      <div className="text-right">
                        <button
                          disabled={orderStatus}
                          className={`btn ${styles.btn_filled}`}
                          onClick={() => {
                            setIsShowRefundBtn(false);
                          }}
                        >
                          Make a Refund
                        </button>
                      </div>
                    ) : (
                      <div>
                        <label className={styles.secondry_title}>
                          Refund Amount
                          <span className="text-danger" style={{ fontSize: "20px" }}>
                            *
                          </span>
                        </label>
                        <InputComponent
                          className="form-control-lg"
                          error={refundErr.amount}
                          formControl={{
                            type: "number",
                            placeholder: "Enter Refund Amount",
                            name: "amount",
                            value: refund?.amount,
                            onChange: handleInput,
                          }}
                        />
                        <FormErrorText
                          error={
                            refundErr.amount
                              ? refund.amount < 0
                                ? "Amount should be greater than zero"
                                : "Required"
                              : refund.amount < 0
                                ? "Amount should be greater than zero"
                                : ""
                          }
                        />
                        <label className={styles.secondry_title}>Reason</label>
                        <span className="text-danger" style={{ fontSize: "20px" }}>
                          *
                        </span>
                        <textarea
                          name="reason"
                          id=""
                          className={`${commonstyles.form_control} ${refundErr.reason && "theme-error-input"
                            } form-control form-control-lg `}
                          rows="5"
                          placeholder="Type Reason"
                          onChange={handleInput}
                        ></textarea>
                        <FormErrorText
                          error={refundErr.reason ? "Required" : ""}
                        />
                        <div className="text-right">
                          <button
                            disabled={refundSubmit}
                            className={`btn ${styles.btn_filled}`}
                            onClick={submitRefund}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )} */}

              {/* <h4>Notes for this Order</h4>
              <div className={`${styles.management_details_form} mb-4`}> */}
              {/* <label className={styles.secondry_title}>Status</label>
                <select
                  name=""
                  id=""
                  className={`form-control ${styles.form_control} mb-4`}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select> */}
              {/* <label for="" className={styles.secondry_title}>
                  Comment
                </label>
                <textarea
                  value={comment}
                  className={`form-control ${styles.form_control} mb-4`}
                  rows="5"
                  placeholder="Type your comments"
                  onChange={(e) => setComment(e.target.value)}
                ></textarea> */}
              {/* <div
                  className={`custom-control custom-checkbox ${styles.custom_control} ${styles.custom_checkbox} mb-3`}
                >
                  {/* <input  type="checkbox" className="custom-control-input custom_control_input" id="customCheck"> */}
              {/* <label
                  className={`custom-control-label ${styles.custom_control_label}`}
                  for="customCheck"
                >
                  <span>
                    Limit number of times this discount can be used in total
                  </span>
                </label> */}
              {/* <div className="text-right">
                  <button
                    className={`btn ${styles.btn_filled}`}
                    onClick={() => sentComment("comments")}
                  >
                    Submit Comment
                  </button>
                </div>
              </div> */}

              <section className={`${styles.order_events} mt-4`}>
                <div className={`${styles.order_events_header}`}>
                  <h4 className={`${styles.text_hide}`}>Order Events</h4>
                  <p style={{ fontSize: "10px", marginRight: "145px" }}>
                    ({orderEventName})
                  </p>
                  <div className={`${styles.order_status}`}>
                    <Dropdown
                      overlay={productList}
                      className={`${styles.order_status_dropdown}`}
                    >
                      <div
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        Change Product
                        <img src={arrowDropDown} alt="image" />
                      </div>
                    </Dropdown>
                  </div>
                </div>
                {orderEventLists.length > 0 &&
                  orderEventLists.map((item) => (
                    <div
                      className={`${styles.order_events_values}`}
                      key={item._id}
                    >
                      <div className={`${styles.order_events_details}`}>
                        <div
                          className={`${styles.order_events_circle} ${item.type === 1
                            ? styles.sucess
                            : item.type === 2
                              ? styles.failed
                              : ""
                            }`}
                        ></div>
                        <div className={`${styles.order_events_content}`}>
                          <div className={`${styles.order_events_date}`}>
                            {item.converted_date} | {item.converted_time}{" "}
                            {item.classification && "| ("}
                            {item.classification && item.classification}
                            {item.classification && ")"}
                          </div>
                          {item.message && (
                            <div className={`${styles.order_events_status}`}>
                              {item.message}
                            </div>
                          )}
                          {item.action === "reschedule" &&
                            item.actionStatus === 0 && (
                              <div
                                className={`${styles.order_events_comments} order_events_comments_datepicker`}
                              >
                                <DatePicker
                                  disabledDate={disabledDate}
                                  onChange={(dateString) =>
                                    rescheduleDate(dateString, item)
                                  }
                                  style={{ width: "100%" }}
                                />
                                <div
                                  onClick={() => rescheduleHandler(item)}
                                  className={`${styles.order_events_btn} ${styles.order_events_btn_filled}`}
                                >
                                  Reschedule
                                </div>
                              </div>
                            )}
                          {item.commentStatus === 1 && item.comment && (
                            <div className={`${styles.order_events_info}`}>
                              {item.comment}
                            </div>
                          )}
                          {/* <div className={`${styles.order_events_btn} ${styles.order_events_btn_borderd}`}>
                          View
                        </div>
                        <div className={`${styles.order_events_btn} ${styles.order_events_btn_borderd}`}>
                          View Details
                        </div>
                        <div className={`${styles.order_events_btn} ${styles.order_events_btn_borderd}`}>
                          Download
                        </div>
                        <div className={`${styles.order_events_btn} ${styles.order_events_btn_bordered}`}>
                          Download Invoice
                        </div>
                        <div className={`${styles.order_events_rma_id}`}>
                          RMA ID: 9834203405
                        </div> */}
                          {item.statusMsg && (
                            <div className={`${styles.order_events_desc_list}`}>
                              <span
                                className={`${styles.order_events_desc_list_heading}`}
                              >
                                Status:
                              </span>
                              <span
                                className={`${styles.order_events_desc_list_description}`}
                              >
                                {item.statusMsg?.replace('__', ' ')}
                              </span>
                            </div>
                          )}
                          {item.cancelledBy && (
                            <div className={`${styles.order_events_desc_list}`}>
                              <span
                                className={`${styles.order_events_desc_list_heading}`}
                              >
                                Cancelled By:
                              </span>
                              <span
                                className={`${styles.order_events_desc_list_description}`}
                              >
                                {item.cancelledBy}
                              </span>
                            </div>
                          )}
                          {item.cancelledBy && (
                           
                           <button
                           className={`btn-sm theme-btn-outlined ${styles.font_small}`}
                           style={transparentButton}
                           onClick={() => downloadCreditMemo(item.refundId)}
                         >
                           Download Credit Memo
                         </button>
                           
                          )}
                          {item.action === "refundStatus" && (
                            <div className={`${styles.order_events_desc_list}`}>
                              {/* Add approved class for backgrond and text color Green */}
                              <span
                                className={`${styles.order_events_desc_list_tag
                                  } ${item.type === 1
                                    ? styles.approved
                                    : styles.rejected
                                  }`}
                              >
                                {item.type === 1 ? "Approved" : "Reject"}
                              </span>
                              <span
                                className={`${styles.order_events_desc_list_heading}`}
                              >
                                {item.type === 1 ? "Approved" : "Rejected"} by
                              </span>
                              <span
                                className={`${styles.order_events_desc_list_description} ${styles.grayesh_orange}`}
                              >
                                Rahul Sharma
                              </span>
                            </div>
                          )}
                          {/* <div className={`${styles.order_events_desc_list}`}>
                          <span className={`${styles.order_events_desc_list_heading}`}>Invoice generated:</span>
                          <span className={`${styles.order_events_desc_list_description} ${styles.order_events_desc_list_link}`}>View Invoice</span>
                        </div> */}
                          {/* <div className={`${styles.order_events_desc_list}`}> */}
                          {/* Add reject class for backgrond and text color Red */}
                          {/* <span className={`${styles.order_events_desc_list_tag} ${styles.rejected}`}>Reject</span>
                          <span className={`${styles.order_events_desc_list_heading}`}>Reject by</span>
                          <span className={`${styles.order_events_desc_list_description} ${styles.grayesh_orange}`}>Rahul Sharma</span>
                        </div> */}
                          {item.action === "refund" && item.actionStatus === 0 && (
                            <div
                              onClick={() => retryHandler(item)}
                              className={`${styles.order_events_btn} ${styles.order_events_btn_borderd}`}
                            >
                              Retry
                            </div>
                          )}
                          {item.commentStatus === 0 && (
                            <div className={`${styles.order_events_comments}`}>
                              <input
                                placeholder="Leave a comment..."
                                value={item.updatedComment}
                                onChange={(e) => onchangeInputComment(e, item)}
                              />
                              <div
                                className={`${styles.order_events_btn} ${styles.order_events_btn_filled}`}
                                onClick={() => postComments(item)}
                              >
                                Post
                              </div>
                            </div>
                          )}
                          {item.action === "agent" && item.actionStatus === 0 && (
                            <div
                              className={`${styles.order_events_delivery_person}`}
                            >
                              <div class="form-row">
                                <div class="col-md-6">
                                  <label for="inputEmail4">
                                    Delivery person
                                  </label>
                                  <select
                                    className={`${styles.form_control} form-control`}
                                    name="agent"
                                    style={{ height: "38px" }}
                                    onChange={agentSelectOnchange}
                                    value={selectedAgent}
                                  >
                                    <option value="">Select agent</option>
                                    {agentList.length > 0 &&
                                      agentList.map((agent) => (
                                        <option
                                          key={agent._id}
                                          value={agent._id}
                                        >
                                          {capitalize(agent.name)}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputEmail4">
                                    Choose vehicle
                                  </label>
                                  <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    name="vehicle"
                                    placeholder="Select vehicle"
                                    onChange={(e) => vehicleSelectOnchange(e)}
                                    value={vehicle}
                                  >
                                    {vehiclesData.length > 0 &&
                                      vehiclesData.map((vehicle, indx) => (
                                        <Option
                                          key={indx}
                                          value={vehicle}
                                        >
                                          {capitalize(vehicle)}
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputPassword4">
                                    Mobile number
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    className={`${styles.form_control} form-control`}
                                    id="inputPassword4"
                                    placeholder=""
                                    value={selectedAgentDetail?.phone}
                                  />
                                </div>
                                <div
                                  className={`${styles.order_events_btn} ${styles.order_events_btn_filled}`}
                                  onClick={() => sentAgent(item)}
                                >
                                  Submit
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className={`${styles.order_events_customer_request}`}>
                          <span className={`${styles.order_events_customer_request_info}`}>Request from customer (Raise a ticket) with photo, writeup, reason</span>
                          <div className={`${styles.onoffswitch}`}>
                            <input type="checkbox" name="enable" className={`${styles.onoffswitch_checkbox}`} id="orderSwitch1" checked />
                            <label className={`${styles.onoffswitch_label}`} htmlFor="orderSwitch1">
                              <span className={`${styles.onoffswitch_inner}`}></span>
                              <span className={`${styles.onoffswitch_switch}`}></span>
                            </label>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </section>
            </div>
            <div className="col-lg-6">
              <h4>
                Customer Details{" "}
                {order?.customerDetails?.name ? "" : "( Guest )"}
              </h4>
              <div
                className={`${styles.management_details_form} ${styles.admin_customer} mb-4`}
              >
                <div className={styles.admin_customer_details}>
                  <div
                    className={`${styles.admin_customer_name} ${styles.border_bottom}`}
                  >
                    <h3 className={styles.secondry_title}>Full name</h3>
                    <span>
                      {order?.customerDetails?.name
                        ? order?.customerDetails?.name
                        : order?.billingAddress?.name}
                    </span>
                  </div>
                  <div
                    className={`${styles.admin_customer_contact} ${styles.border_bottom}`}
                  >
                    <h3 className={styles.secondry_title}>
                      Contact Information
                    </h3>
                    <span>
                      +91
                      {order?.customerDetails?.phone
                        ? order?.customerDetails?.phone
                        : order?.billingAddress?.phone}
                    </span>
                    <span>
                      {order?.customerDetails?.email
                        ? order?.customerDetails?.email
                        : order?.billingAddress?.email}
                    </span>
                  </div>
                  <div
                    className={`${styles.admin_customer_details_address} ${styles.border_bottom}`}
                  >
                    <div className={styles.admin_title_with_link}>
                      <h3 className={styles.secondry_title}>
                        Shipping Address
                      </h3>
                      {!order?.actionPermision && (
                        <div
                          className={styles.admin_link}
                          onClick={editShippingAddress}
                        >
                          Edit
                        </div>
                      )}
                    </div>
                    {order?.shippingAddress?.name && (
                      <span className="mb-3">
                        {`${order?.shippingAddress?.name}`} <br />
                        {`${order?.shippingAddress?.address}`} <br />
                        {`${order?.shippingAddress?.city}`} <br />
                        {`${order?.shippingAddress?.state}, ${order?.shippingAddress?.pincode}`}{" "}
                        <br />
                        +91 {`${order?.shippingAddress?.phone}`} <br />
                        {`${order?.shippingAddress?.email}`}
                      </span>
                    )}
                    <h3 className={styles.secondry_title}>Billing Address</h3>
                    {/* <span>{`${order?.billingAddress?.name} ${order?.billingAddress?.address} ${order?.billingAddress?.city} ${order?.billingAddress?.state} ${order?.billingAddress?.pincode} ${order?.billingAddress?.pincode} ${order?.billingAddress?.phone} ${order?.billingAddress?.email}`}</span> */}
                    {order?.billingAddress?.address ==
                      order?.shippingAddress?.address ? (
                      <span>Same as shipping address</span>
                    ) : (
                      order?.billingAddress?.name && (
                        <span>
                          {`${order?.billingAddress?.name}`} <br />
                          {`${order?.billingAddress?.address}`} <br />
                          {`${order?.billingAddress?.city}`} <br />
                          {`${order?.billingAddress?.state}`},{" "}
                          {`${order?.billingAddress?.pincode}`} <br />
                          {`${order?.billingAddress?.phone}`} <br />
                          {`${order?.billingAddress?.email}`}
                        </span>
                      )
                    )}
                  </div>
                  <div className={"${styles.admin_customer_order_details}"}>
                    <div className={styles.admin_title_with_link}>
                      <span>Orders till Now</span>
                      <div
                        style={{ cursor: "no-drop" }}
                        className={styles.admin_link}
                      >
                        {order?.totalOrders} items
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Transactions</h4>
              <div
                className={`${styles.management_details_form} ${styles.admin_customer}`}
              >
                <div
                  className={`${styles.admin_customer_details} ${styles.admin_customer_transactions}`}
                >
                  {order?.paymentName && (
                    <>
                      <h3 className={styles.secondry_title}>Payment Method</h3>
                      <span className="mb-3">{order?.paymentName}</span>
                    </>
                  )}
                  {order?.paymentStatus && (
                    <>
                      <h3 className={styles.secondry_title}>Payment Status</h3>
                      <span className="mb-3">
                        {order?.paymentStatus === 1 ? "Success" : "Failed"}
                      </span>
                    </>
                  )}
                  {order?.payment?.mode && order?.paymentStatus === 1 && (
                    <>
                      <h3 className={styles.secondry_title}>Payment Type</h3>
                      <span className="mb-3">{order?.payment?.mode}</span>
                    </>
                  )}
                  {order?.payment?.transactionId && (
                    <>
                      <h3 className={styles.secondry_title}>Transaction ID</h3>
                      <span className="mb-3">
                        {order?.payment?.transactionId}
                      </span>
                    </>
                  )}
                  {order?.payment?.amount && (
                    <>
                      <h3 className={styles.secondry_title}>
                        Transaction Amount
                      </h3>
                      <span className="mb-3">
                        Rs.
                        {order?.payment?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </>
                  )}
                  {/* {order?.payment?.status && (
                    <>
                      <h3 className={styles.secondry_title}>Status</h3>
                      <span className="mb-3">{order?.payment?.status}</span>
                    </>
                  )} */}
                  <h3 className={styles.secondry_title}>Created At</h3>
                  {/* <span>May 27, 2021, 6:46:25 PM</span> */}
                  <span className="mb-3">
                    {moment(order?.createdAt).format("MMM Do YYYY, h:mm:ss A")}
                  </span>
                  {order?.invoiceEnablePermision && (
                    <>
                      <h3 className={styles.secondry_title}>Invoice</h3>
                      <button
                        onClick={downloadInvoice}
                        className={`${styles.invoice_btn}`}
                      >
                        Download Invoice
                      </button>
                    </>
                  )}
                  {order?.ewayNo && order?.ewayStatus && (
                    <>
                      <h3 className={styles.secondry_title}>E-way</h3>
                      <button
                        onClick={downloadEway}
                        className={`${styles.invoice_btn}`}
                      >
                        Download E-way
                      </button>
                    </>
                  )}
                </div>
              </div>
              <br />
              {/* <div
                className={`${styles.management_details_form} ${styles.admin_customer} mb-5`}
              >
                <div
                  className={`${styles.admin_customer_details}`}
                >
                  <h3 className={styles.secondry_title}>Notes</h3>
                  {order?.comments?.length > 0 ? (
                    order.comments.map((item, indx) => (
                      <span key={indx}>{item.comments}</span>
                    ))
                  ) : (
                    <span>No notes from customer</span>
                  )}
                </div>
              </div> */}
            </div>

            {/* Order delete confirmation modal  */}
            <ConfirmationBox
              modal={{
                show: ChoosenPrdId? true:false,
                size: "md",
                centered: true,
              }}
              handleCancel={cancelDelete}
              handleConfirm={updateOrder}
              title="Confirm the action"
              confirmText="Ok"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-7">
                    <span className="theme-txt-subtitle">
                    Are you sure want to continue?
                    </span>
                  </div>
                </div>
              </div>
            </ConfirmationBox>
            {/* Order delete confirmation modal ends*/}

            {/* shippingAddress edit modal  */}
            <ModalComponent
              data={{
                show: isEditAddress,
                size: "md",
                centered: true,
              }}
              handleCancel={hideAddressPopup}
              // handleConfirm={removeOrder}
              title="Delete product"
              confirmText="Delete"
            >
              <div className={`modal-header ${styles.modal_header} `}>
                <h5
                  className={`modal-title ${styles.modal_title}`}
                  id="exampleModalLabel"
                >
                  Shipping Address- Edit{" "}
                </h5>
                <img
                  src={CloseIconSmall}
                  className={`${styles.close_icon} cursor-pointer`}
                  alt="CloseIcon"
                  title="Remove"
                  onClick={hideAddressPopup}
                />
              </div>
              <div>
                <label className={styles.Shipping_label}>
                  Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.name}
                  formControl={{
                    type: "text",
                    placeholder: "e.g Ankit kumar",
                    name: "name",
                    value: editAddressForm?.name,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.name} />
                <label className={styles.Shipping_label}>
                  Email
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.email}
                  formControl={{
                    type: "text",
                    placeholder: "example@gmail.com",
                    name: "email",
                    value: editAddressForm?.email,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.email} />
                <label className={styles.Shipping_label}>
                  Mobile
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.phone}
                  formControl={{
                    type: "number",
                    placeholder: "98******33",
                    name: "phone",
                    value: editAddressForm?.phone,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.phone} />
                <label className={styles.Shipping_label}>
                  Pincode
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.pincode}
                  formControl={{
                    type: "number",
                    placeholder: "560078",
                    name: "pincode",
                    value: editAddressForm?.pincode,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.pincode} />
                <label className={styles.Shipping_label}>
                  Address
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.address}
                  formControl={{
                    type: "text",
                    placeholder: "",
                    name: "address",
                    value: editAddressForm?.address,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.address} />
                <label className={styles.Shipping_label}>
                  State
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                {/* <select
                className={`form_control-lg ${styles.shipping_state}`}
                name="regionId"
                // value={regionId}
                // onChange={filterOnChange}
              >
                <option value="">All Region</option>
                {Array.isArray(regions)
                  ? regions.map((region) => (
                    <option key={region._id} value={region._id}>
                      {region.name}
                    </option>
                  ))
                  : null}
              </select> */}
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  // error={refundErr.amount}
                  formControl={{
                    type: "text",
                    placeholder: "",
                    name: "state",
                    disabled: true,
                    value: editAddressForm?.state,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.state} />
                <label className={styles.Shipping_label}>
                  City
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control-lg ${styles.shipping_input}`}
                  error={addError.city}
                  formControl={{
                    type: "text",
                    placeholder: "",
                    name: "city",
                    value: editAddressForm?.city,
                    onChange: addressHandleInput,
                  }}
                />
                <FormErrorText error={addError.city} />
                <div style={{ marginTop: "10px" }} className="text-right">
                  <button
                    className={`btn ${styles.btn_filled_close}`}
                    onClick={hideAddressPopup}
                  >
                    Close
                  </button>
                  <button
                    className={`btn ${styles.btn_filled}`}
                    onClick={submitshippingAddress}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </ModalComponent>
            {/* shippingAddress edit modal ends*/}
          </div>
        </div>
      </div>
    </div>
  );
}
