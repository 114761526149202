import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useState, useEffect } from "react";
import { notification } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";

const NotificationManagement = ({ id, notifications }) => {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  let [notificationList, setNotificationList] = useState(notifications);

  let fieldsList = {
    notification: [
      {
        title: "All Notification",
        note: "notification_note",
        email: "notification_email",
        sms: "notification_sms",
      },
    ],
    user: [
      {
        title: "Add/Delete User",
        note: "a_d_user_note",
        email: "a_d_user_email",
        sms: "a_d_user_sms",
      },
      {
        title: "Add/Delete Role",
        note: "a_d_role_note",
        email: "a_d_role_email",
        sms: "a_d_role_sms",
      },
      {
        title: "Add/Delete Region",
        note: "a_d_region_note",
        email: "a_d_region_email",
        sms: "a_d_region_sms",
      },
      {
        title: "Add/Delete Phone",
        note: "a_d_phone_note",
        email: "a_d_phone_email",
        sms: "a_d_phone_sms",
      },
      {
        title: "Add/Delete Email",
        note: "a_d_email_note",
        email: "a_d_email_email",
        sms: "a_d_email_sms",
      },
    ],
    product: [
      {
        title: "Add/Delete Product",
        note: "a_d_product_note",
        email: "a_d_product_email",
        sms: "a_d_product_sms",
      },
      {
        title: "Add/Delete Category",
        note: "a_d_category_note",
        email: "a_d_category_email",
        sms: "a_d_category_sms",
      },
      {
        title: "Add/Delete Model",
        note: "a_d_model_note",
        email: "a_d_model_email",
        sms: "a_d_model_sms",
      },
      {
        title: "Add/Delete SKU",
        note: "a_d_sku_note",
        email: "a_d_sku_email",
        sms: "a_d_sku_sms",
      },
    ],
  };

  const [fields, setFields] = useState(fieldsList);

  const [disp, setDisp] = useState(true);

  const notificationHandler = (e) => {
    let { value } = e.target;

    let data = notificationList;

    if (data.includes(value)) {
      data = data.filter((e) => {
        if (e != value) {
          return e;
        }
      });
    } else {
      data.push(value);
    }

    // return data

    setNotificationList(data);
  };

  const notificationNoteAll = (e) => {
    setDisp(false);
    const { checked } = e.target;
    let data = notificationList;

    if (checked) {
      fields.notification.map((field) => {
        if (!notificationList.includes(field.note)) {
          data.push(field.note);
        }
      });
      fields.user.map((field) => {
        if (!notificationList.includes(field.note)) {
          data.push(field.note);
        }
      });
      fields.product.map((field) => {
        if (!notificationList.includes(field.note)) {
          data.push(field.note);
        }
      });
    } else {
      fields.notification.map((field) => {
        if (notificationList.includes(field.note)) {
          data = data.filter((e) => {
            if (e != field.note) {
              return e;
            }
          });
        }
      });
      fields.user.map((field) => {
        if (notificationList.includes(field.note)) {
          data = data.filter((e) => {
            if (e != field.note) {
              return e;
            }
          });
        }
      });
      fields.product.map((field) => {
        if (notificationList.includes(field.note)) {
          data = data.filter((e) => {
            if (e != field.note) {
              return e;
            }
          });
        }
      });
    }

    // console.log(data);

    setNotificationList(data);
    setTimeout(() => {
      setDisp(true);
    }, 500);
  };

  const notificationEmailAll = (e) => {
    setDisp(false);
    const { checked } = e.target;
    let data = notificationList;

    if (checked) {
      fields.notification.map((field) => {
        if (!notificationList.includes(field.email)) {
          data.push(field.email);
        }
      });
      fields.user.map((field) => {
        if (!notificationList.includes(field.email)) {
          data.push(field.email);
        }
      });
      fields.product.map((field) => {
        if (!notificationList.includes(field.email)) {
          data.push(field.email);
        }
      });
    } else {
      fields.notification.map((field) => {
        if (notificationList.includes(field.email)) {
          data = data.filter((e) => {
            if (e != field.email) {
              return e;
            }
          });
        }
      });
      fields.user.map((field) => {
        if (notificationList.includes(field.email)) {
          data = data.filter((e) => {
            if (e != field.email) {
              return e;
            }
          });
        }
      });
      fields.product.map((field) => {
        if (notificationList.includes(field.email)) {
          data = data.filter((e) => {
            if (e != field.email) {
              return e;
            }
          });
        }
      });
    }

    // console.log(data);

    setNotificationList(data);
    setTimeout(() => {
      setDisp(true);
    }, 500);
  };

  const notificationSmsAll = (e) => {
    setDisp(false);
    const { checked } = e.target;
    let data = notificationList;

    if (checked) {
      fields.notification.map((field) => {
        if (!notificationList.includes(field.sms)) {
          data.push(field.sms);
        }
      });
      fields.user.map((field) => {
        if (!notificationList.includes(field.sms)) {
          data.push(field.sms);
        }
      });
      fields.product.map((field) => {
        if (!notificationList.includes(field.sms)) {
          data.push(field.sms);
        }
      });
    } else {
      fields.notification.map((field) => {
        if (notificationList.includes(field.sms)) {
          data = data.filter((e) => {
            if (e != field.sms) {
              return e;
            }
          });
        }
      });
      fields.user.map((field) => {
        if (notificationList.includes(field.sms)) {
          data = data.filter((e) => {
            if (e != field.sms) {
              return e;
            }
          });
        }
      });
      fields.product.map((field) => {
        if (notificationList.includes(field.sms)) {
          data = data.filter((e) => {
            if (e != field.sms) {
              return e;
            }
          });
        }
      });
    }
    // console.log(data);

    setNotificationList(data);
    setTimeout(() => {
      setDisp(true);
    }, 500);
  };

  const notificationSubmit = () => {
    let data = {
      _id: id,
      notification: notificationList,
    };

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post(`/admin/notification`, data)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("ok");
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
        } else if (res.data.status === 400) {
          showNotification({
            type: "Error",
            message: "Error",
            description: res.data.msg,
          });
          return;
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Some thing went wrong",
          });
          return;
        }
      })
      .catch((er) => {
        return er;
      });
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    permissionsArr.length > 0 &&
    permissionsArr.find((x) => x === "SiteSettings-General-edit") ===
      "SiteSettings-General-edit" && (
      <>
        <div className="row">
          <div className="col-md-8">
            <div className={commonstyles.profile_display}>
              <div
                className={`${commonstyles.notificationTable} ${commonstyles.profileNotificationTable} `}
              >
                <table>
                  <tr>
                    <th>
                      <div className={commonstyles.profile_header}>
                        <h4>
                          <b>Notification</b>
                        </h4>
                      </div>
                    </th>
                    <th>Notification</th>
                    <th>Email</th>
                    <th>SMS</th>
                  </tr>
                  {fields?.notification.map((list, index) => {
                    return (
                      <tr>
                        <td>{list.title}</td>
                        <td>
                          {/* <input type="checkbox" /> */}
                          <div
                            className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                          >
                            <input
                              type="checkbox"
                              value={list.note}
                              className={
                                commonstyles.onoffswitch_profile_checkbox
                              }
                              id={list.note}
                              onChange={(e) => notificationNoteAll(e)}
                              checked={
                                notificationList.includes(list.note)
                                  ? true
                                  : null
                              }
                            />
                            <label
                              className={commonstyles.onoffswitch_profile_label}
                              htmlFor={list.note}
                            >
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_inner
                                }
                              ></span>
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_switch
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          {/* <input type="checkbox" /> */}
                          <div
                            className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                          >
                            <input
                              type="checkbox"
                              className={
                                commonstyles.onoffswitch_profile_checkbox
                              }
                              id={list.email}
                              value={list.email}
                              onChange={(e) => notificationEmailAll(e)}
                              checked={
                                notificationList.includes(list.email)
                                  ? true
                                  : null
                              }
                            />
                            <label
                              className={commonstyles.onoffswitch_profile_label}
                              htmlFor={list.email}
                            >
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_inner
                                }
                              ></span>
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_switch
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          {/* <input type="checkbox" /> */}
                          <div
                            className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                          >
                            <input
                              type="checkbox"
                              className={
                                commonstyles.onoffswitch_profile_checkbox
                              }
                              id={list.sms}
                              value={list.sms}
                              onChange={(e) => notificationSmsAll(e)}
                              checked={
                                notificationList.includes(list.sms)
                                  ? true
                                  : null
                              }
                            />
                            <label
                              className={commonstyles.onoffswitch_profile_label}
                              htmlFor={list.sms}
                            >
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_inner
                                }
                              ></span>
                              <span
                                className={
                                  commonstyles.onoffswitch_profile_switch
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <br />

                  {disp ? (
                    <>
                      <tr>
                        <td className={commonstyles.tableSubHead}>
                          User details
                        </td>
                        <td></td>
                        <td></td>
                      </tr>

                      {fields?.user?.map((list) => {
                        return (
                          <tr>
                            <td>{list.title}</td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  value={list.note}
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.note}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.note)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.note}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.email}
                                  value={list.email}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.email)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.email}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.sms}
                                  value={list.sms}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.sms)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.sms}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <br />

                      <tr>
                        <td className={commonstyles.tableSubHead}>Products</td>
                        <td></td>
                        <td></td>
                      </tr>

                      {fields?.product?.map((list) => {
                        return (
                          <tr>
                            <td>{list.title}</td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  value={list.note}
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.note}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.note)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.note}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.email}
                                  value={list.email}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.email)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.email}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <div
                                className={`${commonstyles.onoffswitch_profile} ${commonstyles.profile_toggle}`}
                              >
                                <input
                                  type="checkbox"
                                  className={
                                    commonstyles.onoffswitch_profile_checkbox
                                  }
                                  id={list.sms}
                                  value={list.sms}
                                  onClick={(e) => notificationHandler(e)}
                                  checked={
                                    notificationList.includes(list.sms)
                                      ? true
                                      : null
                                  }
                                />
                                <label
                                  className={
                                    commonstyles.onoffswitch_profile_label
                                  }
                                  htmlFor={list.sms}
                                >
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_inner
                                    }
                                  ></span>
                                  <span
                                    className={
                                      commonstyles.onoffswitch_profile_switch
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr className="bg-transparent">
                      <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching details" />
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-right">
            <ButtonComponent
              className=""
              attributes={{
                onClick: notificationSubmit,
              }}
            >
              Save
            </ButtonComponent>
          </div>
        </div>
      </>
    )
  );
};

export default NotificationManagement;
