import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import React, { useState, useRef, useEffect } from "react";
import { notification, Tooltip } from "antd";
import styles from "../../../styles/PaymentNew.module.css";
import SearchComponent from "../SearchManagement/SearchComponent";
import moment from "moment";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import * as XLSX from "xlsx";
import { clone, filter, upperCase, upperFirst } from "lodash";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

export default function AnalyticsList(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const pdfExportComponent = useRef(null);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [currentTab, setCurrentTab] = useState("0");

  const [keywordsOrder, setSortKeywords] = useState();
  const [resultsOrder, setSortResults] = useState();
  const [clicksOrder,setSortClicks] = useState();

  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Keywords",
      visible: true,
      key: "Keywords",
      sorter: true,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.searchKey ? record.searchKey : "-"}</span>
      ),
    },
    {
      title: "Result",
      visible: true,
      key: "Result",
      sorter:true,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.result ? record.result : "-"}</span>
      ),
    },
    {
      title: "Clicks",
      visible: true,
      sorter:true,
      key: "Clicks",
      render: (text, record, index) =>         <span style={{ textTransform: "capitalize" }}>{record.clicks ? record.clicks : "-"}</span>
    }
  ]);

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const history = useHistory();

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, searchNow}
   * To get analytics records according to tab change
   */
  useEffect(() => {
    const { page } = history.location;
    page === "dashboard" && setCurrentTab("1");
    !page === "dashboard" && setCurrentTab("0");
    let searchNow = "";
    getAnalyticsList(pageSize, nextPage, searchNow);
  }, []);

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, searchNow}
   * To get analytics records according to tab change
   */
  useEffect(() => {
    let searchNow = "";
    getAnalyticsList(pageSize, nextPage, searchNow);
  }, [currentTab]);

  /**
   * @author Mithun Prabhu
   * @param {e.target.value}
   * To get analytics records according to search value change
   */
  function handleSeach(e) {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
      };
      getAnalyticsList(pageSize, 1, updatedFilter);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, searchNow}
   * To call product analytics API to set records in state
   */
  function getAnalyticsList(pageSize, nextPage, filterOptions,type) {
    if (!type) {
      filterOptions = {
        pagesize: pageSize,
        nextpage: nextPage,
        search: search,
        type: currentTab == "0" ? 0 : 1,
      };
      setIsLoading({delete:false,list:true});
    }

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/getProductAnalytics", filterOptions)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading(false);

          setIsLoading({ delete: false, list: false });
          setAnalyticsList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

  /**
   * @author Mithun Prabhu
   * @param {value, type}
   * To handle pagination changes and get analytics record
   */
  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      type: currentTab == "0" ? 0 : 1,
      search:search,
      keywordsOrder:keywordsOrder,
      resultsOrder:resultsOrder,
      clicksOrder:clicksOrder
    }
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      updatedFilter.nextpage = 1;
      updatedFilter.pagesize = value;
      getAnalyticsList(value, 1,updatedFilter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      updatedFilter.pagesize = pageSize;
      updatedFilter.nextpage = value;
      getAnalyticsList(pageSize, value,updatedFilter,true);
    }


  }

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, searchNow}
   * To change tab and setting state accordingly
   */
  function handleChange(e) {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "top") {
        setCurrentTab("0");
        setSearch("");
      } else {
        setCurrentTab("1");
        setSearch("");
      }
      setPageSize(10);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {No parameter}
   * To get exported page in pdf format and save it using useRef
   */
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    // let element = container.current || document.body;
    // savePDF(element, {
    //   paperSize: "auto",
    //   margin: 40,
    //   fileName: `Report for ${new Date().getFullYear()}`,
    // });
  };

  /**
   * @author Mithun Prabhu
   * @param {event.target.value}
   * To get exported page in CSV format by calling exportSearchAnalysis API
   */
  const exportToCSV = async (e) => {
    const filterOptions = {};
    if (search) {
      filterOptions.search = search;
    }
    currentTab == "0" ? (filterOptions.type = 0) : (filterOptions.type = 1);
    // if (regionId) {
    //   filterOptions.regionId = regionId;
    // }
    // if (rating) {
    //   filterOptions.rating = parseFloat(rating);
    // }
    // if (filteredDate?.startDate) {
    //   filterOptions.startDate = filteredDate.startDate;
    // }
    // if (filteredDate?.endDate) {
    //   filterOptions.endDate = filteredDate.endDate;
    // }
    // if (sortorder || sortorder === 0) {
    //   filterOptions.sortOrder = sortorder;
    // }
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/exportSearchAnalysis", filterOptions)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data.map((item) => {
            item.Keywords = capitalize(item.Keywords);
          });
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `analytics-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  /**
   * @author Mithun Prabhu
   * @param {string}
   * To capitalize first letter of string
   */
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  function handleTableChange(pagination, filters, sorter, extra) {
  
    let updatedFilter = {
      pagesize: pageSize,
      nextpage: nextPage,
      type: currentTab == "0" ? 0 : 1,
    };
    if (sorter?.columnKey === "Keywords") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.keywordsOrder = sortValue;
      setSortKeywords(sortValue);
      setSortClicks();
      setSortResults();
    }
    else if (sorter?.columnKey === "Result") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.resultsOrder = sortValue;
      setSortResults(sortValue);
      setSortKeywords();
      setSortClicks();
    } else if (sorter?.columnKey === "Clicks") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.clicksOrder = sortValue;
      setSortClicks(sortValue);
      setSortResults();
      setSortKeywords();
    }
    getAnalyticsList(pageSize, 1, updatedFilter, true);
  }

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, search}
   * To trigger search and to get analytics records.No debounce effect involved here
   */
  function searchClick() {
    getAnalyticsList(pageSize, nextPage, search);
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Analytics</h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Analytics-Analytics-view") ===
            "Analytics-Analytics-view" && (
            <div>
              <div
                className={`custom-control custom-radio custom-control-inline ${styles.custom_radio} mr-4`}
              >
                <div>
                  <span
                    className={commonstyles.management_header_downloads_text}
                  >
                    <Tooltip placement="topRight" title="Download pdf">
                      <img
                        className={commonstyles.export_icon}
                        src={pdf_icon}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => exportToPDF(e)}
                      />
                    </Tooltip>
                    <Tooltip placement="topRight" title="Download excel">
                      <img
                        src={excelIcon}
                        className={
                          commonstyles.management_header_downloads_icon
                        }
                        alt="icon"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => exportToCSV(e)}
                      />
                    </Tooltip>
                  </span>
                  <span
                    className={commonstyles.management_header_downloads_text_ex}
                  >
                    Export <img src={exportIcon} alt="icon" />
                  </span>
                </div>
              </div>
            </div>
          )}
      </div>
      {currentTab == "0" &&
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Analytics-Analytics-view") ===
          "Analytics-Analytics-view" && (
          <React.Fragment>
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={40}
              fileName={`Analytics Report for ${new Date().getFullYear()}`}
              author="Damro Team"
            >
              <div className="d-flex">
                <h4 className="mr-4">Top searched keywords</h4>
                <div
                  className={`custom-control custom-radio custom-control-inline ${styles.custom_radio} mr-4 mt-1`}
                >
                  <input
                    type="radio"
                    id="rd_1"
                    name="top"
                    className="custom-control-input"
                    checked={currentTab == "0"}
                    onChange={(e) => handleChange(e)}
                  />

                  <label className="custom-control-label" for="rd_1">
                    <span>Top </span>
                  </label>
                </div>
                <div
                  className={`custom-control custom-radio custom-control-inline ${styles.custom_radio} mr-4 mt-1`}
                >
                  <input
                    type="radio"
                    id="rd_2"
                    name="latest"
                    className="custom-control-input"
                    checked={currentTab == "1"}
                    onChange={(e) => handleChange(e)}
                    //   onChange={setShowAvenue(false)}
                  />
                  <label className="custom-control-label" for="rd_2">
                    <span>Latest</span>
                  </label>
                </div>
              </div>
              <div
                className={`${commonstyles.management_contents_search}`}
                style={{ marginTop: "30px" }}
              >
                <div
                  className={`${commonstyles.management_contents_search_bar} max-100 input-group`}
                >
                  <input
                    type="search"
                    className={`${commonstyles.form_control} form-control`}
                    aria-label="Search"
                    placeholder="Search by keywords...."
                    aria-describedby="search-addon"
                    value={search}
                    onChange={(e) => handleSeach(e)}
                  />
                  <img src={Search} alt="Search icon" />
                </div>
                <ButtonComponent
                  className="theme-btn rnd pl-3 pr-4 ml-2"
                  attributes={{
                    onClick: () => {
                      searchClick();
                    },
                  }}
                >
                  Search
                </ButtonComponent>
              </div>
              <div
                className={`${commonstyles.management_table} table-responsive`}
              >
                <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: analyticsList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
              </div>
            </PDFExport>
          </React.Fragment>
        )}

      {/* <div className={commonstyles.management_contents_search}>
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by Question...."
            aria-describedby="search-addon"
            value={search}
            onChange={(e) => handleSeach(e)}
          />
          <img src={Search} alt="Search icon" />
        </div>
      </div> */}

      {currentTab == "1" &&
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Analytics-Analytics-view") ===
          "Analytics-Analytics-view" && (
          <React.Fragment>
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={40}
              fileName={`Analytics Report for ${new Date().getFullYear()}`}
              author="Damro Team"
            >
              <div className="d-flex">
                <h4 className="mr-4">Latest searched keywords</h4>
                <div
                  className={`custom-control custom-radio custom-control-inline ${styles.custom_radio} mr-4 mt-1`}
                >
                  <input
                    type="radio"
                    id="rd_1"
                    name="top"
                    className="custom-control-input"
                    checked={currentTab == "0"}
                    onChange={(e) => handleChange(e)}
                  />

                  <label className="custom-control-label" for="rd_1">
                    <span>Top </span>
                  </label>
                </div>
                <div
                  className={`custom-control custom-radio custom-control-inline ${styles.custom_radio} mr-4 mt-1`}
                >
                  <input
                    type="radio"
                    id="rd_2"
                    name="latest"
                    className="custom-control-input"
                    checked={currentTab == "1"}
                    onChange={(e) => handleChange(e)}
                    //   onChange={setShowAvenue(false)}
                  />
                  <label className="custom-control-label" for="rd_2">
                    <span>Latest</span>
                  </label>
                </div>
              </div>
              <div
                className={commonstyles.management_contents_search}
                style={{ marginTop: "30px" }}
              >
                <div
                  className={`${commonstyles.management_contents_search_bar} input-group max-100`}
                >
                  <input
                    type="search"
                    className={`${commonstyles.form_control} form-control`}
                    aria-label="Search"
                    placeholder="Search by keywords...."
                    aria-describedby="search-addon"
                    value={search}
                    onChange={(e) => handleSeach(e)}
                  />
                  <img src={Search} alt="Search icon" />
                </div>
                <ButtonComponent
                  className="theme-btn rnd pl-3 pr-4 ml-2"
                  attributes={{
                    onClick: () => {
                      searchClick();
                    },
                  }}
                >
                  Search
                </ButtonComponent>
              </div>
              <div
                className={`${commonstyles.management_table} table-responsive`}
              >
               <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: analyticsList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
              </div>
            </PDFExport>
          </React.Fragment>
        )}
      {!IsLoading.list && Array.isArray(analyticsList) && analyticsList.length
        ? permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Analytics-Analytics-view") ===
            "Analytics-Analytics-view" && (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          )
        : null}
    </div>
  );
}
