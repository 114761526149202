// import DropdownTreeSelect from "react-dropdown-tree-select";
import { useEffect, useState } from "react";
import { TreeSelect } from "antd";
import groupBy from "lodash/groupBy";
import { AxiosInstance,  PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";

const { SHOW_CHILD   } = TreeSelect;


export default function ProductCategoryTreeSelectComponent({
  value,
  handleChange,
  category,
}) {
  const [treeData, setTreeData] = useState([]);
  const [responseData,setResponseData]= useState([]);
// console.log(value)
  /* function getCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName/")
      .then((res) => {
        console.log(res.data)
        if (res && res.data && res.status == "200") {
          // console.log(res.data.daaata);
          // const data = transformData(res.data.daaata);
          //setTreeData(data);
          setResponseData(res.data.daaata);
          //   setCategoryData(res.data.data);
          //   setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        console.log("Failed to get data on Category tree select");
        console.log(er);
      });
  } */

  /* useEffect(() => {
    getCategories();
  }, []); */

  /* function handleTreeSelectChanges(){

  } */

  useEffect(()=>{
    const data = transformData(category);
          setTreeData(data);
  },[category,value])

  function transformData(data) {
    //sort
    if(data.length > 0) {
      data.sort((category1, category2) => {
        const level1 = category1.level,
          level2 = category2.level;

        if (level1 > level2) return 1;
        else if (level2 > level1) return -1;
        else {
          const title1 = category1.name[0];
          const title2 = category2.name[0];

          return title1 > title2 ? 1 : title2 > title1 ? -1 : 0;
        }
      });

      const transformedData = data.map((cat) => buildTreeData(cat));
      let level2Data = transformedData.filter((cat) => cat.level === 2);
      level2Data = groupBy(level2Data, "parentId");
      const level2ParentIds = Object.keys(level2Data);

      let level1Data = transformedData.filter((cat) => cat.level === 1);
      level1Data.forEach((cat) => {
        const ind = level2ParentIds.indexOf(cat.id);
        if (ind > -1) {
          cat.children = level2Data[level2ParentIds[ind]];
        }
      });
      level1Data = groupBy(level1Data, "parentId");
      const level1ParentIds = Object.keys(level1Data);

      let level0Data = transformedData.filter((cat) => cat.level === 0);
      level0Data.forEach((cat) => {
        const ind = level1ParentIds.indexOf(cat.id);
        if (ind > -1) {
          cat.children = level1Data[level1ParentIds[ind]];
        }
      });

      return level0Data;
    }
  }

  function buildTreeData(category) {
    return {
      id: category._id,
      value: category._id,
      title: category.name,
      level: category.level,
      parentId: category.parentId,
      isLeaf: category.level === 2,
      children: category.level < 2 ? [] : undefined,
    };
  }

  return (
    <TreeSelect
      treeData={treeData}
      treeCheckable
      treeCheckStrictly
      showCheckedStrategy={SHOW_CHILD}
      placeholder="Select Categories"
      className={`${commonstyles.form_control} w-100 p-1`}
      value={value}
      bordered={false}
      onChange={handleChange}
      //   loadData={onLoadData}
    />
  );
  //   </TreeSelect>;
  //   return <DropdownTreeSelect data={categories} searchModeOn={false} />;
}
