import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/EditUser.module.css";
import Search from "../../../assets/images/search_icon.svg";
import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Space, Spin, Select } from "antd";
import { formatServerValidtionErrors } from "../../../utils";
import Plus from "../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import styless from "../../../styles/AddProduct.module.css";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import cameraIcon from "../../../assets/images/camera_icon.svg";
import customerProfile from "../../../assets/images/customer_profile.svg";
import validationRules from "../../../utils/validationRules";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import {
  emailRegx,
  minChar,
  passwordReg,
  phoneRegx,
  Saleswithletters,
  Catalogwithletters,
  Discountwithletters,
  Customerwithletters,
} from "../../../utils/RegularExp";
import { runValidationChecks } from "../../../utils";
// import { handlePermission } from "./HandlePermission";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import PermissionTable from "../UserManagement/PermissionTable";
import { AllModules_, buttons, treeData_ } from "../../modules/ReusableComponent/PermissionObject";

const { Option } = Select;
const rules = {
  name: [(val, data) => (!val ? "Name " : true)],
  email: [(val, data) => (!val ? "Email " : true)],
  phone: [(val, data) => (!val ? "Phone " : true)],
  password: [(val, data) => (!val ? "Password " : true)],
  role: [(val, data) => (!val ? "Role " : true)],
};

function AddUser() {
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [Addphone, setAddphone] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [permission, setPermission] = useState([]);
  // const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [isEnabled, setIsenabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [btns, setBtns] = useState(buttons);
  const [tableList, setTableList] = useState(AllModules_[0].data);
  const [catalogShow, setCatalogShow] = useState(false);
  const [salesShow, setSalesShow] = useState(false);
  const [discountShow, setDiscountShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [filePreview, setFilePreview] = useState({
    image: null,
  });
  const [files, setFiles] = useState({
    image: null,
  });
  const [choosenBtn, setChoosenBtn] = useState([
    {
      "id": 1, name: "Dashboard", value: "Dashboard", active: true
    }
  ]);
  const [AllModules, setAllModules] = useState(AllModules_);

  const history = useHistory();

  const onPasswordVisible = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {

            setRegions(res.data.daaata);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.msg);

            setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
    getRoleList();
    buttons.forEach(item => {
      if (item.value === "Dashboard") {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setBtns(buttons);
  }, []);


  /**
   * @author Rajkumar
   * @param {btn}
   * Initial load need to get role list and check particular modules.
   */
  function getRoleList() {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get("admin/role/listRole")
      .then((res) => {
        if (res.data.status === 200) {
          setRoles(res.data.data);
          const cloneAllModules = [...AllModules]
          res?.data?.data?.permission?.forEach(permission => {
            let split = permission.split('-');
            cloneAllModules.forEach(item => {
              if (item.value === split[0]) {
                item.data.forEach(subItem => {
                  if (subItem.value === split[1]) {
                    subItem[split[2]] = true;
                  }
                })
              }
            });
            setTableList(cloneAllModules[0].data);
            setAllModules(cloneAllModules);
          });
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.msg);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }


  /**
   * @author Rajkumar
   * @param {btn}
   * add user with all role and permissions available
   */
  function addUser() {
    if(role === ""){
      return showNotification({
        type: "error",
        message: "Error",
        description: "Please select user role",
      });
    }
    const newpermission = [...permission];
    const data = {
      name: name,
      email: email,
      phone: phone,
      password: password,
      role: role,
      permission: [...new Set(newpermission)],
      enable: isEnabled,
    };
    if (region) {
      data.region = region;
    }
    if (Addphone) {
      data.additionalPhone = Addphone;
    }

    let permissionArr = [];
    let roleType = 1;
    AllModules.forEach(item => {
      item.data.forEach(subItem => {
        if (subItem.add) {
          let permission = item.value + '-' + subItem.value + '-add'
          permissionArr.push(permission);
        }
        if (subItem.edit) {
          let permission = item.value + '-' + subItem.value + '-edit'
          permissionArr.push(permission);
        }
        if (subItem.view) {
          let permission = item.value + '-' + subItem.value + '-view'
          permissionArr.push(permission);
        }
        if (!subItem.add || !subItem.edit || !subItem.view) {
          roleType = 0;
        }
      })
    });

    if (permissionArr?.length > 0) {
      data.permission = permissionArr;
      data.roleType = roleType;
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please choose any one permission",
      });
      return;
    }


    if (password) {
      if (!passwordReg.test(password)) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description:
            "At least 1 number, upper & lower case and special character",
        });
        return;
      }
    }

    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    const formData1 = new FormData();
    formData1.append("data", JSON.stringify(data));
    if (files?.profileImg !== "" || files?.image) {
      formData1.append("profileImg", files?.image);
    }

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("admin/user/register", formData1)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "User details added successfully",
          });
          history.push(`/site-settings/user`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: res.data.msg,
          });
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function userList() {
    history.push("/site-settings/user");
  }

  function handleFormInput(e) {
    const { name, checked } = e.target;

    setIsenabled(checked);
  }


  /**
   * @author Rajkumar
   * @param {btn}
   * remove image when we click on cancel icon by revoking objectURL
   */
  function removeFile(name) {
    if (filePreview[name]) {
      URL.revokeObjectURL(filePreview[name]);
    }
    setFiles({ ...files, [name]: null });
    setFilePreview({ ...filePreview, [name]: null });
  }


  /**
   * @author Rajkumar
   * @param {btn}
   * handle image input and update form
   */
  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        if (filePreview[name]) {
          URL.revokeObjectURL(filePreview[name]);
        }
        const url = URL.createObjectURL(inputFiles[0]);
        setFiles({ ...files, [name]: inputFiles[0] });
        setFilePreview({ ...filePreview, [name]: url });
      }
    }
  }

  /**
   * @author Rajkumar
   * @param {btn}
   * handle checkbox seperately and give permission to particular modules
   */
  function addcheckbox(e, record, type) {
    const { checked } = e.target;
    const cloneAllModules = [...AllModules]
    if (checked) {
      cloneAllModules.forEach(item => {
        item?.data?.forEach(subItem => {
          if (subItem.value === record.value) {
            subItem[type] = true;
            setTableList(item?.data);
          }
        });
      })
    } else {
      cloneAllModules.forEach(item => {
        item?.data?.forEach(subItem => {
          if (subItem.value === record.value) {
            subItem[type] = false;
            setTableList(item?.data);
          }
        });
      })
    }
    setAllModules(cloneAllModules);
  }

  /**
   * @author Rajkumar
   * @param {btn}
   * handle button according module wise permission needed
   */
  const selectedBtn = (btn) => {
    const cloneBtns = [...btns];
    setChoosenBtn(btn);
    cloneBtns.forEach(item => {
      if (btn.id === item.id) {
        item.active = true;
      } else {
        item.active = false;
      }
    })
    setBtns(cloneBtns);
    AllModules.forEach(item => {
      if (item.value === btn.name) {
        setTableList(item.data);
      }
    });
  }


  /**
  * @author Rajkumar
  * @param {Event}
  * handle role and i.e check and uncheck user permissions based on role which is provided from backend
  */
  const handleRoleChange = (e) => {
    const { value } = e.target;
    const cloneAllModules = [...AllModules]
    setRole(value);
    let data = [];
    roles?.forEach(x => {
      if (x._id === value) {
        data = x.permission
      }
    })
    if (data?.length > 0) {
      data?.forEach(permission => {
        let split = permission.split('-');
        cloneAllModules.forEach(item => {
          if (item.value === split[0]) {
            item.data.forEach(subItem => {
              if (subItem.value === split[1]) {
                subItem[split[2]] = true;
              }
            })
          }
        });
        // setTableList(cloneAllModules[0].data);
      });
    } else {
      cloneAllModules.forEach(item => {
        item.data.forEach(subItem => {
          subItem.add = false;
          subItem.view = false;
          subItem.edit = false;
        })
      });
      // setTableList(cloneAllModules[0].data);
    }
    setAllModules(cloneAllModules);
  }

  const SelectAllCheckBox = (e) => {
    const { checked } = e.target;
    const cloneAllModules = [...AllModules];

    if (checked == true) {
      setAllChecked(true)
      cloneAllModules.forEach((data) => data.data.forEach((item) => {
        item.add = true
        item.edit = true
        item.view = true
      }
      ));


      // console.log(newTableList);
    } else if (checked == false) {
      setAllChecked(false)
      cloneAllModules.forEach((data) => data.data.forEach((item) => {
        item.add = false
        item.edit = false
        item.view = false
      }
      ));
      // console.log(newTableList);
    }

    // setNewTableList(cloneAllModules)
  }


  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            onClick={userList}
            style={{ cursor: "pointer" }}
          />
          Add User
        </h1>
        <div className={styless.management_contents_upload}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleFormInput(e)}
              checked={isEnabled}
              id="statusSwitch"
            />

            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings/user`),
            }}
          >
            Cancel
          </ButtonComponent>
          <button
            className={`theme-btn rnd  d-inline-block`}
            onClick={addUser}
            style={{ cursor: "pointer" }}
          >
            Save
          </button>
        </div>
      </div>

      <div className={styles.user_admin}>
        <div className={"media " + styles.user_admin_detail}>
          <div className="media-body">
            <div className="row">
              <div className="col-lg-2">
                <div className={styles.add_user_img}>
                  {filePreview.image ? (
                    <ImagePreviewChip
                      url={filePreview.image}
                      handleClose={() => removeFile("image")}
                    />
                  ) : (
                    <div className={styles.upload_img + " text-center "}>
                      <img src={customerProfile} className={styles.profile_img} alt="profile_img" />
                      <div>
                        <label
                          htmlFor="imageInput"
                          className={`px-2 cursor-pointer`}
                        >
                          <img src={cameraIcon} className={styles.camera_img} alt='camera' />
                        </label>
                        <input
                          name="image"
                          className="d-none"
                          type="file"
                          accept="image/jpeg, image/png, image/svg+xml"
                          id="imageInput"
                          onInput={handleImageInput}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-10 form-row">
                <div className="form-group col-md-6">
                  <label className={styles.label_title}>Name</label>
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>

                  <InputComponent
                    error={formErrors.name}
                    className={` ${styles.form_control} form-control`}
                    formControl={{
                      type: "text",
                      onChange: (e) => {
                        if (!minChar.test(e.target.value)) {
                          if (!formErrors.name) {
                            formErrors.name = {};
                          }
                          formErrors.name = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.name) {
                            formErrors.name = "";
                          }
                        }
                        setName(e.target.value);
                      },
                      placeholder: "Enter name",
                    }}
                    aria-describedby="emailHelp"
                  />
                  <FormErrorText
                    error={formErrors.name && "Enter minimum 3 characters"}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className={styles.label_title}>Email</label>
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>

                  <InputComponent
                    error={formErrors.email}
                    formControl={{
                      type: "email",
                      maxLength: "40",
                      onChange: (e) => {
                        if (!emailRegx.test(e.target.value)) {
                          if (!formErrors.email) {
                            formErrors.email = {};
                          }
                          formErrors.email = "Enter only valid email";
                        } else {
                          if (formErrors.email) {
                            formErrors.email = "";
                          }
                        }
                        setEmail(e.target.value);
                      },
                      placeholder: "Enter email",
                    }}
                    className={"form-control " + styles.form_control}
                    aria-describedby="emailHelp"
                  />
                  <FormErrorText
                    error={formErrors.email && "Enter only valid email"}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className={styles.label_title}>Password</label>
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>

                  <InputComponent
                    error={formErrors.password}
                    formControl={{
                      type: visible ? "text" : "password",
                      visible: visible,
                      onPasswordVisible: onPasswordVisible,
                      onChange: (e) => {
                        if (!passwordReg.test(e.target.value)) {
                          if (!formErrors.password) {
                            formErrors.password = {};
                          }
                          formErrors.password =
                            "At least 1 number, upper & lower case and special character and minimum 8 characters";
                        } else {
                          if (formErrors.password) {
                            formErrors.password = "";
                          }
                        }
                        setPassword(e.target.value);
                      },
                      placeholder: "Enter password",
                    }}
                    className={"form-control " + styles.form_control}
                    aria-describedby="emailHelp"
                  />
                  <span
                    id="password"
                    className="password"
                    onClick={onPasswordVisible}
                  >
                    <img
                      src={visible ? eyeClose : eyeOpen}
                      alt="visible"
                      onClick={onPasswordVisible}
                      className={styles.eyeIcon}
                    />
                  </span>
                  <FormErrorText
                    error={
                      formErrors.password &&
                      "At least 1 number, upper & lower case and special character and minimum 8 characters"
                    }
                  />
                </div>
                <div className={"form-group col-md-6"}>
                  <label className={styles.label_title}>
                    Mobile
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>{" "}
                  </label>
                  <div className={styles.adduser_input_countycode}>
                    <span>+91</span>
                    <InputComponent
                      error={formErrors.phone}
                      formControl={{
                        type: "number",
                        placeholder: "Enter phone number",

                        onChange: (e) => {
                          if (!phoneRegx.test(e.target.value)) {
                            if (!formErrors.phone) {
                              formErrors.phone = {};
                            }
                            formErrors.phone = "Enter valid phone number";
                          } else {
                            if (formErrors.phone) {
                              formErrors.phone = "";
                            }
                          }
                          setPhone(e.target.value);
                        },
                        maxLength: 10,
                      }}
                      className={"form-control " + styles.form_control}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <FormErrorText
                    error={formErrors.phone && "Enter valid phone number"}
                  />
                </div>
                <div className={"form-group col-md-6"}>
                  <label className={styles.label_title}>
                    Additional Phone Number (Optional)
                  </label>
                  <div className={styles.adduser_input_countycode}>
                    <span>+91</span>
                    <InputComponent
                      error={formErrors.Addphone}
                      formControl={{
                        type: "number",
                        placeholder: "Enter additional phone number",

                        onChange: (e) => {
                          if (!phoneRegx.test(e.target.value)) {
                            if (!formErrors.Addphone) {
                              formErrors.Addphone = {};
                            }
                            formErrors.Addphone = "Enter valid phone number";
                          } else {
                            if (formErrors.Addphone) {
                              formErrors.Addphone = "";
                            }
                          }
                          setAddphone(e.target.value);
                        },
                        maxLength: 10,
                      }}
                      className={"form-control " + styles.form_control}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <FormErrorText
                    error={formErrors.Addphone && "Enter valid phone number"}
                  />
                </div>

                {/* {role !== "super_admin" && ( */}
                <div className="col-lg-6">
                  <div>
                    <label className={styles.label_title}>
                      Warehouse
                    </label>
                    <select
                      className={"form-control " + styles.form_control}
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">All region</option>
                      {regions.map((region) => (
                        <>
                          <option value={region._id} label={region.name}>
                            {region.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.user_permission}>
          <div className="row">
            <div className={`col-md-6 ${styles.uesr_permission_heading} `}>
              <h4 className={styles.user_heading}>Role
              <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
              </h4>
              <select
                className={"form-control " + styles.form_control}
                onChange={(e) => handleRoleChange(e)}
                value={role}
                style={{ cursor: "pointer" }}
              >
                {role ==="" &&
                <option key={"select"} value={""} label={"Please select user role"}></option>}
                {roles.map((role) => (
                  <>
                    <option key={role._id} value={role._id} label={role.name}>
                      {role.name}
                    </option>
                  </>
                ))}
              </select>
            </div>
            <div className="col-md-6 text-right">
              <input className="mr-2 mt-1" type="checkbox" id="allCheckBox" onChange={SelectAllCheckBox} name="allCheckBox" checked={allChecked} />
              <label for="allCheckBox"> Select all modules</label>
            </div>
          </div>

        </div>

        {role !== "super_admin" && (
          <PermissionTable
            btns={btns}
            addcheckbox={addcheckbox}
            tableList={tableList}
            selectedBtn={selectedBtn}
          />
        )}
      </div>
    </div>
  );
}

export default AddUser;
