import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory, useParams } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import SearchComponent from "../SearchManagement/SearchComponent";
import { notification } from "antd";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import commonstyles from "../../../styles/Management_Common.module.css";
import adminEdit from "../../../assets/images/admin_product_edit_icon.svg";

export default function EditDeliveryManagement() {

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { id } = useParams();
    const [agentDetials, setAgentDetails] = useState([]);
    const [enable, setEnable] = useState(false);
    const [editable, setEditable] = useState('');

    useEffect(() => {
        checkEditoption();
        getAgentDetails();
    },[]);

      /**
   * @author RAJKUMAR
   * @param {}
   * SMALL NOTIFICATION WHETHER AGENT IS OUT FOR DELIVERY OR NOT
   */
    const checkEditoption = () => {
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .get(`/deliveryAgent/checkAgent/${id}`)
        .then((res) => {
        if (res.data.status === 200) {
            setEditable(res.data.editable);
        } else if (res.data.status === 400) {
        }
        })
        .catch((er) => {
            showNotification({
                type: "error",
                message: "Failed",
                description: "Error occured",
            });
        })
            .finally(() => {
            setIsLoading(false);
        });
    }

     /**
   * @author RAJKUMAR
   * @param {}
   * GET AGENT DETAILS
   */
    const getAgentDetails = () => {
        setIsLoading(true);
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .get(`/deliveryAgent/agentDetail/${id}`)
        .then((res) => {
          if (res.data.status === 200) {
            setAgentDetails(res.data.data);
            setEnable(res.data.data.enable);
          } else if (res.data.status === 400) {
          }
        })
        .catch((er) => {
          setIsLoading(false);
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    function showNotification({ type, message, description }) {
        let options = {
          message: message || "Message",
          description: description,
          duration: 5,
        };
        notification[type](options);
    }

      /**
   * @author RAJKUMAR
   * @param {Event,id}
   * HANDLE I.E ENABLE / DISABLE DELIVERY AGENT
   */
    const handleInput = (e,id) => {
        const { checked } = e.target;
        setAgentDetails(prevState => {
            return {...prevState , 'enable' : checked }
        })
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .get(`/deliveryAgent/${id}/${checked}`)
        .then((res) => {
          if (res.data.status === 200) {
            
            showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
    }

    const editHandle = () => {
        
        history.push({
            pathname: `/editDelivery/${id}`
        });
    }

    return (
        <div className={styles.management_contents}>
            <SearchComponent />
            <div className={styles.management_header}>
                <h1>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => history.push("/catalog/deliveryList/delivery")}
                    />
                    Delivery Person
                </h1>
                
            </div>
            {isLoading === true ? (
                <tr className="bg-transparent d-flex justify-content-center">
                    <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching delivery details..." />
                    </td>
                </tr>
            ) : (
                <div className={`${styles.management_details}`}>
                    
                <div className={`${styles.management_details_head} ${styles.deilvery_management_details_head_title} d-flex justify-content-between` }>
                    <h5>Delivery Person</h5>
                    <div className={`${styless.onoffswitchbtn} ${styles.deilvery_management_switchs}`}>
                        <div className={`${styless.onoffswitch} d-inline-block align-top mr-0`}>
                            <input
                            type="checkbox"
                            name="enable"
                            className={styless.onoffswitch_checkbox}
                            onChange={(e) => { handleInput(e,agentDetials._id)} }
                            checked={agentDetials.enable}
                            id="statusSwitch" 
                            />
                            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
                            <span className={styless.onoffswitch_inner}></span>
                            <span className={styless.onoffswitch_switch}></span>
                            </label>
                        </div>
                    </div>
                </div>
                    <div className={` ${styles.management_details_contents} ${styles.delivery_management_view_contents} ml-0 mr-0 row`}>
                        <div className="col-md-10">
                            <h6 style={{ textTransform : 'capitalize'}}><span>User Name: </span> { agentDetials?.name?.split("__")[0] ? agentDetials.name.split("__")[0] : ''} { agentDetials?.name?.split("__")[1] ?  agentDetials?.name?.split("__")[1] : ''} </h6>
                            <h6><span>Email: </span> {agentDetials.email}</h6>
                            <h6><span>Phone: </span>+91 {agentDetials.phone}</h6>
                            <h6><span>Address: </span> { agentDetials?.address?.split("__")[0] ? agentDetials?.address?.split("__")[0] : ''}</h6>
                        </div>
                        <div className="col-md-2 text-right">
                            <img src = { adminEdit } alt = "Edit" onClick = { editHandle } style = {{ cursor : 'pointer'}} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
