import commonStyles from "../../../styles/Management_Common.module.css";
import ReactPaginate from "react-paginate";
import leftArrow from "../../../assets/images/pagination_left_arrow.svg";
import rightArrow from "../../../assets/images/pagination_right_arrow.svg";

const prevIcon = <img src={leftArrow} alt="" />;
const nextIcon = <img src={rightArrow} alt="" />;

export default function PaginateWrapperComponent(props) {
  return typeof props.data === "object" ? (
    <nav aria-label="Page navigation">
      <ReactPaginate
        containerClassName={`${commonStyles.pagination} pagination justify-content-end`}
        pageClassName={`${commonStyles.page_item} page-item`}
        pageLinkClassName={`${commonStyles.page_link} page-link`}
        previousClassName={`${commonStyles.page_item} page-item`}
        nextClassName={` ${commonStyles.page_item} page-item`}
        previousLinkClassName={`${commonStyles.page_link} page-link`}
        nextLinkClassName={`${commonStyles.page_link} page-link`}
        disabledClassName="disabled"
        //   activeClassName="active"
        //   activeClassName={commonStyles.page_item_active}
        activeLinkClassName={commonStyles.page_link_active}
        pageCount={props.data.endPage}
        previousLabel={prevIcon}
        nextLabel={nextIcon}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        //   initialPage={1}
        forcePage={props.nextPage - 1}
        onPageChange={(e) => props.handleChange(e, "nextPage")}
      />
    </nav>
  ) : null;
}
