import React from "react";
import { useState, useRef, useEffect } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/ZoneList.module.css";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { notification } from "antd";
import { useHistory } from "react-router";
import styless from "../../../styles/AddProduct.module.css";
import { onlyNumbersWithLimit, otpRegx, userNameWithSpace } from "../../../utils/RegularExp";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";

const city = {
  name: "",
  description: "",
  zipCode: "",
  displayOnHomePage: false,
};

const shipping = {
  shippingPrice: "",
  minTotal: "",
  maxTotal: "",
};

const firstCity = JSON.parse(JSON.stringify(city));

const firstShipping = JSON.parse(JSON.stringify(shipping));

/* const intialFilterState = {
  regionId: "",
  name: "",
  description: "",
  shipppingInDays: "",
  cities: [firstCity],
  shipping: [firstShipping],
}; */

function intialState() {
  const firstCity = JSON.parse(JSON.stringify(city));

  const firstShipping = JSON.parse(JSON.stringify(shipping));

  return {
    regionId: "",
    name: "",
    description: "",
    shipppingInDays: "",
    cities: [firstCity],
    shipping: [firstShipping],
  }
}

function AddZone() {
  const [regions, setRegions] = useState([]);
  const [filter, setFilter] = useState(intialState());
  const [errorCity, setErrorCity] = useState({
    name: false,
    zipCode: false,
    regionId: false,
    zone: false,
    shipppingInDays: false,
  });
  const [errorShipping, setErrorShipping] = useState({
    shippingPrice: false,
    minTotal: false,
    maxTotal: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isWrong, setIsWrong] = useState("")
  const history = useHistory();

  useEffect(() => {
    const { state } = history.location;
    if (state?.zoneId && state?._id) {
      setIsLoading(true);
      getZoneDetails(state);
      // setIsLoading(false);
    } else {
      setIsLoading(false);

    }
    getRegionList();
  }, []);


  const [editZoneId, setEditZoneId] = useState('');
  /**
   * @author Mithun Prabhu
   * @param {data}
   * EDIT-FLOW FETCH ZONE DETAILS
   */
  function getZoneDetails(data) {
    // setIsLoading(true);
    let params = {
      regionId: data._id,
      zoneId: data.zoneId,
    };
    AxiosInstance(PORTS.REGION_SERVICE, "multipart/form-data")
      .post("/admin/zone/getZoneDetails", params)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setEditZoneId(res.data.daaata[0].zones._id);
          if (res.data.daaata[0].zones.cities.length > 0) {
            res.data.daaata[0].zones.cities.map((item) => {
              if (item.zipCode.length > 0) {
                item.zipCode = [item.zipCode.join()];
              }
            });
          }
          const setData = {
            regionId: res.data.daaata[0]._id,
            name: res.data.daaata[0].zones.name,
            description: "",
            shipppingInDays: res.data.daaata[0].zones.shipppingInDays,
            cities: res.data.daaata[0].zones.cities,
            shipping: res.data.daaata[0].zones.shipping,
          };
          setFilter(setData);
        }
      })
      .catch((er) => {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  /**
   * @author Mithun Prabhu
   * GET DROPDOWN REGION LIST
   */
  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status == 200) {
          // console.log(res.data.daaata);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function handleInput(e) {
    // console.log(e.target.value);
    let { name, value } = e.target;
    // value = value.trim();
    if (name === "name") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setErrorCity({ ...errorCity, [name]: "Enter only alphabet and minimum 3" });
      } else {
        setErrorCity({ ...errorCity, [name]: "" })
      }
    }
    /* if (value.trim()=="" ) {
      setErrorCity({ ...errorCity, [name]: "Required" });
    } else {
      setErrorCity({ ...errorCity, [name]: "" });
    } */
    // if (value.length === 0 && e.which === 32) e.preventDefault();
    const updatedFilter = { ...filter };
    updatedFilter[name] = value;
    setFilter(updatedFilter);
  }
  /**
   * @author Mithun Prabhu
   * @param {type}
   * ADD NEW CITY
   */
  function addNewCity(type) {
    const updatedValue = JSON.parse(
      JSON.stringify(type === "city" ? filter.cities : filter.shipping)
    );
    const newCity = JSON.parse(
      JSON.stringify(type === "city" ? city : shipping)
    );
    updatedValue.push(newCity);
    const updatedFilter = { ...filter };
    if (type === "city") {
      updatedFilter.cities = updatedValue;
    } else {
      updatedFilter.shipping = updatedValue;
    }
    setFilter(updatedFilter);
  }
  /**
   * @author Mithun Prabhu
   * ADD NEW SHIPPING
   */
  function addNewShipping() {
    let lastArray = filter.shipping.length;
    let currentMinTotal = filter.shipping[lastArray - 1].minTotal, isSameMinTotal = false, isSameMaxTotal = false;
    let currentMaxTotal = filter.shipping[lastArray - 1].maxTotal
    let cloneShipping = [...filter.shipping]
    cloneShipping.pop();
    cloneShipping?.findIndex(item => {
      if ( item.minTotal === currentMinTotal || item.maxTotal === currentMinTotal) {
        isSameMinTotal = true
      }
      if ( item.minTotal === currentMaxTotal || item.maxTotal === currentMaxTotal) {
        isSameMaxTotal = true
      }
    })
    if (
        currentMinTotal &&
        currentMaxTotal &&
        (filter.shipping[lastArray - 1].shippingPrice)
      ) {
        if(isSameMinTotal || isSameMaxTotal) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: `Entered ${isSameMinTotal? 'Minimum Total': 'Maximum Total'} already used in above prices. Please enter different prices range to continue`,
          });
        } else {
          const updatedValue = JSON.parse(
            JSON.stringify(filter.shipping)
          );
          const newCity = JSON.parse(
            JSON.stringify(shipping)
          );
          updatedValue.push(newCity);
          const updatedFilter = { ...filter };
          updatedFilter.shipping = updatedValue;
          setFilter(updatedFilter);
        }
    } else {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please Enter Minimum total/Maximum total/Price to continue",
      });
    }
  }
  /**
   * @author Mithun Prabhu
   * @param {indx, type}
   * REMOVE CITY
   */
  function removeCity(indx, type) {
    // console.log(indx);
    const updatedValue = type === "city" ? filter.cities : filter.shipping;
    updatedValue.splice(indx, 1);
    const updatedFilter = { ...filter };
    if (type === "city") {
      updatedFilter.cities = updatedValue;
    } else {
      updatedFilter.shipping = updatedValue;
    }
    setFilter(updatedFilter);
  }

  function handleChange(e, indx, type) {
    // console.log(e, indx, type);//cheking all field
    let { name, value } = e.target;
    // value = value.trim();
    const updatedValue = filter.cities;
    if (type === "zipCode") {
      updatedValue.forEach((x, index) => {
        if (index === indx) {
          x.zipCode = [value.trim()];
        }
      });
    } else if (type === "name") {
      updatedValue.forEach((x, index) => {
        if (index === indx) {
          x.name = value;
        }
      });
    }
    const updatedFilter = { ...filter };
    updatedFilter.cities = updatedValue;
    setFilter(updatedFilter);
  }

  function shippingHandleChange(e, indx) {
    // console.log("test")
    const numbers = /^[0-9\b]+$/;
    const { name, value } = e.target;
    const updatedFilter = { ...filter };
    if (!value && value === "") {
      setErrorShipping({ ...errorShipping, [name]: "Required" });
    } else {
      setErrorShipping({ ...errorShipping, [name]: "" });
    }
    if (value === "" || numbers.test(value)) {
      // console.log(value.length);

      if (indx === "shipppingInDays") {
        if (value.length > 2) {
          setErrorCity({ ...errorCity, [indx]: "Maximum 2 digits" });
        } else {
          setErrorCity({ ...errorCity, [indx]: "" });
          updatedFilter[name] = Number(value);
        }

      } else {
        const updatedValue = filter.shipping;
        updatedValue.forEach((x, index) => {
          if (index === indx) {
            x[name] = Number(value);
          }
        });
        updatedFilter.shipping = updatedValue;
      }
      setFilter(updatedFilter);
    }
    if (indx > 0) {
      if (filter.shipping[indx].minTotal <= filter.shipping[indx - 1].maxTotal) {
         setIsWrong("true")
      } else {
        setIsWrong("false")
      }
    }
  }
  /**
   * @author Mithun Prabhu
   * SAVE REGION
   */
  // *** SAVE REGION *** //
  function handleFormSubmit() {
    const { state } = history.location;
    const isError = validate();
    let filteNew = JSON.parse(JSON.stringify(filter));
    var MinTotalvalues = filteNew?.shipping?.map(function(item){ return item.minTotal });
    var MaxTotalvalues = filteNew?.shipping?.map(function(item){ return item.maxTotal });
    var overallPrice = [...MinTotalvalues, ...MaxTotalvalues]
    var isMinMaxTotalDuplicate = overallPrice.some(function(item, idx){ 
        return overallPrice.indexOf(item) != idx 
    });
    if (isMinMaxTotalDuplicate) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: `Please enter unique Minimum Total/Maximum Total to continue`,
      });
      return false
    }
    if (filteNew.name) {
      if (!userNameWithSpace.test(filteNew.name) || !filteNew.name === "") {
        setErrorCity({ ...errorCity, name: "Enter only alphabet and minimum 3" })
        return false
      } else {
        setErrorCity({ ...errorCity, name: "" })
      }
    }
    if (filteNew.cities.length > 0) {
      filteNew.cities.map((item) => {
        item.zipCode = item?.zipCode[0]?.split(",").map(Number);
      });
    }
    if (isError) {
      let cloneFilter = { ...filteNew };
      if (state?.zoneId && state?._id) {
        cloneFilter._id = state._id;
      }
      if (editZoneId) {
        filteNew._id = editZoneId
      }
      AxiosInstance(PORTS.REGION_SERVICE, "multipart/form-data")
        .post("/admin/zone/addZone", filteNew)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: state?.zoneId
                ? "Zone details updated successfully"
                : "Zone details added successfully",
            });
            setFilter(intialState());
            history.push(`/site-settings/zone`);
          } else if (res.data.status === 400) {
            showNotification({
              type: "error",
              message: "Adding zone failed",
              description: res.data?.msg || "Error occured",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Adding zone failed",
            description: "Error occured",
          });
        });
    } else {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
    }
  }

  const [wrongIndx, setWrongIndx] = useState([]);
  // *** VALIDATE *** //
  function validate() {
    let bool = true;
    const cloneErrorCity = { ...errorCity };
    const cloneErrorShipping = { ...errorShipping };
    if (!filter.regionId) {
      bool = false;
      cloneErrorCity.regionId = true;
    } else {
      cloneErrorCity.regionId = false;
    }
    /* if (filter.name==="") {
      setErrorCity({ ...errorCity, name: "Required" });
    } else {
      setErrorCity({ ...errorCity, name: "" });
    } */
    if (!filter.name) {
      bool = false;
      cloneErrorCity.name = true && "Required";
    } else {
      cloneErrorCity.name = false;
    }
    if (!filter.shipppingInDays) {
      bool = false;
      cloneErrorCity.shipppingInDays = true && "Required";
    } else {
      cloneErrorCity.shipppingInDays = false;
    }
    filter.cities.forEach((city, index) => {
      if (!city.name) {
        bool = false;
        cloneErrorCity.city = true;
      }
      if (!city.zipCode.length) {
        bool = false;
        cloneErrorCity.zipCode = true;
      }
      let splitedZip = city.zipCode[0]?.split(',');
      if (splitedZip?.length) {
        splitedZip.forEach(code => {
          if (code.length === 6 && otpRegx.test(code)) {
            city.isWrongZip = false;
          } else {
            bool = false;
            cloneErrorCity.zipCode = true;
            city.isWrongZip = true;
          }
        })
      }
    });
    let WrongInd = [];
    filter.shipping.forEach((ship, index) => {
      if (!ship.shippingPrice && ship.shippingPrice !== 0) {
        bool = false;
        cloneErrorShipping.shippingPrice = true;
      }
      if (!ship.maxTotal) {
        bool = false;
        cloneErrorShipping.maxTotal = true;
      }
      if (!ship.minTotal) {
        bool = false;
        cloneErrorShipping.minTotal = true;
      }
      if (ship.minTotal > ship.maxTotal) {
        bool = false;
        cloneErrorShipping.minTotal = true;
      }
      if (index > 0) {
        if (filter.shipping[index].minTotal < filter.shipping[index - 1].maxTotal) {
          bool = false;
          cloneErrorShipping.minTotal = true;
          WrongInd.push(index);
          ship.isWrong = true;
        } else {
          ship.isWrong = false;
        }
      }
    });
    setWrongIndx(WrongInd);
    setErrorCity(cloneErrorCity);
    setErrorShipping(cloneErrorShipping);
    return bool;
  }
  /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * NOTIFICATION FUCTION 
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  const { state } = history.location;

  // *** CHECKBOX ONCHANGE FUNCTON *** //
  function checkboxOnchangeHandler(indx) {
    const filterNew = { ...filter };
    filterNew.cities.map((city, index) => {
      if (index === indx) {
        city.displayOnHomePage = !city.displayOnHomePage;
      }
    });
    setFilter(filterNew);
  }

  return isLoading === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching zone details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            style={{ cursor: "pointer" }}
            src={BlackLeftArrow}
            alt=""
            onClick={() => history.push(`/site-settings/zone`)}
          />
          {state?._id ? "Edit Zone" : "Add Zone"}
        </h1>

        <div className={commonstyles.management_contents_upload}>
          <button
            className="theme-btn d-inline-block mr-3"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/site-settings/zone`)}
          >
            Cancel
          </button>
          <button
            className=" theme-btn d-inline-block"
            style={{ cursor: "pointer" }}
            onClick={handleFormSubmit}
          >
            Save
          </button>
        </div>
      </div>
      <div className={commonstyles.management_details}>
        <div className={commonstyles.management_details_form}>
          <div className="row">

            <div className="col-lg-6">
              <div >
                <div >
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Warehouse
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <select
                    // className="form-control form-control-lg form_control mb-4"
                    className={`form-control form-control-md
                                        ${errorCity.regionId &&
                      "theme-error-input"
                      } ${commonstyles.form_control} mb-4`}
                    name="regionId"
                    value={filter.regionId}
                    onChange={handleInput}
                  >
                    <option>{/* Select Region */}</option>
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <option key={region._id} value={region._id}>
                          {region.name}
                        </option>
                      ))
                      : null}
                  </select>
                  <FormErrorText error={errorCity.regionId ? "Required" : ""} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div >
                <div >
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Zone Name
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-md
                  ${errorCity.name &&
                      "theme-error-input"
                      } ${commonstyles.form_control} mb-4`}
                    formControl={{
                      placeholder: "Zone Name",
                      name: "name",
                      value: filter.name,
                      autoComplete: "off",
                      onChange: handleInput,
                    }}
                  />
                  <FormErrorText error={errorCity.name  /* && "Required" */} />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
            <div >
              <div >
                <label htmlFor="" className={commonstyles.secondry_title}>
                City
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    {" "}
                    *{" "}
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-md
                  ${
                    errorCity.name &&
                    "theme-error-input"
                  } ${commonstyles.form_control} mb-4`}
                  formControl={{
                    placeholder: "Select City",
                    name: "name",
                    value: filter.name,
                    autoComplete: "off",
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={errorCity.name}  />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div >
                <label htmlFor="" className={commonstyles.secondry_title}>
                Pincode
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    {" "}
                    *{" "}
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-md
                  ${
                    errorCity.name &&
                    "theme-error-input"
                  } ${commonstyles.form_control} mb-4`}
                  formControl={{
                    placeholder: "Eg, 560 001",
                    name: "name",
                    value: filter.name,
                    autoComplete: "off",
                    onChange: handleInput,
                  }}
                />
                <FormErrorText error={errorCity.name } />
              </div>
            </div>
          </div> */}
            <div className="col-md-12">
              <div className={`${commonstyles.management_details_form} ${commonstyles.management_contents_main} pl-0 pr-0 ml-0`}>
                {Array.isArray(filter.cities) &&
                  filter.cities.map((city, indx, arr) => (
                    <div key={indx} className="row">

                      {/* <div className="col-lg-4">
                    <div className={commonstyles.management_details_contents}>
                      <div className={`${commonstyles.management_details_form} ${commonstyles.equalHeight}`}>
                      <div
                        className={
                            "custom-control custom-checkbox " +
                            commonstyles.custom_control +
                            commonstyles.custom_checkbox
                        }>
                        <input
                          type="checkbox"
                          className={
                            "custom-control-input " +
                            commonstyles.custom_control_input
                          }
                          id="customCheck2"
                          name="enable"
                          value="Bike"
                          checked={city.displayOnHomePage}
                          onChange={() => checkboxOnchangeHandler(indx)}
                        />
                        <label
                          className={
                            "custom-control-label " +
                            commonstyles.custom_control_label
                          }
                          for="customCheck2"
                          ><span>Display on home page</span>
                        </label>
                      </div> */}
                      {/* <label htmlFor="" className={commonstyles.secondry_title}>
                        Enable 
                          <span
                            className="text-danger"
                            style={{ fontSize: "20px" }}
                          >
                            {" "}
                            *{" "}
                          </span>
                        </label>
                            <input
                              type="checkbox"
                              className={`${commonstyles.custom_checkbox}`}
                              id="vehicle1"
                              name="enable"
                              value="Bike"
                              checked={city.displayOnHomePage}
                              onChange={() => checkboxOnchangeHandler(indx)}
                            />{" "}
                        <label for="vehicle1">Display on home page</label> 

                        <div className={`${styless.onoffswitch} d-inline-block align-top`}>
                          <input
                            type="checkbox"
                            name="enable"
                            className={styless.onoffswitch_checkbox}
                            onChange={() => checkboxOnchangeHandler(indx)}
                            checked={city.displayOnHomePage}
                            id="statusSwitch"
                          />
                          
                          <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
                            <span className={styless.onoffswitch_inner}></span>
                            <span className={styless.onoffswitch_switch}></span>
                          </label>
                        </div>  */}

                      {/* </div>
                    </div>
                  </div> */}
                      <div className="col-lg-6">
                        <div >
                          <div>
                            <label htmlFor="" className={commonstyles.secondry_title}>
                              City
                              <span
                                className="text-danger"
                                style={{ fontSize: "20px" }}
                              >
                                {" "}
                                *{" "}
                              </span>
                            </label>
                            <InputComponent
                              error={
                                errorCity.city
                                  ? filter.cities[indx].name
                                    ? false
                                    : true
                                  : false
                              }
                              className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                              formControl={{
                                placeholder: "Type City",
                                name: "cities",
                                value: filter.cities[indx].name,
                                onChange: (e) => handleChange(e, indx, "name"),
                              }}
                            />
                            <FormErrorText
                              error={
                                errorCity.city
                                  ? filter.cities[indx].name
                                    ? false
                                    : "Required"
                                  : false
                              }
                            />
                            <div
                              className={
                                "custom-control custom-checkbox " +
                                commonstyles.custom_control +
                                commonstyles.custom_checkbox
                              }>
                              <input
                                type="checkbox"
                                className={
                                  "custom-control-input " +
                                  commonstyles.custom_control_input
                                }
                                id={filter.cities[indx].name}
                                name="enable"
                                value={filter.cities[indx].name}
                                checked={city.displayOnHomePage}
                                onChange={() => checkboxOnchangeHandler(indx)}
                              />
                              <label
                                className={
                                  "custom-control-label " +
                                  commonstyles.custom_control_label
                                }
                                for={filter.cities[indx].name}
                              ><span className={commonstyles.checkbox_display}>Display on home page</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div >
                          <div >
                            <label htmlFor="" className={commonstyles.secondry_title}>
                              Pincode
                              <span
                                className="text-danger"
                                style={{ fontSize: "20px" }}
                              >
                                {" "}
                                *{" "}
                              </span>
                            </label>
                            <InputComponent
                              error={
                                errorCity.zipCode
                                  ? filter.cities[indx].zipCode[0]
                                    ? false
                                    : true
                                  : false
                              }
                              type="number"
                              className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                              formControl={{
                                placeholder: "Eg, 560 001",
                                name: "zipcode",
                                value: Array.isArray(filter.cities[indx].zipCode)
                                  ? filter.cities[indx].zipCode[0]
                                  : "",
                                onChange: (e) => handleChange(e, indx, "zipCode"),
                              }}
                            />
                            <FormErrorText
                              error={
                                errorCity.zipCode
                                  ? filter.cities[indx].zipCode[0]
                                    ? false
                                    : "Required"
                                  : false
                              }
                            />
                            {city.isWrongZip && <FormErrorText
                              error="Please enter valid zipcode"
                            />}
                          </div>
                        </div>
                        {/* <label for="vehicle1">Status</label>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                      checked={city.displayOnHomePage}
                      onChange={() => checkboxOnchangeHandler(indx)}
                    ></input>  */}

                      </div>
                      {filter.cities.length > 1 && (
                        <div className="col-lg-4 ml-auto align-self-end mb-2 text-right add_text theme-btn-link text-danger">
                          <span onClick={() => removeCity(indx, "city")}>
                            Remove City
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className=" col-md-12 pl-3 pr-3 d-block text-right">
              <div
                className={styles.title_text_value}
                onClick={() => addNewCity("city")}
              >
                <img src={Plus} alt="" />
                <span className="theme-btn-link">Add City</span>
              </div>
            </div>
          </div>
        </div>



        <div className={`${commonstyles.management_details_form} mt-4`}>
          <h4 className={`${styles.title_h4}`}>Shipping Price</h4>
          {Array.isArray(filter.shipping) &&
            filter.shipping.map((ship, indx, arr) => (
              <div className="row" key={indx}>
                <div className="col-lg-6">
                  <div className={commonstyles.management_details_contents}>
                    <div>
                      <label htmlFor="" className={commonstyles.secondry_title}>
                        Minimum Total
                        <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      <InputComponent
                        error={
                          errorShipping.minTotal
                            ? filter.shipping[indx].minTotal
                              ? false
                              : true
                            : false
                        }
                        className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                        formControl={{
                          placeholder: "Type Minimum Total",
                          name: "minTotal",
                          value: filter.shipping[indx].minTotal,
                          maxLength: 10,
                          onChange: (e) => shippingHandleChange(e, indx),
                        }}
                      />
                      <FormErrorText
                        error={
                          errorShipping.minTotal
                            ? filter.shipping[indx].minTotal
                              ? filter.shipping[indx].maxTotal && ((filter.shipping[indx].minTotal > filter.shipping[indx].maxTotal) ||
                                (filter.shipping[indx].minTotal === filter.shipping[indx].maxTotal))
                                ? "Minimum total is less than the maximum total"
                                : false
                              : "Required"
                            : filter.shipping[indx].maxTotal && (filter.shipping[indx].minTotal > filter.shipping[indx].maxTotal ||
                              filter.shipping[indx].minTotal === filter.shipping[indx].maxTotal)
                              ? "Minimum total is less than the maximum total"
                              : false
                        }
                      />
                      {(ship.isWrong && isWrong==="true") && <FormErrorText
                        error="Minimum total should be greater than the above maximum total"
                      />}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={commonstyles.management_details_contents}>
                    <div>
                      <label htmlFor="" className={commonstyles.secondry_title}>
                        Maximum Total
                        <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      <InputComponent
                        error={
                          errorShipping.maxTotal
                            ? filter.shipping[indx].maxTotal
                              ? false
                              : true
                            : false
                        }
                        className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                        formControl={{
                          placeholder: "Type Maximum Total",
                          name: "maxTotal",
                          maxLength: 7,
                          value: filter.shipping[indx].maxTotal,
                          onChange: (e) => shippingHandleChange(e, indx),
                        }}
                      />
                      <FormErrorText
                        error={
                          errorShipping.maxTotal
                            ? filter.shipping[indx].maxTotal
                              ? false
                              : "Required"
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={commonstyles.management_details_contents}>
                    <div>
                      <label htmlFor="" className={commonstyles.secondry_title}>
                        Price
                        <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      <InputComponent
                        error={
                          errorShipping.shippingPrice
                            ? filter.shipping[indx].shippingPrice
                              ? false
                              : true
                            : false
                        }
                        className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                        formControl={{
                          placeholder: "Type Price",
                          name: "shippingPrice",
                          maxLength: 7,
                          value: filter.shipping[indx].shippingPrice,
                          onChange: (e) => shippingHandleChange(e, indx),
                        }}
                      />
                      <FormErrorText
                        error={
                          errorShipping.shippingPrice
                            ? (filter.shipping[indx].shippingPrice || filter.shipping[indx].shippingPrice === 0)
                              ? false
                              : "Required"
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
                {filter.shipping.length > 1 && (
                  <div className="col-lg-4 ml-auto align-self-end mb-2 text-right add_text theme-btn-link text-danger">
                    <span onClick={() => removeCity(indx, "shipping")}>
                      Remove Shipping
                    </span>
                  </div>
                )}
              </div>
            ))}
          <div className="col-lg-12 text-right">
            <div
              className={styles.title_text_value}
              onClick={() => addNewShipping("shipping")}
            >
              <img src={Plus} alt="" />
              <span className="theme-btn-link">Add Price</span>
            </div>
          </div>
        </div>

        <div  >

          <div className="row ml-0 mr-0">
            <div className={`${commonstyles.management_details_form} col-lg-6 mt-4`} >
              <h4 className={styles.title_h4}>Shipping (days)</h4>
              <div className={commonstyles.management_details_contents}>
                <div>
                  <label htmlFor="" className={commonstyles.secondry_title}>
                    Days
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <InputComponent
                    error={errorCity.shipppingInDays}
                    className={`form-control form-control-lg ${commonstyles.form_control} mb-4`}
                    formControl={{
                      placeholder: "Number of days",
                      name: "shipppingInDays",
                      value: filter.shipppingInDays,
                      maxLength: 10,
                      onChange: (e) => shippingHandleChange(e, "shipppingInDays"),
                    }}
                  />
                  <FormErrorText
                    error={errorCity.shipppingInDays ? errorCity.shipppingInDays : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddZone;
