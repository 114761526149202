import styles from "../../../styles/AddProduct.module.css";
import CloseIconSmall from "../../../assets/images/close_icon_small.svg";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../utils";

export default function DecorChipComponent({ decor, handleClose }) {
  return (
    <div className={`${styles.media} media`}>
      <img
        className="align-self-start mr-3"
        src={decor.image}
        alt="Product image"
      />
      <img
        src={CloseIconSmall}
        className={`${styles.close_icon} cursor-pointer`}
        alt="CloseIcon"
        title="Remove"
        onClick={(e) => handleClose(decor)}
      />
      <div className="media-body">
        <h5 className="mt-0">{decor.title}</h5>
        <p>{}</p>
      </div>
    </div>
  );
}
