import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import Search from "../../../assets/images/search_icon.svg";
import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Space, Spin, Select, Tree } from "antd";
import { formatServerValidtionErrors } from "../../../utils";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import {
    emailRegx,
    minChar,
    passwordReg,
    phoneRegx,
    Saleswithletters,
    Catalogwithletters,
    Discountwithletters,
    Customerwithletters,
} from "../../../utils/RegularExp";
import PermissionTable from "../UserManagement/PermissionTable";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { AllModules_, buttons, treeData_ } from "../../modules/ReusableComponent/PermissionObject";

function AddRole() {
    const [formErrors, setFormErrors] = useState({});
    const [name, setName] = useState(null);
    const history = useHistory();
    const { state } = history.location;
    const [isLoading, setIsLoading] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [btns, setBtns] = useState(buttons);
    const [tableList, setTableList] = useState(AllModules_[0].data);
    const [AllModules, setAllModules] = useState(AllModules_);
    const [choosenBtn, setChoosenBtn] = useState([
        {
            "id": 1, name: "Dashboard", value: "Dashboard", active: true
        }
    ]);
    const [treeData, setTreeData] = useState(treeData_);

    useEffect(() => {
        if (state?._id) {
            getRoleDetails(state._id);
        }
        buttons.forEach(item => {
            if (item.value === "Dashboard") {
                item.active = true;
            } else {
                item.active = false;
            }
        });
        setBtns(buttons);
        return () => {
            history['isRole'] = true;
        }
    }, [])

    /**
  * @author Rajkumar
  * @param {id}
  * By getting id of particular role getting all details from API
  */
    function getRoleDetails(id) {
        setIsLoading(true);
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
            .get(`/admin/role/detail/${id}`)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setIsLoading(false);
                    let data = res.data.data;
                    const cloneAllModules = [...AllModules]
                    setName(data.name);
                    setCheckedKeys(data.permissionTabs);
                    data?.permission?.forEach(permission => {
                        let split = permission.split('-');
                        cloneAllModules.forEach(item => {
                            if (item.value === split[0]) {
                                item.data.forEach(subItem => {
                                    if (subItem.value === split[1]) {
                                        subItem[split[2]] = true;
                                    }
                                })
                            }
                        });
                    });
                    setTableList(cloneAllModules[0].data);
                    setAllModules(cloneAllModules);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((er) => {
                setIsLoading(false);
                console.log(er);
            });
    }


    /**
   * @author Rajkumar
   * @param {btn}
   * handle checkbox seperately and give permission to particular modules
   */
    function addcheckbox(e, record, type) {
        const { checked } = e.target;
        let cloneCheckedKeys = [...checkedKeys];
        let unSelectedParent, unSelectedChild = "";
        if (checked) {
            AllModules.forEach(item => {
                item?.data?.forEach(subItem => {
                    if (subItem.value === record.value) {
                        subItem[type] = true;
                    }
                    if (subItem.add && subItem.edit && subItem.view) {
                        cloneCheckedKeys.push(record.value);
                    }
                });
            })
        } else {
            AllModules.forEach(item => {
                item?.data?.forEach(subItem => {
                    if (subItem.value === record.value) {
                        subItem[type] = false;
                        if (subItem.add === false && subItem.edit === false && subItem.view === false) {
                            unSelectedParent = item.value;
                            unSelectedChild = subItem.value;
                        }
                    }
                });
            })
        }
        cloneCheckedKeys = cloneCheckedKeys.filter((val) => unSelectedParent !== val);
        cloneCheckedKeys = cloneCheckedKeys.filter((val) => unSelectedChild !== val);
        setAllModules(AllModules);
        setCheckedKeys(cloneCheckedKeys);
    }

    /**
   * @author Rajkumar
   * @param {checkedKeysValue, info}
   * On checking particular module of tree bottom table having all checkbox should be selected and uncheck all set to false
   */
    const onCheck = (checkedKeysValue, info) => {
        setCheckedKeys(checkedKeysValue);
        if (info.checked || info?.id) {
            AllModules.forEach(item => {
                if (checkedKeysValue?.length > 0) {
                    checkedKeysValue.forEach(x => {
                        if (item?.data?.length > 0) {
                            item.data.forEach(subItem => {
                                if (subItem.value === x) {
                                    subItem.add = true;
                                    subItem.edit = true;
                                    subItem.view = true;
                                }
                            });
                        }
                    })
                } else {
                    if (item?.data?.length > 0) {
                        item.data.forEach(subItem => {
                            subItem.add = false;
                            subItem.edit = false;
                            subItem.view = false;
                        });
                    }
                }
                if (item.value === (info?.id ? info?.value : choosenBtn?.value)) {
                    setTableList(item?.data);
                }
            });
        } else {
            AllModules.forEach(item => {
                let arr = info?.node?.children;
                if (arr?.length > 0 && item?.data?.length > 0) {
                    item.data.forEach(subItem => {
                        arr.forEach(x => {
                            if (x.key === subItem.value) {
                                subItem.add = false;
                                subItem.edit = false;
                                subItem.view = false;
                            }
                        })
                    })
                } else {
                    item.data.forEach(subItem => {
                        if (info?.node?.key === subItem.value) {
                            subItem.add = false;
                            subItem.edit = false;
                            subItem.view = false;
                        }
                    })
                }
                if (item.value === choosenBtn?.value) {
                    setTableList(item?.data);
                }
            });
        }
        setAllModules(AllModules);
    };

    /**
   * @author Rajkumar
   * @param {btn}
   * handle button according module wise permission needed
   */
    const selectedBtn = (btn) => {
        const cloneBtns = [...btns];
        setChoosenBtn(btn);
        cloneBtns.forEach(item => {
            if (btn.id === item.id) {
                item.active = true;
            } else {
                item.active = false;
            }
        })
        setBtns(cloneBtns);
        AllModules.forEach(item => {
            if (item.value === btn.name) {
                setTableList(item.data);
            }
        });
        onCheck(checkedKeys, btn);
    }

    /**
   * @author Rajkumar
   * @param {btn}
   * handle change for name
   */
    const inputOnChange = (e) => {

        if (!minChar.test(e.target.value)) {
            if (!formErrors.name) {
                formErrors.name = {};
            }
            formErrors.name = "Enter minimum 3 characters";
        } else {
            if (formErrors.name) {
                formErrors.name = "";
            }
        }

        let val = e.target.value.replace(/[^\w\s]/gi, "");
        if (val.trim()) {
            setName(val);
        } else {
            setName('');
        }
    }

    /**
   * @author Rajkumar
   * @param {}
   * update changes done in role,permissions and particular checkbox permission
   */
    const save = () => {

        if (!name) {
            setFormErrors({ name: "Enter minimum 3 characters" })
            showNotification({
                type: "error",
                message: "Error",
                description: name?.length < 3 ? "Please enter minimum three characters" : "Please enter the role name",
            });
            return;
        }
        if (name?.length > 2) {
            let permissionArr = [];
            let roleType = 1;
            AllModules.forEach(item => {
                item.data.forEach(subItem => {
                    if (subItem.add) {
                        let permission = item.value + '-' + subItem.value + '-add'
                        permissionArr.push(permission);
                    }
                    if (subItem.edit) {
                        let permission = item.value + '-' + subItem.value + '-edit'
                        permissionArr.push(permission);
                    }
                    if (subItem.view) {
                        let permission = item.value + '-' + subItem.value + '-view'
                        permissionArr.push(permission);
                    }
                    if (!subItem.add || !subItem.edit || !subItem.view) {
                        roleType = 0;
                    }
                })
            });
            if (permissionArr?.length > 0) {
                let params = {
                    name: name,
                    permission: permissionArr,
                    permissionTabs: checkedKeys,
                    roleType: roleType
                }
                continueToSave(params);
            } else {
                showNotification({
                    type: "error",
                    message: "Error",
                    description: "Plese choose any one permission",
                });
            }
        } else {
            showNotification({
                type: "error",
                message: "Error",
                description: name?.length < 3 ? "Please enter minimum three characters" : "Please enter the role name",
            });
        }
    }


    /**
  * @author Rajkumar
  * @param {params}
  * update changes done in role,permissions and particular checkbox permission and call API according conditions of id
  */
    function continueToSave(params) {
        let url = "";
        if (state?._id) {
            params._id = state._id;
            url = "/admin/role/updateRole"
        } else {
            url = "/admin/role/addRole"
        }
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
            .post(url, params)
            .then((res) => {
                if (res.data.status === 200) {
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: res.data.msg,
                    });
                    history.push(`/site-settings/user`);
                } else if (res.data.status === 400) {
                    showNotification({
                        type: "warning",
                        message: "There were few errors",
                        description: res.data.msg,
                    });
                }
            })
            .catch((er) => {
                showNotification({
                    type: "error",
                    message: "Failed",
                    description: "Error occured",
                });
            });
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    return (
        <div className={styles.management_contents}>
            <div className={styles.management_header}>
                <h1>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => history.push(`/site-settings/user`)}
                    />
                    {state?._id ? "Edit Role" : "Add Role"}
                </h1>
                <div className={styles.management_contents_upload}>
                    <button
                        className={`${commonstyles.sidebarButton} 
                            ${commonstyles.sidebarButton_active} 
                            d-inline-block`}
                        onClick={save}
                    >
                        Save
                    </button>
                </div>
            </div>
            {isLoading === true ? (
                <tr className="bg-transparent d-flex justify-content-center">
                    <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching role details..." />
                    </td>
                </tr>
            ) : (
                <div className={styles.management_details}>
                    <div className={styles.management_details_contents}>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Role <span className="text-danger" style={{ fontSize: "20px" }}>
                                        *
                                    </span>
                                </label>
                                <InputComponent
                                    error={formErrors.name}
                                    className="form-control-lg"
                                    formControl={{
                                        placeholder: "Enter the role name",
                                        type: 'text',
                                        value: name,
                                        name: "name",
                                        onChange: inputOnChange,
                                    }}
                                />
                                <FormErrorText
                                    error={formErrors.name && formErrors.name}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Permissions
                                </label>
                                <Tree
                                    checkable
                                    checkedKeys={checkedKeys}
                                    onCheck={onCheck}
                                    treeData={treeData}
                                />
                            </div>
                        </div>
                        <PermissionTable
                            checkedKeys={checkedKeys}
                            btns={btns}
                            addcheckbox={addcheckbox}
                            tableList={tableList}
                            selectedBtn={selectedBtn}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddRole;