import { useEffect, /* useRef, */ useState } from "react";
import { Select } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
const { Option } = Select;

export default function CategorySearchSelectComponent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getParentCategories();
  }, []);

  function getParentCategories() {
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .get("/admin/catalog/getParentCategory")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // console.log(res.data.data.categories);
          // const reslt = res.data.data[0];
          // console.log(res.data.data);
          setCategories(res.data.data);
          //   setPagination(res.data.pagination);
          // setPagination({ ...pagination, total: reslt.totalCategories });
        }
      })
      .catch((er) => {
        console.log("getParentCategories error", er);
      })
      .finally(() => setIsLoading(false));
  }

  function onChange(value) {
    props.handleCategoryIdUpdate(value);
  }

  // function onSearch(val) {
  //   const filters = { ...filter, searchKey: val };
  //   setFilter(filters);
  //   debouncedSearch(filters);
  // }

  return (
    <Select
      showSearch
      placeholder="Search Category"
      //   optionFilterProp="children"
      loading={isLoading}
      onChange={onChange}
      bordered={false}
      id={props.id}
      value={props.value}
      // onSearch={onSearch}
      className={`${commonstyles.form_control}  ${
        props.error && "theme-error-input"
      } form-control form-control-lg p-1`}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {/* <Option value=""></Option> */}
      {categories.map((product) => (
        <Option value={product._id} key={product._id}>
          {product.name}
        </Option>
      ))}
    </Select>
  );
}
