import React, { useState, useEffect } from "react";
import styles from "../../../styles/AddDiscount.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import styless from "../../../styles/AddProduct.module.css";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Select,
  Radio,
  Input,
  Checkbox,
  TimePicker,
} from "antd";
import validationRules from "../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../utils";
import moment from "moment";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { useHistory } from "react-router";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import {
  onlyNumbers,
  minChar,
  minChar4Limit,
  onlyThreeNumbers,
} from "../../../utils/RegularExp";
import { Link, useParams } from "react-router-dom";

const { Option } = Select;

const initialState = {
  ruleStatus: true,
  ruleName: "",
  couponCode: "",
  offerType: true,
  offerAmount: "",
  applicableCategory: [],
  usesPerCoupon: "",
  region: [],
  description: "",
  url: "",
  usesPerCustomer: "",
};

export default function OfferManagement(props) {
  const history = useHistory();
  const { id } = useParams();

  const Bannertype = history.location.state;

  const bannerType = Bannertype;

  // localStorage.setItem("Bannertype", bannerType);

  const [ruleStatus, setRuleStatus] = useState(true);
  const [ruleName, setruleName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [currenttype, setCurrentType] = useState("");
  const [minAmount, setMinAMount] = useState(0);

  const [currentMinAm, setCurrentMinAm] = useState(0);
  const [offerAmount, setOfferAmount] = useState("");
  const [usesPerCoupon, setUsesPerCoupon] = useState("");
  const [usesPerCustomer, setUsesPerCustomer] = useState("");
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [description, setDescription] = useState("");
  // const [offerType,setOfferType] = useState("");

  const [region, setRegion] = useState([]);
  const [regions, setRegions] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [parentProducts, setParentProducts] = useState([]);
  const [parentCollections, setParentCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [url, setUrl] = useState("");
  const [randomCode, setRandomeCode] = useState("");

  const [file, setFile] = useState(null);
  // const [loading, setLoading] = useState({ details: false, update: false });
  const [showCategory, setShowCategory] = useState("all");
  // const [showProduct, setShowProduct] = useState("all");
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState("");
  // const [discountDetails, setDiscountDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState("");

  const [startTime, setStartTime] = useState("");

  const [persistType, setPersistType] = useState("");

  const [endTime, setEndTime] = useState("");

  const [formErrors, setFormErrors] = useState({
    ruleName: false,
    couponCode: false,
    region: false,
    offerAmount: false,
    banner: false,
    startDate: false,
    endDate: false,
    usesPerCoupon: false,
    usesPerCustomer: false,
  });

  const [endDateSelect, setEndDateSelect] = useState(true);

  const rules = {
    ruleName: [(val, data) => (!val ? "Name " : true)],
    couponCode: [(val, data) => (!val ? "Coupon code " : true)],
    region: [(val, data) => (!val?.length ? "Select regions " : true)],
    // banner: [(val, data) => !url && "Banner "],
    startDate: [
      (val, data) => (!val || val == "Invalid date" ? "Start date " : true),
    ],
    endDate: [
      (val, data) => (!val || val == "Invalid date" ? "End date " : true),
    ],
    offerAmount: [(val, data) => (!val ? "Offer amount " : true)],
    usesPerCoupon: [(val, data) => (!val ? "Uses per coupon " : true)],
    usesPerCustomer: [(val, data) => (!val ? "Uses per customer " : true)],
  };

  // useEffect(() => {
  //   getOfferDetails(selectedProducts);
  // }, []);

  /**
   * @author Mithun Prabhu
   * @param {parentCategoryData,parentProductData,parentCollectionData}
   * GET ALL OFFER DETAILS AND SETTING STATE SEPERATELY
   */
  function getOfferDetails(
    parentCategoryData,
    parentProductData,
    parentCollectionData
  ) {
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`offers/couponDetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const offerInformation = res.data;
          setIsLoading(false);
          setRuleStatus(offerInformation.data.ruleStatus);
          setruleName(offerInformation.data.ruleName);
          setRandomeCode(offerInformation.data.couponCode);
          setCurrentType(offerInformation.data.offerType);
          setCurrentMinAm(offerInformation.data.minAmount);
          setMinAMount(offerInformation.data.minAmount);
          setOfferAmount(offerInformation.data.offerAmount);
          setUsesPerCoupon(offerInformation.data.usesPerCoupon);
          setUsesPerCustomer(offerInformation.data.usesPerCustomer);
          setSdate(
            moment(offerInformation.data.startDate).utc().format("YYYY-MM-DD")
          );
          setEndTime(
            moment(offerInformation.data.endDate).utc().format("HH:mm")
          );
          setStartTime(
            moment(offerInformation.data.startDate).utc().format("HH:mm")
          );
          setEdate(
            moment(offerInformation.data.endDate).utc().format("YYYY-MM-DD")
          );
          setPersistType(offerInformation.data.type);
          setDescription(offerInformation.data.description);
          setUrl(offerInformation.data.banner);
          setRegion(offerInformation.data.region?.length === 0? ['All Warehouse'] : offerInformation.data.region);

          if (offerInformation.data.applicableDecor && offerInformation.data.offerApplicable === 3) {
            setShowCategory("specificc");
          } else if (offerInformation.data.applicableProducts.length > 0) {
            setShowCategory("specificp");
          } else if (offerInformation.data.applicableCategory.length > 0) {
            setShowCategory("specific");
          }

          if (offerInformation.data.offerApplicable === 1) {
            setShowCategory("all");
          }

          // setCategories(offerInformation.data.applicableCategory);
          let arrC = [];
          parentCategoryData.forEach((product) => {
            offerInformation.data.applicableCategory.forEach((subPrd) => {
              if (product._id === subPrd) {
                arrC.push(product?.name);
              }
            });
          });

          setSelectedCategories(arrC);

          if (offerInformation.data.applicableDecor && offerInformation.data.offerApplicable === 3) {
            setShowCategory("specificc");
          } else if (offerInformation.data.applicableProducts.length > 0) {
            setShowCategory("specificp");
          } else if (offerInformation.data.applicableCategory.length > 0) {
            setShowCategory("specific");
          }

          offerInformation.data.endDate
            ? setEndDateSelect(true)
            : setEndDateSelect(false);

            setCategories(arrC);
          let arr = [];
          parentProductData.forEach((product) => {
            offerInformation.data.applicableProducts.forEach((subPrd) => {
              if (product._id === subPrd) {
                arr.push(product?.name);
              }
            });
          });
          setSelectedProducts(arr);
          setProducts(arr);
          setCollections(offerInformation.data.applicableDecor);
          let result = "";
          parentCollectionData.forEach((product) => {
            if (product._id === selectedCollections) {
              result = product?.title;
            }
          });
          setSelectedCollections(result);
          return;
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
          return;
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
        return;
      });
  }
  // getOfferDetails();

  useEffect(() => {
    if (sdate && edate) {
      setEdate(moment(edate).format("YYYY-MM-DD"));
    }

    // setIsLoading(false);
  }, []);

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            // res.data.daaata.unshift({
            //   name: "All",
            //   _id: "All",
            // });
            setRegions(res.data.daaata);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);

            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);

  useEffect(() => {
    if (
      parentCategories?.length >= 0 &&
      parentProducts?.length >= 0 &&
      parentCollections?.length >= 0
    ) {
      getOfferDetails(parentCategories, parentProducts, parentCollections);
    }
  }, [parentCollections, parentCategories, parentProducts]);

  useEffect(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getCategoryName")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.daaata);
          }
        })
        .catch((er) => {
          console.error("Failed to fetch parent categories");
        });
    }
    getParentCategories();
  }, []);

  useEffect(() => {
    function getParentProducts() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("admin/catalog/getSimpleProductList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentProducts(res.data.data);
            // getOfferDetails(res.data.data);
            // getParentProducts(res.data.data);
          }
        })
        .catch((er) => {
          console.error("Failed to fetch parent categories");
        });
    }
    getParentProducts();
  }, []);

  useEffect(() => {
    function getParentCollections() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("admin/catalog/getAllDecor")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            // setParentCollections(...res.data.data.map((id) => id._id));
            setParentCollections(res.data.data);
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getParentCollections();
  }, []);

  /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleRegionChange(value) {
    let selectedVal = [];
    let isAll = false;
    if (value?.length > 0) {
      value.forEach((item, index) => {
        if (value[value.length - 1] === "All Warehouse") {
          isAll = true;
        }
      });
    };
    if (isAll) {
      selectedVal.push("All Warehouse");
    } else {
      selectedVal = value?.filter((x, indx) => x !== "All Warehouse");
    }
    setRegion(selectedVal);
    // setDiscountDetails({ ...discountDetails, region: value });
  }

  function handleOfferChange(e) {
    const { name, value, checked } = e.target;

    name == "ruleName" && setruleName(value);
    name == "couponCode" && setRandomeCode(value.trim());
    name == "offerAmount" && setOfferAmount(value);
    name == "usesPerCoupon" && setUsesPerCoupon(value);
    name == "usesPerCustomer" && setUsesPerCustomer(value);
    name == "description" && setDescription(value);

    if (["ruleStatus"].includes(name)) {
      setRuleStatus(checked);
    }
    // if (["couponCode"].includes(name)) {
    //   setDiscountDetails({
    //     ...discountDetails,
    //     [name]: value.trim(),
    //   });

    // showNotification({
    //   type: "error",
    //   message: "CouponCode",
    //   description: "Coupon Code Remove Space",
    // });
  }

  function handlefirstdate(e) {
    setSdate(moment(e.target.value).format("YYYY-MM-DD"));
  }

  function handlelastdate(e) {
    setEdate(moment(e.target.value).format("YYYY-MM-DD"));
  }

  function handleOfferType(e) {
    const { name, checked } = e.target;
    if (checked) {
      setCurrentType(name);
      // setDiscountDetails({ ...discountDetails, offerType: name });
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {value}
   * HANDLING ALL TYPES OF CATEGORY/PRODUCT/COLLECTION
   */
  function handleCategory(e) {
    const { name, value, checked } = e.target;

    if (checked && name == "specific") {
      setCollections("");
      setShowCategory("specific");
      setProducts([]);
    }
    if (checked && name == "all") {
      setShowCategory("all");
      setCollections("");
      setCategories([]);
      setProducts([]);
    }
    if (checked && name == "specificp") {
      setShowCategory("specificp");
      setCollections("");
      setCategories([]);
    }
    if (checked && name == "specificc") {
      setShowCategory("specificc");
      setCategories([]);
      setProducts([]);
    }
  }

  function handleImageInput(e) {
    const { name, files } = e.target;

    setFile(e.target.files[0]);
    if (url) {
      URL.revokeObjectURL(url);
    }
    const generatedUrl = URL.createObjectURL(e.target.files[0]);
    setUrl(generatedUrl);
    // setDiscountDetails({ ...discountDetails, banner: generatedUrl });
  }

  function removeFile() {
    if (url) {
      setUrl("");
      // setDiscountDetails({ ...discountDetails, url: "" });
      setFile(null);
      // setUrl(null);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {}
   * CALLING API FOR ADDING ALL DETAILS OF COUPON
   */
  function addDiscount() {
    let selArrCat = [], selArrPrd = []
    parentCategories?.forEach(parItem => {
      categories?.forEach(selItem => {
        if(parItem?.name === selItem) {
          selArrCat.push(parItem?._id)
        }
      })
    })
    parentProducts?.forEach(parItem => {
      products?.forEach(selItem => {
        if(parItem?.name === selItem) {
          selArrPrd.push(parItem?._id)
        }
      })
    })
    const data = {
      _id: id,
      ruleName: ruleName.trim(),
      ruleStatus: parseInt(ruleStatus),
      couponCode: randomCode.toUpperCase().trim(),
      usesPerCoupon: parseInt(usesPerCoupon),
      usesPerCustomer: parseInt(usesPerCustomer),
      startDate: sdate,
      endDate: edate,
      applicableCategory: selArrCat,
      applicableProducts: selArrPrd,
      // applicableDecor: collections,
      offerAmount: parseInt(offerAmount),
      region: region,
      offerType: parseInt(currenttype),
      minAmount: minAmount != null ? parseInt(minAmount) : 0,
      type: persistType,
      startTime: startTime == "Invalid date" ? "" : startTime,
      endTime: endTime == "Invalid date" ? "" : endTime,
    };

    if (collections != "") {
      data.applicableDecor = collections;
    }

    if (
      showCategory == "all" ||
      showCategory == "specific" ||
      showCategory == "specificp" ||
      showCategory == "specificc"
    ) {
      if (showCategory == "all") {
        data.offerApplicable = 1;
      } else if (showCategory == "specific") {
        data.offerApplicable = 0;
      } else if (showCategory == "specificp") {
        data.offerApplicable = 2;
      } else if (showCategory == "specificc") {
        data.offerApplicable = 3;
      }
    }

    if (description) {
      data.description = description;
    }

    if (data.ruleStatus == 0) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please update status",
      });
      return;
    }

    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    if (
      ruleName.trim().length < 3 ||
      ruleName.trim().length > 25 ||
      randomCode.trim().length < 4 ||
      randomCode.trim().length > 25
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description:
          "Code/Name must be more than 3 characters and less than 25 characters",
      });
      return;
    }
    // if (!url) {
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description: "Please select banner image",
    //   });
    //   return;
    // }

    if (
      !onlyNumbers.test(offerAmount) ||
      !onlyNumbers.test(usesPerCoupon) ||
      !onlyNumbers.test(usesPerCustomer)
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors where fields entered with number",
      });
      return;
    }
    if (usesPerCoupon === "" || usesPerCustomer === "") {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors where fields are null/empty",
      });
      return;
    }

    let isAllRegion = false;
    if (data?.region?.length > 0) {
      data?.region.forEach(item => {
        if (item === "All Warehouse") {
          isAllRegion = true;
        }
      })
    }

    if(isAllRegion) {
      data.region = [];
    }

    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("banner", file);

    // if (Object.keys(result).length) {
    //   setFormErrors(result);
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description: "Resolve the errors and try again",
    //   });
    //   return;
    // }
    if (edate >= sdate) {
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post(`offers/${id ? "updateCoupon" : "createCoupon"}`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description:
                persistType == "0"
                  ? "General coupon details updated successfully"
                  : "Banner coupon details updated successfully",
            });
            history.push(`/discount`);
            // props.handleClose(true);
          } else if (
            res.data.status === 400 &&
            res.data.msg == "Coupon code already exists"
          ) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: "Coupon code already exists",
            });
            return;
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });
            const errors = formatServerValidtionErrors(res.data.error);
            return;
            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert("End date should be greater than start date Please check...");
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {value}
   * ONCHANGE SELECT MULTIPLE CATEGORIES AND PUSH TO ARRAY
   */
  function handleSingleCategory(value) {
    setCategories(value);

    let arr = [];
    parentCategories.forEach((product) => {
      value.forEach((subPrd) => {
        if (product._id === subPrd) {
          arr.push(product?.name);
        }
      });
    });

    setSelectedCategories(arr);
    // setDiscountDetails({ ...discountDetails, applicableCategory: value });
  }

  function handleSingleProduct(value) {
    setProducts(value);
    let arr = [];
    parentProducts.forEach((product) => {
      value.forEach((subPrd) => {
        if (product._id === subPrd) {
          arr.push(product?.name);
        }
      });
    });

    setSelectedProducts(arr);
    // setDiscountDetails({ ...discountDetails, applicableCategory: value });
  }

  function handleSingleCollection(value) {
    setCollections(value);

    let arr = "";
    parentCollections.forEach((product) => {
      if (product._id === value) {
        arr = product?.title;
      }
    });

    setSelectedCollections(arr);
    // setDiscountDetails({ ...discountDetails, applicableCategory: value });
  }

  function handleMinAmount(e) {
    setCurrentMinAm(e.target.value);
    setMinAMount(e.target.value);
  }

  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * HANDLE NONE OR MINIMUM AMOUNT TYPE
   */
  function handleminAmountType(e) {
    const { name, checked, value } = e.target;

    if (value == "0") {
      setCurrentMinAm(value);
      setMinAMount(value);
    } else if (value == "1") {
      setCurrentMinAm(value);
    }
  }

  const { Option } = Select;

  function handleChange(value) {
    setRuleStatus(parseInt(value));
  }

  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * TO OPEN END DATE ITEMS FROM CHECKBOX
   */
  function onDateSelect(e) {
    if (e.target.checked == true) {
      setEndDateSelect(true);
    } else {
      setEndDateSelect(false);
    }
  }

  // *** ONCHANGE HANDLER FOR TIME *** //
  const onChangeTimeHandlerStart = (time, type) => {
    const startTime = moment(type, "HH:mm").format("HH:mm");
    setStartTime(startTime);
  };

  /**
   * @author Mithun Prabhu
   * @param {time, type}
   * CHANGE TIME IN LOCAL
   */
  const onChangeTimeHandlerEnd = (time, type) => {
    const endTime = moment(type, "HH:mm").format("HH:mm");
    setEndTime(endTime);
  };

  /**
   * @author Mithun Prabhu
   * @param {}
   * GENERATE RANDOM CODE
   */
  function makeRandomCode() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    setRandomeCode(result);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <Link to={`/discount`}>
            <img src={BlackLeftArrow} alt="" />
          </Link>
          {persistType == "0" ? "Edit General Coupon" : "Edit Banner Coupon"}
        </h1>
        <div>
          {/* <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleOfferChange(e)}
              checked={ruleStatus}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div> */}
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/discount`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
                className="rnd br-7"
                attributes={{ onClick: addDiscount }}
                style={{ cursor: "pointer" }}
              >
                Save
              </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching discount details..." />
            </td>
          </tr>
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.management_details}>
            <div className="col-lg-12">
              <div
                className={`${styles.add_discount_full_box} row add_discount_form`}
              >
                <div className="col-lg-6">
                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_contents_header}>
                      <h4>
                        Code
                        <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          *
                        </span>
                      </h4>
                      <p
                        className={styles.generate_code}
                        onClick={makeRandomCode}
                      >
                        Generate Code
                      </p>
                    </div>
                    <div className={styles.management_details_form}>
                      <InputComponent
                        className="form-control-lg"
                        error={formErrors.couponCode}
                        formControl={{
                          name: "couponCode",

                          onChange: (e) => {
                            if (!minChar4Limit.test(e.target.value)) {
                              if (!formErrors.couponCode) {
                                formErrors.couponCode = {};
                              }
                              formErrors.couponCode =
                                "Enter minimum 4 characters";
                            } else {
                              if (formErrors.couponCode) {
                                formErrors.couponCode = "";
                              }
                            }

                            handleOfferChange(e);
                          },
                          value: randomCode,
                          maxLength: "25",
                          style: { textTransform: "uppercase" },
                        }}
                        //   onChange={(e) => setCode(e.target.value)}
                      />
                      <FormErrorText error={formErrors.couponCode} />
                      <span className={styles.info_text}>
                        Customers will enter this discount code at checkout.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={styles.management_details_contents}>
                    <h4>
                      Warehouse
                      <span className="text-danger" style={{ fontSize: "20px" }}>
                        *
                      </span>  
                    </h4>
                    <div className={styles.management_details_form}>
                      <Select
                        mode="multiple"
                        name="region"
                        style={{ 
                          border: formErrors.region? "#ff7575":"1px solid #d9d9d9",
                          boxShadow: formErrors.region?"0 0 3px 1px #ff7575":'',
                          width: "100%"
                        }} 
                        placeholder="Select Warehouse"
                        onChange={(e) => handleRegionChange(e)}
                        optionLabelProp="label"
                        value={region}
                        autoComplete="none"
                      >
                        {regions?.length > 1 && <Option value="All Warehouse">All Warehouse</Option>}
                        {regions.map((region) => (
                          <Option value={region._id} label={region.name}>
                            <div className="demo-option-label-item">
                              {region.name}
                            </div>
                          </Option>
                        ))}
                      </Select>
                      <FormErrorText error={formErrors.region} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <div className={styles.management_details_contents_header}>
                    <h4>
                      Name
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </h4>
                  </div>

                  <div className={styles.management_details_form}>
                    <InputComponent
                      className="form-control-lg"
                      formControl={{
                        name: "ruleName",
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.ruleName) {
                              formErrors.ruleName = {};
                            }
                            formErrors.ruleName = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.ruleName) {
                              formErrors.ruleName = "";
                            }
                          }

                          handleOfferChange(e);
                        },
                        value: ruleName,
                       
                      }}
                      error={formErrors.ruleName}
                    />
                    <FormErrorText error={formErrors.ruleName} />
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h4>
                    Status
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <div className={styles.management_details_form}>
                    <Select
                      style={{ width: "100%" }}
                      value={
                        ruleStatus == 1
                          ? "Approve"
                          : ruleStatus == 2 && "Reject"
                      }
                      onChange={handleChange}
                    >
                      <Option value="1">Approve</Option>
                      <Option value="2">Reject</Option>
                    </Select>
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h4>
                    Type
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <div className={styles.management_details_form}>
                    <div
                      className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                    >
                      <input
                        type="radio"
                        id="rd_1"
                        name="0"
                        className="custom-control-input"
                        checked={currenttype == "0"}
                        onClick={(e) => handleOfferType(e)}
                      />
                      <label className="custom-control-label" for="rd_1">
                        <span>Percentage</span>
                      </label>
                    </div>
                    <div
                      className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                    >
                      <input
                        type="radio"
                        id="rd_2"
                        name="1"
                        className="custom-control-input"
                        checked={currenttype == "1"}
                        onClick={(e) => handleOfferType(e)}
                      />
                      <label className="custom-control-label" for="rd_2">
                        <span>Fixed amount</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <div className={styles.management_details_contents}>
                    <h4>
                      Value
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </h4>

                    <div className={styles.management_details_form}>
                      <InputComponent
                        className="form-control-lg"
                        formControl={{
                          maxlength: currenttype == "0" ? 2 : 20,
                          type: "text",
                          name: "offerAmount",
                          onChange: (e) => {
                            if (!onlyNumbers.test(e.target.value)) {
                              if (!formErrors.offerAmount) {
                                formErrors.offerAmount = {};
                              }
                              formErrors.offerAmount = "Enter only numbers";
                            } else {
                              if (formErrors.offerAmount) {
                                formErrors.offerAmount = "";
                              }
                            }
                            if (
                              currenttype == "0" &&
                              !onlyThreeNumbers.test(e.target.value)
                            ) {
                              formErrors.offerAmount =
                                "Enter only 2 numbers within 100";
                            }

                            handleOfferChange(e);
                          },
                          value: offerAmount,
                          maxLength: "5",
                        }}
                        error={formErrors.offerAmount}
                      />
                      <FormErrorText error={formErrors.offerAmount} />
                    </div>
                  </div>

                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_form}>
                      <label>Applies to</label>
                      {/* <ButtonComponent
                        className="rnd add_discountpost"
                        style={{ cursor: "pointer" }}
                      >
                        Post
                      </ButtonComponent> */}
                      <div
                        className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                      >
                        <input
                          type="radio"
                          id="rd_5"
                          name="all"
                          className="custom-control-input"
                          //   value="1"
                          checked={showCategory == "all"}
                          onClick={(e) => handleCategory(e)}
                        />
                        <label className="custom-control-label" for="rd_5">
                          <span>All products</span>
                        </label>
                      </div>

                      <div
                        className={`custom-control custom-radio ${styles.custom_radio}`}
                      >
                        <input
                          type="radio"
                          id="rd_6"
                          name="specific"
                          className="custom-control-input"
                          //   value="0"
                          checked={showCategory == "specific"}
                          onClick={(e) => handleCategory(e)}
                        />
                        <label className="custom-control-label" for="rd_6">
                          <span>Specific categories</span>
                        </label>
                      </div>
                      {showCategory == "specific" && (
                        <div className={styles.management_details_form}>
                          <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Select one category"
                            onChange={(e) => handleSingleCategory(e)}
                            optionLabelProp="label"
                            value={categories}
                          >
                            {parentCategories.map((category) => (
                              <Option
                                value={category.name}
                                label={category.name}
                              >
                                <div className="demo-option-label-item">
                                  {category.name}
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`custom-control custom-radio ${styles.custom_radio}`}
                      >
                        <input
                          type="radio"
                          id="rd_7"
                          name="specificp"
                          className="custom-control-input"
                          //   value="0"
                          checked={showCategory == "specificp"}
                          onClick={(e) => handleCategory(e)}
                        />
                        <label className="custom-control-label" for="rd_7">
                          <span>Specific products</span>
                        </label>
                      </div>
                      {showCategory == "specificp" && (
                        <div className={styles.management_details_form}>
                          <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Select one product"
                            onChange={(e) => handleSingleProduct(e)}
                            optionLabelProp="label"
                            value={products}
                          >
                            {parentProducts.map((product) => (
                              <Option value={product.name} label={product.name}>
                                <div className="demo-option-label-item">
                                  {product.name}
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div
                        className={`custom-control custom-radio ${styles.custom_radio}`}
                      >
                        <input
                          type="radio"
                          id="rd_8"
                          name="specificc"
                          className="custom-control-input"
                          //   value="0"
                          checked={showCategory == "specificc"}
                          onClick={(e) => handleCategory(e)}
                        />
                        <label className="custom-control-label" for="rd_8">
                          <span>Specific collections</span>
                        </label>
                      </div>
                      {showCategory == "specificc" && (
                        <div className={styles.management_details_form}>
                          <Select
                            mode="single"
                            style={{ width: "100%" }}
                            placeholder="Select one collection"
                            onChange={(e) => handleSingleCollection(e)}
                            optionLabelProp="label"
                            value={collections}
                          >
                            {parentCollections.map((collection) => (
                              <Option
                                value={collection._id}
                                label={collection.title}
                              >
                                <div className="demo-option-label-item">
                                  {collection.title}
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h4>
                    Minimum Amount
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <div className={styles.management_details_form}>
                    <div
                      className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                    >
                      <input
                        type="radio"
                        id="none"
                        name="none"
                        className="custom-control-input"
                        checked={currentMinAm == 0 && currentMinAm !== ""}
                        value={0}
                        onChange={(e) => handleminAmountType(e)}
                      />
                      <label className="custom-control-label" for="none">
                        <span>None</span>
                      </label>
                    </div>
                    <div
                      className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                    >
                      <input
                        type="radio"
                        id="minAmount"
                        name="minAmount"
                        className="custom-control-input"
                        checked={currentMinAm > 0 || currentMinAm === ""}
                        value="1"
                        onChange={(e) => handleminAmountType(e)}
                      />
                      <label className="custom-control-label" for="minAmount">
                        <span>Minimum purchase amount</span>
                      </label>
                      {(currentMinAm > 0 || currentMinAm === "") && (
                        <div className={styles.management_details_form}>
                          <input
                            style={{ width: "100%" }}
                            placeholder="Enter amount"
                            onChange={(e) => handleMinAmount(e)}
                            value={currentMinAm}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <ButtonComponent
                    className="rnd add_discountpost"
                    style={{ cursor: "pointer" }}
                  >
                    Post
                  </ButtonComponent>
                  <h4>Usage limits</h4>
                  <div className={styles.management_details_form}>
                    <Radio>
                      Limit number of times this discount can be used in total
                    </Radio>
                    <Radio>Limit to one use per customer</Radio>
                  </div>
                </div> */}
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h5>
                    Validity
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h5>

                  <div className={styles.management_details_form}>
                    <div className="row mb-2 justify-content-start">
                      <div className={`col-lg-6 ${styles.input_img}  mb-3`}>
                        <label>Start date</label>

                        <InputComponent
                          className="form-control-sm"
                          error={formErrors.startDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          max={moment(sdate)
                            .add(1, "years")
                            .format("YYYY-MM-DD")}
                          formControl={{
                            name: "startDate",
                            type: "date",
                            style: {
                              cursor: "pointer",
                              padding: "5px",
                              textTransform: "uppercase",
                            },
                            value: sdate,
                            onChange: (e) => handlefirstdate(e),
                          }}
                        />

                        <FormErrorText
                          error={formErrors.startDate ? "Required" : ""}
                        />
                      </div>

                      <div className={`col-lg-6 ant_time_pick_input mb-3`}>
                        <label className={`${styles.label_block} `}>
                          Start time
                          {/* <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          *
                        </span> */}
                        </label>
                        <TimePicker
                          error={formErrors.startTime}
                          // use12Hours
                          format="HH:mm"
                          name="startTime"
                          onChange={onChangeTimeHandlerStart}
                          value={startTime && moment(startTime, "HH:mm")}
                        />
                      </div>

                      {endDateSelect == true && (
                        <div className={`row mr-0 ml-0 full_width`}>
                          <div className={`col-lg-6 ${styles.input_img}`}>
                            <label>End date</label>

                            <InputComponent
                              className="form-control-sm"
                              error={formErrors.endDate}
                              min={moment(new Date()).format("YYYY-MM-DD")}
                              max={moment(edate)
                                .add(1, "years")
                                .format("YYYY-MM-DD")}
                              formControl={{
                                name: "endDate",
                                type: "date",
                                // value: newCategory.name,
                                style: {
                                  cursor: "pointer",
                                  padding: "5px",
                                  textTransform: "uppercase",
                                },
                                value: edate,
                                onChange: (e) => handlelastdate(e),
                              }}
                            />
                            <FormErrorText
                              error={formErrors.endDate ? "Required" : ""}
                            />
                          </div>

                          <div className="col-lg-6 ant_time_pick_input mb-3">
                            <label className={`${styles.label_block} `}>
                              End time
                              {/*  <span
                          className="text-danger"
                          style={{ fontSize: "20px" }}
                        >
                          *
                        </span> */}
                            </label>

                            <TimePicker
                              error={formErrors.endTime}
                              name="endTime"
                              // use12Hours
                              format="HH:mm"
                              onChange={onChangeTimeHandlerEnd}
                              value={endTime && moment(endTime, "HH:mm")}
                            />
                          </div>

                          <span
                            style={{
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {/* {errorMessage} */}
                          </span>
                        </div>
                      )}

                      {/* <div className="col-lg-12">
                        <div className={styles.checkbox}>
                          <Checkbox
                            onChange={onDateSelect}
                            checked={endDateSelect}
                          >
                            Set end date
                          </Checkbox>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h4>
                    Usage limit total
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <div className={styles.management_details_form}>
                    {/*  <input
                      type="text"
                      className={`form-control form-control-lg ${styles.form_control}`}
                      name="usesPerCoupon"
                      onChange={(e) => handleOfferChange(e)}
                      value={discountDetails.usesPerCoupon}
                      maxLength="3"
                    />  */}
                    <InputComponent
                      className={`form-control-lg`}
                      formControl={{
                        type: "number",
                        name: "usesPerCoupon",
                        onChange: (e) => {
                          if (!onlyNumbers.test(e.target.value)) {
                            if (!formErrors.usesPerCoupon) {
                              formErrors.usesPerCoupon = {};
                            }
                            formErrors.usesPerCoupon = "Enter only numbers";
                          } else {
                            if (formErrors.usesPerCoupon) {
                              formErrors.usesPerCoupon = "";
                            }
                          }

                          handleOfferChange(e);
                        },
                        maxLength: "3",
                        value: usesPerCoupon,
                      }}
                      error={formErrors.usesPerCoupon}
                    />
                    <FormErrorText error={formErrors.usesPerCoupon} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
                >
                  <div className={styles.management_details_contents_header}>
                    <h4>Summary</h4>
                    <p className={styles.summaru_active}>
                      {ruleStatus == "1" ? "Active" : "Inactive"}
                    </p>
                  </div>
                  <div className={`${styles.summaru_active_pera}`}>
                    {" "}
                    <p>{randomCode.toUpperCase()}</p>
                    <p>
                      {currenttype == "1" && offerAmount
                        ? "Rs." +
                          offerAmount +
                          ` for ${
                            showCategory == "all"
                              ? "all products"
                              : showCategory == "specific"
                              ? selectedCategories.length > 0
                                ? selectedCategories
                                : "specific categories"
                              : showCategory == "specificp"
                              ? selectedProducts.length > 0
                                ? selectedProducts
                                : "specific products"
                              : showCategory == "specificc" &&
                                selectedCollections.length > 0
                              ? selectedCollections
                              : "specific collections"
                          }`
                        : currenttype == "0" &&
                          offerAmount &&
                          offerAmount +
                            "%" +
                            ` for ${
                              showCategory == "all"
                                ? "all products"
                                : showCategory == "specific"
                                ? selectedCategories.length > 0
                                  ? selectedCategories
                                  : "specific categories"
                                : showCategory == "specificp"
                                ? selectedProducts.length > 0
                                  ? selectedProducts
                                  : "specific products"
                                : showCategory == "specificc" &&
                                  selectedCollections.length > 0
                                ? selectedCollections
                                : "specific collections"
                            }`}
                    </p>
                    <p>
                      {currentMinAm == "0"
                        ? "For everyone"
                        : currentMinAm >= 1 && minAmount > 0
                        ? "Minimum purchase of Rs." + minAmount
                        : "Minimum purchase of Rs.0"}
                    </p>
                    <p>
                      {usesPerCustomer && `${usesPerCustomer} use per customer`}
                    </p>
                    <p>
                      {sdate != "Invalid date"
                        ? `Active from ${moment(sdate).format(
                            "DD/MM/YYYY"
                          )} till ${moment(edate).format("DD/MM/YYYY")}`
                        : sdate == "Invalid date" && ""}
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
                >
                  <div className={styles.management_details_contents_header}>
                    <h4>Description</h4>
                  </div>
                  <textarea
                    className={`${commonstyles.form_control} form-control form-control-lg `}
                    placeholder="Type your description"
                    rows="4"
                    autoComplete="off"
                    name="description"
                    onChange={(e) => handleOfferChange(e)}
                    value={description}
                    // maxLength="50"
                  />
                </div>
                {persistType == "1" && (
                  <div
                    className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                  >
                    <div className={styles.upload_img_header}>
                      <h5>
                        Banner
                        {/* <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span> */}
                      </h5>
                    </div>

                    <div className={styles.management_details_contents}>
                      <div className={styles.upload_img_header}></div>
                      <div
                        className={`${commonstyles.management_details_form} ${styles.add_image_card}`}
                      >
                        {url ? (
                          <>
                            <ImagePreviewChip
                              url={url}
                              className={styles.added_image}
                              width="60px"
                              height="60px"
                              handleClose={removeFile}
                            />
                          </>
                        ) : (
                          <div className={` ${styles.upload_img} text-center`}>
                            <div>
                              <label
                                htmlFor="categoryFileInput"
                                className={`${styles.btn} px-2 `}
                              >
                                Add file
                                <InputComponent
                                  className="form-control-lg"
                                  formControl={{
                                    type: "file",
                                    name: "url",
                                    accept:
                                      "image/jpeg, image/png, image/svg+xml",
                                    id: "categoryFileInput",
                                    onChange: (e) => handleImageInput(e),
                                  }}
                                  // error={formErrors.banner}
                                />
                                {/* <FormErrorText
                                error={formErrors.banner ? "Required" : ""}
                              /> */}
                              </label>
                            </div>

                            <span>or drop a file to Upload</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
                >
                  <h4>
                    Usage limit per user
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <div className={styles.management_details_form}>
                    {/* <input
                      type="text"
                      className={`form-control form-control-lg ${styles.form_control}`}
                      name="usesPerCustomer"
                      onChange={(e) => handleOfferChange(e)}
                      value={discountDetails.usesPerCustomer}
                      maxLength="2"
                    />  */}
                    <InputComponent
                      className={`form-control-lg`}
                      formControl={{
                        type: "number",
                        name: "usesPerCustomer",
                        onChange: (e) => {
                          if (!onlyNumbers.test(e.target.value)) {
                            if (!formErrors.usesPerCustomer) {
                              formErrors.usesPerCustomer = {};
                            }
                            formErrors.usesPerCustomer = "Enter only numbers";
                          } else {
                            if (formErrors.usesPerCustomer) {
                              formErrors.usesPerCustomer = "";
                            }
                          }

                          handleOfferChange(e);
                        },
                        maxLength: "2",
                        value: usesPerCustomer,
                      }}
                      error={formErrors.usesPerCustomer}
                    />
                    <FormErrorText error={formErrors.usesPerCustomer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-left">
              {/* <ButtonComponent
                className="rnd  pl-4 pr-4  theme-btn-outlined"
                style={{ cursor: "pointer" }}
              >
                Draft
              </ButtonComponent> */}
            </div>
           
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
