import React from "react";
import ProfileImage from "../../../assets/images/profile_dummy.svg";
import commonstyles from "../../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { notification } from "antd";
import styles from "../../../styles/EditUser.module.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import validationRules from "../../../utils/validationRules";
import adminEdit from "../../../assets/images/admin_product_edit_icon.svg";
import {
  onlyAlphabetsWithSpace,
  phoneRegx,
  passwordReg,
  minChar,
  userName,
} from "../../../utils/RegularExp";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { upperFirst } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import customerProfile from "../../../assets/images/customer_profile.svg";
import cameraIcon from "../../../assets/images/camera_icon.svg";

const ProfileEdit = (props) => {
  const { Detail, setDetails, dispName } = props;
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();

  const [detailsform, setDetailsForm] = useState({});

  const [editState, setEditState] = useState(true);

  const [formErrors, setFormErrors] = useState({});

  const [passState, setPassState] = useState(false);

  const [newpassword, setNewpassword] = useState("");

  const [confirmpassword, setConfirmpassword] = useState("");

  const [loading, setLoading] = useState({ details: false, update: false });

  const [password, setPassword] = useState("");

  const rule1 = {
    name: [validationRules.required],
  };

  const rule2 = {
    name: [validationRules.required],
    newpassword: [validationRules.required],
    confirmpassword: [validationRules.required],
    password: [validationRules.required],
  };

  const [rules, setRules] = useState({
    name: [validationRules.required],
  })

  // const rules = {
  //   name: [validationRules.required],
  //   newpassword: [validationRules.required],
  //   confirmpassword: [validationRules.required],
  //   password: [validationRules.required],
  // };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  useEffect(() => {
    setDetailsForm(Detail)
  }, [Detail])

  function activeEdit() {
    setEditState(!editState);
    setPassState(false);
    setRules(rule1);
  }

  function changePassword() {
    setEditState(true);
    setPassState(!passState);
    setRules(rule2);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  /**
   * @author Mithun Prabhu
   * @param {}
   * UPDATE PROFULE DETAILS
   */
  function editProfileDetails() {
    if (!formErrors.name) {
      let data = {};

      if (passState) {
        data = {
          id: detailsform._id,
          name: detailsform.name.trim(),
          newpassword: newpassword,
          confirmpassword: confirmpassword,
          password: password,
        };
      } else {
        data = {
          id: detailsform._id,
          name: detailsform.name.trim(),
        };
      }

      const result = validate(undefined, data);

      // console.log(result)

      if (Object.keys(result).length > 0) {
        setFormErrors(result);
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
        return;
      }

      if (!onlyAlphabetsWithSpace.test(name)) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Enter only alphabets in name field",
        });
        return;
      }

      if (newpassword || confirmpassword) {
        if (
          !passwordReg.test(password)
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description:
              "Please enter valid old password",
          });
          return;
        }
      }

      if (password) {
        if (
          !passwordReg.test(newpassword) ||
          !passwordReg.test(confirmpassword)
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description:
              "Please enter valid password in new password and confirm password",
          });
          return;
        }
      }



      if (password || newpassword || confirmpassword) {
        if (
          !passwordReg.test(newpassword) ||
          !passwordReg.test(confirmpassword)
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description:
              "At least 1 number, upper & lower case and special character",
          });
          return;
        }
      }
      setFormErrors([]);

      const formData = new FormData();

      formData.append("data", JSON.stringify(data));

      if (detailsform?.profileImg?.name) {
        formData.append("profileImg", detailsform?.profileImg);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      setLoading({ details: true, update: true });
      AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .post(`admin/profileUpdate`, formData, config)
        .then((res) => {
          if (res.data.status === 200) {
            if (passState) {
              setNewpassword("");
              setConfirmpassword("");
              setPassword("");
              showNotification({
                type: "success",
                message: "Success",
                description: "Password updated successfully",
              });
            } else {
              showNotification({
                type: "success",
                message: "Success",
                description: "Profile updated successfully",
              });
            }
            props.getDetails();
            getDetails();
            // setEditState(true);
            setPassState(false);

            // history.push(`/site-settings/profile`);
          } else if (
            res.data.status === 400 &&
            res.data.msg === "Old Password not matched"
          ) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: "Old Password not matched",
            });
            return;
          } else if (
            res.data.status === 400 &&
            res.data.msg === "New password and confirm password not match"
          ) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: "New password and confirm password not match",
            });
            return;
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });
            return;
          } else {
            showNotification({
              type: "Error",
              message: "Error",
              description: "Couldnt fetch required data",
            });
            return;
          }
        })
        .catch((er) => {
          return er;
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function getDetails() {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`admin/profileDetails`)
      .then((res) => {
        if (res.data.status === 200) {
          setDetailsForm(res.data.data);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {

      });
  }

  /**
   * @author Mithun Prabhu
   * @param {name,data}
   * VALIDATE PROFILE DETAILS
   */
  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];

      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  const onPasswordVisible1 = () => {
    setVisible1(!visible1);
  };

  const onPasswordVisible2 = () => {
    setVisible2(!visible2);
  };

  const onPasswordVisible3 = () => {
    setVisible3(!visible3);
  };

  function handleImageInput(e) {
    // activeEdit();
    const { name, files } = e.target;
    const file_name = files[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (files[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        setDetailsForm({ ...detailsform, profileImg: files[0] });
      }
    }
    // let image = e.target.files[0];
    // editProfileDetailsImage(image)
  }

  function editProfileDetailsImage(imagefile) {
    let data = {};

    if (passState) {
      data = {
        id: detailsform._id,
        name: detailsform.name.trim(),
        newpassword: newpassword,
        confirmpassword: confirmpassword,
        password: password,
      };
    } else {
      data = {
        id: detailsform._id,
        name: detailsform.name.trim(),
      };
    }

    // const result = validate(undefined, data);


    const formData = new FormData();

    formData.append("data", JSON.stringify(data));

    if (imagefile) {
      formData.append("profileImg", imagefile);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    setLoading({ details: true, update: true });
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post(`admin/profileUpdate`, formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          if (passState) {
            setNewpassword("");
            setConfirmpassword("");
            setPassword("");
            showNotification({
              type: "success",
              message: "Success",
              description: "Password updated successfully",
            });
          } else {
            showNotification({
              type: "success",
              message: "Success",
              description: "Profile updated successfully",
            });
          }
          props.getDetails();
          getDetails();
          setPassState(false);
          // history.push(`/site-settings/profile`);
        } else if (
          res.data.status === 400 &&
          res.data.msg === "Old Password not matched"
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: "Old Password not matched",
          });
          return;
        } else if (
          res.data.status === 400 &&
          res.data.msg === "New password and confirm password not match"
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: "New password and confirm password not match",
          });
          return;
        } else if (res.data.status === 400) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: res.data.msg,
          });
          return;
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
          return;
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });

  }

  function removeFile(name) {
    setDetailsForm({ ...detailsform, profileImg: null });
    // editProfileDetailsImage()
  }

  return (
    <>
      <div className={commonstyles.profile_display}>
        <div className={commonstyles.profile_header}>
          <h4>Profile</h4>
        </div>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "SiteSettings-General-edit") ===
          "SiteSettings-General-edit" && (
            <div className="mr-5">
              {!editState && (
                <div
                  className={commonstyles.management_contents_upload}
                  style={{ float: "right" }}
                >
                  <ButtonComponent
                    className="cnc"
                    attributes={{
                      onClick: activeEdit,
                    }}
                  >
                    Cancel
                  </ButtonComponent>

                  <ButtonComponent
                    className="rnd"
                    attributes={{
                      disabled: loading.update,
                      onClick: editProfileDetails,
                    }}
                  >
                    {loading.update ? "Saving" : "Save"}
                  </ButtonComponent>
                </div>
              )}
            </div>
          )}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "SiteSettings-General-edit") ===
          "SiteSettings-General-edit" && (
            <div className={` row ${commonstyles.profile_details}`}>
              <div className={`col-md-2 ${commonstyles.profile_image}`}>
                {/* {detailsform?.profileImg ? (
                  <img src={detailsform?.profileImg} />
                ) : (
                  <img src={ProfileImage} />
                )} */}
                {(editState) ? (
                  detailsform?.profileImg &&
                  <ImagePreviewChip
                    url={detailsform?.profileImg?.name ? URL.createObjectURL(detailsform?.profileImg) : detailsform?.profileImg}
                    hideCloseIcon={true}
                  />
                ) : (!editState && detailsform?.profileImg) ? (
                  <ImagePreviewChip
                    url={detailsform?.profileImg?.name ? URL.createObjectURL(detailsform?.profileImg) : detailsform?.profileImg}
                    handleClose={() => removeFile("image")}
                  />
                ) : (!editState && !detailsform?.profileImg) && (
                  <div className={styles.upload_img + " text-center "}>
                    {/* <img src={customerProfile} className={styles.profile_img} alt="profile_img" /> */}
                    <div>
                      <label
                        htmlFor="imageInput"
                        className={`px-2 cursor-pointer`}
                      >
                        <img src={cameraIcon} className={styles.camera_img} alt='camera' />
                      </label>
                      <input
                        name="image"
                        className="d-none"
                        type="file"
                        accept="image/jpeg, image/png, image/svg+xml"
                        id="imageInput"
                        onInput={handleImageInput}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={`col-md-10 ${commonstyles.profile_detail}`}>
                <div className={` ${commonstyles.list} row`}>
                  <div className="col-md-2">User name:</div>
                  <div className="col-md-8">
                    {editState ? (
                      <div>
                        {upperFirst(dispName)}
                      </div>
                    ) : (
                      <>
                        <InputComponent
                          className="form-control form-control-lg form_control"
                          error={formErrors.name}
                          style={{ textTransform: "capitalize" }}
                          formControl={{
                            name: "name",
                            value: detailsform.name,
                            maxLength: 40,
                            onChange: (e) => {
                              if (!minChar.test(e.target.value)) {
                                if (!formErrors.name) {
                                  formErrors.name = {};
                                }
                                formErrors.name = "Enter minimum 3 characters";
                              } else if (!userName.test(e.target.value)) {
                                if (!formErrors.name) {
                                  formErrors.name = {};
                                }
                                formErrors.name = "Enter valid name";
                              } else {
                                if (formErrors.name) {
                                  formErrors.name = "";
                                }
                              }
                              setDetails("name", e.target.value);
                            },
                          }}
                        />
                        <FormErrorText error={formErrors.name} />
                      </>
                    )}
                  </div>
                  <div
                    className="col-md-2 text-center"
                    style={{ cursor: "pointer" }}
                    onClick={activeEdit}
                  >
                    {editState && <img src={adminEdit} alt="Edit" />}
                  </div>
                </div>
                <div className={` ${commonstyles.list} row`}>
                  <div className="col-md-2">Email:</div>
                  <div className="col-md-8">
                    <div>{detailsform.email}</div>
                  </div>
                </div>
                <div className={` ${commonstyles.list} row`}>
                  <div className="col-md-2">Phone:</div>
                  <div className="col-md-8">
                    <div>+91 {detailsform.phone}</div>
                  </div>
                </div>
                {/* <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-2">
                                Address: 
                            </div>
                            <div className="col-md-8">
                                    <div ><p>22 'A' main, 1st stage, 2nd cross, JP Nagar. near Guru Kula apartments, Bengaluru - 560048, Karnataka,India,</p></div> 
                            </div>
                        </div> */}
                {/* <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-3">
                                Address : 
                            </div>
                            <div className="col-md-7">
                                { editState ? 
                                    <div > 22 'A' main, 1st stage, 2nd cross, JP Nagar. near Guru Kula apartments, Bengaluru - 560048, Karnataka,India,</div> : 
                                    <>
                                    <InputComponent
                                        className="form-control form-control-lg form_control"
                                        formControl={{
                                        name: "address",
                                        value: " 22 'A' main, 1st stage, 2nd cross, JP Nagar. near Guru Kula apartments, Bengaluru - 560048, Karnataka,India,",
                                        maxLength: 20 }}
                                    />
                                    </> 
                                }
                            </div>
                        </div> */}
                {!passState && (
                  <div className={` ${commonstyles.list} row`}>
                    <div className="col-md-2"></div>
                    <div className="col-md-10">
                      <div
                        onClick={changePassword}
                        className={commonstyles.link}
                      >
                        Change password
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {passState && (
                <>
                  <div className={` ${commonstyles.profile_password} row`}>
                    <div
                      className={` ${commonstyles.profile_password_hr} col-md-12`}
                    >
                      <p></p>
                    </div>
                    <div
                      className={` ${commonstyles.profile_header} col-md-12 `}
                    >
                      <h4>Change password</h4>
                    </div>
                    <div
                      className={` ${commonstyles.management_details} col-md-6`}
                    >
                      <div className={commonstyles.management_details_contents}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div
                              className={commonstyles.management_details_form}
                            >
                              <label for="">Old Password</label>
                              <InputComponent
                                className="form-control form-control-lg form_control"
                                error={formErrors.password}
                                formControl={{
                                  type: visible1 ? "text" : "password",
                                  visible1: visible1,
                                  onPasswordVisible1: onPasswordVisible1,
                                  name: "companyId",
                                  value: password,
                                  placeholder: "-",
                                  onChange: (e) => {
                                    if (!passwordReg.test(e.target.value)) {
                                      if (!formErrors.password) {
                                        formErrors.password = {};
                                      }
                                      formErrors.password =
                                        "At least 1 number, upper & lower case and special character and minimum 8 characters";
                                    } else {
                                      if (formErrors.password) {
                                        formErrors.password = "";
                                      }
                                    }
                                    setPassword(e.target.value);
                                  },
                                }}
                              />

                              <FormErrorText error={formErrors.password} />
                              <span
                                id="password"
                                className="pass_eye_icon"
                                onClick={onPasswordVisible1}
                                style={{
                                  position: "absolute",
                                  top: "50px",
                                  right: "50px",
                                }}
                              >
                                <img
                                  src={visible1 ? eyeClose : eyeOpen}
                                  alt="visible"
                                  onClick={onPasswordVisible1}
                                  className={styles.eyeIcon}
                                />
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div
                              className={commonstyles.management_details_form}
                            >
                              <label for="">New Password</label>
                              <InputComponent
                                className="form-control form-control-lg form_control"
                                error={formErrors.newpassword}
                                formControl={{
                                  type: visible2 ? "text" : "password",
                                  visible2: visible2,
                                  onPasswordVisible2: onPasswordVisible2,
                                  name: "companyId",
                                  value: newpassword,
                                  placeholder: "-",
                                  onChange: (e) => {
                                    if (!passwordReg.test(e.target.value)) {
                                      if (!formErrors.newpassword) {
                                        formErrors.newpassword = {};
                                      }
                                      formErrors.newpassword =
                                        "At least 1 number, upper & lower case and special character and minimum 8 characters";
                                    } else {
                                      if (formErrors.newpassword) {
                                        formErrors.newpassword = "";
                                      }
                                    }
                                    setNewpassword(e.target.value);
                                  },
                                }}
                              />
                              <FormErrorText error={formErrors.newpassword} />
                              <span
                                id="password"
                                className="pass_eye_icon"
                                onClick={onPasswordVisible2}
                                style={{
                                  position: "absolute",
                                  top: "50px",
                                  right: "50px",
                                }}
                              >
                                <img
                                  src={visible2 ? eyeClose : eyeOpen}
                                  alt="visible"
                                  onClick={onPasswordVisible2}
                                  className={styles.eyeIcon}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div
                              className={commonstyles.management_details_form}
                            >
                              <label for="">Confirm Password</label>
                              <InputComponent
                                className="form-control form-control-lg form_control"
                                error={formErrors.confirmpassword}
                                formControl={{
                                  type: visible3 ? "text" : "password",
                                  visible3: visible3,
                                  onPasswordVisible3: onPasswordVisible3,
                                  name: "companyId",
                                  placeholder: "-",
                                  value: confirmpassword,
                                  onChange: (e) => {
                                    if (newpassword !== e.target.value) {
                                      formErrors.confirmpassword =
                                        "Confirm password should be same as new password";
                                    } else {
                                      formErrors.confirmpassword = "";
                                    }

                                    setConfirmpassword(e.target.value);
                                  },
                                }}
                              />
                              <FormErrorText
                                error={formErrors.confirmpassword}
                              />
                              <span
                                id="password"
                                className="pass_eye_icon"
                                onClick={onPasswordVisible3}
                                style={{
                                  position: "absolute",
                                  top: "50px",
                                  right: "50px",
                                }}
                              >
                                <img
                                  src={visible3 ? eyeClose : eyeOpen}
                                  alt="visible"
                                  onClick={onPasswordVisible3}
                                  className={styles.eyeIcon}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className={commonstyles.passwordTypes}>
                        <div className={commonstyles.heading}>
                          Password Must contain:
                        </div>
                        <div className={commonstyles.passpoints}>
                          At least 1 upper Case letter (A-Z)
                        </div>
                        <div className={commonstyles.passpoints}>
                          At least 1 number (0-9)
                        </div>
                        <div className={commonstyles.passpoints}>
                          At least 8 characters
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <div
                        className={commonstyles.management_contents_upload}
                        style={{ float: "right" }}
                      >
                        <ButtonComponent
                          className="cnc"
                          attributes={{
                            onClick: changePassword,
                          }}
                        >
                          Cancel
                        </ButtonComponent>

                        <ButtonComponent
                          className="rnd"
                          attributes={{
                            disabled: loading.update,
                            onClick: editProfileDetails,
                          }}
                        >
                          {loading.update ? "Saving" : "Save"}
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default ProfileEdit;
