import axios from "axios";
import { logout } from "./index";

const baseURL = process.env.REACT_APP_BASE_URL_AWS;

export const MAIN_SITE = "https://damro-next.demo-fsit.com";

export const PORTS = {
  CATALOG_SERVICE: process.env.REACT_APP_ADMIN_CATALOG_SERVICE_PORT,
  REGION_SERVICE: process.env.REACT_APP_ADMIN_REGION_SERVICE_PORT,
  USER_SERVICE: process.env.REACT_APP_ADMIN_USER_SERVICE_PORT,
  ORDER_SERVICE: process.env.REACT_APP_ADMIN_ORDER_SERVICE_PORT,
  CMS: process.env.REACT_APP_ADMIN_CMS,
  INVOICE: process.env.REACT_APP_ADMIN_INVOICE,
  INVOICE_DOWNLOAD: process.env.REACT_APP_DOWNLOAD_INVOICE,
  MARKETING: process.env.REACT_APP_ADMIN_MARKETING,
  DASHBOARD_SERVICE: process.env.REACT_APP_ADMIN_DASHBOARD_SERVICE_PORT,
  NEWSLETTER_SERVICE: process.env.REACT_APP_ADMIN_NEWSLETTER,
};

export const AxiosInstance = (port, contentType) => {
  const Axios = axios.create({
    // baseURL: `http://127.0.0.1:${port}/api/v1/`
    baseURL: `${baseURL}:${port}/api/v1/`,
  });

  Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return Axios;
};
