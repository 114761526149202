import { Divider } from "antd";
import React from "react";
// import InputComponent from "../ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import style from "../../../styles/Login.module.css";
import styles from "../../../styles/NewLogin.module.css";
import banner from "../../../assets/images/modal_banner.png";
import mainLogo from "../../../assets/images/main_logo.png";
import eyeOpen from "../../../assets/images/eye.svg";
import OTPScreen from "react-otp-input";
import { Alert } from "antd";
import Spinner from "react-bootstrap/Spinner";

 /**
   * @author Mithun Prabhu
   * @param {props}
   * TO DISPLAY VERIFY OTP COMPONENT AND GET VALIDATED
   */
const VerifyOTPComponent = (props) => {
  return (
    <div className={`${styles.login_bg} row m-0`}>
      <div className={`${styles.login_otp_box}`} >
        <div className={styles.logo_img}>
          <img src={mainLogo} alt="logo" />
        </div>
        <form onSubmit={props.onVerifyOtp} data-test="login_otp_form">
          <div className={`${styles.login_otp_text}`}>
            <h4 className={style.admin_login_heading}>Verification Code</h4>
            <p>Please enter code sent your mobile number.</p>
          </div>
          {props.error && props.error.message ? (
            <Alert
              className="alert_message"
              closable
              message={props.error.message}
              type={props.error.type}
              showIcon
            />
          ) : (
            ""
          )}
          <div className={style.otp_input_section}>
            <OTPScreen
              className={`otp_input_form ${style.otp_input_field} ${style.otp_input_field_admin}`}
              numInputs={6}
              value={props.otp}
              onChange={props.onChangeOtp}
              separator={<span>-</span>}
            />
            {props.otpError && props.otpError.trim() !== "" ? (
              <span className={style.error} data-test="otp_error">
                {props.otpError}
              </span>
            ) : (
              ""
            )}
          </div>
          {props.show ? (
            <h5 className={`${style.otp_msg} ${style.otp_msg_admin}`}>
              <span
                onClick={props.resend_otp_handler}
                data-test="resend_otp"
              >
                Resend OTP
              </span>
            </h5>
          ) : (
            ""
          )}
          <div className={`${style.otp_login_btn} `}>
            <ButtonComponent
              data={{
                type: "submit",
                className: "form-control login_btn login_btn_admin",
                "data-test": "verify_otp",
              }}
            >
              {props.clickable ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="spinner_btn"
                />
              ) : null}
              Verify
            </ButtonComponent>
          </div>
          <h6 className={`${style.terms} ${style.back_to_login}`}>
            Back to{" "}
            <span
              data-test="sign_in_btn"
              onClick={props.go_back_to_signin}

            >
              LogIn
            </span>
          </h6>
        </form>
      </div>
    </div>
  );
};
export default VerifyOTPComponent;
