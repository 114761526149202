import styles from "../../../styles/AddProduct.module.css";
import ownStyles from "../../../styles/EmbeddedProductsTable.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import Down_arrow from "../../../assets/images/down_arrow.svg";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../utils";
import { useEffect, useRef, useState } from "react";
import PaginateWrapperComponent from "../ReusableComponent/PaginateWrapperComponent";
import NoProductsTableRowComponent from "./NoProductsTableRowComponent";
import ProductChipComponent from "./ProductChipComponent";

const intialFilterState = {
  categoryId: "",
  regionId: "",
  searchKey: "",
  status: "",
  pagesize: 7,
  nextpage: 1,
};

export default function EmbeddedProductsTableComponent(props) {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState);
  const [isMinimal, setIsMinimal] = useState(false);
  const timeoutId = useRef();

  const productIds = Array.isArray(props.products)
    ? props.products.map((p) => p._id)
    : [];
  const filteredSelectedProducts = Array.isArray(props.selectedProducts)
    ? props.selectedProducts.filter((p) => productIds.includes(p._id))
    : [];

  useEffect(() => {

    filterProducts(filter);
  }, [filter.nextpage, props.products]);

  function filterProducts(filter) {
    const { searchKey, pagesize, nextpage } = filter;
    let filtered = [];

    let prds = props.products || [];

    if (searchKey) {
      let query = searchKey.toLowerCase();
      prds = prds.filter((p) => {
        if (p.name.toLowerCase().indexOf(query) > -1) {
          return p;
        }
      });
    }

    let offsetIndex = nextpage <= 1 ? 0 : (nextpage - 1) * pagesize;

    for (let i = offsetIndex; i < prds.length; i++) {
      if (filtered.length >= pagesize) {
        break;
      }
      filtered[filtered.length] = prds[i];
    }
    const paginationData = { ...pagination };
    paginationData.endPage = Math.ceil(prds.length / pagesize);

    setFilteredProducts(filtered);
    setPagination(paginationData);
  }

  function debouncedSearch(filter) {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      filterProducts(filter);
    }, 500);
  }

  function handleInput(e) {
    const { name, value } = e.target;
    const updated = { ...filter, [name]: value };

    if (name === "searchKey") {
      updated.nextpage = 1;
      setFilter(updated);

      debouncedSearch(updated);
    }
  }

  function updateSelection(e, product) {
    const action = e.target.checked ? "add" : "remove";
    props.handleSelection(action, product, props.formField);
  }

  function handlePaginationChanges(value, type) {
    if (type === "nextPage") {
      setFilter({ ...filter, nextpage: value.selected + 1 });
    }
  }

  function handleProductChipClose(product) {
    if (filteredSelectedProducts.length === 1 && isMinimal) {
      setIsMinimal(false);
    }
    props.handleSelection("remove", product, props.formField);
  }


  const selectedProductIDs = filteredSelectedProducts
    ? filteredSelectedProducts.map((pr) => pr._id)
    : [];
  const hideElementClass = isMinimal ? `fade ${ownStyles.hide}` : "";


  return (
    <div
      className={`${commonstyles.management_details_form} position-relative`}
    >
      <div
        className={`${ownStyles.toggle_icon}`}
        onClick={() => setIsMinimal(!isMinimal)}
      >
    
        {isMinimal ? (
          <img src={Down_arrow} title="Expand" />
        ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
      </div>
      <div
        className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClass} mb-4`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            aria-describedby="search-products"
            placeholder="Search products"
            name="searchKey"
            value={filter.searchKey}
            onChange={handleInput}
          />
          <img src={Search} alt="" />
        </div>
      </div>

      <div className={styles.management_table_product}>
        {Array.isArray(filteredSelectedProducts) &&
          filteredSelectedProducts.map((product) => (
            <ProductChipComponent
              key={product._id}
              product={product}
              handleClose={handleProductChipClose}
            />
          ))}
      </div>

      <div
        className={`${commonstyles.management_table} ${ownStyles.animatable_elements} ${hideElementClass}  table-responsive`}
      >
        <table className={`${ownStyles.table} table table-borderless`}>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Product ID</th>
              <th scope="col">Image</th>
              <th scope="col">Product Name</th>
              <th scope="col">SKU</th>
              <th scope="col">Status</th>

            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredProducts) && filteredProducts.length ? (
              filteredProducts.map((product) => (
                <tr key={product._id} className={ownStyles.table_row}>
                  <td>
                    <div
                      className={` ${commonstyles.custom_control} ${commonstyles.custom_checkbox} custom-control custom-checkbox text-center`}
                    >
              
                      <input
                        className={` ${commonstyles.custom_control_input} custom-control-input`}
                        type="checkbox"
                        onChange={(e) => updateSelection(e, product)}
                        checked={selectedProductIDs.includes(product._id)}
                        id={`${props.formField}checkBox${product._id}`}
                      />
                      <label
                        className={`${commonstyles.custom_control_label} custom-control-label cursor-pointer`}
                        htmlFor={`${props.formField}checkBox${product._id}`}
                      ></label>
                    </div>
                  </td>
                
                  <td>{product.tallyId}</td>
          
                  <td>
                    {product.image ? (
                      <img
                        src={product.image}
                        className={commonstyles.admin_table_img}
                        alt="Product image"
                      />
                    ) : "-"}
                  </td>
                 
                  <td>{product.name}</td>
                  
                  <td>{product.sku}</td>
                  <td>{product.enable ? "Active" : "Inactive"}</td>
                </tr>
              ))
            ) : (
              <NoProductsTableRowComponent
                products={filteredProducts}
                filter={filter}
              />
            )}
          </tbody>
        </table>
      </div>
      <div
        className={`${commonstyles.table_footer} ${ownStyles.animatable_elements} ${hideElementClass}`}
      >
        <PaginateWrapperComponent
          nextPage={filter.nextpage}
          data={pagination}
          handleChange={handlePaginationChanges}
        />

        <div className={commonstyles.table_pages}>
          <button
            type="button"
            className={`${commonstyles.btn} btn mr-3`}
            onClick={() => setIsMinimal(true)}
          >
            Collapse
          </button>
          
        </div>
      </div>
    </div>
  );
}
