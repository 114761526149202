import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/AddProduct.module.css";
import ownStyles from "../../../styles/EmbeddedProductsTable.module.css";
import Down_arrow from "../../../assets/images/down_arrow.svg";
import ProductSearchSelectComponent from "./ProductSearchSelectComponent";
import WYSIWYGEditor from "../../modules/ReusableComponent/WYSIWYGEditor";
import { EditorState } from "draft-js";
import FormErrorText from "./FormErrorText";
import { useEffect, useState } from "react";
import { sku } from "../../../utils/RegularExp";

const editorOptions = {
  wrapperClassName: `${commonstyles.form_control} form-control-lg p-1 h-auto`,
  toolbarClassName: "bg-light rounded-lg theme-txt-sm",
  editorClassName: "px-2 mt-3 mb-1 ",
  //placeholder: "Type product features",
  toolbar: {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "emoji",
      "link",
      "textAlign",
      "colorPicker",
      // "embedded",
      // "remove",
      "history",
    ],

    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
      className: "theme-txt-sm",
      component: undefined,
      dropdownClassName: undefined,
    },

    fontSize: {
      icon: undefined,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: "theme-txt-sm",
      component: undefined,
      dropdownClassName: undefined,
    },

    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Spartan",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      className: "theme-txt-sm",
      component: undefined,
      dropdownClassName: "",
    },
  },
};

export default function ProductDetailsFormComponent(props) {
  // console.log(props)
  const [editorState, setEditorState] = useState(null);
  const [isMinimal, setIsMinimal] = useState(true);
  const [isMinimalfea, setIsMinimalfea] = useState(true);
  const [isMinimalhigh, setIsMinimalhigh] = useState(true);
  const [isMinimalmater, setIsMinimalmater] = useState(true);
  const [isMinimalpack, setIsMinimalpack] = useState(true);
  const [isMinimalterms, setIsMinimalterms] = useState(true);

  const { formErrors } = props;

  useEffect(() => {
    setEditorState(() => EditorState.createEmpty());
  }, []);

  function handleChange(e) {
    let { name, value, checked } = e.target;
    // console.log(`${name} : ${value}`);
    const values = props.form;
    if (name === "isParent") {
      // checkbox
      values[name] = checked;
    } else {
      values[name] = value;
    }
    // console.log(values);
    if(name==="sku"){
      value=value.trim();
      if (!e.target.value.match(sku) || !e.target.value === "") {
        formErrors.sku = "Enter valid data";
      }else{
        formErrors.sku = "";
      }
    }
    props.handleProductFormUpdate(values);
  }

  function handleParentIdUpdate(val) {
    const values = props.form;
    values.isParent = false;
    values.parentId = val;
    // console.log(values);
    props.handleProductFormUpdate(values);
  }

  function handleEditorUpdate(htmlContent,formField) {
    const values = props.form;
    values[formField] = htmlContent;
    props.handleProductFormUpdate(values);
  }
 
  const hideElementClass = isMinimal ? `fade ${ownStyles.hide}` : "";
  const hideElementClassfea = isMinimalfea ? `fade ${ownStyles.hide}` : "";
  const hideElementClasshigh = isMinimalhigh ? `fade ${ownStyles.hide}` : "";
  const hideElementClassmater = isMinimalmater ? `fade ${ownStyles.hide}` : "";
  const hideElementClasspack = isMinimalpack ? `fade ${ownStyles.hide}` : "";
  const hideElementClassterms = isMinimalterms ? `fade ${ownStyles.hide}` : "";
  return (
    <div className="col-lg-12 mb-4">
      <h4 className="theme-form-section-title">Product Attribute</h4>
      <div className={`${commonstyles.management_details_form} ml-0`}>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Overview</label>
            <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() =>setIsMinimal(!isMinimal)}
          // onClick={() => setIsMinimal(!isMinimal)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimal ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
            {/* <textarea
              className={`${commonstyles.form_control} ${formErrors.overview && "theme-error-input"
                } form-control`}
              placeholder="Type overview"
              rows="3"
              name="overview"
              value={props.form.overview}
              onChange={handleChange}
            /> */}
            <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClass} mb-4`}
            >
            <WYSIWYGEditor
              content={props.form.overview || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"overview")}}
            />
            <FormErrorText error={formErrors.overview} />
          </div>
          </div>
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Product features</label>
            {/* <textarea
              rows="3"
              name="productFeature"
              value={props.form.productFeature}
              // onChange={handleChange}
              disabled
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type Product Feature"
            /> */}
             <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() => setIsMinimalfea(!isMinimalfea)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimalfea ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
            <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClassfea} mb-4`}
          >
            <WYSIWYGEditor
              content={props.form.productFeature || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"productFeature")}}
            />
            </div>
          </div>
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Product Highlights</label>
            {/* <textarea
              rows="3"
              name="product_highlights"
              value={props.form.product_highlights}
              // onChange={handleChange}
              disabled
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type Product Feature"
            /> */}
           <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() => setIsMinimalhigh(!isMinimalhigh)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimalhigh ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
         <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClasshigh} mb-4`}
          >
            <WYSIWYGEditor
              content={props.form.product_highlights || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"product_highlights")}}
            />
          </div>
          </div>
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Materials Care</label>
            {/* <textarea
              rows="3"
              name="materialCare"
              value={props.form.materialCare}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type Materials Care details"
            /> */}
            <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() => setIsMinimalmater(!isMinimalmater)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimalmater ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
         <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClassmater} mb-4`}
          >
             <WYSIWYGEditor
              content={props.form.materialCare || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"materialCare")}}
            />
          </div>
          </div>
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Packaging</label>
            {/* <textarea
              rows="3"
              name="packaging"
              value={props.form.packaging}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type packaging"
            /> */}
            <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() => setIsMinimalpack(!isMinimalpack)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimalpack ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
         <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClasspack} mb-4`}
          >
            <WYSIWYGEditor
              content={props.form.packaging || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"packaging")}}
            />
          </div>
          </div>
          {/* <div className="col-lg-12 mb-4">
            <label className="theme-form-label">
              Delivery &amp; Installation
            </label>
            <textarea
              rows="3"
              name="deliveryInstallation"
              value={props.form.deliveryInstallation}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type your Delivery &amp; Installation"
            />
          </div> */}
          {/* <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Warranty</label>
            <textarea
              rows="3"
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type your Warrenty"
              name="warranty"
              value={props.form.warranty}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">T&amp;C</label>
            {/* <textarea
              rows="3"
              name="termsConditions"
              value={props.form.termsConditions}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type T&amp;C"
            /> */}
             <div
           className={`${ownStyles.toggle_icon}`}
           onClick={() => setIsMinimalterms(!isMinimalterms)}
           >
           {/* ${ selectedProductIDs.length === 0 ? "fade" : ""} */}
           {isMinimalmater ? (
           <img src={Down_arrow} title="Expand" />
           ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
         </div>
         <div
            className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClassterms} mb-4`}
          >
            <WYSIWYGEditor
              content={props.form.termsConditions || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"termsConditions")}}
            />
            </div>
          </div>
          <div className="col-lg-12 mb-4">
            <label className="theme-form-label">Keywords</label>
            <textarea
              // rows="3"
              name="keywords"
              value={props.form.keywords}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type keywords"
            />
            {/* <WYSIWYGEditor
              content={props.form.keywords || ""}
              options={editorOptions}
              onEditorStateChange={(content)=>{handleEditorUpdate(content,"keywords")}}
            /> */}
          </div>
          {/* <div className="col-lg-6 mb-4">
            <label className="theme-form-label">Product Feature</label>
            <input
              type="text"
              name="productFeature"
              value={props.form.productFeature}
              onChange={handleChange}
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="Type Product Feature"
            />
          </div> */}
          {!props.isSetProduct&&<div className="col-lg-5 mb-4">
            <label className="theme-form-label">
              SKU
              <span className="text-danger" style={{ fontSize: "20px" }}>
                *
              </span>
            </label>
            <input
              type="text"
              name="sku"
              value={props.form.sku}
              onChange={handleChange}
              className={`${commonstyles.form_control} ${
                formErrors.sku && "theme-error-input"
              } form-control`}
              placeholder="Type Sku number"
              disabled ="true"
            />
            <FormErrorText error={formErrors.sku} />
          </div>}
          {!props.isSetProduct&&<div className="col-lg-3 mb-4">
            <label className="theme-form-label">
              is Parent
              <span className="ml-1">
                {props.form?.isParent
                  ? "  ( Configurable Product )"
                  : "  ( Simple Product )"}
              </span>
            </label>
            <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
              <input
                type="checkbox"
                // name="onoffswitch"
                name="isParent"
                checked={props.form.isParent}
                onChange={handleChange}
                className={styles.onoffswitch_checkbox}
                id="switchIsParent"
              />
              <label
                className={styles.onoffswitch_label}
                htmlFor="switchIsParent"
              >
                <span className={styles.onoffswitch_inner}></span>
                <span className={styles.onoffswitch_switch}></span>
              </label>
            </div>
          </div>}
          {!props?.form?.isParent&&!props.isSetProduct&&<div
            className={` ${props.form.isParent ? "fade" : ""} col-lg-4 mb-4`}
          >
            <label className="theme-form-label">
              Choose Parent
              {/* <span className="text-danger" style={{ fontSize: "20px" }}>
                *
              </span> */}
            </label>
            <ProductSearchSelectComponent
             // error={formErrors.parentId}
              value={props.form.parentId || ""}
              handleParentIdUpdate={handleParentIdUpdate}
            />
           {/*  <FormErrorText error={formErrors.parentId} /> */}
            {/* <input
              type="text"
              className={`${commonstyles.form_control} form-control form-control-lg`}
              placeholder="232425"
              name="parentId"
              // value={props.form.parentId}
              // onChange={handleChange}
            /> */}
          </div>}
        </div>
      </div>
    </div>
  );
}
