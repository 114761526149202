import React, { useEffect, useState, useRef } from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/PaymentNew.module.css";
import { drawGraph } from "../../../DrawGraph/DrawGraph";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Select } from "antd";
const { Option } = Select;


export default function Dashboard(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [BarGraph, setBarGraph] = useState([]);
  const [pieChart1, setPieChart1] = useState([]);
  const [pieChart2, setPieChart2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [AbandonedOrderAmount, setAbandonedOrderAmount] = useState([]);
  const [AbandonedEmailsCount, setAbandonedEmailsCount] = useState({
    totalAbandonedEmails: "",
    pendingEmails: "",
    pendingEmailOrders: "",
  });
  const [regions, setRegions] = useState([]);
  const [singleRegionId, setSingleRegionId] = useState(null);
  const [regionId, setRegionId] = useState("");
  const [isAllData, setIsAllData] = useState(true);

  useEffect(() => {
    setRegionId("");
    getRegionList();
  }, []);

  useEffect(() => {
    if (singleRegionId || singleRegionId === '') {
      getAbandonedGraph(singleRegionId);
      AbandonedPieGraph(singleRegionId);
      abandonedOrderAmount(singleRegionId);
      abandonedEmailsCount(singleRegionId);
    }
  }, [singleRegionId])

  /**
   * @author Rajkumar
   * @param {reg_id}
   * GET ABANDONED GRAPH
   */
  function getAbandonedGraph(reg_id) {
    setIsLoading(true);
    let params = { regionId: reg_id };
    if (reg_id === "") delete params.regionId;
    AxiosInstance(8003, "application/json")
      .post(`admin/getAbandonedGraph`, params)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          let data = res.data;
          var trace1 = {
            x: data?.abandondonedCheckouts?.key,
            y: data?.abandondonedCheckouts?.value,
            type: "bar",
            name: "Abandoned",
            marker: {
              color: "#C7856C",
              // opacity: 0.7,
            },
          };

          var trace2 = {
            x: data?.abandonedRecovered?.month,
            y: data?.abandonedRecovered?.amount,
            type: "bar",
            name: "Recovered",
            marker: {
              color: "#EBCAAB",
              // opacity: 0.5
            },
          };
          var graphData = [trace1, trace2];
          let graph = drawGraph(
            graphData,
            null,
            null,
            "grpBar",
            "",
            null,
            950,
            250,
            null,
            null,
            null
          );
          setBarGraph(graph);
          if (data?.abandondonedCheckouts?.key?.length > 0) {
            setIsAllData(true);
          } else {
            setIsAllData(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  /**
   * @author Rajkumar
   * @param {reg_id}
   * GET ABANDONED PIE GRAPH
   */
  function AbandonedPieGraph(reg_id) {
    let params = { regionId: reg_id };
    if (reg_id === "") delete params.regionId;
    AxiosInstance(8003, "application/json")
      .post(`admin/AbandonedPieGraph`, params)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          let data = res.data;
          let arr1 = [
            `Rs ${data?.data?.abandonedItemsAndAmount[0]?.recoveredTotalAmount
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            `Rs ${data?.data?.abandonedItemsAndAmount[0]?.abandonedTotalAmount
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          ];
          let arr2 = [
            `Qty ${data?.data?.abandonedItemsAndAmount[0]?.recoveredTotalItems}`,
            `Qty ${data?.data?.abandonedItemsAndAmount[0]?.abandonedPendingItems}`,
          ];
          let pieTrace1 = {
            values: [
              data?.percentages?.recoveredItemsPercentage,
              data?.percentages?.abandonedItemsPercentage,
            ],
            labels: ["Recovered", "Abandoned"],
            hoverinfo: "label+percent",
            textposition: "inside",
            text: arr1,
            insidetextorientation: "radial",
            type: "pie",
            hole: 0.4,
            marker: {
              colors: ["#C7856C", "#EBCAAB"],
            },
          };
          let chart = drawGraph(
            [pieTrace1],
            null,
            null,
            "ABpieChart",
            null,
            null,
            450,
            250,
            null,
            null,
            null
          );
          setPieChart1(chart);

          let pieTrace2 = {
            values: [
              data?.percentages?.recoveredAmountPercentage,
              data?.percentages?.abandonedAmountPercentage,
            ],
            labels: ["Recovered", "Abandoned"],
            hoverinfo: "label+percent",
            textposition: "inside",
            insidetextorientation: "radial",
            text: arr2,
            type: "pie",
            hole: 0.4,
            marker: {
              colors: ["#C7856C", "#EBCAAB"],
            },
          };
          let chart1 = drawGraph(
            [pieTrace2],
            null,
            null,
            "ABpieChart",
            null,
            null,
            450,
            250,
            null,
            null,
            null
          );
          setPieChart2(chart1);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function abandonedOrderAmount(reg_id) {
    let params = { regionId: reg_id };
    if (reg_id === "") delete params.regionId;
    AxiosInstance(8003, "application/json")
      .post(`admin/abandonedOrderAmount`, params)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          let data = res.data?.data?.abandonedItemsAndAmount[0];
          setAbandonedOrderAmount(data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function abandonedEmailsCount(reg_id) {
    let params = { regionId: reg_id };
    if (reg_id === "") delete params.regionId;
    AxiosInstance(8003, "application/json")
      .post(`admin/abandonedEmailsCount`, params)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          setIsLoading(false);
          let data = res.data?.data;
          const cloneAbandonedEmailsCount = { ...AbandonedEmailsCount };
          cloneAbandonedEmailsCount.totalAbandonedEmails =
            data?.abandonedEmailSent[0]?.totalAbandonedEmails;
          cloneAbandonedEmailsCount.pendingEmails =
            data?.totalEmailSent[0]?.totalEmails;
          cloneAbandonedEmailsCount.pendingEmailOrders =
            data?.pendingEmailOrders[0]?.pendingEmails;
          setAbandonedEmailsCount(cloneAbandonedEmailsCount);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  /**
   * @author Rajkumar
   * GET REGION LIST
   */
  function getRegionList() {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Warehouse", value: "", _id: "" };
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            setSingleRegionId("")
          } else if (res.data?.daaata?.length === 1) {
            setRegionId(res.data.daaata[0]?._id)
            setSingleRegionId(res.data.daaata[0]?._id)
          } else {
            setSingleRegionId("")
          }
        }
      })
      .catch((er) => {
        console.log(er);
        setSingleRegionId("");
      });
  }

  function filterOnChange(e) {
    // const { value } = e.target;
    props.filterOnChange(e);
    setRegionId(e);
    getAbandonedGraph(e);
    AbandonedPieGraph(e);
    abandonedOrderAmount(e);
    abandonedEmailsCount(e);
  }

  function handleChange(e) {
    setRegionId(e);
  }

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching dashboard details..." />
            </td>
          </tr>
        </div>
      ) : (
        <PDFExport
          ref={props.pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName={`Abandoned Checkout for ${new Date().getFullYear()}`}
          author="Damro Team"
        >
          <div>
            <div className="d-flex justify-content-between">
              {permissionsArr.length > 0 &&
                permissionsArr.find((x) => x === "Marketing-Abandoned-view") ===
                  "Marketing-Abandoned-view" && (
                  <div className="card_dropdown show col-md-6">
                    <h1 className={commonstyles.management_header_heading}>
                      Dashboard
                    </h1>
                  </div>
                )}
              <div>
                {/* {localStorage.getItem("role") === "super_admin" && ( */}
                <div className="show col-md-6">
                  {permissionsArr.length > 0 && 
                    permissionsArr.find(
                      (x) => x === "Marketing-Abandoned-view"
                    ) === "Marketing-Abandoned-view" && regions?.length > 1 && (
                      <Select
                        className={`antd_filter_dashboard`}
                        name="regionId"
                        value={regionId ? regionId : ""}
                        // defaultValue="Select region"
                        onChange={(e) => filterOnChange(e)}
                      >
                        {/* <Option value="">All Warehouse</Option> */}
                        {Array.isArray(regions)
                          ? regions.map((region) => (
                              <Option key={region._id} value={region._id}>
                                {region.name}
                              </Option>
                            ))
                          : null}
                      </Select>
                    )}
                </div>
                {/* )} */}
              </div>
            </div>
            {!isAllData && (
              <tr className="bg-transparent d-flex justify-content-center">
                <td colSpan="100%">No Data</td>
              </tr>
            )}

            {isAllData && (
              <>
                {permissionsArr.length > 0 &&
                  permissionsArr.find(
                    (x) => x === "Marketing-Abandoned-view"
                  ) === "Marketing-Abandoned-view" && (
                    <div
                      className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                    >
                      <div className="row">
                        <div className="col-lg-12 mb-4 ">
                          <h4>Abandoned Checkout Recovery Performance</h4>
                          {BarGraph}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                  {permissionsArr.length > 0 &&
                    permissionsArr.find(
                      (x) => x === "Marketing-Abandoned-view"
                    ) === "Marketing-Abandoned-view" && (
                      <div className="col-lg-6 mb-3 ">
                        <div
                          className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                        >
                          <h4>Abandoned Amount in INR</h4>
                          <div>{pieChart1}</div>
                        </div>
                      </div>
                    )}
                  <div className="col-lg-6 mb-3 ">
                    {permissionsArr.length > 0 &&
                      permissionsArr.find(
                        (x) => x === "Marketing-Abandoned-view"
                      ) === "Marketing-Abandoned-view" && (
                        <div
                          className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                        >
                          <h4>Abandoned Checkout Quantity</h4>
                          <div>{pieChart2}</div>
                        </div>
                      )}
                  </div>
                </div>

                <div className="row">
                  {permissionsArr.length > 0 &&
                    permissionsArr.find(
                      (x) => x === "Marketing-Abandoned-view"
                    ) === "Marketing-Abandoned-view" && (
                      <div className="col-lg-6 mb-3 ">
                        <div
                          className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                        >
                          <h4>
                            Total Abandoned Checkout and
                            <br />
                            Orders Amount Report
                          </h4>
                          <hr />
                          <div
                            className={`${styles.abandoned_dashboard_table_details}`}
                          >
                            <div className="d-flex justify-content-between">
                              <p>Total Amount</p>
                              <p>
                                {AbandonedOrderAmount?.totalAmount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Amount of Recovered Orders</p>
                              <p>
                                {AbandonedOrderAmount?.totalAmountRecovered
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Pending Orders</p>
                              <p>{AbandonedOrderAmount?.pendingItems}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="col-lg-6 mb-3 ">
                    {permissionsArr.length > 0 &&
                      permissionsArr.find(
                        (x) => x === "Marketing-Abandoned-view"
                      ) === "Marketing-Abandoned-view" && (
                        <div
                          className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                        >
                          <h4>Total Abandoned Checkout and Orders Quantity</h4>
                          <hr />
                          <div
                            className={`${styles.abandoned_dashboard_table_details}`}
                          >
                            <div className="d-flex justify-content-between">
                              <p>Total Abandoned Checkout orders Quantity</p>
                              <p>{AbandonedOrderAmount?.totalItems}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Amount of Recovered Orders</p>
                              <p>{AbandonedOrderAmount?.totalItemsRecovered}</p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-6 mb-3 ">
                    {permissionsArr.length > 0 &&
                      permissionsArr.find(
                        (x) => x === "Marketing-Abandoned-view"
                      ) === "Marketing-Abandoned-view" && (
                        <div
                          className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}
                        >
                          <h4>Email Report</h4>
                          <hr />
                          <div
                            className={`${styles.abandoned_dashboard_table_details}`}
                          >
                            <div className="d-flex justify-content-between">
                              <p>Total Emails Sent</p>
                              <p>{AbandonedEmailsCount?.pendingEmails}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Emails for Abandoned Checkout</p>
                              <p>
                                {AbandonedEmailsCount?.totalAbandonedEmails}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>Emails for Pending Orders</p>
                              <p>{AbandonedEmailsCount?.pendingEmailOrders}</p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        </PDFExport>
      )}
    </>
  );
}
