import React from 'react';
import { DatePicker, Space, Empty } from 'antd';
// import Loader from "react-loader-spinner";

const { RangePicker } = DatePicker;

const AntDateRangeSelectComponent = (props) => {
    return (
        <Space direction="vertical" size={12}>
        <RangePicker {...props.selectProps} notFoundContent={props.fetching && props.selectOptions.length === 0 &&
            <Empty />
        }>
        </RangePicker>
        </Space>
    );
}

export default AntDateRangeSelectComponent;