import React, { useState, useEffect } from "react";
import styles from "../../../styles/AddDiscount.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import styless from "../../../styles/AddProduct.module.css";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Select,
  Radio,
  Input,
  Checkbox,
  TimePicker,
} from "antd";
import validationRules from "../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";

import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { useHistory } from "react-router";

import {
  onlyNumbers,
  onlyThreeNumbers,
  minChar,
  onlyAlphabetsWithSpace,
  phoneRegx,
} from "../../../utils/RegularExp";

const { Option } = Select;

const initialState = {
  // ruleStatus: true,
  ruleName: "",
  couponCode: "",
  offerType: true,
  offerAmount: "",
  applicableCategory: [],
  applicableProducts: [],
  applicableDecor: "",
  usesPerCoupon: "",
  region: [],
  description: "",
  url: "",
  usesPerCustomer: "",
};

export default function OfferManagement() {
  const history = useHistory();

  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [regions, setRegions] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [url, setUrl] = useState(null);
  const [currenttype, setCurrentType] = useState("0");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [showCategory, setShowCategory] = useState("all");
  const [discountDetails, setDiscountDetails] = useState(initialState);
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState("");
  const [parentCollections, setParentCollections] = useState([]);
  const [parentProducts, setParentProducts] = useState([]);
  const [minAmount, setMinAMount] = useState();
  const [randomCode, setRandomeCode] = useState("");

  const [currentMinAm, setCurrentMinAm] = useState(0);

  const [endDateSelect, setEndDateSelect] = useState(false);

  const [startTime, setStartTime] = useState("");

  const [endTime, setEndTime] = useState("");

  const [formErrors, setFormErrors] = useState({
    ruleName: false,
    couponCode: false,
    offerAmount: false,
    region: false,
    // banner: false,
    startDate: false,
    endDate: false,
    usesPerCoupon: false,
    usesPerCustomer: false,
  });

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState("");

  const rules = {
    ruleName: [(val, data) => (!val ? "Name " : true)],
    couponCode: [(val, data) => (!val ? "Coupon code " : true)],
    region: [(val, data) => (!val?.length ? "Select regions " : true)],
    // banner: [(val, data) => !file && "Banner "],
    startDate: [
      (val, data) => (!val || val == "Invalid date" ? "Start date " : true),
    ],
    endDate: [
      (val, data) => (!val || val == "Invalid date" ? "End date " : true),
    ],
    offerAmount: [(val, data) => (!val ? "Offer amount " : true)],
    usesPerCoupon: [(val, data) => (!val ? "Uses per coupon " : true)],
    usesPerCustomer: [(val, data) => (!val ? "Uses per customer " : true)],
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            // res.data.daaata.unshift({
            //   name: "All",
            //   _id: "All",
            // });
            setRegions(res.data.daaata);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);

            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
    return(() => {
      history['isBanner'] = true;
    })
  }, []);

  useEffect(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getCategoryName")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.daaata);
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getParentCategories();
  }, []);

  useEffect(() => {
    function getParentProducts() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("admin/catalog/getSimpleProductList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentProducts(res.data.data);
          }
        })
        .catch((er) => {
          console.error("Failed to fetch parent categories");
        });
    }
    getParentProducts();
  }, []);

  useEffect(() => {
    function getParentCollections() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("admin/catalog/getAllDecor")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCollections(res.data.data);
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getParentCollections();
  }, []);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function discountList() {
    history.push("/discount");
  }

  function handleRegionChange(e) {
    let selectedVal = [];
    let isAll = false;
    if (e?.length > 0) {
      e.forEach((item, index) => {
        if (e[e.length - 1] === "All Warehouse") {
          isAll = true;
        }
      });
    };
    if (isAll) {
      selectedVal.push("All Warehouse");
    } else {
      selectedVal = e?.filter((x, indx) => x !== "All Warehouse");
    }
    if(selectedVal.length === 0){
      setFormErrors({...formErrors,region:true })
    }else{
      setFormErrors({...formErrors, region:false})
    }
    discountDetails.region = selectedVal;
    setDiscountDetails({ ...discountDetails });
  }

  function handleOfferChange(e) {
    const { name, value, checked } = e.target;
    if (
      [
        "ruleName",
        "offerAmount",
        "usesPerCoupon",
        "description",
        "usesPerCustomer",
      ].includes(name)
    ) {
      discountDetails[name] = value;

      setDiscountDetails({ ...discountDetails });
    }
    if (["ruleStatus"].includes(name)) {
      discountDetails[name] = checked;

      setDiscountDetails({ ...discountDetails });
    }
    if (["couponCode"].includes(name)) {
      setRandomeCode(value);
      setDiscountDetails({
        ...discountDetails,
        [name]: value.split(" ").join(""),
      });
      // showNotification({
      //   type: "error",
      //   message: "CouponCode",
      //   description: "Coupon Code Remove Space",
      // });
    }
  }

  function handlefirstdate(e) {
    setSdate(moment(e.target.value).format("YYYY-MM-DD"));
    if(e.target.value !== ""){
      setFormErrors({...formErrors,startDate:false })
    }else{
      setFormErrors({...formErrors,startDate:true })
    }
  }

  function handlelastdate(e) {
    
    setEdate(moment(e.target.value).format("YYYY-MM-DD"));
    if(e.target.value !== ""){
      setFormErrors({...formErrors,endDate:false })
    }else{
      setFormErrors({...formErrors,endDate:true })
    }
  }

  function handleOfferType(e) {
    const { name, checked } = e.target;

    if (checked) {
      setCurrentType(name);
      discountDetails.offerType = name;
      //   setDiscountDetails({ ...discountDetails });
    }
  }

  function handleCategory(e) {
    const { name, value, checked } = e.target;

    if (checked && name == "specific") {
      setShowCategory("specific");
      setProducts([]);
      discountDetails.applicableDecor = "";
      setCollections("");
    }
    if (checked && name == "all") {
      setShowCategory("all");
      discountDetails.applicableCategory = [];
      setProducts([]);
      discountDetails.applicableDecor = "";
      setCollections("");
    }
    if (checked && name == "specificp") {
      setShowCategory("specificp");
      discountDetails.applicableCategory = [];
      discountDetails.applicableDecor = "";
      setCollections("");
    }
    if (checked && name == "specificc") {
      setShowCategory("specificc");
      discountDetails.applicableCategory = [];
      setProducts([]);
    }
  }

  function handleImageInput(e) {
    const { name, files } = e.target;
    setFile(e.target.files[0]);
    if (url) {
      URL.revokeObjectURL(url);
    }
    const generatedUrl = URL.createObjectURL(e.target.files[0]);
    setUrl(generatedUrl);
    discountDetails.url = generatedUrl;

    // if (name) {
    //   const file_name = files[0].name.toLowerCase();
    //   if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
    //     showNotification({
    //       type: "error",
    //       message: "There were few errors",
    //       description: "Please upload only jpg,jpeg and png format images",
    //     });
    //     return;
    //   } else if (files[0].size > 1024 * 1024 * 3) {
    //     showNotification({
    //       type: "error",
    //       message: "There were few errors",
    //       description: "Please upload file less than 3MB size",
    //     });
    //     return;
    //   } else {

    //   }
    // }
  }

  function removeFile() {
    if (url) {
      discountDetails.url = "";
      setFile(null);
      setUrl(null);
    }
  }

  function addDiscount() {
    let selArrCat = [], selArrPrd = []
    parentCategories?.forEach(parItem => {
      discountDetails?.applicableCategory?.forEach(selItem => {
        if(parItem?.name === selItem) {
          selArrCat.push(parItem?._id)
        }
      })
    })
    parentProducts?.forEach(parItem => {
      products?.forEach(selItem => {
        if(parItem?.name === selItem) {
          selArrPrd.push(parItem?._id)
        }
      })
    })
    const data = {
      ruleName: discountDetails.ruleName.trim(),
      // ruleStatus: JSON.parse(discountDetails.ruleStatus),
      couponCode: randomCode.toUpperCase().trim(),
      usesPerCoupon: parseInt(discountDetails.usesPerCoupon),
      usesPerCustomer: parseInt(discountDetails.usesPerCustomer),
      startDate: sdate,
      endDate: edate,
      applicableCategory: selArrCat,
      applicableProducts: selArrPrd,
      // applicableDecor: discountDetails.applicableDecor,
      offerAmount: parseInt(discountDetails.offerAmount),
      // description: discountDetails.description
      //   ? discountDetails.description
      //   : "Type your description",
      region: discountDetails.region,
      offerType: parseInt(currenttype),
      minAmount: parseInt(minAmount) || parseInt(currentMinAm),
      type: 1,
      startTime: startTime,
      endTime: endTime,
    };

    if (discountDetails.applicableDecor != "") {
      data.applicableDecor = discountDetails.applicableDecor;
    }

    if (
      showCategory == "all" ||
      showCategory == "specific" ||
      showCategory == "specificp" ||
      showCategory == "specificc"
    ) {
      if (showCategory == "all") {
        data.offerApplicable = 1;
      } else if (showCategory == "specific") {
        data.offerApplicable = 0;
      } else if (showCategory == "specificp") {
        data.offerApplicable = 2;
      } else {
        data.offerApplicable = 3;
      }
    }

    if (discountDetails.description) {
      data.description = discountDetails.description;
    }
    const result = runValidationChecks(rules, undefined, { ...data });
    let isAllRegion = false;
    if (data?.region?.length > 0) {
      data?.region.forEach(item => {
        if (item === "All Warehouse") {
          isAllRegion = true;
        }
      })
    }

    if(isAllRegion) {
      data.region = [];
    }

    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("banner", file);


    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }
    if (
      discountDetails.ruleName.trim().length < 3 ||
      discountDetails.ruleName.trim().length > 25 ||
      randomCode.trim().length < 3 ||
      randomCode.trim().length > 25
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description:
          "Code/Name must be more than 2 characters and less than 25 characters",
      });
      return;
    }
    // if (!url) {
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description: "Please select banner image",
    //   });
    //   return;
    // }

    if (
      !onlyNumbers.test(discountDetails.offerAmount) ||
      !onlyNumbers.test(discountDetails.usesPerCoupon) ||
      !onlyNumbers.test(discountDetails.usesPerCustomer)
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors where fields entered with number",
      });
      return;
    }

    if (
      discountDetails.usesPerCoupon === "" ||
      discountDetails.usesPerCustomer === ""
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors where fields are null/empty",
      });
      return;
    }
   

    // if (
    //   !discountDetails.ruleName.trim().length > 3 &&
    //   !discountDetails.couponCode.trim().length > 3
    // ) {
    //   showNotification({
    //     type: "success",
    //     message: "Success",
    //     description: "Code must be more than 3 letters",
    //   });
    // }
    if (edate >= sdate) {
      setLoading({ details: false, update: true });
      AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
        .post("offers/createCoupon", formData)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Banner coupon details added successfully",
            });
            history.push("/discount");
          } else if (
            res.data.status === 400 &&
            res.data.msg == "Coupon code already exists"
          ) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: "Coupon code already exists",
            });
            return;
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });
            const errors = formatServerValidtionErrors(res.data.error);
            return;

            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      alert("End date should be greater than start date.");
    }
  }

  function handleSingleCategory(value) {
    discountDetails.applicableCategory = value;

    let arr = [];
    parentCategories.forEach((product) => {
      value.forEach((subPrd) => {
        if (product.name === subPrd) {
          arr.push(product?.name);
        }
      });
    });

    setSelectedCategories(arr);
  }

  function handleminAmountType(e) {
    const { name, checked, value } = e.target;
    if (value == 0) {
      setCurrentMinAm(value);
      setMinAMount(value);
    } else if (checked == "1") {
      setCurrentMinAm(value);
    }
  }

  function handleMinAmount(e) {
    setMinAMount(e.target.value);
  }

  function handleSingleProduct(value) {
    setProducts(value);

    let arr = [];
    parentProducts.forEach((product) => {
      value.forEach((subPrd) => {
        if (product.name === subPrd) {
          arr.push(product?.name);
        }
      });
    });

    setSelectedProducts(arr);
    setDiscountDetails({ ...discountDetails, applicableProducts: value });
  }

  function handleSingleCollection(value) {
    setCollections(value);

    let arr = "";
    parentCollections.forEach((product) => {
      if (product._id === value) {
        arr = product?.title;
      }
    });

    setSelectedCollections(arr);
    setDiscountDetails({ ...discountDetails, applicableDecor: value });
  }
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function onDateSelect(e) {
    if (e.target.checked == true) {
      setEndDateSelect(true);
    } else {
      setEndDateSelect(false);
    }
  }

  function makeRandomCode() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setFormErrors({...formErrors,couponCode:false})
    setRandomeCode(result);
  }

  // *** ONCHANGE HANDLER FOR TIME *** //
  const onChangeTimeHandlerStart = (time, type) => {
    const startTime = moment(type, "HH:mm").format("HH:mm");
    setStartTime(startTime);
  };

  const onChangeTimeHandlerEnd = (time, type) => {
    const endTime = moment(type, "HH:mm").format("HH:mm");
    setEndTime(endTime);
  };

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={discountList}
          />
          Add Banner Coupon
        </h1>
        <div>
          {/* <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleOfferChange(e)}
              checked={discountDetails.ruleStatus}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div> */}
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/discount`),
            }}
          >
            Cancel
          </ButtonComponent>
          {/* <div className="col-lg-6 text-right"> */}
          <ButtonComponent
            className="rnd br-7"
            attributes={{ onClick: addDiscount, disabled: loading.update }}
            style={{ cursor: "pointer" }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        {/* </div> */}
        </div>
      </div>

      <div className={styles.management_details}>
        <div className="col-lg-12">
          <div
            className={`${styles.add_discount_full_box} row add_discount_form`}
          >
            <div className="col-lg-6">
              <div className={styles.management_details_contents}>
                <div className={styles.management_details_contents_header}>
                  <h4>
                    Code
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </h4>
                  <p className={styles.generate_code} onClick={makeRandomCode}>
                    Generate Code
                  </p>
                </div>
                <div className={styles.management_details_form}>
                  <InputComponent
                    className="form-control-lg"
                    error={formErrors.couponCode}
                    formControl={{
                      name: "couponCode",
                      value: randomCode,
                      onChange: (e) => {
                        if (!minChar.test(e.target.value)) {
                          if (!formErrors.couponCode) {
                            formErrors.couponCode = {};
                          }
                          formErrors.couponCode = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.couponCode) {
                            formErrors.couponCode = "";
                          }
                        }

                        handleOfferChange(e);
                      },

                      maxLength: "25",
                      style: { textTransform: "uppercase" },
                    }}
                    //   onChange={(e) => setCode(e.target.value)}
                  />
                  <FormErrorText error={formErrors.couponCode} />
                  <span className={styles.info_text}>
                    Customers will enter this discount code at checkout.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={`${styles.management_details_contents} `}>
                <h4>Warehouse
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                  </span>
                </h4>
                
                <div className={styles.management_details_form}>
                  <Select
                    mode="multiple"
                    name="region"
                    style={{ 
                      border: formErrors.region? "#ff7575":"1px solid #d9d9d9",
                      boxShadow: formErrors.region?"0 0 3px 1px #ff7575":'',
                      width: "100%"
                    }} 
                    placeholder="Select Warehouse"
                    value={discountDetails.region}
                    onChange={(e) => handleRegionChange(e)}
                    error={formErrors.region}  
                    optionLabelProp="label"
                  >
                    {regions?.length > 1 && <Option value="All Warehouse">All Warehouse</Option>}
                    {regions.length > 0 &&
                      regions.map((region) => (
                        <Option value={region._id} label={region.name}>
                          <div className="demo-option-label-item">
                            {region.name}
                          </div>
                        </Option>
                      ))}
                  </Select>
                  <FormErrorText error={formErrors.region} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <div className={styles.management_details_contents_header}>
                <h4>
                  Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </h4>
              </div>

              <div className={styles.management_details_form}>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.ruleName}
                  formControl={{
                    name: "ruleName",
                    onChange: (e) => {
                      if (!minChar.test(e.target.value)) {
                        if (!formErrors.ruleName) {
                          formErrors.ruleName = {};
                        }
                        formErrors.ruleName = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.ruleName) {
                          formErrors.ruleName = "";
                        }
                      }

                      handleOfferChange(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.ruleName} />
              </div>
            </div>
            
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <h4>
                Types
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </h4>
              <div className={styles.management_details_form}>
                <div
                  className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                >
                  <input
                    type="radio"
                    id="rd_1"
                    name="0"
                    className="custom-control-input"
                    checked={currenttype === "0"}
                    onClick={(e) => handleOfferType(e)}
                  />
                  <label className="custom-control-label" for="rd_1">
                    <span>Percentage</span>
                  </label>
                </div>
                <div
                  className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                >
                  <input
                    type="radio"
                    id="rd_2"
                    name="1"
                    className="custom-control-input"
                    checked={currenttype === "1"}
                    onClick={(e) => handleOfferType(e)}
                  />
                  <label className="custom-control-label" for="rd_2">
                    <span>Fixed amount</span>
                  </label>
                </div>
              </div>
            </div>
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <div className={styles.management_details_contents}>
                <h4>
                  Value
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </h4>

                <div className={styles.management_details_form}>
                  <h4>Discount value</h4>
                  <InputComponent
                    className="form-control-lg"
                    formControl={{
                      maxlength: currenttype == "0" ? 2 : 20,
                      type: "text",
                      name: "offerAmount",
                      onChange: (e) => {
                        if (!onlyNumbers.test(e.target.value)) {
                          if (!formErrors.offerAmount) {
                            formErrors.offerAmount = {};
                          }
                          formErrors.offerAmount = "Enter only numbers";
                        } else {
                          if (formErrors.offerAmount) {
                            formErrors.offerAmount = "";
                          }
                        }
                        if (
                          currenttype == "0" &&
                          !onlyThreeNumbers.test(e.target.value)
                        ) {
                          formErrors.offerAmount =
                            "Enter only 2 numbers within 100";
                        }

                        handleOfferChange(e);
                      },
                    }}
                    error={formErrors.offerAmount}
                  />
                  <FormErrorText error={formErrors.offerAmount} />
                </div>
              </div>

              <div className={styles.management_details_contents}>
                <div className={styles.management_details_form}>
                  {/* <ButtonComponent
                    className="rnd add_discountpost"
                    style={{ cursor: "pointer" }}
                  >
                    Post
                  </ButtonComponent> */}
                  <label>Applies to</label>
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                  >
                    <input
                      type="radio"
                      id="rd_5"
                      name="all"
                      className="custom-control-input"
                      value="1"
                      checked={showCategory === "all"}
                      onClick={(e) => handleCategory(e)}
                    />
                    <label className="custom-control-label" for="rd_5">
                      <span>All products</span>
                    </label>
                  </div>

                  <div
                    className={`custom-control custom-radio ${styles.custom_radio}`}
                  >
                    <input
                      type="radio"
                      id="rd_6"
                      name="specific"
                      className="custom-control-input"
                      value="0"
                      checked={showCategory === "specific"}
                      onClick={(e) => handleCategory(e)}
                    />
                    <label className="custom-control-label" for="rd_6">
                      <span>Specific categories</span>
                    </label>
                  </div>
                  {showCategory === "specific" && (
                    <div className={styles.management_details_form}>
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select one category"
                        onChange={(value) => handleSingleCategory(value)}
                        optionLabelProp="label"
                      >
                        {parentCategories.map((category) => (
                          <Option value={category.name} label={category.name}>
                            <div className="demo-option-label-item">
                              {category.name}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio}`}
                  >
                    <input
                      type="radio"
                      id="rd_7"
                      name="specificp"
                      className="custom-control-input"
                      //   value="0"
                      checked={showCategory == "specificp"}
                      onClick={(e) => handleCategory(e)}
                    />
                    <label className="custom-control-label" for="rd_7">
                      <span>Specific products</span>
                    </label>
                  </div>
                  {showCategory == "specificp" && (
                    <div className={styles.management_details_form}>
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select one product"
                        onChange={(e) => handleSingleProduct(e)}
                        optionLabelProp="label"
                        value={products}
                      >
                        {parentProducts.map((product) => (
                          <Option value={product.name} label={product.name}>
                            <div className="demo-option-label-item">
                              {product.name}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio}`}
                  >
                    <input
                      type="radio"
                      id="rd_8"
                      name="specificc"
                      className="custom-control-input"
                      //   value="0"
                      checked={showCategory == "specificc"}
                      onClick={(e) => handleCategory(e)}
                    />
                    <label className="custom-control-label" for="rd_8">
                      <span>Specific collections</span>
                    </label>
                  </div>
                  {showCategory == "specificc" && (
                    <div className={styles.management_details_form}>
                      <Select
                        mode="single"
                        style={{ width: "100%" }}
                        placeholder="Select one collection"
                        onChange={(e) => handleSingleCollection(e)}
                        optionLabelProp="label"
                        value={collections}
                      >
                        {parentCollections.map((collection) => (
                          <Option
                            value={collection._id}
                            label={collection.title}
                          >
                            <div className="demo-option-label-item">
                              {collection.title}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <ButtonComponent
                className="rnd add_discountpost"
                style={{ cursor: "pointer" }}
              >
                Post
              </ButtonComponent>
              <h4>Usage limits</h4>
              <div className={styles.management_details_form}>
                <Radio>
                  Limit number of times this discount can be used in total
                </Radio>
                <Radio>Limit to one use per customer</Radio>
              </div>
            </div> */}
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <h5>
                Validity
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </h5>

              <div className={styles.management_details_form}>
                <div className="row mb-2">
                  <div className={`col-lg-6 ${styles.input_img}`}>
                    <label>Start date</label>

                    <InputComponent
                      className="form-control-sm"
                      error={formErrors.startDate}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      max={moment(new Date())
                        .add(1, "years")
                        .format("YYYY-MM-DD")}
                      formControl={{
                        name: "startDate",
                        value: sdate,
                        type: "date",
                        style: {
                          cursor: "pointer",
                          padding: "5px",
                          textTransform: "uppercase",
                        },
                        onChange: (e) => handlefirstdate(e),
                      }}
                    />
                    <FormErrorText
                      error={formErrors.startDate ? "Required" : ""}
                    />
                  </div>

                  <div className={`col-lg-6 ant_time_pick_input mb-3`}>
                    <label className={`${styles.label_block} `}>
                      Start time
                    </label>
                    <TimePicker
                      error={formErrors.startTime}
                      // use12Hours
                      format="HH:mm"
                      name="startTime"
                      onChange={onChangeTimeHandlerStart}

                      // value={startTime && moment(startTime, "HH:mm:ss A")}
                    />
                    {/* <input
                      className="form-control-lg"
                      error={formErrors.startDate}
                      name="startDate"
                      // value: newCategory.name,
                      type="date"
                      style={{ padding: "20px" }}
                      onChange={(e) => handlefirstdate(e)}
                      type="date"
                      min="2021-11-25"
                      className="form-control"
                    /> */}
                  </div>

                  {endDateSelect == true && (
                    <div className={`row mr-0 ml-0 full_width`}>
                      <div className={`col-lg-6 ${styles.input_img}`}>
                        <label>End date</label>

                        <InputComponent
                          className="form-control-sm"
                          error={formErrors.endDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          max={moment(new Date())
                            .add(1, "years")
                            .format("YYYY-MM-DD")}
                          formControl={{
                            name: "endDate",
                            type: "date",
                            value: edate,
                            style: {
                              cursor: "pointer",
                              padding: "5px",
                              textTransform: "uppercase",
                            },
                            onChange: (e) => handlelastdate(e),
                          }}
                        />
                        <FormErrorText
                          error={formErrors.endDate ? "Required" : ""}
                        />
                      </div>

                      <div className="col-lg-6 ant_time_pick_input mb-3">
                        <label className={`${styles.label_block} `}>
                          End time
                        </label>

                        <TimePicker
                          error={formErrors.endTime}
                          name="endTime"
                          // use12Hours
                          format="HH:mm"
                          onChange={onChangeTimeHandlerEnd}
                          // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                          // value={endTime && moment(endTime, "HH:mm:ss")}
                        />
                      </div>

                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {/* {errorMessage} */}
                      </span>
                    </div>
                  )}

                  <div className="col-lg-12">
                    <div className={`${styles.checkbox} mt-2 ml-0`}>
                      <Checkbox onChange={onDateSelect}>Set end date</Checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box}`}
            >
              <h4>
                Usage limit total
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </h4>
              <div className={styles.management_details_form}>
                {/* <input
                  type="text"
                  className={`form-control form-control-lg ${styles.form_control}`}
                  name="usesPerCoupon"
                  onChange={(e) => handleOfferChange(e)}
                  maxLength="3"
                /> */}
                <InputComponent
                  className={`form-control-lg`}
                  error={formErrors.usesPerCoupon}
                  formControl={{
                    type: "number",
                    name: "usesPerCoupon",
                    onChange: (e) => {
                      if (!onlyNumbers.test(e.target.value)) {
                        if (!formErrors.usesPerCoupon) {
                          formErrors.usesPerCoupon = {};
                        }
                        formErrors.usesPerCoupon = "Enter only numbers";
                      } else {
                        if (formErrors.usesPerCoupon) {
                          formErrors.usesPerCoupon = "";
                        }
                      }
                      handleOfferChange(e);
                    },
                    maxLength: "5",
                  }}
                />
                <FormErrorText error={formErrors.usesPerCoupon} />
              </div>
            </div>{" "}
          </div>
          <div className="col-lg-5">
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
            >
              <div className={styles.management_details_contents_header}>
                <h4>Summary</h4>
                <p className={styles.summaru_active}>
                  {" "}
                  <p className={styles.summaru_active}>Active</p>
                </p>
              </div>
              <div className={`${styles.summaru_active_pera}`}>
                <p>{randomCode.toUpperCase()}</p>
                <p>
                  {currenttype == "1" && discountDetails.offerAmount
                    ? "Rs." +
                      discountDetails.offerAmount +
                      ` for ${
                        showCategory == "all"
                          ? "all products"
                          : showCategory == "specific"
                          ? selectedCategories.length > 0
                            ? selectedCategories
                            : "specific categories"
                          : showCategory == "specificp"
                          ? selectedProducts.length > 0
                            ? selectedProducts
                            : "specific products"
                          : showCategory == "specificc" &&
                            selectedCollections.length > 0
                          ? selectedCollections
                          : "specific collections"
                      }`
                    : currenttype == "0" && discountDetails.offerAmount
                    ? discountDetails.offerAmount +
                      "%" +
                      ` for ${
                        showCategory == "all"
                          ? "all products"
                          : showCategory == "specific"
                          ? selectedCategories.length > 0
                            ? selectedCategories
                            : "specific categories"
                          : showCategory == "specificp"
                          ? selectedProducts.length > 0
                            ? selectedProducts
                            : "specific products"
                          : showCategory == "specificc" &&
                            selectedCollections.length > 0
                          ? selectedCollections
                          : "specific collections"
                      }`
                    : delete discountDetails.offerAmount}
                </p>
                <p>
                  {currentMinAm == "0"
                    ? "For everyone"
                    : currentMinAm >= 1 && minAmount > 0
                    ? "Minimum purchase of Rs." + minAmount
                    : "Minimum purchase of Rs.0"}
                </p>
                <p>
                  {discountDetails.usesPerCustomer &&
                    `${discountDetails.usesPerCustomer} use per customer`}
                </p>
                <p>
                  {sdate != "Invalid date"
                    ? `Active from ${moment(sdate).format(
                        "DD/MM/YYYY"
                      )} till ${moment(edate).format("DD/MM/YYYY")}`
                    : sdate == "Invalid date" && ""}
                </p>
              </div>
            </div>
            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
            >
              <div className={styles.management_details_contents_header}>
                <h4>Description</h4>
              </div>
              <textarea
                className={`${commonstyles.form_control} form-control form-control-lg `}
                placeholder="Type your description"
                rows="4"
                autoComplete="off"
                name="description"
                onChange={(e) => handleOfferChange(e)}
                // maxLength="50"
              />
            </div>

            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
            >
              <div className={styles.upload_img_header}>
                <h4>Banner</h4>
              </div>

              <div className={styles.management_details_contents}>
                <div className={styles.upload_img_header}></div>
                <div
                  className={`${commonstyles.management_details_form} ${styles.add_image_card}`}
                >
                  {url ? (
                    <>
                      <ImagePreviewChip
                        url={url}
                        className={styles.added_image}
                        width="60px"
                        height="60px"
                        handleClose={removeFile}
                      />
                    </>
                  ) : (
                    <div className={` ${styles.upload_img} text-center`}>
                      <div>
                        <label
                          htmlFor="categoryFileInput"
                          className={`${styles.btn} px-2 `}
                        >
                          Add file
                          <InputComponent
                            className="form-control-lg"
                            formControl={{
                              type: "file",
                              name: "url",
                              accept: "image/jpeg, image/png, image/svg+xml",
                              id: "categoryFileInput",
                              onChange: (e) => handleImageInput(e),
                            }}
                          />
                        </label>
                      </div>

                      <span>or drop a file to Upload</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${styles.management_details_contents} ${styles.add_discount_full_box} add_discount_form`}
            >
              <h4>
                Usage limit per user
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </h4>
              <div className={styles.management_details_form}>
                {/* <input
                  type="text"
                  className={`form-control form-control-lg ${styles.form_control}`}
                  name="usesPerCustomer"
                  onChange={(e) => handleOfferChange(e)}
                  maxLength="2"
                /> */}
                <InputComponent
                  className={`form-control-lg`}
                  formControl={{
                    type: "number",
                    name: "usesPerCustomer",
                    onChange: (e) => {
                      if (!onlyNumbers.test(e.target.value)) {
                        if (!formErrors.usesPerCustomer) {
                          formErrors.usesPerCustomer = {};
                        }
                        formErrors.usesPerCustomer = "Enter only numbers";
                      } else {
                        if (formErrors.usesPerCustomer) {
                          formErrors.usesPerCustomer = "";
                        }
                      }
                      handleOfferChange(e);
                    },
                    maxLength: "5",
                  }}
                  error={formErrors.usesPerCustomer}
                />
                <FormErrorText error={formErrors.usesPerCustomer} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 text-left">
          {/* <ButtonComponent
            className="rnd  pl-4 pr-4  theme-btn-outlined"
            style={{ cursor: "pointer" }}
          >
            Draft
          </ButtonComponent> */}
        </div>
        
      </div>
    </div>
  );
}
