import React, { useState, useRef, useEffect } from "react";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";

// import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/Bulkupload.module.css";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import CloseIconSmall from "../../../../assets/images/close_icon_small.svg";
import bulkImg from "../../../../assets/login_banner_img.png"
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import { Alert, notification, Checkbox } from "antd";
import SearchComponents from "../../SearchManagement/SearchComponent";
import Compressor from "compressorjs";
import commonstyles from "../../../../styles/Management_Common.module.css";

export default function BulkUploadComponent(props) {
  const [entityType, setEntityType] = useState("product");
  const [file, setFile] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [response, setResponse] = useState({});
  const inputFileElRef = useRef(null);
  const [compressedImage, setCompressedImage] = useState([]);
  const [allImages, setAllImages] = useState({});
  const [fileArrayVal, setFileArrayVal] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const { handleClose } = props;
  // console.log(props);
  const APIendPoints = {
    product: "/admin/catalog/importProduct",
    inventoryAndPrice: "/admin/product/importInventory",
    faq: "/admin/product/questionAnswer",
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (name === "entityType") {
      setEntityType(value);
      removeFile();
      if ("key" in response) {
        setResponse({});
      }
    }

    if(value === "image") {
      removeFile()    
    } else {
      setAllImages({});
    }
  }

  function handleFileInput(e) {
    const { files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      // console.log(file);
      setFile(file);
      if ("key" in response) {
        setResponse({});
      }
    }
  }

  function removeFile() {
    // inputFileElRef.current.value = null;
    setFile(null);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /**
   * @author Mithun Prabhu
   * @param {}
   * SUBMIT BULK UPLOAD
   */
  function submitBulkUpload() {
    setIsloading(true);
    if(Object.keys(allImages).length > 0) {
      submitImageBulkUpload();
    } else {
      setResponse({});
      let entityType_ = entityType;
      if(entityType === "Single / Multiple Products") {
        entityType_ = "product"
      }
      const url = APIendPoints[entityType_];
      // console.log(entityType_);
      // console.log(url);
      // console.log(props.region);
      if (props.region === "region") {
        const formData = new FormData();
        formData.append("marginFile", file);
        // console.log(formData);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        AxiosInstance(PORTS.CMS, undefined)
          .post("/admin/zone/importZone", formData, config)
          .then((res) => {
            // console.log(res);
            if (res.data.status === 200) {
              const cookieString = document.cookie || '';
              let cookieArr = cookieString.split(';')
              cookieArr.forEach(item => {
                let cookieName = item.split('=')
                document.cookie = `${cookieName[0]} = `
              })
              setResponse({
                type: "success",
                message: res.data.msg,
                key: Date.now(),
              });
              removeFile();
              setFileArrayVal([]);
              setTimeout(() => {
                setResponse({});
              }, 10000);
            } else if (res.data.status === 400) {
              setResponse({
                type: "error",
                message: res.data.msg,
                key: Date.now(),
              });
            } else {
              showNotification({
                type: "error",
                message: "Failed",
                description: res?.data?.msg || "Failed to upload data",
              });
            }
          })
          .catch((er) => {
            showNotification({
              type: "error",
              message: "Failed",
              description: er?.message || "Error occured",
            });
          })
          .finally(() => setIsloading(false));
      } else {
        const formData = new FormData();
        formData.append("marginFile", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        AxiosInstance(PORTS.CMS, undefined)
          .post(url, formData, config)
          .then((res) => {
            if (res.data.status === 200) {
              setResponse({
                type: "success",
                message: res.data.msg,
                key: Date.now(),
              });
              removeFile();
              setTimeout(() => {
                setResponse({});
              }, 10000);
            } else if (res.data.status === 400) {
              setResponse({
                type: "error",
                message: res.data.msg,
                key: Date.now(),
              });
            } else {
              showNotification({
                type: "error",
                message: "Failed",
                description: res?.data?.msg || "Failed to upload data",
              });
            }
          })
          .catch((er) => {
            showNotification({
              type: "error",
              message: "Failed",
              description: er?.message || "Error occured",
            });
          })
          .finally(() => setIsloading(false));
      }
    }
  }

  function submitImageBulkUpload() {
    setIsloading(true);
    // updateProduct/category
    // api - /admin/uploadImages - Post Method
    // formdata - files
    const formData = new FormData();

    // if(isActive) {
    //   Object.keys(allImages).forEach(function(key, index) {
    //     new Compressor(allImages[key], {
    //       convertTypes: "image/png",
    //       quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
    //       success: (compressedResult) => {
    //         allImages[key] = compressedResult
    //       }
    //     })
    //   })
    // }
    console.log(allImages)
    for (let i = 0; i < Object.keys(allImages).length; i++) {
      formData.append("files", allImages[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    AxiosInstance(PORTS.CATALOG_SERVICE, undefined)
      .post("/admin/uploadImages", formData, config)
      .then((res) => {
        if (res.data.status === 200) {
          setAllImages({});
          setFileArrayVal([]);
          removeFile();
          setResponse({
            type: "success",
            message: res.data.msg,
            key: Date.now(),
          });
          setTimeout(() => {
            setResponse({});
          }, 10000);
        } else if (res.data.status === 400) {
          setResponse({
            type: "error",
            message: res.data.msg,
            key: Date.now(),
          });
        } else {
          showNotification({
            type: "error",
            message: "Failed",
            description: res?.data?.msg,
          });
        }
      })
      .catch((er) => {
      })
      .finally(() => setIsloading(false));
  }

  const handleImageInput = (e, indx, bannerName) => {
    const { name, files: inputFiles } = e.target;
    console.log(inputFiles);
    const totalSize = Object.values(inputFiles).reduce((t, {size}) => t + size, 0);
    let isAllow = false;
    Object.keys(inputFiles).forEach(function(key, index) {
      if (!inputFiles[key].name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (totalSize > 1024 * 1024 * 100) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 100MB size",
        });
        return;
      } else {
        isAllow = true;
      }
    });
    if(isAllow) {
      let sArr = {...inputFiles}
      let fileArray = [];
      if(isActive) {
        Object.keys(sArr).forEach(function(key, index) {
          new Compressor(sArr[key], {
            convertTypes: "image/png",
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
              const myFile = new File([compressedResult], compressedResult?.name, {
                type: compressedResult.type,
              });
              sArr[key] = myFile
            }
          })
        })
        setAllImages(sArr);
      } else {
        setAllImages(sArr);
      }
      for (let i = 0; i < inputFiles.length; i++) {
        fileArray.push(URL.createObjectURL(inputFiles[i]))
      }
      setFileArrayVal(fileArray)
    }
  };
  
  // useEffect(() => {
  //   console.log(compressedImage)
  // },[compressedImage])

  return (
    <div className={styles.management_contents}>
      {/* Header */}
      <SearchComponents />
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Click to go back img"
            onClick={handleClose}
            className="cursor-pointer"
          />
          Bulk Upload
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button
            className={`${styles.btn} ${styles.btn_filled} text-decoration-none cursor-pointer`}
            disabled={!file}
          >
            Save
          </button> */}
          <ButtonComponent
            className="rnd"
            attributes={{ disabled: (!file && allImages.length===0), onClick: submitBulkUpload }}
          >
            {isLoading? "Uploading...":"Upload"}
          </ButtonComponent>
        </div>
      </div>
      {/* Header ends*/}

      <div className={styles.management_details}>
        <div className="row mb-4">
          <div className="col-lg-6">
            {/* Select Entity type */}

            {!props.region === "region" || props.product === "product" ?
              <>
                <h4>Import</h4>
                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_form}>
                    <label htmlFor="">Entity Type</label>
                    <select
                      className={`${styles.form_control} form-control`}
                      name="entityType"
                      value={entityType}
                      onChange={handleInputChange}
                    >
                      <option value="Single / Multiple Products">Upload Single/Multiple Products</option>
                      <option value="inventoryAndPrice">Upload Product Price</option>
                      <option value="faq">Upload FAQs</option>
                      <option value="image">Upload Images</option>
                    </select>
                  </div>
                </div>
              </>
              : ""}
            {/* Select Entity type ends*/}

            {/* File input controls */}
            {(props.region === "region" || props.product === "product") && entityType !== "image" ?
              <>
                <h4>File to Import</h4>
                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.file_import}`}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="d-block" htmlFor="fileInput">Select File to Import</label>
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <span className={styles.choose_file}>Choose file</span>
                        </label>

                        {file?.name ? (
                          <>
                            <span className={styles.choosed_file_name}>
                              {file.name || ""}
                            </span>

                            <img
                              src={CloseIconSmall}
                              className={styles.close_icon + " cursor-pointer"}
                              alt="Remove icon"
                              title="Remove"
                              onClick={removeFile}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                    <input
                      ref={inputFileElRef}
                      type="file"
                      id="fileInput"
                      value=""
                      // accept={props.product === "product" ? ".csv" : ".xlsx"}
                      className="d-none"
                      onInput={handleFileInput}
                    />
                  </div>
                </div>
              </>
              : ""}
            {/* File input controls ends */}

            {entityType === "image" &&
              <div className="row">
                  <div className="col-lg-6 mb-4">
                      <div className="d-flex align-items-right justify-content-between">
                          <div className={commonstyles.abandoned_email}>
                              <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                  <Checkbox 
                                    name="isActive"
                                    checked={isActive}
                                    onChange={()=> setIsActive(!isActive)}
                                  >
                                    Image compressor
                                  </Checkbox>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            }

            {entityType === "image" &&
              <>
                <h4>Images to Import</h4>
                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.file_import}`}
                  >
                    <input
                        name="image"
                        type="file"
                        accept="image/*"
                        id="imageInput"
                        value=""
                        multiple
                        onChange={(e) => handleImageInput(e)}
                    />
                  </div>
                </div>
              </>
            }
          </div>

          {entityType === "image" && <div className="col-lg-6">
            <div className={`${styles.management_details_form} `}>
              <h4>Images Uploaded</h4>
              <div className={styles.management_details_contents}>
                <div className={`${styles.management_details_form} ${styles.bulkuploadimageheight}`}>
                  <div className={styles.bulk_img_list}>
                    {fileArrayVal?.length > 0 && fileArrayVal.map((item, index) => (
                        // <img className={styles.added_image} src={bulkImg} alt="Bulk Img"></img>
                        <img 
                          class={styles.added_image} 
                          key={index}
                          src={item} 
                          alt={index}
                        />
                    ))}
                    {/* <img src={CloseIconSmall} className={`${styles.close_icon} ${styles.bulk_close_icon}`}/> */}
                  </div>
                </div>
              </div>
              {entityType === "image" && fileArrayVal?.length > 0 &&<ButtonComponent
                className="rnd"
                attributes={{ onClick: () => {
                  setAllImages({});
                  setFileArrayVal([]);
                }}}
              >
                Remove All Images
              </ButtonComponent>}
            </div>
          </div>}

          <div className="col-lg-6">
            {response.key ? (
              <Alert
                key={response.key}
                className="alert_message"
                closable
                message={response.message}
                type={response.type}
                showIcon
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
