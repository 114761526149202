import { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom"
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import adminEdit from "../../../assets/images/admin_product_edit_icon.svg";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import style from "../../../styles/Login.module.css";
import Search from "../../../assets/images/search_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import ProfileDummy from "../../../assets/images/profile_dummy.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
import { Tabs } from 'antd';
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ModalComponent from "../../modules/ReusableComponent/ModalComponent";
import { Modal, Button } from 'antd';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import OTPScreen from "react-otp-input";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
    emailRegx,
    minChar,
    passwordReg,
    phoneRegx,
    Saleswithletters,
    Catalogwithletters,
    Discountwithletters,
    Customerwithletters,
  } from "../../../utils/RegularExp";
  import {
    formatServerValidtionErrors,
    runValidationChecks,
  } from "../../../utils";
  const initialFormState = {
    email: "",
    name: "",
    phone: "",
  };
  /* const rules = {
    name: [(val, data) => (!val ? "Name " : true)],
    email: [(val, data) => (!val ? "Email " : true)],
    phone: [(val, data) => (!val ? "Phone " : true)],
  }; */
export default function DashboardProfile() {
  const [profileUpdate, setProfileUpdate] = useState(initialFormState);
  const [profileList, setProfileList] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const { TabPane } = Tabs;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editMode,setEditMode]=useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
//   let [ data ,setData ] = useState({  profileImg : null,});
  const [fileList, setFileList] = useState([]);
  const [pa, setPa] = useState("");
  const [newpa, setNewPa] = useState("");
  const [confirm, setConfirm] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  /* const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  console.log(fileList); */

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getProfileList();
  }, []);

  function getProfileList() {
    // setIsLoading(false);
    // setIsLoading({ delete: false, list: true });

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get("/admin/profileDetails")
      .then((res) => {
          // console.log(res.data.data);
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading(false);
          // setIsLoader(false);
          // setIsLoading({ delete: false, list: false });
          setProfileList(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        // setIsLoading({ delete: false, list: false });
      });
  }

  function updateProfilePassword() {
    // setIsLoading(false);
    // setIsLoading({ delete: false, list: true });
    
    const data = {
      // _id: id,
      password: pa,
      newpassword: newpa,
      confirmpassword: confirm,
    };
    if(data.password || data.newpassword || data.confirmpassword){
      if (!passwordReg.test(data.password) || !data.password === "") {
        setFormErrors({...formErrors,pa : "At least 1 number, upper & lower case and special character"})
        return false
      }else{
        setFormErrors({...formErrors,pa : ""})
      }
      if (!passwordReg.test(data.newpassword) || !data.newpassword === "") {
        setFormErrors({...formErrors,newpa : "At least 1 number, upper & lower case and special character"})
        return false
      }else{
        setFormErrors({...formErrors,newpa : ""})
      }
        if(data.newpassword !== data.confirmpassword) {
          formErrors.confirm ="Confirm password should be same as new password";
        } else {
          formErrors.confirm = "";
        }
    }
    /* if (data.password || data.newpassword || data.confirmpassword) {
      if (
        !passwordReg.test(data.password) ||
        !passwordReg.test(data.newpassword) ||
        !passwordReg.test(data.confirmpassword)
      ) {
        showNotification({
          type: "warning",
          message: "There were few errors",
          description:
            "At least 1 number, upper & lower case and special character",
        });
        return;
      }
    } */
    /* const result = runValidationChecks(rules, undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    } */

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/admin/updatePassword", data)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "User details updated successfully",
          });
          history.push(`/dashboard`)
          return;
        } else if (
          res.data.status === 400 &&
          res.data.msg === "New password and confirm password not match"
        ) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: "New password and confirm password not match",
          });
          return;
        } else if (res.data.status === 400) {
          showNotification({
            type: "warning",
            message: "There were few errors",
            description: res.data.msg,
          });

          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        // setIsLoading(false);
        // setIsLoading({ delete: false, list: false });
      });
  }

  const onPasswordVisible = () => {
    setVisible(!visible);
  };
  const onPasswordVisible1 = () => {
    setVisible1(!visible1);
  };
  const onPasswordVisible2 = () => {
    setVisible2(!visible2);
  };
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // console.log(editMode);

  function editBanner() {
    setEditMode(true);
  }

  /* const upLoadImg = (e) => {
    e.preventDefault();
    console.log(data.profileImg);
  }
  const onFileChange = event => {
    console.log(event.target.files[0]);
    setData({ profileImg : event.target.files[0] });
  };
console.log("data",data);*/

//html

// { selectedImage ? 
//     <img width={"150px"} src={ URL.createObjectURL(selectedImage) } /* className = { styles.img_edit_profile } */ alt="" /> : 
//     <img width={"150px"} src={ ProfileDummy } /* className = { styles.img_edit_profile } */ alt="" />
// }
{/* <input
    type="image"
    name="myImage"
    onChange={(event) => {
        console.log(event.target.files[0]);
        setSelectedImage(event.target.files[0]);
    }}
/>  */}

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
      <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            // onClick={() => history.push("/banner")}
          />
           General setting
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="status"
              className={styles.onoffswitch_checkbox}
            //   onChange={handleFormInput}
            //   checked={editForm?.status}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd"
            attributes={{
              // disabled: loading.update,
              onClick: updateProfilePassword,
            }}
          >
         Save{/* {loading.update ? "Saving" : "Save"} */}
          </ButtonComponent>
        </div>
      </div>
          <div className="card-container">
                <Tabs type="card">
                <TabPane tab="Profile" key="1">
                <div className={styles.management_details_contents}>
                    <div class="container my-4">
                        <div className="row">
                            <div class="col-md-2 mb-4">
                                <div>
                                    {/* {selectedImage && (
                                        <div>
                                        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                                        <button onClick={()=>setSelectedImage(null)}>Remove</button>
                                        </div>
                                    )} */}
                                    <ImgCrop rotate>
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={onChange}
                                            // onPreview={onPreview}
                                        >
                                            {fileList.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                            </div>
                            <div class="col-md-9 mb-4">
                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                    <label for="">User Name : </label>
                                    <InputComponent
                                        className={`form-control form-control-lg ${styles.form_control}`}
                                        
                                        formControl={{
                                        placeholder: "",
                                        name: "name",
                                        disabled:editMode===false,
                                        value: profileList.name,
                                        //   onChange: (e) => handleAvenueFormChange(e, indx),
                                        }}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                    <label for="">Email :</label>
                                    <InputComponent
                                        className={`form-control form-control-lg ${styles.form_control}`}
                                        
                                        formControl={{
                                        placeholder: "",
                                        name: "email",
                                        disabled:editMode===false,
                                        value: profileList.email,
                                        //   onChange: (e) => handleAvenueFormChange(e, indx),
                                        }}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                    <label for="">Phone : </label>
                                    <InputComponent
                                        className={`form-control form-control-lg ${styles.form_control}`}
                                        
                                        formControl={{
                                        placeholder: "",
                                        name: "phone",
                                        disabled:editMode===false,
                                        value: profileList.phone,
                                        //   onChange: (e) => handleAvenueFormChange(e, indx),
                                        }}
                                    />
                                    </div>
                                </div>
                                {/* <div className="col-lg-12 mb-4">
                                    <div className={styles.management_details_form}>
                                    <label for="">Address : </label>
                                        <textarea
                                            className={`${commonstyles.form_control} form-control form-control-lg `}
                                            disabled={editMode===false}
                                            placeholder="Type your Description"
                                            rows="4"
                                            autoComplete="off"
                                            name="description"
                                            // value={addForm.description}
                                            // onChange={handleFormInput}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div class="col-md-1 mb-4">
                                {/* <img
                                    src={adminEdit}
                                    className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                                    onClick={editBanner}
                                    alt="Edit action icon"
                                    title="Edit profile"
                                /> */}
                            </div>
                            {/*<Button type="primary" onClick={showModal}>Change Password</Button>
                                 <Modal title="Verify to change password" visible={isModalVisible} okText="Verify" onOk={handleOk} onCancel={handleCancel}>
                                    <div className="col-lg-12 mb-4">
                                        <div className={styles.management_details_form}>
                                            <label for="">Email/Mobile</label>
                                            <InputComponent
                                                className={`form-control form-control-lg ${styles.form_control}`}
                                                
                                                formControl={{
                                                placeholder: "example@gmail.com/9876543210",
                                                name: "apiKey",
                                                //   value: avenue.apiKey,
                                                //   onChange: (e) => handleAvenueFormChange(e, indx),
                                                }}
                                            />
                                        </div>
                                        <div className={style.otp_input_section}>
                                          <OTPScreen
                                            className={`otp_input_form ${style.otp_input_field} ${style.otp_input_field_admin}`}
                                            numInputs={6}
                                            // value={props.otp}
                                            // onChange={props.onChangeOtp}
                                            separator={<span>-</span>}
                                          />
                                        //  {props.otpError && props.otpError.trim() !== "" ? (
                                        //     <span className={style.error} data-test="otp_error">
                                        //       {props.otpError}
                                        //     </span>
                                        //   ) : (
                                        //     ""
                                        //   )}
                                        </div>
                                     </div>
                                </Modal> */}
                                {/* <a href="" onClick={()=>setIsEnable(true)}>Change Password</a> */}
                                <Button type="primary" onClick={()=>setIsEnable(true)}>Change Password</Button>
                        </div>
                        {isEnable &&
                          <div className="row">
                              <div className="col-lg-6">
                                  <label>Old Password</label>
                                  <input
                                  type={visible ? "text" : "password"}
                                  visible={visible}
                                  onPasswordVisible={onPasswordVisible}
                                  className={` ${styles.form_control} form-control form-control-lg mb-4 `}
                                  placeholder="-"
                                  onChange={(e) => {
                                        if (!e.target.value.match(passwordReg) || !e.target.value === "") {
                                            formErrors.pa = "At least 1 number, upper & lower case and special character and minimum 8 characters";
                                        }else{
                                            formErrors.pa = "";
                                        }
                                        setPa(e.target.value);
                                      }}
                                  />
                                  <span
                                  id="password"
                                  className="pass_eye_icon"
                                  onClick={onPasswordVisible}
                                  style={{
                                      position: "absolute",
                                      top: "40px",
                                      right: "30px",
                                  }}
                                  >
                                  <img
                                      src={visible ? eyeClose : eyeOpen}
                                      alt="visible"
                                      onClick={onPasswordVisible}
                                      className={styles.eyeIcon}
                                  />
                                  </span>
                                  <FormErrorText error={formErrors.pa} />
                              </div>
                              <div className="col-lg-6">
                                  <label>New Password</label>
                                  <input
                                  type={visible1 ? "text" : "password"}
                                  visible={visible1}
                                  onPasswordVisible1={onPasswordVisible1}
                                  className={` ${styles.form_control} form-control form-control-lg mb-4 `}
                                  placeholder="-"
                                  onChange={(e) => {
                                      if (!e.target.value.match(passwordReg) || !e.target.value === "") {
                                          formErrors.newpa = "At least 1 number, upper & lower case and special character and minimum 8 characters";
                                      }else{
                                          formErrors.newpa = "";
                                      }
                                      setNewPa(e.target.value);
                                  }}
                                  />
                                  <span
                                  id="password"
                                  className="pass_eye_icon"
                                  onClick={onPasswordVisible1}
                                  style={{
                                      position: "absolute",
                                      top: "40px",
                                      right: "30px",
                                  }}
                                  >
                                  <img
                                      src={visible1 ? eyeClose : eyeOpen}
                                      alt="visible"
                                      onClick={onPasswordVisible1}
                                      className={styles.eyeIcon}
                                  />
                                  </span>
                                  <FormErrorText error={formErrors.newpa} />
                              </div>
                              <div className="col-lg-6">
                                  <label>Confirm Password</label>
                                  <input
                                  type={visible2 ? "text" : "password"}
                                  visible2={visible2}
                                  onPasswordVisible2={onPasswordVisible2}
                                  className={` ${styles.form_control} form-control form-control-lg mb-4 `}
                                  placeholder="-"
                                  onChange={(e) => {
                                      if (newpa !== e.target.value) {
                                      formErrors.confirm =
                                          "Confirm password should be same as new password";
                                      } else {
                                      formErrors.confirm = "";
                                      }

                                      setConfirm(e.target.value);
                                  }}
                                  />
                                  <span
                                  id="password"
                                  className="pass_eye_icon"
                                  onClick={onPasswordVisible2}
                                  style={{
                                      position: "absolute",
                                      top: "40px",
                                      right: "30px",
                                  }}
                                  >
                                  <img
                                      src={visible2 ? eyeClose : eyeOpen}
                                      alt="visible"
                                      onClick={onPasswordVisible2}
                                      className={styles.eyeIcon}
                                  />
                                  </span>
                                  <FormErrorText error={formErrors.confirm} />
                              </div>
                          </div>
                        }
                    </div>
                </div>
                </TabPane>
                </Tabs>
            </div>
    </div>
  );
}
