import styles from "../../../styles/AddProduct.module.css";
import EmbeddedProductsTableComponent from "./EmbeddedProductsTableComponent";

export default function RelatedProductSelectFormComponent(props) {
  return (
    <div className="col-lg-12 mb-4">
      <div className={styles.table_heading_toggle}>
        <h4 className="mr-4 theme-form-section-title">{props.title}</h4>
        {!props?.isToggle&&<div
          className={`${styles.onoffswitch} ${styles.yes_no} d-inline-block align-top`}
        >
          <input
            type="checkbox"
            name={props.formField}
            className={styles.onoffswitch_checkbox}
            id={`${props.formField}switch`}
            checked={props.isVisible}
            onChange={props.handleComponentVisibility}
          />
          <label
            className={styles.onoffswitch_label}
            htmlFor={`${props.formField}switch`}
          >
            <span className={styles.onoffswitch_inner}></span>
            <span className={styles.onoffswitch_switch}></span>
          </label>
        </div>}
      </div>
      {props.isVisible ? (
        <EmbeddedProductsTableComponent
          products={props.products}
          selectedProducts={props.selectedProducts}
          handleSelection={props.updateSelectedProducts}
          formField={props.formField}
        />
      ) : null}
    </div>
  );
}
