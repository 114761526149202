import { Space, Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";

// const icon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export default function LoadingIndicator(props) {
  const text = props.loadingText || "Loading...";
  const size = props.size || "large";
  const wrapperClass = props.className || "d-flex justify-content-center";
  const textClass = props.textClass || "text-black-50 mt-2";
  return (
    <div className={wrapperClass}>
      <Space size="small">
        <Spin /* indicator={icon} */ size={size} tip={text}  className={textClass} />
      </Space>
    </div>
  );
}
