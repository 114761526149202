import React, { useEffect, useState } from "react";
import LoginComponent from "./components/pages/LoginNew/LoginComponent";
import { AxiosInstance, PORTS } from "./utils/apiService";
import { isAuthenticated } from "./utils";
import VerifyOTPComponent from "./components/pages/VerifyOTP/VerifyOTPComponent";
import { emailRegx, otpRegx } from "./utils/RegularExp";
import NewPasswordComponent from "./components/pages/LoginNew/NewPasswordComponent";
import ForgotComponent from "./components/pages/LoginNew/ForgotComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/globals.css";

import {
  // BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PrivateRoutes from "./components/pages/PrivateRoutes";

const App = (props) => {
  // const router = '';
  const history = useHistory();
  const location = useLocation();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [loginFormError, setloginFormError] = useState({
    email: "",
    password: "",
  });
  const [isLoginErrorEmpty, setIsLoginErrorEmpty] = useState(false);
  const [isLoginEmpty, setisLoginEmpty] = useState(true);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({
    message: null,
    type: null,
  });
  const [isAuthenticate, setAuthenticate] = useState(false);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    if (isAuthenticated()) {
      setAuthenticate(true);
      if (location.pathname !== "/") {
        history.push(location.pathname);
      } else {
        history.push("/dashboard");
      }
    } else {
      setAuthenticate(false);
    }

    const isLoginErrorEmpty = Object.values(loginFormError).every(
      (x) => x === null || x === ""
    );
    setIsLoginErrorEmpty(isLoginErrorEmpty);

    const isLoginEmpty = Object.values(loginForm).every(
      (x) => x === null || x === ""
    );
    setisLoginEmpty(isLoginEmpty);
  }, [loginFormError, loginForm]);

  const onPasswordVisible = () => {
    setVisible(!visible);
  };

  const removeErrorMsg = () => {
    setError({
      message: null,
      type: null,
    });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const loginFormErrorNew = { ...loginFormError };

    switch (name) {
      case "email":
        value.trim() === ""
          ? (loginFormErrorNew.email = "Please enter an email address")
          : (loginFormErrorNew.email = "");
        break;
      case "password":
        value.trim() === ""
          ? (loginFormErrorNew.password = "Please enter a password")
          : (loginFormErrorNew.password = "");
        break;
      default:
        break;
    }

    setLoginForm({ ...loginForm, [name]: value });
    setloginFormError(loginFormErrorNew);
  };
  let [ clickable , setClickable ] = useState(false);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    setClickable(true)
    const loginFormErrorNew = { ...loginFormError };
    if (loginForm.email.trim() === "") {
      loginFormErrorNew.email = "Please enter an email address";
    } else if (!emailRegx.test(loginForm.email.trim())) {
      loginFormErrorNew.email = "Please enter a valid email address";
    } else {
      loginFormErrorNew.email = "";
    }

    if (loginForm.password.trim() === "") {
      loginFormErrorNew.password = "Please enter a password";
    } else {
      loginFormErrorNew.password = "";
    }
    setloginFormError(loginFormErrorNew);
    const isFormEmpty = Object.values(loginFormErrorNew).every(
      (x) => x === null || x === ""
    );
    if (isLoginErrorEmpty && !isLoginEmpty && isFormEmpty) {
      login_handler();
    }else {
      setClickable(false)
    }
  };

  const login_handler = () => {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/admin/login", loginForm)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          setError({
            message: response.msg,
            type: "success",
          });
          // setOtp(response?.otp);
          setTimeout(() => {
            removeErrorMsg();
            setShow(true);
          }, 3000);
          setClickable(false)
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setError({
            message: response.msg,
            type: "error",
          });
          setTimeout(() => {
            removeErrorMsg();
          }, 5000);
          setClickable(false)
        }
      })
      .catch((err) => {
        var error = err && err.response;
        setClickable(false)
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            // window.location.href = "/";
            history.push("/");
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          setError({
            message: "Internal Server Error",
            type: "success",
          });
          setTimeout(() => {
            removeErrorMsg();
          }, 5000);
        }
      });
  };

  const onOtpModalExit = () => {
    setLoginForm({
      email: "",
      password: "",
    });
    setloginFormError({
      email: "",
      password: "",
    });
    setShow(false);
    setOtpError("");
    setOtp("");
  };

  const onChangeOtp = (otp) => {
    var otpErr = "";
    if (otp.trim() === "") {
      otpErr = "";
    } else if (!otpRegx.test(otp)) {
      otpErr = "Please enter a 6-digit pin";
    } else {
      otpErr = "";
    }
    setOtp(otp);
    setOtpError(otpErr);
  };
  const onVerifyOtp = (e) => {
    e.preventDefault();
    setClickable(true)
    let otpErr = "";
    if (otp.trim() === "") {
      otpErr = "Please enter a otp";
    } else if (!otpRegx.test(otp.trim())) {
      otpErr = "Please enter a 6-digit pin";
    } else {
      otpErr = "";
    }
    setOtpError(otpErr);
    if (otpError.trim() === "" && otp.trim() !== "") {
      let data = {
        email: loginForm.email,
        otp: otp,
      };
      verify_otp_api(data);
    }
    else{
      setClickable(false)
    }
  };
  const verify_otp_api = (data) => {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/verifyTfa", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const roleName=response?.roleName?.name?.charAt(0)?.toUpperCase() + response?.roleName?.name?.slice(1)
          localStorage.setItem("token", response.token);
          localStorage.setItem("role", response?.role);
          localStorage.setItem("accessregion", response?.region || '');
          localStorage.setItem("roleName", roleName || '');
          if (response.permissions.length > 0) {
            localStorage.setItem(
              "permissions",
              JSON.stringify(response.permissions)
            );
          }

          setError({
            message: response.msg,
            type: "success",
          });
          setTimeout(() => {
            removeErrorMsg();
            setAuthenticate(true);
            // router.push("/dashboard");
            history.push("/dashboard");
            setShow(false);
          }, 3000);
          setClickable(false)
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setError({
            message: response.msg,
            type: "error",
          });
          setTimeout(() => {
            removeErrorMsg();
            setAuthenticate(false);
          }, 5000);
          setClickable(false)
        }
      })
      .catch((err) => {
        var error = err && err.response;
        setClickable(false)
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          setError({
            message: "Internal Server Error",
            type: "success",
          });
          setTimeout(() => {
            removeErrorMsg();
            setAuthenticate(false);
          }, 5000);
        }
      });
  };
  const resend_otp_handler = () => {
    setOtpError("");
    const data = {
      email: loginForm.email,
      password: loginForm.password,
    };
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/admin/login", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          setOtp("");
          setError({
            message: "OTP resent successfully",
            type: "success",
          });
          setTimeout(() => {
            removeErrorMsg();
          }, 3000);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setError({
            message: response.msg,
            type: "error",
          });
          setTimeout(() => {
            removeErrorMsg();
          }, 5000);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          setError({
            message: "Internal Server Error",
            type: "success",
          });
          setTimeout(() => {
            removeErrorMsg();
          }, 5000);
        }
      });
  };

  const go_back_to_signin = () => {
    setLoginForm({
      email: "",
      password: "",
    });
    setloginFormError({
      email: "",
      password: "",
    });
    setOtpError("");
    setOtp("");
    setAuthenticate(false);
    setShow(false);
  };
  // console.log(show , isAuthenticate);
  return (
    <React.Fragment>
      {/* <Router> */}
      <Switch>
        <Route path="/" exact>
          {!show && !isAuthenticate ? (
            <LoginComponent
              visible={visible}
              loginFormError={loginFormError}
              error={error}
              onPasswordVisible={onPasswordVisible}
              onInputChange={onInputChange}
              onLoginForm={onLoginFormSubmit}
              clickable={clickable}
            />
          ) : (
            // <LoginOTPComponent
            //   show={show}
            //   otp={otp}
            //   otpError={otpError}
            //   error={error}
            //   resend_otp_handler={resend_otp_handler}
            //   onOtpModalExit={onOtpModalExit}
            //   onChangeOtp={onChangeOtp}
            //   onVerifyOtp={onVerifyOtp}
            // />
            <VerifyOTPComponent
              show={show}
              otp={otp}
              otpError={otpError}
              error={error}
              resend_otp_handler={resend_otp_handler}
              onOtpModalExit={onOtpModalExit}
              onChangeOtp={onChangeOtp}
              onVerifyOtp={onVerifyOtp}
              go_back_to_signin={go_back_to_signin}
              clickable={clickable}
            />
          )}
        </Route>
        <Route path="/Forgotpassword" exact>
        <ForgotComponent/>
        </Route>
        <Route path="/Verifyotp" exact>
        <VerifyOTPComponent/>
        </Route>
        <Route path="/Newpassword" exact>
        <NewPasswordComponent/>
        </Route>
        <PrivateRoute path="/">
          <PrivateRoutes />
        </PrivateRoute>
      </Switch>
      {/* </Router> */}
    </React.Fragment>
    // <VerifyOtp />
  );
};

export default App;
