import React from "react";
const InputComponent = (props) => {
  let classes = "form-control theme-form-control";
  if (props.className) {
    classes = `${classes} ${props.className}`;
  }
  if (props.error) {
    classes = `${classes} theme-error-input`;
  }

  return (
    <input
      min={props.min}
      max={props.max}
      autoComplete="off"
      type="text"
      {...props.formControl}
      className={classes}
      style={props?.style}
    />
  );
};

export default InputComponent;
