export default function NoProductsTableRowComponent({ products, filter }) {
  if (Array.isArray(products) && products.length === 0) {
    let message,
      className = "";
    if (typeof filter === "object" && filter.hasOwnProperty("searchKey")) {
      if (
        typeof filter.searchKey === "string" &&
        filter.searchKey.trim().length > 0
      ) {
        // message = "Product not available";
        message = "No data found";
        // className = "text-danger";
      } else {
        message = "No data found";
      }

      return (
        <tr className="bg-transparent">
          <td colSpan="4" className={className}>
            {message}
          </td>
        </tr>
      );
    }
  }
  return null;
}
