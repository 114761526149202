const Tabs = [
  {
    id: 1,
    key: 1,
    name: "Email Templates",
    link: "communications",
    isActive: true,
  },
  {
    id: 2,
    key: 2,
    name: "Email Logs",
    link: "communications/emailogs",
    isActive: false,
  },
  {
    id: 3,
    key: 3,
    name: "Newsletter Templates",
    link: "communications/newstemplate",
    isActive: false,
  },
  {
    id: 4,
    key: 4,
    name: "Newsletter Templates",
    link: "communications/newstemplate",
    isActive: false,
  },
  {
    id: 4,
    key: 4,
    name: "Newsletter Subscribers",
    link: "communications/newssubs",
    isActive: false,
  },
  {
    id: 5,
    key: 5,
    name: "SMS Templates",
    link: "communications/smstemplates",
    isActive: false,
  },
  {
    id: 6,
    key: 6,
    name: "SMS Logs",
    link: "smslogs",
    isActive: false,
  },
];

export default Tabs;
