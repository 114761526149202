import React, { useEffect, useState, useRef } from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/PaymentNew.module.css";
import WYSIWYGEditor from "../../../modules/ReusableComponent/WYSIWYGEditor";
import { Checkbox, DatePicker, notification, Select, Switch, TimePicker, Space } from 'antd';
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import moment from "moment";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { Link } from "react-router-dom";
import { clone, upperCase, upperFirst } from "lodash";

export default function AdandonedCheckoutDetails(props) {
    const history = useHistory();
    const { state } = history.location;
    const [isLoading, setIsLoading] = useState(false);
    const [AbandonedCartEmailDetails, setAbandonedCartEmailDetails] = useState([]);

    useEffect(() => {
        // console.log(state);
        checkoutDetails(state);
        return () => {
            history['isAbandonDet']  = true;
        }
    }, []);

    function checkoutDetails(_state) {
        setIsLoading(true);
        let params = { abandonedCartId: _state?._id }
        AxiosInstance(8003, "application/json")
        .post("admin/abandonedCartEmailDetails ", params)
        .then((res) => {
          if (res && res.data && res.status == "200") {
            setIsLoading(false);
            let data = res.data?.data?.abandonedEmailsDetail[0];
            console.log(data);
            setAbandonedCartEmailDetails(data);
          }
        })
        .catch((er) => {
          const data = { type: "error" };
          if (er.response) {
            data.message = "Error";
            data.description = "Failed to get data";
            setIsLoading(false);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return (
        <div>
            {isLoading === true ? (
              <div style={{ marginLeft: "40%" }}>
               <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching checkout details..." />
                 </td>
                </tr>
              </div>
             ) : (
        <div className={`${commonstyles.management_contents} ${styles.management_contents}`}>
            <SearchComponent />
            <div className={commonstyles.management_header}>
                <h1 className={commonstyles.management_header_heading}>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => history.push("/abandonedDashboard")}
                    />
                    Abandoned Checkout Details
                </h1>
                {/* <ButtonComponent
                    className="rnd pl-3 pr-4"
                >
                    Send Email
                </ButtonComponent> */}
            </div>
            <div className={`${commonstyles.management_header} ${commonstyles.subheading_title} ${commonstyles.no_border_bottom} mb-2 pb-0`}>
                    <h4>Products</h4>
                    <h4>Abandoned Checkout ID: <span># {AbandonedCartEmailDetails?.abandonedId}</span></h4>
            </div>
            <div className={`${styles.management_details}`}>
            <div className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}>
                <div className="row">
                    <div className={`${styles.abandoned_checkout} col-lg-9 mb-2 `}>
                        <div className="row">
                            <div className="col-lg-4">
                                <p>User Name</p>
                            </div>
                            <div className="col-lg-8">
                                <h6>{upperFirst(AbandonedCartEmailDetails?.customerName)}</h6>
                            </div>
                            <div className="col-lg-4">
                                <p>Contact Information</p>
                            </div>
                            <div className="col-lg-8">
                            <h6>{upperFirst(AbandonedCartEmailDetails?.customerEmail)} | {AbandonedCartEmailDetails?.customerPhone === undefined || AbandonedCartEmailDetails?.customerPhone.length !== 10 ? AbandonedCartEmailDetails?.customerPhone : AbandonedCartEmailDetails?.customerPhone.length === 10 && '+91' + AbandonedCartEmailDetails?.customerPhone}</h6>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.abandoned_checkout_status} col-lg-3 mb-2 text-right`}>
                        <p>Status: <span>{AbandonedCartEmailDetails?.abandonedStatus===1? "Recovered":"Abandoned"}</span></p>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <hr />
                    </div>
                </div>
                <div className={`${styles.abandoned_checkout} `}>
                    {AbandonedCartEmailDetails?.products?.length>0 && AbandonedCartEmailDetails?.products.map(item => (
                        <div key={item._id} className="row">
                            <div className="col-lg-3">
                                <h6>Product</h6>
                                <p className={`${styles.abandoned_checkout_product_name} `}>{upperFirst(item?.productname)}</p>
                            </div>
                            <div className="col-lg-3">
                                <h6>Quantity</h6>
                                <p>{item?.quantity}</p>
                            </div>
                            <div className="col-lg-3">
                                <h6>Checkout Status</h6>
                                <p>{AbandonedCartEmailDetails?.abandonedStatus===1? "Abandoned":"Recovered"}</p>
                            </div>
                            <div className="col-lg-3">
                                <h6>Sub Total</h6>
                                <p>Rs. {item?.actualAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div >
                {AbandonedCartEmailDetails?.emailSent?.length>0 && AbandonedCartEmailDetails?.emailSent.map((email, indx) => (
                    <div key={indx}>
                        <div className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}>
                            <div class="row">
                            <div className={`${styles.abandoned_checkout} col-lg-9 mb-2 `}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6>{upperFirst(email?.emailType)}</h6>
                                    </div>
                                    <div className="col-lg-12">
                                        <p>Abandoned checkout was recovered {moment(email?.emailSentDate).format("DD MMM, YYYY")}{email?.orderId&&", can "} 
                                            {email?.orderId&&<Link to={`/order/${email.orderId}`} href="#">View the order.</Link>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.abandoned_checkout_status} col-lg-3 mb-2 text-right`}>
                                <p>Status: <span>{email?.status===1?"Recovered":"Sent"}</span></p>
                            </div>
                            </div>
                            <div class="row">
                                {email?.emailLink&&<div className={`${styles.abandoned_checkout_link} col-lg-12 mb-2 `}>
                                <a target="_blank" href={email?.emailLink}>{email?.emailLink}</a>
                                </div>}
                        </div>
                        </div>

                       
                    </div>
                ))}
            </div>
            {/* <div className={`${styles.management_details_contents} ${styles.abandoned_dashboard_box}`}>
                <div className="row">
                    <div className={`${styles.abandoned_checkout} col-lg-9 mb-2 `}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h6>1st reminder Email</h6>
                            </div>
                            <div className="col-lg-12">
                                <p>A reminder email was sent to the customer 6 Aug 2022</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.abandoned_checkout_status} col-lg-3 mb-2 text-right`}>
                        <p>Email Status: <span>Sent</span></p>
                    </div>
                    <div className={`${styles.abandoned_checkout_link} col-lg-12 mb-2 `}>
                        <a>http://damro.com/9383958/checkout/19823998294uj9q349j9q380001210</a>
                    </div>
                </div>
            </div> */}
        </div>
     </div>
    )}
    </div>
    )
}