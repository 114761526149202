import React, { useEffect, useState } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import { Popover } from "antd";
import FormErrorText from "./FormErrorText";
import ProductOptionSearchSelectComponent from "./ProductOptionSearchSelectComponent";
import validationRules from "../../../utils/validationRules";
const initial={
  warrenty1:"",
  warrenty2:"",
  width:"",
  // depth:"",
  // height:"",
}
export default function ProductOptionsFormComponent(props) {
  // console.log(props)
  //Saves propsOption-index as index  and value as in request format ({optionId: "", optionType: ""})
  //as objects in selectedOptions
  const [selectedOptions, updateSelectedOptions] = useState([]);
  const [searchKey, setSearchKey] = useState(undefined);
  const { options: propOptions, selected: propSelected, formErrors } = props;
  // const [formErrors, setFormErrors] = useState({})
  const [form, setForm]= useState(initial)
  // const rules = {
  //   warrenty1: [validationRules.required],
  //   warrenty2: [validationRules.required],
  // }
  useEffect(()=>{
    if(propSelected){
      let exitData=propSelected.find((element)=>{
        if(element.key==="Warranty"){
          return true
        }
      })
      // value.split(" ") setForm()
      // console.log(exitData);
      if(exitData){
        // console.log(exitData.value.split(" ")[0]);
        let datas=exitData.value.split(" ")
        form.warrenty1=datas[0];
        form.warrenty2=datas[1];
        setForm({...form});
      }else{
        // war=warrenty;
      }
      
      /* dimensions */
      let exitDataDimensions=propSelected.find((element)=>{
        if(element.key==="Dimensions"){
          return true
        }
      })
      // value.split(" ") setForm()
      // console.log(exitDataDimensions);
      if(exitDataDimensions){
        // console.log(exitData.value.split(" ")[0]);
        let datas=exitDataDimensions.value.split("&&")
        form.width=datas[0];
        // form.depth=datas[1];
        // form.height=datas[2];
        setForm({...form});
      }else{
        // war=warrenty;
      }
    }
  },[]);
  //Load selected options
  useEffect(() => {
    // console.log(props.propSelected);
    // console.log(props.propOptions);
   /*  for(let i=0;i<props.propSelected.length;i++){
      for(let j=0;j<props.propOptions.length;j++){
        if(props.propSelected[i].optionId==props.propOptions[j]){

        }
      }
    } */
    // console.log(propSelected);
    if (Array.isArray(propSelected) && propSelected.length) {
      // const names = propOptions.map((o) => o.name);
      const autoSelectedOptions = [];
      const transformedPropOptions = [];
      //Create a new array of product options to easily find
      //a selected option
      for (let i = 0; i < propOptions.length; i++) {
        let attr = { index: i, optionIds: [] };
        attr.optionIds = propOptions[i].options.map((o) => o._id);
        transformedPropOptions.push(attr);
      }

      // console.log(transformedPropOptions);

      // console.log("---autoselecting---");
      function findIndexOfOption(id) {
        let index = -1;
        for (let i = 0; i < transformedPropOptions.length; i++) {
          // console.log(i);

          const exist = transformedPropOptions[i].optionIds.includes(id);
          // console.log(exist);
          // console.log(id);
          // console.log(transformedPropOptions[i].optionIds);

          if (exist) {
            index = transformedPropOptions[i].index;
            break;
          }
        }
        // console.log(index);
        return index;
      }
      // console.log(propSelected);
      // return;
      for (let i = 0; i < propSelected.length; i++) {
        const _optionId = propSelected[i].optionId? propSelected[i].optionId:propSelected[i]._id;
        let option = { index: undefined, value: {} };
        // console.log(_optionId);
        // console.log(propSelected[i]);

        // let idx = names.indexOf(propSelected[i].key);
        let idx = findIndexOfOption(_optionId);
        // console.log(idx);
        if (idx > -1) {
          option.index = idx;

          // console.log("name ", propSelected[i].key);
          // console.log("idx ", idx);

          option.value.optionType = propOptions[idx]?.optionType || 0;
          option.value.optionId = _optionId;
          option.value.attributeId = propSelected[i].attributeId;
          option.id=propSelected[i].attributeId;
          // console.log(option);
          autoSelectedOptions.push(option);
        }
      }
      // console.log(autoSelectedOptions);
    // selectedOptions.push({ index: indx, value: { optionType, optionId: "" }, id: option._id });


      // console.log("autoSelectedOptions");
      // console.log(autoSelectedOptions);
      if (autoSelectedOptions.length) {
        // console.log(autoSelectedOptions)
        const values = autoSelectedOptions.map((o) => o.value);
        // console.log("values");
        values[0].optionType = values[0].optionType ? values[0].optionType : 0;
        // values[0].attributeId = values[0]._id;
        // console.log(values);
        props.handleUpdate(values);
        updateSelectedOptions(autoSelectedOptions);
        // console.log(autoSelectedOptions);

      }
      // console.log("---autoselecting ends---");
    }
  }, [propOptions]);

  function addOption(indx, option) {
    const optionType = propOptions[indx].optionType || 0;
    form.width=form.width="" ? form.width="" : form.width;
    // form.depth=form.depth="" ? form.depth="" : form.depth;
    // form.height=form.height="" ? form.height="" : form.height;
    form.warrenty1=form.warrenty1="" ? form.warrenty1="" : form.warrenty1;
    form.warrenty2=form.warrenty2="" ? form.warrenty2="" : form.warrenty2;
    selectedOptions.push({ index: indx, value: { optionType, optionId: "" }, id: option._id });
    updateSelectedOptions([...selectedOptions]);
  }

  function updateOptions(value, indx, sel) {
    selectedOptions[indx].value.optionId = value;
    selectedOptions[indx].value.attributeId = sel.id;
    selectedOptions[indx].value.optionType = selectedOptions[indx].value.optionType ? selectedOptions[indx].value.optionType : 0;

    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    // console.log(selectedOptionsArray);
    props.handleUpdate(selectedOptionsArray);
    updateSelectedOptions([...selectedOptions]);
  }

  function removeOptions(indx) {
    if(selectedOptions[indx].id==="60c0d5bbb6b47120e857ab32"){
    selectedOptions.splice(indx, 1);
    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    props.handleUpdate(selectedOptionsArray);
    form.width="";
    // form.depth="";
    // form.height="";
    updateSelectedOptions([...selectedOptions]);
    }else if(selectedOptions[indx].id==="60c0e08cb6b47120e857ab36"){
      selectedOptions.splice(indx, 1);
      let selectedOptionsArray = selectedOptions.map((o) => o.value);
      props.handleUpdate(selectedOptionsArray);
      form.warrenty1="";
      form.warrenty2="";
      updateSelectedOptions([...selectedOptions]);
    }else{
    selectedOptions.splice(indx, 1);
    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    props.handleUpdate(selectedOptionsArray);
    updateSelectedOptions([...selectedOptions]);
  }
  }
  function handle(warrenty1, warrenty2, indx, sel){
    // console.log(warrenty1, warrenty2);
  
    const updatedValues = { ...form };
    updatedValues["warrenty1"] = warrenty1;
    updatedValues["warrenty2"] = warrenty2;
    setForm(updatedValues);
   
    /* let { name, value } = e.target;
    console.log(value, indx, sel);
    const updatedValues = { ...form };
    updatedValues[name] = value;
    setForm(updatedValues);
    console.log(`${form.warrenty1} ${form.warrenty2}`); */
    const warrenty=`${warrenty1} ${warrenty2}`;
    let war;

    let matchedOption= propOptions[9].options.find(element => {
      // console.log(element.value==warrenty ? "true" : "false");
      if(element.name==warrenty && element.value==warrenty){
        return true
      }
    });
    
    if(matchedOption){
      war=matchedOption._id
    }else{
      war=warrenty;
    }

    selectedOptions[indx].value.optionId = war;
    selectedOptions[indx].value.attributeId = sel.id;
    selectedOptions[indx].value.optionType = selectedOptions[indx].value.optionType ? selectedOptions[indx].value.optionType : 0;

    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    // console.log(selectedOptionsArray);
    props.handleUpdate(selectedOptionsArray);
    updateSelectedOptions([...selectedOptions]);
  }
  function handleDimension(width, depth, height, indx, sel){
    // console.log(width, depth, height);
    const updatedValues = { ...form };
    updatedValues["width"] = width;
    updatedValues["depth"] = depth;
    updatedValues["height"] = height;
    setForm(updatedValues);
    width = width === '' ? width : width+ (depth&&"&&");
    depth = depth === '' ? depth : depth+"&&";

    const Dimensions=`${width}${depth}${height}`;
    let Dimens;

    let matchedOption= propOptions[2].options.find(element => {
      // console.log(element.value==Dimensions ? "true" : "false");
      if(element.name==Dimensions && element.value==Dimensions){
        return true
      }
    });
    
    if(matchedOption){
      Dimens=matchedOption._id
    }else{
      Dimens=Dimensions;
    }

    selectedOptions[indx].value.optionId = Dimens;
    selectedOptions[indx].value.attributeId = sel.id;
    selectedOptions[indx].value.optionType = selectedOptions[indx].value.optionType ? selectedOptions[indx].value.optionType : 0;

    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    // console.log(selectedOptionsArray);
    props.handleUpdate(selectedOptionsArray);
    updateSelectedOptions([...selectedOptions]);
  }

  // console.log(propOptions[9].options);
  function handleParentIdUpdate(value, indx, sel) {
    // console.log("value"+ value, "indx"+ indx, "sele"+sel);
    let data = "";
    if(value.length > 1) {
      setSearchKey([value[1]]);
      data = value[1];
    } else {
      setSearchKey(value);
      data = value[0];
    }
    selectedOptions[indx].value.optionId = data;
    selectedOptions[indx].value.attributeId = sel.id;
    selectedOptions[indx].value.optionType = selectedOptions[indx].value.optionType ? selectedOptions[indx].value.optionType : 0;

    let selectedOptionsArray = selectedOptions.map((o) => o.value);
    // console.log(selectedOptionsArray);
    props.handleUpdate(selectedOptionsArray);
    updateSelectedOptions([...selectedOptions]);
  }
  // console.log(form);
  console.log(selectedOptions);

  return (
    <div className="col-lg-12 mb-4">
      <h4 className="theme-form-section-title">
        Product Options
       {/*  <span className="text-danger" style={{ fontSize: "20px" }}>
          *
        </span> */}
      </h4>
      <div
        className={`${commonstyles.management_details_form} select_input_name ml-0`}
        //className={`${commonstyles.management_details_form} ${formErrors.general ? "theme-error-blk" : ""}`}
      >
        {/* <label className="theme-form-label">Static Product Option</label> */}
        <div className="row">
          {/* Iterate through addedSelectOption and use saved index to get contents */}
          {/* console.log(selectedOptions), */
          selectedOptions.map((selectedOption, indx) => {
            // console.log(selectedOption.value);
            // console.log(propOptions)
            // optionPicked=[];
            // optionPicked.push(propOptions[selectedOption.index]._id)
            return (
              <React.Fragment key={indx}>
                
                <div className="col-lg-2 mb-4 pt-2 theme-form-label">
                  {propOptions[selectedOption.index].name}
                </div>

                <div className="col-lg-8 mb-4">
                  {/* Iterate available options */}
                  {(selectedOption.id==="60bdc54e97a14a2840b2e807" || propOptions[selectedOption.index]._id==="60bdc54e97a14a2840b2e807")?<select
                    className={`${commonstyles.form_control} form-control form-control-lg`}
                    value={selectedOption.value.optionId}
                    disabled
                    onChange={(e) => updateOptions(e.target.value, indx, selectedOption)}
                  >
                    <option value="">Select an option</option>
                    {propOptions[selectedOption.index].options.sort((a, b) => (a.name > b.name ? 1 : -1)).map((optn) => (
                      <option key={optn._id} value={optn._id}>
                        {optn.name}
                      </option>
                    ))}
                  </select> :
                    <ProductOptionSearchSelectComponent
                      // searchKey={searchKey}
                      currentRefDim={props.refsDim}
                      currentRef={props.refs}
                      form={form}
                      // formErrors={formErrors.warrenty1 && formErrors.warrenty2}
                      selectedId={selectedOption.id}
                      searchKey={selectedOption.value.optionId}
                      products={propOptions[selectedOption.index].options.sort((a, b) => (a.value > b.value ? 1 : -1))}
                      handleParentIdUpdate={(value) => handleParentIdUpdate(value, indx, selectedOption)}
                      handle={(warrenty1, warrenty2) => handle(warrenty1, warrenty2, indx, selectedOption)}
                      handleDimension={(width, depth, height) => handleDimension(width, depth, height, indx, selectedOption)}
                    />
                  }
                </div>


                <div
                  className="col-lg-2 mb-4 pt-2"
                  onClick={() => {selectedOption?.id!=="60bdc54e97a14a2840b2e807"&&removeOptions(indx)}}
                >
                  <span className="theme-form-label text-danger cursor-pointer">
                    {selectedOption?.id!=="60bdc54e97a14a2840b2e807"? 'Remove' : ''}
                  </span>
                </div>
              </React.Fragment>
            );
          })}
          <div className="col-lg-12 text-right add_text">
            <Popover
              trigger="focus"
              placement="topLeft"
              title="Product options"
              content={
                <ul
                  className="list-group list-group-flush"
                  style={{ maxHeight: "250px", overflow: "auto" }}
                >
                  {props.options.map((option, idx) => (
                    // console.log(selectedOptions.some(item =>{if(item.id==option._id){console.log("--------------------");}console.log(item.id , option._id)})),
                    selectedOptions.some(item => item.id === option._id)===true ? "" :
                    <li
                      className="list-group-item list-group-item-action p-1 cursor-pointer"
                      key={idx}
                      onClick={() => addOption(idx, option)}
                    >
                      {option.name}
                    </li>
                  ))}
                </ul>
              }
            >
              <span className="theme-btn-link" tabIndex="0">
                Add New
              </span>
            </Popover>
          </div>
        </div>
      </div>
      {/* <FormErrorText error={formErrors.general} /> */}
    </div>
  );
}
