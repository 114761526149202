import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  addressRegx,
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  emailRegx,
  passwordReg,
  phoneNumber,
  phoneRegx,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";

import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = Select;
const initialFormState = {
  title: "",
  slug: "",
  headerContent: "",
  footerContent: "",
  categoryId: [],
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
  enable: true,
};

export default function LandingCategoryComponent() {
  const rules = {
    title: [validationRules.required],
    // slug: [validationRules.required],
    // region: [validationRules.required],
    headerContent: [validationRules.required],
    footerContent: [validationRules.required],
  };
  const history = useHistory();
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  // const [regions, setRegions] = useState([]);
  // const [filterRegions, setFilterRegions] = useState([]);
  // const [regionId, setRegionId] = useState("");
  const { state } = history.location;
  const [page, setPage] = useState(state);
  const [bannerDetail, setBannerDetail] = useState([]);
  const [editForm, setEditForm] = useState(
    JSON.parse(JSON.stringify(initialFormState))
  );
  const [category, setCategory] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getCategoryName")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.daaata);
            setIsLoading(false);
          }
        })
        .catch((er) => {
          console.error("Failed to fetch parent categories");
        });
    }
    getParentCategories();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    category && getLandingList();
  }, [category]);
  /**
   * @author Rajkumar
   * To get list of landing
   */
  function getLandingList() {
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`/categoryPage/${category}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const val =
            res.data.data[0] || JSON.parse(JSON.stringify(initialFormState));
          // console.log(val);
          // setEditForm({});
          // console.log(initialFormState);
          setEditForm(val);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
   

    if (name === "title") {
      if (!e.target.value.match(addressRegx) || !e.target.value === "") {
        formErrors.title = "required";
      } else {
        formErrors.title = "";
      }
    }
  
    const updatedValues = { ...editForm, [name]: value };

    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
  }

  function handleCategory(e) {
    console.log(e);
    editForm.categoryId = e;
    setCategory({ ...editForm });
  }

  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // *** SAVE CHANGES *** //
  function submit() {
    let data = JSON.parse(JSON.stringify(editForm));
    // data.slug = data.slug.trim();
    // data.regionId = regionId ? regionId : "";

    delete data.userName;
    delete data.updatedAt;
    console.log(data);
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    // setIsLoading(true);

    let add = "/categoryPage/createPage";
    let edit = "/categoryPage/updatePage";

    AxiosInstance(PORTS.CMS, "application/json")
      .post(`/categoryPage/createPage`, data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          // setIsLoading(false);
          getLandingList();
          showNotification({
            type: "success",
            message: "Success",
            description: state?._id
              ? "Landing page updated successfully"
              : "Landing page added successfully",
          });
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res?.data?.msg,
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          Landing Category Management
        </h1>
      </div>
      <div className={`${styles.management_header} ${styles.banner_city_list}`}>
        <div className={`${styles.banner_citys}`}>
          <Select
            className={"form-control " + styles.form_control}
            // onChange={(e) => setRegion(e.target.value)}
            // value={region}
            mode="multiple"
            placeholder="Select one state"
            name="category"
            // value={category}
            onChange={(e) => handleCategory(e)}
            style={{ cursor: "pointer" }}
          >
            {parentCategories.map((category) => (
              <>
                <option value={category._id} label={category.name}>
                  {category.name}
                </option>
              </>
            ))}
          </Select>
          <div className={`${styles.banner_citys_update}`}>
            {editForm?.userName && (
              <p>
                Last update by: <span>{editForm?.userName}</span>
              </p>
            )}
            {editForm?.updatedAt && (
              <p>
                Last update on:{" "}
                <span>
                  {moment(editForm?.updatedAt).format("DD/MM/YYYY | hh:mm A")}
                </span>
              </p>
            )}
          </div>
        </div>

        <div className={styles.management_contents_upload}>
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd mr-3 outline_btn no_outline_btn"
            attributes={{
              onClick: () => history.push(`/Content`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/* {isLoading === true ? (
                <tr className="bg-transparent d-flex justify-content-center">
                    <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching banner details..." />
                    </td>
                </tr>
            ) : ( */}
      <div className={styles.management_details}>
        <div /* key={indx} */ className={styles.management_details_contents}>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Page title
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.title}
                  formControl={{
                    placeholder: "",
                    name: "title",
                    value: editForm?.title,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.title} />
              </div>
            </div>
            {/* Slug */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Slug
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputComponent
                error={formErrors.slug}
                className="form-control-lg"
                formControl={{
                  placeholder: "Type slug",
                  name: "slug",
                  value: editForm?.slug,
                  onChange: handleFormInput,
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
            {/* Slug ends */}
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Top Content
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>

                {/* {page?._id && editForm.content && ( */}
                <CKEditor
                  error={formErrors.headerContent}
                  editor={ClassicEditor}
                  data={editForm?.headerContent || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...editForm };
                    // console.log(data);
                    // console.log(values);
                    values.headerContent = data;
                    setEditForm(values);
                  }}
                />
                {/* )} */}
                {/*  {!(page?._id && editForm.content) && (
                                        <CKEditor
                                        editor={ ClassicEditor }
                                        data={editForm?.content || ""}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            const values = editForm;
                                            values.content = data;
                                            setEditForm(values);
                                        } }
                                    />
                                    )} */}

                <FormErrorText error={formErrors.content} />
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Bottom Content
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <CKEditor
                  error={formErrors.footerContent}
                  editor={ClassicEditor}
                  data={editForm?.footerContent || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const values = { ...editForm };
                    // console.log(data);
                    // console.log(values);
                    values.footerContent = data;
                    setEditForm(values);
                  }}
                />
                {/* <FormErrorText error={formErrors.content} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.management_details_contents}>
          <h4 className="theme-form-section-title">
            Search Engine Optimization
          </h4>
          <div className="row">
            {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">URL Key</label>
                            <InputComponent
                                className={`form-control form-control-lg ${styles.form_control}`}
                                // error={formErrors.urlkey}
                                formControl={{
                                placeholder: "",
                                name: "urlkey",
                                value: editForm?.urlkey,
                                onChange:handleFormInput,
                                }}
                            />
                            // <FormErrorText error={formErrors.urlkey} />
                            </div>
                        </div> */}
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Title</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  // error={formErrors.metaTitle}
                  formControl={{
                    placeholder: "",
                    name: "metaTitle",
                    value: editForm?.metaTitle,
                    onChange: handleFormInput,
                  }}
                />
                {/* <FormErrorText error={formErrors.metaTitle} /> */}
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Keywords</label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  // error={formErrors.metaKeywords}
                  formControl={{
                    placeholder: "",
                    name: "metaKeywords",
                    value: editForm?.metaKeywords,
                    onChange: handleFormInput,
                  }}
                />
                {/* <FormErrorText error={formErrors.metaKeywords} /> */}
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">Meta Description</label>
                <textarea
                  className={`form-control form-control-lg ${styles.form_control}`}
                  // error={formErrors.metaDescription}
                  // formControl={{
                  placeholder=""
                  name="metaDescription"
                  value={editForm?.metaDescription}
                  onChange={handleFormInput}
                  // }}
                />
                {/* <FormErrorText error={formErrors.metaDescription} /> */}
              </div>
            </div>
            {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">Meta Description</label>
                                <select
                                    className={"form-control " + styles.form_control}
                                    name="regionId"
                                    value={regionId}
                                    onChange={handleFormInput}
                                    style={{ cursor: "pointer" }}
                                    >
                                    <option value="" disabled>All region</option>
                                    {regions.map((region) => (
                                        <>
                                        <option value={region._id} label={region.name}>
                                            {region.name}
                                        </option>
                                        </>
                                    ))}
                                </select>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
