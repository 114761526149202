import { useEffect } from "react";
import { Route, useHistory, Redirect } from "react-router";

import { isAuthenticated } from "./utils";

export default function PrivateRoute({ children, ...rest }) {
  // return <Route {...rest} render={() => children} />;
  return isAuthenticated() ? (
    <Route {...rest} render={() => children} />
  ) : (
    <Redirect to="/" />
  );
}
