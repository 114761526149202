import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ModularForm from "./ModularForm";
import ModularHeader from "./ModularHeader";
import Compressor from "compressorjs";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";

const { Option } = Select;
const modularTypesData = [
    { id: 1, name: "Modular Kitchen", value: 1 },
    { id: 2, name: "Modular Wardrobe", value: 2 },
];
const KitchenShapes = [
    { id: 1, name: "L-Shape", value: 1 },
    { id: 2, name: "U-Shape", value: 2 },
    { id: 3, name: "Island", value: 3 },
    { id: 4, name: "Straight line", value: 4 },
    { id: 5, name: "Parallel", value: 5 },
    { id: 6, name: "Peninsula", value: 6 }
];
const WardrobeShapes = [
    { id: 7, name: "Hanging Wardrobe", value: 7 },
    { id: 8, name: "Sliding Wardrobe", value: 8 }
];
const initialFormState = {
    name: "",
    status: false,
    // sellingPrice: "",
    // mrpPrice: "",
    specification: "",
    productDetails: "",
    regionId: [],
    type: 1,
    page: 1,
    images: []
};

export default function Modular() {

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [regionId, setRegionId] = useState("");
    const [regions, setRegions] = useState([]);
    const [filterRegions, setFilterRegions] = useState([]);
    const [modularTypes, setModularTypes] = useState(modularTypesData);
    const [modularType, setModularType] = useState(1);
    const [shapes, setShapes] = useState(KitchenShapes);
    const [shape, setShape] = useState(1);
    const [modulars, setModulars] = useState([JSON.parse(JSON.stringify(initialFormState))]);
    const [remainingRegions, setRemainingRegions] = useState([]);
    const [compressedImage, setCompressedImage] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [lastUpdateUserDetails, setLastUpdateUserDetails] = useState([]);
    const [isShowConfirmModal, setisShowConfirmModal] = useState(false);
    const [selectedModularDetails, setSelectedModularDetails] = useState({});

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    useEffect(() => {
        getRegionList();
    }, []);

    useEffect(() => {
        const cloneModulars = [...modulars];
        cloneModulars.forEach((x, index) => {
            if (index === compressedImage?.indx) {
                if (
                    x["images"].length < 5
                ) {
                    x["images"].push(compressedImage?.compressedResult);
                } else {
                    showNotification({
                        type: "error",
                        message: "There were few errors",
                        description:
                            "You can't able to add more than five image for this banner",
                    });
                    return;
                }
            }
        });
        setModulars(cloneModulars);
    }, [compressedImage])

    /**
     * @author Rajkumar
     * To get region list
    */
    function getRegionList() {
        // setIsLoading(true);
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("/admin/region/getRegionList")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    const data = res.data.daaata;
                    setRegions(data);
                    setFilterRegions(data);
                    setRemainingRegions(data);
                    let params = {
                        type: [1],
                        page: 1
                    }
                    modularProduct(params, data, true);
                    if(res.data.daaata?.length === 1) {
                        setRegionId(data[0]?._id)
                    }
                } else {
                    setIsLoading(false);
                }
            }).catch((er) => {
                console.log(er);
                setIsLoading(false);
            });
    }

    function modularProduct(params, regs, type) {
        setIsLoading(true);
        if (!params?.regionId) { delete params.regionId }
        if (params.page) { params.page = parseFloat(params.page)}
        AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
            .post("/modular/modularProduct", params)
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    const data = res?.data?.data[0]?.modular;
                    setLastUpdateUserDetails(res?.data?.data[0]);
                    let cloneRemainingRegions = { ...remainingRegions };
                    if (data?.length > 0) {
                        data?.forEach(item => {
                            item.images = item?.modulars;
                            let regionIds = [];
                            if (item?.regionId?.length > 0) {
                                regs.forEach((x) => {
                                    item.regionId.forEach((y) => {
                                        if (x._id === y) {
                                            regionIds.push(x.name);
                                        }
                                    });
                                });
                                item.regionId = regionIds;
                            } else {
                                item.regionId = ["All"];
                            }
                            let reg = [];
                            data.forEach((x) => {
                                x?.regionId?.forEach((y) => {
                                    reg.push(y);
                                });
                            });
                            if (type) {
                                cloneRemainingRegions = regs.filter(
                                    (o) => !reg.includes(o.name)
                                );
                            }
                        })
                        let accessibleModulars = data?.filter(x => x?.regionId?.length > 0) || [];
                        setModulars(accessibleModulars);
                        setRemainingRegions(cloneRemainingRegions);
                    } else {
                        setModulars([JSON.parse(JSON.stringify(initialFormState))]);
                    }
                    setIsLoading(false);
                    setIsUpdating(false);
                } else {
                    setIsLoading(false);
                }
            }).catch((er) => {
                console.log(er);
                setIsLoading(false);
            });
    }

    function filterOnChangeforRegion(e, type) {
        console.log(e);
        // const { name, value } = e.target;
        let params = {
            regionId: regionId,
            type: [parseFloat(shape)],
            page: parseFloat(modularType)
        }
        if (type === "regionId") {
            setRegionId(e);
            params.regionId = e;
        } else if (type === "modularType") {
            setModularType(e);
            if (e === 1 || e === "1") {
                setShapes(KitchenShapes);
                setShape(1);
                params.type = [1];
            } else {
                setShapes(WardrobeShapes);
                setShape(7);
                params.type = [7];
            }
            params.page = parseFloat(e);
        } else if (type === "shapes") {
            setShape(e);
            params.type = [parseFloat(e)];
        }
        modularProduct(params, regions, true);
    };

    function handleFormInput(e, data, indx) {
        const { name, value } = e.target;
        const cloneModulars = [...modulars];
        cloneModulars.forEach((cloneModular, index) => {
            if (indx === index) {
                if(name === "sellingPrice" || name === "mrpPrice") {
                    if(value?.trim()) {
                        const inputtedValue = e.currentTarget.value
                        if (isNaN(inputtedValue)) return
                        cloneModular[name] = inputtedValue
                    } else {
                        cloneModular[name] = "";
                    }
                } else {
                    if(value?.trim()) {
                        cloneModular[name] = value.replace(/[^\w\s]/gi, "");
                    } else {
                        cloneModular[name] = "";
                    }
                }
            }
        });
        setModulars(cloneModulars);
    }

    function toggleStatusOnChange(e, data, indx) {
        const cloneModulars = [...modulars];
        let enableModular = [];
        cloneModulars.forEach(cloneModular => {
            if (cloneModular["status"]) {
                enableModular.push(cloneModular);
            }
        });
        cloneModulars.forEach((cloneModular, index) => {
            if (indx === index && enableModular?.length < 12) {
                cloneModular["status"] = !cloneModular["status"];
            } else if(indx === index) {
                cloneModular["status"] = false;
                showNotification({
                    type: "warning",
                    message: "Warning",
                    description: "Please enable maximum twelve modulars only",
                });
            }
        });
        setModulars(cloneModulars);
    }

    function searchSelectOnChange(value, name, data, indx) {
        let selectedVal = [];
        let isAll = false;
        if (value?.length > 0) {
            value.forEach((item, index) => {
                if (value[value.length - 1] === "All") {
                    isAll = true;
                }
            });
        };
        if (isAll) {
            selectedVal.push("All");
        } else {
            selectedVal = value?.filter((x, indx) => x !== "All");
        }

        const cloneModulars = [...modulars];
        const cloneRemainingRegions = { ...remainingRegions };
        let arr = [];
        if (name === "regionId" && cloneModulars?.length > 1 && value[value.length - 1] === "All") {
            let isOtherRegion = false;
            cloneModulars?.forEach(item => {
                item?.regionId?.forEach(regionID => {
                    regions?.forEach(orgReg => {
                        if (regionID === orgReg?.name) {
                            isOtherRegion = true;
                        }
                    })
                })
            });
            if (isOtherRegion) {
                let params = {
                    cloneModulars, indx, selectedVal, cloneRemainingRegions
                }
                setSelectedModularDetails(params);
                // setisShowConfirmModal(true);
                continueToAddRegion(params?.cloneModulars, 
                    params?.indx, params?.selectedVal, 
                    params?.cloneRemainingRegions);
            } else {
                continueToAddRegion(cloneModulars, indx, selectedVal, cloneRemainingRegions);
            }
        } else {
            if (name === "regionId") {
                continueToAddRegion(cloneModulars, indx, selectedVal, cloneRemainingRegions);
            }
        }
        setModulars(cloneModulars);
    }

    function continueToAddRegion(cloneModulars, indx, selectedVal, cloneRemainingRegions) {
        let arr = [];
        cloneModulars.forEach((x, index) => {
            if (index === indx) {
                x["regionId"] = selectedVal;
            }
            x.regionId?.forEach((y) => arr.push(y));
        });
        cloneRemainingRegions = regions.filter(
            (o) => !arr.includes(o.name)
        );
        setRemainingRegions(cloneRemainingRegions);
    }

    function ckEditorOnchange(event, editor, type, indx) {
        const value = editor.getData();
        const cloneModulars = [...modulars];
        cloneModulars.forEach((x, index) => {
            if (index === indx) {
                x[type] = value;
            }
        });
        setModulars(cloneModulars);
    }

    function handleCheckboxChange(e, data, indx) {
        const { name, checked } = e.target;
        const cloneModulars = [...modulars];
        cloneModulars.forEach((x, index) => {
            if (index === indx) {
                x['isActive'] = checked
            }
        });
        setModulars(cloneModulars);
    }

    const handleImageInput = (e, data, indx) => {
        const { name, files: inputFiles } = e.target;
        const cloneModulars = [...modulars];
        const file_name = inputFiles[0].name.toLowerCase();
        if (name) {
            if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
                showNotification({
                    type: "error",
                    message: "There were few errors",
                    description: "Please upload only jpg,jpeg and png format images",
                });
                return;
            } else if (inputFiles[0].size > 1024 * 1024 * 3) {
                showNotification({
                    type: "error",
                    message: "There were few errors",
                    description: "Please upload file less than 3MB size",
                });
                return;
            } else {
                let isAct = false;
                cloneModulars.forEach((x, index) => {
                    if (index === indx) {
                        isAct = x['isActive'] ? x['isActive'] : false;
                    }
                });
                if (isAct) {
                    // setCompressedImage
                    new Compressor(inputFiles[0], {
                        convertTypes: "image/png",
                        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                        success: (compressedResult) => {
                            let obj = {
                                indx,
                                compressedResult
                            }
                            setCompressedImage(obj);
                        }
                    })
                } else {
                    cloneModulars.forEach((x, index) => {
                        if (index === indx) {
                            if (
                                x["images"].length < 5
                            ) {
                                x["images"].push(inputFiles[0]);
                            } else {
                                showNotification({
                                    type: "error",
                                    message: "There were few errors",
                                    description:
                                        "You can't able to add more than five image for this banner",
                                });
                                return;
                            }
                        }
                    });
                    setModulars(cloneModulars);
                }
            }
        }
    };

    function addModular(bannerName) {
        const cloneModulars = [...modulars];
        let lastArray = cloneModulars.length;
        // let lastSellingPrice = parseFloat(cloneModulars[lastArray - 1]?.sellingPrice);
        // let lastMrpPrice = parseFloat(cloneModulars[lastArray - 1]?.mrpPrice);
        if (
            cloneModulars[lastArray - 1].name && cloneModulars[lastArray - 1].specification
            && cloneModulars[lastArray - 1].productDetails
        ) {
            const _initialFormState = {
                name: "",
                status: false,
                // sellingPrice: "",
                // mrpPrice: "",
                specification: "",
                productDetails: "",
                regionId: [],
                type: shape,
                page: modularType,
                images: []
            };
            const newModular = JSON.parse(JSON.stringify(_initialFormState));
            cloneModulars.push(newModular);
            setModulars(cloneModulars);
        } else {
            showNotification({
                type: "warning",
                message: "There were few errors",
                description: "Please add name/specification/productDetails to continue",
            });
        }
    }

    function removeImages(img, img_indx, data, modular_indx) {
        const cloneModulars = [...modulars];
        cloneModulars.forEach((x, index) => {
            if (index === modular_indx) {
                x.images = x?.images?.filter((x, indx) => indx !== img_indx);
                x.modulars = x?.modulars?.filter((x, indx) => x !== (!img?.name && img));
            }
        });
        setModulars(cloneModulars);
    }

    function submit() {
        let error = false;
        const cloneModulars = [...modulars];
        cloneModulars.forEach((item) => {
            if (
                !item.name && !item.specification
                && !item.productDetails
            ) {
                showNotification({
                    type: "warning",
                    message: "There were few errors",
                    description: "Please add product name/specification/productDetails to continue",
                });
                error = true;
            }
            // if (
            //     (parseFloat(item.sellingPrice) > parseFloat(item.mrpPrice))
            // ) {
            //     error = "priceError";
            // }
        });
        if (!error) {
            setIsUpdate(true);
            continueSave();
        }
        if(error === "priceError") {
            showNotification({
                type: "warning",
                message: "There were few errors",
                description: "Selling price should be less than the mrp price",
            });
            error = false;
        }
    }

    function continueSave() {
        setIsUpdating(true);
        const cloneModulars = [...modulars];
        let images = [];
        cloneModulars.forEach((item, indx) => {
            if (item?.images.length > 0) {
                item.images.forEach((x, index) => {
                    if (x?.name) {
                        x["modularType"] = indx;
                    }
                    images.push(x);
                });
            }
            item.type = parseFloat(shape);
            item.page = parseFloat(modularType);
            let regionIds = [];
            let isAllRegion = false;
            item?.regionId?.forEach(reg => {
                if (reg === "All") {
                    isAllRegion = true;
                }
            });
            if (isAllRegion) {
                item.regionId = [];
            } else {
                regions?.forEach((x) => {
                    item?.regionId?.forEach((y) => {
                        if (x.name === y) {
                            regionIds.push(x._id);
                        }
                    });
                });
            }
            item.regionId = regionIds;
            delete item?.images;
            delete item?.createdAt;
            delete item?.isActive;
            item.modulars = item?.modulars?.filter((x) => !x?.name);
        });
        let data = { modulars: cloneModulars };
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        images.forEach((field, indx) => {
            let _name = "modular_image_" + field.modularType;
            delete field.modularType;
            if (field?.name) {
                formData.append(_name, field);
            }
        });
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        AxiosInstance(PORTS.DASHBOARD_SERVICE)
            .post("/modular/createmodularProduct", formData, config)
            .then((res) => {
                if (res.data.status === 200) {
                    showNotification({
                        type: "success",
                        message: "Success",
                        description: res.data.msg,
                    });
                    // history.push("/Content");
                    setIsUpdate(false);
                    setRegionId("");
                    let params = {
                        type: [parseFloat(shape)],
                        page: parseFloat(modularType)
                    }
                    modularProduct(params, regions, true);
                    // setIsUpdating(false);
                    // getRegionList(page ? page : 1);
                } else if (res.data.status === 400) {
                    // let cloneModulars_ = [...modulars];
                    // cloneModulars_.splice(-1);
                    // setModulars(cloneModulars_);
                    showNotification({
                        type: "warning",
                        message: "Warning",
                        description: res.data.msg,
                    });
                    let params = {
                        type: [parseFloat(shape)],
                        page: parseFloat(modularType)
                    }
                    modularProduct(params, regions, true);
                    setIsUpdate(false);
                    setIsUpdating(false);
                }
            })
            .catch((er) => {
                showNotification({
                    type: "error",
                    message: "Adding Modulars failed",
                    description: "Error occured",
                });
                setIsUpdate(false);
                setIsUpdating(false);
            })
            .finally(() => {
                setIsUpdate(false);
                // setIsUpdating(false);
            });
    }

    /**
     * @author Rajkumar
     * @param {Banner_indx, bannerName}
     * To remove banners
     */
    function removeModular(Modular_indx) {
        const cloneModulars = [...modulars];
        cloneModulars?.forEach((item, indx) => {
            if (indx === Modular_indx) {
                if (item?._id) {
                    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
                        .get(`/modular/modularRemove/${item._id}`)
                        .then((res) => {
                            let remainingModulars = cloneModulars.filter((x, index) => (index !== Modular_indx));
                            setModulars(remainingModulars);
                            let params = {
                                type: [parseFloat(shape)],
                                page: parseFloat(modularType)
                            }
                            modularProduct(params, regions, true);
                        })
                        .catch((er) => {
                            // console.log("Failed to get data on Category tree select");
                            console.log(er);
                        });
                } else {
                    let remainingModulars = cloneModulars.filter((x, index) => (index !== Modular_indx));
                    setModulars(remainingModulars);
                }
            }
        })
    }

    function cancelDelete() {
        setSelectedModularDetails({});
        setisShowConfirmModal(false);
    }

    function deleteCustomer() {
        setisShowConfirmModal(false);
        continueToAddRegion(selectedModularDetails?.cloneModulars, 
            selectedModularDetails?.indx, selectedModularDetails?.selectedVal, 
            selectedModularDetails?.cloneRemainingRegions);
    }

    return (
        <div className={styles.management_contents}>
            <ModularHeader
                regionId={regionId}
                filterOnChangeforRegion={filterOnChangeforRegion}
                filterRegions={filterRegions}
                modularTypes={modularTypes}
                modularType={modularType}
                shapes={shapes}
                shape={shape}
                isUpdate={isUpdate}
                submit={submit}
                lastUpdateUserDetails={lastUpdateUserDetails}
            />
            {(isLoading || isUpdating) ? (
                <tr className="bg-transparent d-flex justify-content-center">
                    <td colSpan="100%">
                        <LoadingIndicator loadingText={isUpdating ? "Updating Modular..." : "Fetching Modular details..."} />
                    </td>
                </tr>
            ) : (
                <div className={styles.management_details}>
                    <ModularForm
                        regions={regions}
                        regionId={regionId}
                        modulars={modulars}
                        remainingRegions={remainingRegions}
                        handleFormInput={handleFormInput}
                        searchSelectOnChange={searchSelectOnChange}
                        ckEditorOnchange={ckEditorOnchange}
                        handleCheckboxChange={handleCheckboxChange}
                        handleImageInput={handleImageInput}
                        addModular={addModular}
                        removeImages={removeImages}
                        removeModular={removeModular}
                        toggleStatusOnChange={toggleStatusOnChange}
                    />
                    {/* Region select confirmation modal  */}
                    <ConfirmationBox
                        modal={{
                            show: isShowConfirmModal,
                            size: "lg",
                            centered: true,
                        }}
                        handleCancel={cancelDelete}
                        handleConfirm={deleteCustomer}
                        title="Warning!"
                        confirmText="Ok"
                    >
                        <div className="container-fluid">
                            <div className="row">
                                <p
                                    className={"theme-txt-detail confirm_message"}
                                >
                                    Modular already created for other regions. If you click okay, It will overwrite the other region Modulars?
                                </p>
                            </div>
                        </div>
                    </ConfirmationBox>
                    {/* Region select confirmation modal ends*/}
                </div>
            )}
        </div>
    );
}