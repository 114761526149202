import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";

import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  minChar,
  userNameWithSpace,
  emailRegx,
  onlyAlphabetsWithSpaceMinThree,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import FaqFormComponent from "../../modules/ProductManagement/FaqFormComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";

const { Option } = Select;

export default function AddBannerComponent() {
  const initialFormState = {
    templatename: "",
    sendername: "",
    tomail: [],
    fromemail: "",
    subject: "",
    description: "",
    toid: [],
    mailtype: 1,
  };

  const rules = {
    templatename: [(val, data) => (!val ? "Template name " : true)],
    sendername: [(val, data) => (!val ? "Sender name " : true)],
    fromemail: [(val, data) => (!val ? "From mail " : true)],
    subject: [(val, data) => (!val ? "Subject " : true)],
    description: [(val, data) => (!val ? "Description " : true)],
  };

  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.USER_SERVICE, "application/json")
        .get("getAllCustomers")
        .then((res) => {
          if (res.data.status === 200) {
            setEmails(res.data.data);
            setIds(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);

  function handleIdChange(e) {
    editForm.toid = e;
    setEditForm({ ...editForm });
  }

  function handleMailChange(e) {
    editForm.tomail = e;
    setEditForm({ ...editForm });
  }

   /**
   * @author Mithun Prabhu
   * @param {pageSize,nextPage,searchNow}
   * INPUT HANDLE CHANGE
   */
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    const updatedValues = { ...editForm };
    if (["status"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  /**
   * @author Mithun Prabhu
   * @param {pageSize,nextPage,searchNow}
   * ADD MAIL DETAILS
   */
  function submit(e) {
    if (!onlyAlphabetsWithSpaceMinThree.test(editForm.templatename)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add valid template name",
      });
      return;
    }
    if (!onlyAlphabetsWithSpaceMinThree.test(editForm.sendername)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add valid sender name",
      });
      return;
    }

    if (!emailRegx.test(editForm.fromemail)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add valid sender mail",
      });
      return;
    }

    if (!onlyAlphabetsWithSpaceMinThree.test(editForm.subject)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add valid subject",
      });
      return;
    }

    if (!onlyAlphabetsWithSpaceMinThree.test(editForm.description)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Please add valid description",
      });
      return;
    }

    let data = {
      templatename: editForm.templatename,
      sendername: editForm.sendername,
      fromemail: editForm.fromemail,
      tomail: editForm.tomail,
      subject: editForm.subject,
      description: editForm.description,
      toid: editForm.toid,
      mailtype: 1,
    };

    if (data.name) {
      if (!userNameWithSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }

    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post(`admin/mail/sendmail`, data)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description:
              editForm.mailtype == 1
                ? "Email added successfully"
                : "Email saved and sent successfully",
          });
          history.push(`/site-settings/notification`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding/Sending mail failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding banner failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  const { state } = history.location;

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/site-settings/notification")}
          />
          Add Mail
        </h1>
        <div className={styles.management_contents_upload}>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings/notification`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            Send
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>

      <div className={styles.management_details}>
        {/* {avenueform.map((avenue, indx) => ( */}
        {/*  {Array.isArray(editForm.faq) &&
              editForm.faq.map((faq, indx) => (
            <React.Fragment key={indx}> */}
        <div className={styles.management_details_contents}>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Template name{" "}
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.templatename}
                className="form-control-lg mb-3"
                formControl={{
                  placeholder: "Type template name",
                  name: "templatename",

                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.templatename) {
                        formErrors.templatename = {};
                      }
                      formErrors.templatename = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.templatename) {
                        formErrors.templatename = "";
                      }
                    }
                    handleFormInput(e);
                  },
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.templatename} />
            </div>
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Sender name{" "}
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.sendername}
                className="form-control-lg mb-3"
                formControl={{
                  placeholder: "Type sender name",
                  name: "sendername",

                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.sendername) {
                        formErrors.sendername = {};
                      }
                      formErrors.sendername = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.sendername) {
                        formErrors.sendername = "";
                      }
                    }
                    handleFormInput(e);
                  },
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.sendername} />
            </div>
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Sender mail{" "}
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.fromemail}
                className="form-control-lg mb-3"
                formControl={{
                  placeholder: "Type sender mail",
                  name: "fromemail",
                  type: "email",
                  onChange: (e) => {
                    if (!emailRegx.test(e.target.value)) {
                      if (!formErrors.fromemail) {
                        formErrors.fromemail = {};
                      }
                      formErrors.fromemail = "Enter valid mail";
                    } else {
                      if (formErrors.fromemail) {
                        formErrors.fromemail = "";
                      }
                    }
                    handleFormInput(e);
                  },
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.fromemail} />
            </div>
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Subject{" "}
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.subject}
                className="form-control-lg mb-3"
                formControl={{
                  placeholder: "Type subject",
                  name: "subject",

                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.subject) {
                        formErrors.subject = {};
                      }
                      formErrors.subject = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.subject) {
                        formErrors.subject = "";
                      }
                    }
                    handleFormInput(e);
                  },
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.subject} />
            </div>
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Description{" "}
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.description}
                className="form-control-lg mb-3"
                formControl={{
                  placeholder: "Type description",
                  name: "description",

                  onChange: (e) => {
                    if (!minChar.test(e.target.value)) {
                      if (!formErrors.description) {
                        formErrors.description = {};
                      }
                      formErrors.description = "Enter minimum 3 characters";
                    } else {
                      if (formErrors.description) {
                        formErrors.description = "";
                      }
                    }
                    handleFormInput(e);
                  },
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.description} />
            </div>

            {/* Region start */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>To mail</label>
              <div className={styles.management_details_form}>
                <Select
                  mode="multiple"
                  name="tomail"
                  style={{ width: "100%" }}
                  placeholder="Select mail"
                  onChange={(e) => handleMailChange(e)}
                  optionLabelProp="label"
                  value={editForm.tomail}
                >
                  {emails.map((mail) => (
                    <Option value={mail._id} label={mail.name}>
                      <div className="demo-option-label-item">{mail.email}</div>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>To id</label>
              <div className={styles.management_details_form}>
                <Select
                  mode="multiple"
                  name="toid"
                  style={{ width: "100%" }}
                  placeholder="Select id"
                  onChange={(e) => handleIdChange(e)}
                  optionLabelProp="label"
                  value={editForm.toid}
                >
                  {ids.map((id) => (
                    <Option value={id._id} label={id.name}>
                      <div className="demo-option-label-item">{id.name}</div>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
