import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import blackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/AddCategory.module.css";
import SwitchComponent from "../../modules/ReusableComponent/SwitchComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import excelIcon from "../../../assets/images/excel_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import validationRules from "../../../utils/validationRules";
import { notification, Select, Tooltip } from "antd";
import { ExportCSV } from "../../modules/ReusableComponent/ExportCsv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import { Catalogwithletters } from "../../../utils/RegularExp";
import is from "date-fns/esm/locale/is/index.js";
import SearchComponent from "../SearchManagement/SearchComponent";
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
function initialState() {
  return {
    level: "",
    // parentId: ""
  };
}
const intialFilterState = {
  regionId: "",
};

export default function ReportManagement(props) {
  const history = useHistory();
  const [newCategory, setNewCategory] = useState(initialState());
  //   const [parentCategories, setParentCategories] = useState([]);
  //   const [parentCategoriesALl, setParentCategoriesAll] = useState([]);
  const [regions, setRegions] = useState([]);
  const [filter, setFilter] = useState(intialFilterState);
  const [customerData, setCustomerData] = useState([]);
  const [mostSold, setMostSold] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mostViewedProduct, setMostViewedProduct] = useState([]);
  const [popularSearch, setPopularSearch] = useState([]);
  //   const [isLoading, setIsLoading] = useState({
  //     parentCategory: false,
  //     update: false,
  //   });
  const [formErrors, setFormErrors] = useState({});
  //export cs or XLXS
  let fileName, csvData;

  //   const rules = {
  //     level: [validationRules.deepRequired],
  //     parentId: [validationRules.required],

  //   };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        });
    }
    function getReportDetails(filter) {
      let type = +newCategory.level;
      //   console.log(type);
      let data = {
        type: +newCategory.level,
        regionId: filter.regionId === "" ? null : filter.regionId,
      };
      AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
        .post("/admin/reports/getReportList", data)
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.status === 200) {
            setIsLoading(false);
            setCustomerData(res.data.data);
            /* setMostSold(res.data.mostSold);
            setMostViewedProduct(res.data.mostViewedProduct);
            setPopularSearch(res.data.popularSearch); */
          } else {
            setIsLoading(false);
          }
        })
        .catch((er) => {
          console.error("Failed to fetch parent categories");
          setIsLoading(false);
        });
    }
    getRegionList();
    getReportDetails(filter);
  }, [filter.regionId, newCategory]);

  function handleChanges(csvData, fileName) {
    //   console.log(newCategory);
    if (newCategory.level === "1") {
      fileName = "Most sold";
      csvData = customerData;
    } else if (newCategory.level === "2") {
      fileName = "Most viewed product";
      csvData = customerData;
    } else if (newCategory.level === "3") {
      fileName = "Popular search";
      csvData = customerData;
    } else if (newCategory.level === "4") {
      fileName = "Customers data";
      csvData = customerData;
    } /* else{

    } */
    exportToCSV(csvData, fileName);
    showNotification({
      type: "success",
      message: "Success",
      description: "Report generated successfully",
    });
  }
  function handleInput(e) {
    const { name, value, checked } = e.target;
    setNewCategory({ level: value });
    const updatedFilter = { ...filter };
    updatedFilter[name] = value;
    setFilter(updatedFilter);
    /*if(value==="0"){
        fileName="Most sold";
        csvData = mostSold;
        // // console.log(csvData);
        // mostSold.forEach(data=>{
        //     // console.log(data.allProducts);
        //     csvData=data.allProducts;
        // })
    }else if(value==="1"){
        fileName="Most viewed product";
        csvData = mostViewedProduct;
    }else if(value==="2"){
        fileName="Popular search";
        csvData = popularSearch;
    }else {
        fileName="Customer data";
        csvData = customerData;
    }*/
    /* let arr = [],
      relvel = value === "2" ? 1 : 0;
    if (name === "level") {
      parentCategoriesALl.map((x) => {
        if (x.level === relvel) {
          arr.push(x);
        }
      });
      setParentCategories(arr);
    }
    if (["level"].includes(name)) {
      const val = parseInt(value);
      setNewCategory({
        ...newCategory,
        parentId: "",
        [name]: !isNaN(val) ? val : value,
      });
    } else {
      setNewCategory({ ...newCategory, [name]: value });
    } */
  }

  function handleSelect(e) {
    // setNewCategory({ level: value });
    const updatedFilter = { ...filter };
    updatedFilter.regionId = e;
    setFilter(updatedFilter);
  }
  /* function createRequestData() {
    const data = JSON.parse(JSON.stringify(newCategory));
    data.level = parseInt(data.level);
    data.sortValue = parseInt(data.sortValue);
    if (data.level === 0) {
      delete data.parentId;
    }

    return data;
  } */

  /*function submit() {
     setFormErrors({});
    const data = createRequestData();
    const result = runValidationChecks(rules, undefined, { ...data, files });
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    } 
    
  }*/

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    // console.log(csvData, fileName);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function routerToSalesReport(type) {
    history.push({
      pathname: "/reports/salesreports",
      state: type,
    });
  }

  function routerToCustomerAnalysis(type) {
    history.push({
      pathname: "/reports/CustomerAnalytics",
      state: type,
    });
  }

  function routerToProductAnalysis(type) {
    history.push({
      pathname: "/reports/ProductReports",
      state: type,
    });
  }

  return (
    <div className={styles.management_contents}>
      <SearchComponent region={filter.regionId} />
      <div className={`${styles.management_header} mt-5 mb-0 no_bottom_broder`}>
        <h1 className={styles.management_header_heading}>Reports</h1>
        {/* <div>
          <span className={commonstyles.management_header_downloads_text}>
            <Tooltip placement="topRight" title="Download pdf">
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Download excel">
              <img
                src={excelIcon}
                className={commonstyles.management_header_downloads_icon}
                alt="icon"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </span>
          <span className={commonstyles.management_header_downloads_text_ex}>
            Export <img className="export-icon" src={exportIcon} alt="icon" />
          </span>
        </div> */}
      </div>
      <div
        className={`${styles.management_details} d-flex justify-content-start mb-4`}
      >
        {/* <div>
          <Select
            className="mr-2"
            defaultValue="Select Region"
            style={{ width: 140 }}
            onSelect={(e) => handleSelect(e)}
            name="regionId"
            // optionLabelProp="label"
          >
            {Array.isArray(regions)
              ? regions.map((region) => (
                  <option key={region._id} value={region._id}>
                    {region.name}
                  </option>
                ))
              : null}
          </Select>
        </div> */}
        {/* <div>

          <select
            className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
            name="regionId"
            style={{ cursor: "pointer" }}
            value={filter.regionId}
            onChange={handleInput}
          >
            <option value="">All Region</option>
            {Array.isArray(regions)
              ? regions.map((region) => (
                  <option key={region._id} value={region._id}>
                    {region.name}
                  </option>
                ))
              : null}
          </select>
        
        </div> */}
        {/* <div>
          <Select className="mr-2" defaultValue="Months" style={{ width: 140 }}>
            <Option value="1">Janaury</Option>
            <Option value="2">February</Option>
            <Option value="3">March</Option>
            <Option value="4">April</Option>
            <Option value="5">May</Option>
            <Option value="6">June</Option>
            <Option value="7">July</Option>
            <Option value="8">August</Option>
            <Option value="9">September</Option>
            <Option value="10">October</Option>
            <Option value="11">November</Option>
            <Option value="12">December</Option>
          </Select>
        </div> */}
        {/* <div>
          <select
            className={`${commonstyles.form_control} ${commonstyles.form_control_select_report}`}
            style={{ cursor: "pointer" }}
          >
            <option selected value="0">
              Months
            </option>
            <option value="1">Janaury</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div> */}
      </div>
      <div className="row">
        <div
          className="col-lg-4 mb-4"
          // style={{ cursor: "pointer" }}
          // onClick={() => history.push("/salesreports")}
        >
          <div className={`${commonstyles.reports_card_box}`}>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => routerToSalesReport("Total Items sold")}
            >
              Sales Report
            </h4>
            <div className={`${commonstyles.reports_card_listed}`}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Total Items sold")}
              >
                Total Amount
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Total orders")}
              >
                Total orders
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Refund")}
              >
                Refund
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Shipment")}
              >
                Shipment
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Invoice")}
              >
                Invoice
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToSalesReport("Transactions")}
              >
                Transactions
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 mb-4"
          // style={{ cursor: "pointer" }}
          // onClick={() => history.push("/CustomerAnalytics")}
        >
          <div className={`${commonstyles.reports_card_box}`}>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => routerToCustomerAnalysis("Total Customers")}
            >
              Customers Analytics
            </h4>
            <div className={`${commonstyles.reports_card_listed}`}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToCustomerAnalysis("Total Customers")}
              >
                Total Customers
              </p>
              
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToCustomerAnalysis("One-Time Customers")}
              >
                One-Time Customers
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  routerToCustomerAnalysis("Repeat Customers Report")
                }
              >
                Repeat Customers Report
              </p>
              {/* <p style={{ cursor: "pointer" }} onClick={() => routerToCustomerAnalysis('Refund')}>Registered Customer</p> */}
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToCustomerAnalysis("New Customers")}
              >
                New Customers
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  routerToCustomerAnalysis("Non Registered Customers")
                }
              >
                Non Registered Customers
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 mb-4"
        >
          <div className={`${commonstyles.reports_card_box}`}>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => routerToProductAnalysis("Most Viewed Products")}
            >
              Product Report
            </h4>
            <div className={`${commonstyles.reports_card_listed}`}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Most Viewed Products")}
              >
                Most Viewed Products
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Most Sold")}
              >
                Most Sold
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Least Sold")}
              >
                Least Sold
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Popular Color")}
              >
                Popular color
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Most Searched Keywords")}
              >
                Most Searched Keywords
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => routerToProductAnalysis("Latest Searched Keyword")}
              >
                Latest Searched Keyword
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
