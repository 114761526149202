// import styles from "../../styles/ProductManagement.module.css";
import { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom";
// import AddFAQComponent from "./AddFAQComponent";
//import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
// import EditProductComponent from "./EditProductComponent";
// import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import plusIcon from "../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";
import NoImage from ".././../../assets/NoImage.svg"

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

export default function SearchComponent(props) {
  /*const [parentCategories, setParentCategories] = useState([]);
  const [faqList, setFaqList] = useState([]);
   const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState); */
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchOrders, setSearchOrders] = useState([]);
  const [searchCollections, setSearchCollections] = useState([]);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [visible, setVisible] = useState(false);

  let textInput = useRef();
  const [searchKey, setsearchKey] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  /* loader */
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const history = useHistory();

  // useEffect(() => {

     /**
   * @author RAJKUMAR
   * @param {}
   * CALLING GLOBAL SEARCH API AND SET STATE FOR CATEGORY,PRODUCT,COLLECTION,CUSTOMR AND USER
   */
    function searchList(searchKeyValue) {
      // setIsLoading(false);
      setIsLoading({ delete: false, list: true });
      const filterOptions = {
        searchKey: searchKeyValue,
        regionId: props.region === "" ? null : props.region,
      };
      // console.log(filterOptions);
      AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
        .post("/admin/globalSearch", filterOptions)
        .then((res) => {
          //   console.log(res.data);
          if (res.status === 200 && res.data.status === 200) {
            setIsLoader(false);
            setIsLoading({ delete: false, list: false });
            setSearchCategory(res.data.Category);
            setSearchOrders(res.data.Orders);
            setSearchCollections(res.data.Collections);
            setSearchCustomers(res.data.Customers);
            setSearchProducts(res.data.Products);
            setSearchUsers(res.data.Users);
            // setIsLoading(false);
            // console.log(Object.keys(res.data ).length);
          }
        })
        .catch((er) => {
          return er;
        })
        .finally(() => {
          // setIsLoading(false);
          setIsLoading({ delete: false, list: false });
        });
    }
  // }, [searchKey]);

  function handleSeach(e) {
    setIsEnabled(true);
    setVisible(true);
    // console.log(textInput.current.value);
    // const { value } = e.target;

    setsearchKey(textInput.current.value);
    searchList(textInput.current.value);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleSeach();
    }
  }

  return (
    <>
      <div className={commonstyles.management_contents_search}>
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="searchKey"
            className={`${commonstyles.form_control} form-control`}
            aria-label="searchKey"
            placeholder="Search..."
            aria-describedby="search-addon"
            ref={textInput}
            // value={searchKey}
            // onChange={(e) => handleSeach(e)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <img src={Search} alt="Search icon" />
          <div className={commonstyles.management_contents_upload}>
            <span style={{ cursor: "pointer" }} onClick={(e) => handleSeach(e)}>
              Search
            </span>
          </div>
        </div>
      </div>

      {/* <Button type="primary" onClick={() => setVisible(true)}>
        Open Modal of 1000px width
      </Button> */}
      <Modal
        title={searchKey === "" ? "Search results - All" : "Search results -"+searchKey}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1100}
        height={620}
      >
        {isEnabled && IsLoading.list === true ? (
          <div
            style={{
              marginLeft: "50vh",
              marginTop: "7vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching search results..." />
              </td>
            </tr>
          </div>
        ) : (
          <div className={commonstyles.search_list}>
            {searchCategory.length > 0 && (
              <h2 className={commonstyles.search_title}>Category</h2>
            )}
            <div className="row">
              {Array.isArray(searchCategory) &&
                searchCategory.length > 0 &&
                searchCategory.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() =>
                        history.push(`/catalog/category/${item._id}`)
                      }
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                        {item.image ? <img src={item.image} alt="image" /> : <img src={NoImage} alt="image" />  }
                        <div className={commonstyles.card_contents}>
                          <h3>{item.name ? item.name : "-"}</h3>
                          {/* <p>1200+ Lorem</p> */}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchOrders.length > 0 && (
              <h2 className={commonstyles.search_title}>Orders</h2>
            )}
            <div className="row">
              {Array.isArray(searchOrders) &&
                searchOrders.length > 0 &&
                searchOrders.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() => history.push(`/order/${item._id}`)}
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                        {item.productImage[0].link ? <img src={item.productImage[0].link} alt="image" /> : <img src={NoImage} alt="image" />}
                        <div className={commonstyles.card_contents}>
                          <h3>{item.products[0] ? item.products[0] : "-"}</h3>
                          {/* <p>1200+ Lorem</p> */}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchCollections.length > 0 && (
              <h2 className={commonstyles.search_title}>Collections</h2>
            )}
            <div className="row">
              {Array.isArray(searchCollections) &&
                searchCollections.length > 0 &&
                searchCollections.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() =>
                        history.push(`/catalog/decors/${item._id}`)
                      }
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                        {item.image ?    <img src={item.image} alt="image" /> : <img src={NoImage} alt="image" /> }
                     
                        <div className={commonstyles.card_contents}>
                          <h3>{item.title ? item.title : "-"}</h3>
                          {/* <p>1200+ Lorem</p> */}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchCustomers.length > 0 && (
              <h2 className={commonstyles.search_title}>Customers</h2>
            )}
            <div className="row">
              {Array.isArray(searchCustomers) &&
                searchCustomers.length > 0 &&
                searchCustomers.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() => history.push(`/customer/${item._id}`)}
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                      <img alt={item?.name} src={item?.profileImg? item?.profileImg : NoImage} />
                        <div className={commonstyles.card_contents}>
                          <h3>{item.name ? item.name : "-"}</h3>
                          <p>{item.email ? item.email : "-"}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchProducts.length > 0 && (
              <h2 className={commonstyles.search_title}>Products</h2>
            )}
            <div className="row">
              {Array.isArray(searchProducts) &&
                searchProducts.length > 0 &&
                searchProducts.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() =>
                        history.push(`/catalog/products/edit/${item._id}`)
                      }
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                      {item.feature_image ?    <img src={item.feature_image} alt="image" /> : <img src={NoImage} alt="image" /> }
                        <div className={commonstyles.card_contents}>
                          <h3>{item.name ? item.name : "-"}</h3>
                          <p>{item.productType ? item.productType : "-"}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchUsers.length > 0 && (
              <h2 className={commonstyles.search_title}>Users</h2>
            )}
            <div className="row">
              {Array.isArray(searchUsers) &&
                searchUsers.length > 0 &&
                searchUsers.map((item, indx, arr) => (
                  <>
                    <div
                      key={item._id}
                      onClick={() =>
                        history.push(`/site-settings/user/${item._id}`)
                      }
                      className="col-md-4 cursor-pointer"
                    >
                      <div className={commonstyles.search_card}>
                      <img src={item?.profileImg? item?.profileImg : NoImage} alt="image" />
                        <div className={commonstyles.card_contents}>
                          <h3>{item.name ? item.name : "-"}</h3>
                          <p>{item.email ? item.email : "-"}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {searchOrders.length === 0 &&
              searchCategory.length === 0 &&
              searchCollections.length === 0 &&
              searchCustomers.length === 0 &&
              searchProducts.length === 0 &&
              searchUsers.length === 0 && <h4>No results found!</h4>}
          </div>
        )}
      </Modal>
    </>
  );
}
