import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte, reject } from "lodash";
import { isAuthenticated, runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  noSpace,
  alphaNumwithSpace,
  alphanumericwithdashHyphen,
  minChar,
  minCharWithNoMaxLimit,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import MainLeftBanner from "../CMSManagement/MainLeftBanner";
import LeftRightTopBanner from "../CMSManagement/LeftRightTopBanner";
import MadeForBanner from "../CMSManagement/MadeForBanner";
import moment from "moment";
import LoginWithOtpBanner from "./LoginWithOtpBanner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';

const { Option } = Select;
const initialFormState = {
  _id: "",
  title: "",
  slug: "",
  content: "",
  // urlkey:"",
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
  enable: true,
};

export default function LandingComponent() {
  const rules = {
    title: [(val, data) => (!val ? "Title " : true)],
    slug: [(val, data) => (!val ? "Slug " : true)],
    regionId: [(val, data) => (data.regionId ? true : "Region ")],
    content: [(val, data) => (!val ? "Content " : true)],
    metaTitle: [(val, data) => (!val ? "Meta title " : true)],
    metaKeywords: [(val, data) => (!val ? "Meta keywords " : true)],
    metaDescription: [(val, data) => (!val ? "Meta description " : true)],
  };

  const history = useHistory();

  const { state } = history.location;

  // const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [filterRegions, setFilterRegions] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [page, setPage] = useState(state);
  const [bannerDetail, setBannerDetail] = useState([]);
  const [editForm, setEditForm] = useState(
    JSON.parse(JSON.stringify(initialFormState))
  );
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
            if (res.data.daaata[0]) {
              setRegionId(res.data.daaata[0]?._id);
            }
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    getRegionList();
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    setPage(state ? state : 3);
    setFormErrors({});
    setEditForm([]);
    regionId && getLandingList();
    return () => {
      history["isRegion"] = true;
    };
  }, [regionId]);
  /**
   * @author Rajkumar
   * To get landing list
   */
  function getLandingList() {
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`/landingPage/${regionId}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // let length = res.data.length;
          const val =
            res.data.data[0] || JSON.parse(JSON.stringify(initialFormState));

          setEditForm(val);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInputSlug(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) {
      return false;
    } else {
      if (!alphanumericwithdashHyphen.test(event.target.value)) {
        formErrors.slug = "Enter only alphabet and number";
      }
      setEditForm({
        ...editForm,
        slug: event.target.value.toLowerCase().split(" ").join(""),
      });
    }
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    // console.log(value);

    if (!minChar.test(e.target.value)) {
      if (!formErrors.metaDescription) {
        formErrors.metaDescription = {};
      }
      formErrors.metaDescription = "Enter minimum 3 characters";
    } else {
      if (formErrors.metaDescription) {
        formErrors.metaDescription = "";
      }
    }
    if (["title"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      if (!alphaNumwithSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaTitle"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaTitle = "Enter only alphabet and number";
      // }
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaKeywords"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaKeywords = "Enter only alphabet and number";
      // }
     
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaDescription"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaDescription = "Enter only alphabet and number";
      // }
    
      setEditForm({ ...editForm, [name]: value });
    }

    const updatedValues = { ...editForm, [name]: value };
    // if (name === "regionId") {
    //   setRegionId(value);
    //   getLandingList();
    // }
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
  }

  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // *** SAVE CHANGES *** //
  function submit() {
    let data = JSON.parse(JSON.stringify(editForm));
    data.slug = data.slug.trim();
    data.regionId = regionId ? regionId : "";
    data._id = regionId ? regionId : "";

    delete data.userName;
    delete data.updatedAt;
    // console.log(data);
    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    AxiosInstance(PORTS.CMS, "application/json")
      // .post(`/landingPage/${editForm?._id ? "updatePage" : "addPage"}/`, data)
      .post(`/landingPage/updatePage/`, data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          setIsLoading(false);
          getLandingList();
          showNotification({
            type: "success",
            message: "Success",
            description: editForm?._id
              ? "Warehouse wise landing page updated successfully"
              : "Warehouse wise landing page added successfully",
          });
          history.push(`/Content`);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res?.data?.msg,
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            console.log(file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    console.log(editor);
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function handleRegion(e) {
    setIsLoading(true);
    const { name, value } = e.target;
    console.log({ name, value });
    setRegionId(value);
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`/landingPage/${value}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const val =
            res.data.data[0] || JSON.parse(JSON.stringify(initialFormState));
          // console.log(val);
          // setEditForm({});
          // console.log(initialFormState);
          setEditForm(val);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          Landing Page Management
        </h1>
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "50vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching landing page details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div>
          <div
            className={`${styles.management_header} ${styles.banner_city_list}`}
          >
            <div className={`${styles.banner_citys}`}>
              <span className="text-danger" style={{ fontSize: "20px" }}>
                *
              </span>
              <select
                className={"form-control " + styles.form_control}
                name="regionId"
                error={formErrors.regionId}
                value={regionId}
                onChange={(e) => handleRegion(e)}
                style={{ cursor: "pointer" }}
              >
                {regions.map((region) => (
                  <>
                    <option value={region._id} label={region.name}>
                      {region.name}
                    </option>
                  </>
                ))}
              </select>
              <FormErrorText error={formErrors.regionId} />
              <div className={`${styles.banner_citys_update}`}>
                {editForm?.userName && (
                  <p style={{ textTransform: "capitalize" }}>
                    Last updated by: <span>{editForm?.userName}</span>
                  </p>
                )}
                {editForm?.updatedAt && (
                  <p>
                    Last updated on:{" "}
                    <span>
                      {moment(editForm?.updatedAt).format(
                        "DD/MM/YYYY | hh:mm A"
                      )}
                    </span>
                  </p>
                )}
              </div>
            </div>

            <div className={styles.management_contents_upload}>
              <div className={`${styles.onoffswitch} d-inline-block align-top`}>
                <input
                  type="checkbox"
                  name="enable"
                  className={styles.onoffswitch_checkbox}
                  onChange={handleFormInput}
                  checked={editForm?.enable}
                  id="statusSwitch"
                />
                <label
                  className={styles.onoffswitch_label}
                  htmlFor="statusSwitch"
                >
                  <span className={styles.onoffswitch_inner}></span>
                  <span className={styles.onoffswitch_switch}></span>
                </label>
              </div>
              <ButtonComponent
                className="rnd mr-3 outline_btn no_outline_btn"
                attributes={{
                  onClick: () => history.push(`/Content`),
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className="rnd"
                attributes={{
                  onClick: submit,
                }}
              >
                Save
              </ButtonComponent>
            </div>
          </div>

          <div
            className={`${styles.management_details} ${styles.landing_category_management}`}
          >
            <div
              /* key={indx} */ className={styles.management_details_contents}
            >
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Page title
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.title}
                      formControl={{
                        placeholder: "",
                        name: "title",
                        value: editForm?.title,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.title) {
                              formErrors.title = {};
                            }
                            formErrors.title = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.title) {
                              formErrors.title = "";
                            }
                          }

                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.title} />
                  </div>
                </div>
                {/* Slug */}
                <div className="col-lg-12 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Slug
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    error={formErrors.slug}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type slug",
                      name: "slug",
                      value: editForm?.slug,
                      onChange: (e) => {
                        if (!minChar.test(e.target.value)) {
                          if (!formErrors.slug) {
                            formErrors.slug = {};
                          }
                          formErrors.slug = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.slug) {
                            formErrors.slug = "";
                          }
                        }
                        handleInputSlug(e);
                      },
                    }}
                  />
                  <FormErrorText error={formErrors.slug} />
                </div>
                {/* Slug ends */}
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Content
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>

                    {/* {editForm.content && ( */}
                    <CKEditor
                      error={formErrors.content}
                      editor={ClassicEditor}
                      data={
                        // (url && (
                        //   <>
                        //     <ImagePreviewChip
                        //       url={url}
                        //       className={styles.added_image}
                        //       width="60px"
                        //       height="60px"
                        //       // handleClose={removeFile}
                        //     />
                        //   </>
                        // )) ||
                        editForm?.content || ""
                      }
                      config={{ extraPlugins: [uploadPlugin] }}
                      // config = {{ckfinder:{isAuthenticated:false}}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };
                        // console.log(data);

                        values.content = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.content) {
                            formErrors.content = {};
                          }
                          formErrors.content = "Content required";
                        } else {
                          if (formErrors.content) {
                            formErrors.content = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />

                    {/* )} */}
                    {/*  {!(page?._id && editForm.content) && (
                                        <CKEditor
                                        editor={ ClassicEditor }
                                        data={editForm?.content || ""}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            const values = editForm;
                                            values.content = data;
                                            setEditForm(values);
                                        } }
                                    />
                                    )} */}

                    <FormErrorText error={formErrors.content} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.management_details_contents}>
              <h4 className="theme-form-section-title">
                Search Engine Optimization
              </h4>
              <div className="row">
                {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">URL Key</label>
                            <InputComponent
                                className={`form-control form-control-lg ${styles.form_control}`}
                                // error={formErrors.urlkey}
                                formControl={{
                                placeholder: "",
                                name: "urlkey",
                                value: editForm?.urlkey,
                                onChange:handleFormInput,
                                }}
                            />
                            // <FormErrorText error={formErrors.urlkey} />
                            </div>
                        </div> */}
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Title{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaTitle}
                      formControl={{
                        placeholder: "",
                        name: "metaTitle",
                        // error: formErrors.metaTitle,
                        value: editForm?.metaTitle,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaTitle) {
                              formErrors.metaTitle = {};
                            }
                            formErrors.metaTitle = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaTitle) {
                              formErrors.metaTitle = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaTitle} />
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Keywords{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaKeywords}
                      formControl={{
                        placeholder: "",
                        name: "metaKeywords",
                        value: editForm?.metaKeywords,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaKeywords) {
                              formErrors.metaKeywords = {};
                            }
                            formErrors.metaKeywords =
                              "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaKeywords) {
                              formErrors.metaKeywords = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaKeywords} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Description{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaDescription}
                      // formControl={{
                      placeholder=""
                      name="metaDescription"
                      value={editForm?.metaDescription}
                      onChange={(e) => handleFormInput(e)}
                      // maxLength="40"
                      // }}
                    />
                    <FormErrorText error={formErrors.metaDescription} />
                  </div>
                </div>
                {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">Meta Description</label>
                                <select
                                    className={"form-control " + styles.form_control}
                                    name="regionId"
                                    value={regionId}
                                    onChange={handleFormInput}
                                    style={{ cursor: "pointer" }}
                                    >
                                    <option value="" disabled>All region</option>
                                    {regions.map((region) => (
                                        <>
                                        <option value={region._id} label={region.name}>
                                            {region.name}
                                        </option>
                                        </>
                                    ))}
                                </select>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
