import React from "react";
const ButtonComponent = (props) => {
  let classes = "theme-btn";
  if (props.className) {
    classes = `${classes} ${props.className}`;
  }

  return (
    <button {...props.attributes} className={classes}>
      {props.children}
    </button>
  );
};

export default ButtonComponent;
