import { Modal } from "antd";
import { useState } from "react";
export default function MediaURLInputModalComponent(props) {
  const title = props.type === "image" ? "Add Image URL" : "Add Video URL";
  const [url, setURL] = useState("");

  function checkURLValid(url) {
    let isValid = false;
    try {
      isValid = !!new URL(url);
    } catch (er) {
    } finally {
      return isValid;
    }
  }

  function handleSubmit() {
    const link = url,
      type = props.type;

    props.handleAddURL(link, type);
    setURL("");
  }

  const isURLValid = checkURLValid(url);

  return (
    <Modal
      title={title}
      visible={props.show}
      closable
      //   onOk={handleOk}
      onCancel={props.handleClose}
      onOk={handleSubmit}
      okButtonProps={{ className: "theme-btn", disabled: !isURLValid }}
      cancelButtonProps={{ className: "theme-btn-outlined" }}
      okText="Add"
    >
      <input
        type="url"
        placeholder="https://"
        className="form-control"
        value={url}
        onChange={(e) => setURL(e.target.value)}
      />
    </Modal>
  );
}
