import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import React, { useState, useRef, useEffect } from "react";
import { notification, Menu, Dropdown, Tooltip } from "antd";
import dots from "../../../../assets/images/column_dots.svg";
import styles from "../../../../styles/PaymentNew.module.css";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { useParams } from "react-router";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import eyeView from "../../../../assets/images/view_icon.PNG";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import plusIcon from "../../../../assets/images/plus_white_icon.svg";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import moment from "moment";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import { upperCase } from "lodash";
import * as XLSX from "xlsx";
import exportIcon from "../../../../assets/images/export_icon.svg";
import discountstyles from "../../../../styles/AdminDiscount.module.css";

const pagesizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Ratings", value: "Ratings" },
  { _id: 3, status: 1, name: "Name", value: "Name" },
  { _id: 4, status: 1, name: "Review", value: "Review" },
  { _id: 5, status: 1, name: "Status", value: "Status" },
  { _id: 6, status: 1, name: "Operation", value: "Operation" },
];

const filters = [
  { id: 1, isChecked: false, name: "Status", value: "Status" },
  { id: 2, isChecked: false, name: "Order Date", value: "date" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 0 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "Custom" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Approve", value: 1 },
  { _id: 2, isChecked: false, name: "Reject", value: 2 },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

let initialRenderCount = 1;

export default function AnalyticsList(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  let { id } = useParams();

  const initialFilterState = {
    _id: id,
    pagesize: 10,
    nextpage: 1,
    search: "",
  };

  const history = useHistory();

  const [Status, setStatus] = useState("");
  const [filterDays, setFilterDays] = useState(null);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [currentTab, setCurrentTab] = useState("0");
  const [ratingsForm, setRatingsForm] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [statuus, setStatuus] = useState("");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const pdfExportComponent = useRef(null);
  const [pagination, setPagination] = useState({});
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [visible, setVisible] = useState(false);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  let stateStatus =
    localStorage.getItem("role") === "Admin"
      ? [
        {
          _id: 0,
          name: "All",
          value: 0,
        },
        {
          _id: 3,
          name: "Pending",
          value: 3,
        },
        {
          _id: 1,
          name: "Approved",
          value: 1,
        },
        {
          _id: 2,
          name: "Rejected",
          value: 2,
        },
      ]
      : [
        {
          _id: 0,
          name: "All",
          value: 0,
        },
        {
          _id: 3,
          name: "Pending",
          value: 3,
        },
        {
          _id: 1,
          name: "Approved",
          value: 1,
        },
        {
          _id: 2,
          name: "Rejected",
          value: 2,
        },
      ];
  const [search, setSearch] = useState("");

  // const [reviewId, setReviewId] = useState([]);

  const reviewId = [];

  let reviews = ratingsForm;

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Ratings",
      visible: false,
      key: "Ratings",
      render: (text, record, index) => (
        <span>
          {record.rating > 0 && Array.from(Array(Math.floor(record.rating))).map(() => (
            <label>
              <FaStar color="#EBCA42" />
            </label>
          ))}
        </span>
      ),
    },
    {
      title: "Name",
      visible: false,
      key: "Name",
      render: (text, record, index) => (
        <td>{record.userName ? record.userName : "-"}</td>
      ),
    },
    {
      title: "Review",
      visible: false,
      key: "Review",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>
          {record.description ? record.description : "-"}
        </td>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <td>
          <span
            className={
              record.status == 1
                ? discountstyles.status_active
                : discountstyles.status_inactive
            }
          >
            {record.status == 0
              ? "Pending"
              : record.status == 1
                ? "Approved"
                : record.status == 2
                  ? "Rejected"
                  : "All"}
          </span>
        </td>
      ),
    },
    {
      title: "Operation",
      visible: false,
      key: "Operation",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-edit") ===
        "Marketing-Rating-edit" && (
          <span>
            <span>
              <Link
                to={{
                  pathname: `/rating/${id}/${index}`,
                  state: record,
                }}
              >
                <img
                  src={eyeView}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer`}
                  alt="Edit category"
                />
              </Link>
            </span>
          </span>
        ),
    },
  ]);



  /**
   * @author Mithun Prabhu
   * @param {ONCLICK}
   * TO CALL EXPORT PDF USING USErEF
   */
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {event}
   * TO CALL EXPORT CSV API
   */
  const exportToCSV = async (e) => {
    AxiosInstance(PORTS.INVOICE, "application/json")
      .get(`products/reviewDetailofProduct/${id}`)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data[0].reviews;
          /* data.map((item) => {
            item["Module Name"] = capitalize(item["Module Name"]);
          }); */
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);

          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `offer-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("ReviewList"));
    let cloneSelectedOrder = [];

    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        reviewId.push(item._id);

        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedReview")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedReview"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
      reviewId.push(record._id);
    }
    localStorage.setItem("selectedReview", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("ReviewList", JSON.stringify(cloneOrderList));
    setRatingsForm(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedReview");
    const { checked } = e.target;

    const cloneOrderList = JSON.parse(localStorage.getItem("ReviewList"));

    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedReview")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedReview"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedReview", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("ReviewList", JSON.stringify(cloneOrderList));
    setRatingsForm(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getReviewList(initialFilterState);
    localStorage.removeItem("selectedReview");
    return () => {
      localStorage.removeItem("ReviewList");
      localStorage.removeItem("selectedReview");
      history["isReviews"] = true;
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getReviewList(initialFilterState) {
    if (initialRenderCount == 1) {
      delete initialFilterState.status;
    }

    initialRenderCount += 1;

    initialFilterState.pagesize = pageSize;
    initialFilterState.nextpage = nextPage;


    if (!search) {
      delete initialFilterState.search;
    } else {
      initialFilterState.search = search;
    }

    // if (initialFilterState && initialFilterState.search) {
    //   initialFilterState.search = initialFilterState.search;
    // } else if (initialFilterState.search !== "") {
    //   if (initialFilterState && initialFilterState.search !== "") {
    //     initialFilterState.search = initialFilterState.search;
    //   }
    // }

    if (initialFilterState && initialFilterState.status) {
      initialFilterState.status = parseInt(initialFilterState.status);
    } else if (Status !== "") {
      if (initialFilterState && initialFilterState.status !== "") {
        initialFilterState.status = parseInt(Status);
      }
    }

    if (initialFilterState && initialFilterState.filterDays) {
      initialFilterState.filterDays = parseInt(initialFilterState.filterDays);
    } else if (initialFilterState.filterDays !== "") {
      if (initialFilterState && initialFilterState.filterDays !== "") {
        initialFilterState.filterDays = parseInt(filterDays);
      }
    }

    if (initialFilterState && initialFilterState.startDate) {
      initialFilterState.startDate = initialFilterState.startDate;
    } else if (filteredDate.startDate !== "") {
      if (initialFilterState && initialFilterState.startDate !== "") {
        initialFilterState.startDate = filteredDate.startDate;
      }
    }
    if (initialFilterState && initialFilterState.endDate) {
      initialFilterState.endDate = initialFilterState.endDate;
    } else if (filteredDate.endDate !== "") {
      if (initialFilterState && initialFilterState.endDate !== "") {
        initialFilterState.endDate = filteredDate.endDate;
      }
    }

    if (initialFilterState.filterDays === "") {
      initialFilterState.filterDays = null;
    }
    if (
      initialFilterState.status == "" ||
      initialFilterState.status == NaN ||
      initialFilterState.status == null ||
      initialFilterState.status == undefined
    ) {
      delete initialFilterState.status;
    }


    setFilterOptionsGlobal(initialFilterState);
    AxiosInstance(PORTS.INVOICE, "application/json")
      .post("products/reviewDetail", initialFilterState)
      .then((res) => {
        // setReviewId(res.data.data.map((review) => review.reviews[0]._id));
        if (res && res.data && res.status == "200") {
          if (res.data.data.length > 0) {
            res.data.data[0].reviews.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem(
              "ReviewList",
              JSON.stringify(res.data.data[0].reviews)
            );
            setRatingsForm(res.data.data[0].reviews);
            setPagination(res.data.pagination);
          } else {
            setRatingsForm([]);
          }
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function searchHandler(e) {
    const { value } = e.target;
    initialFilterState.search = value;
    setSearch(value);

    if (!value) {
      initialFilterState.search = "";
      setSearch(initialFilterState.search);
      getReviewList(initialFilterState);
    }
  }

  function searchClick() {
    getReviewList(initialFilterState);
  }

  function debounceSearch(filter) {
    filter.nextpage = 1;
    setNextPage(1);
    // initialFilterState.search = search;
    // setStatus(initialFilterState.status);
    getReviewList(filter);
  }

  const [deliveryName, setDeliveryName] = useState('All');
  const [dayName, setDayName] = useState('Select order date');

  function filterOnChange(name, value) {
    let updatedFilter = {};
    initialFilterState.pagesize = initialFilterState.pagesize;
    initialFilterState.nextpage = initialFilterState.nextpage;
    initialFilterState._id = id;

    initialFilterState.search = search;

    if (name === "filterDays" && value.value !== "Custom") {
      initialFilterState.filterDays = value._id;
      setDayName(value.name)
      setFilterDays(value.value);
    }
    if (name === "Status") {
      initialFilterState.status = value._id;
      setDeliveryName(value.name)
      setStatus(value.value);
    }
    if (value.value === "Custom") {
      initialFilterState.filterDays = '';
      setDayName(value.name)
      setFilterDays(value.value);
    }

    debounceSearch(initialFilterState);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);

    let start = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    let end = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";

    initialFilterState.startDate = start;
    initialFilterState.endDate = end;

    debounceSearch(initialFilterState);
  };

  /**
   * @author Mithun Prabhu
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }

      initialFilterState.pagesize = initialFilterState.pagesize;
      initialFilterState.nextpage = initialFilterState.nextpage;
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "Status") {
          initialFilterState.status = '';
          setDeliveryName('All')
          setStatus("");
        }
        if (item.value === "date") {
          initialFilterState.filterDays = '';
          setDayName('Select order date')
          setFilterDays("");
        }
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
    // getReviewList(initialFilterState);
    debounceSearch(initialFilterState);
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCategory(values);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
   * @author Mithun Prabhu
   * @param {e, id}
   * This is component for checking and unchecking columns in list page
   */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCategory(items);
  };

  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewCategory = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  /**
   * @author Mithun Prabhu
   * @param {Menu}
   * This is component for removing/adding columns with onClick event
   */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>

            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Status") === "Status" && (
                <div className="card_dropdown show col-md-6">
                    <div
                      style={{ width: "100%" }}
                      className="filter_heading card_dropdown_heading"
                    >
                      {deliveryName}
                      <img alt="" src={arrowDown} width={10} height={20} />
                    </div>
                    <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                      {Array.isArray(stateStatus)
                        ? stateStatus.map((delivery) => (
                            <span
                              key={delivery._id}
                              onClick={() =>
                                filterOnChange("Status", delivery)
                              }
                            >
                              <label>{delivery.name}</label>
                            </span>
                          ))
                        : null}
                    </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                      style={{ width: "100%" }}
                      className="filter_heading card_dropdown_heading"
                    >
                      {dayName}
                      <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterOnChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "Custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {data}
   * This is for checking through different action objects and calling API for submit
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {item}
   * This is for checking through different action objects and calling API for submit
   */
  const applyBulkActionHandler = (item) => {
    let cloneForm = ratingsForm;
    if (item.value == 1) {

      let cloneSelectedIds = JSON.parse(localStorage.getItem("selectedReview"));

      if(cloneSelectedIds?.length > 0) {
        cloneSelectedIds.map((selected) => {
          cloneForm = cloneForm.map((itm) => {
            if (selected == itm._id) {
              itm.status = 1;
              return itm;
            } else {
              return itm;
            }
          });
        });

        let data = {
          productId: id,
          reviews: cloneForm,
        };
    
        data.checkReviewer = 1;

        submit(data);
      } else {
        showNotification({
          type: "error",
          message: "error",
          description: "Please select correct option",
        });
      }
    } else if (item.value == 2) {
      let cloneSelectedIds = JSON.parse(localStorage.getItem("selectedReview"));

      if (cloneSelectedIds?.length > 0) {
      cloneSelectedIds.map((selected) => {
        cloneForm = cloneForm.map((itm) => {
          if (selected == itm._id) {
            itm.status = 2;
            return itm;
          } else {
            return itm;
          }
        });
      });

      let data = {
        productId: id,
        reviews: cloneForm,
      };

      // localStorage.getItem("role") == "super_admin" ||
      // localStorage.getItem("role") == "SuperAdminNew"
      data.checkReviewer = 1;
      // : delete data.checkReviewer;

      submit(data);
      } else {
        showNotification({
          type: "error",
          message: "error",
          description: "Please select correct option",
        });
      }
      // getReviewList(initialFilterState);
    }
  };

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      initialFilterState.pagesize = value;
      initialFilterState.nextpage = 1;

      getReviewList(initialFilterState);
    } else if (type === "nextPage") {
      setNextPage(value);
      initialFilterState.nextpage = value;

      getReviewList(initialFilterState);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {data}
   * API call for submit review details
   */
  function submit(data) {
    delete data.reviews.filter((item) => delete item.key);

    delete data.reviews.filter((item) => delete item.isChecked);

    delete data.reviews.filter((item) => delete item.gallery);

    delete data.reviews.filter((item) => delete item.userId);

    delete data.reviews.filter((item) => delete item.createdAt);

    localStorage.getItem("role") == "Admin" && delete data.checkReviewer;

    // localStorage.getItem("role") == "Admin"
    //   ? data.reviews.filter((review) => (review.status = 1))
    //   : data.reviews.filter((review) => (review.status = 2));

    if (localStorage.getItem("role") == "Admin") {
      delete data.checkReviewer;
    }

    data.reviews.filter((review) => delete review.adminApprovalStatus);
    data.reviews.filter((review) => delete review.description);
    data.reviews.filter((review) => delete review.individual);
    data.reviews.filter((review) => delete review.rating);
    data.reviews.filter((review) => delete review.userName);
    data.reviews.filter((review) => delete review.title);

    const apiEndPoint = "products/bulkUpdateReviews";

    AxiosInstance(PORTS.INVOICE)
      .post(apiEndPoint, data)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Reviews updated successfully",
          });
          history.push(`/rating/${id}`);
          getReviewList(initialFilterState)
          localStorage.removeItem('selectedReview');
        } else if(res.data.status === 400) {
          showNotification({
            type: "error",
            message: "error",
            description: res.data.msg,
          });
          localStorage.removeItem('selectedReview');
          getReviewList(initialFilterState)
        } else {
          showNotification({
            type: "error",
            message: "error",
            description: "Please select correct option",
          });
        }

      })
      .catch((er) => {
        return er;
      });
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />

      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <Link
            to={{
              pathname: "/rating"
            }}>
            <img src={BlackLeftArrow} alt="" />
          </Link>
          Rating & Reviews
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Marketing-Rating-view") ===
          "Marketing-Rating-view" && (
            <div>
              <span className={commonstyles.management_header_downloads_text}>
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
                <Tooltip placement="topRight" title="Download excel">
                  <img
                    src={excelIcon}
                    className={commonstyles.management_header_downloads_icon}
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToCSV(e)}
                  />
                </Tooltip>
              </span>
              <span
                className={commonstyles.management_header_downloads_text_ex}
              >
                Export <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
      </div>
      <div className={commonstyles.management_header}>
        <Link
          to={{
            pathname: `/rating/${id}/add`,
            state: { id: id, reviews },
          }}
        >
          <ButtonComponent className="rnd pl-3 pr-4 outline_btn">
            {/* <img src={plusIcon} className="mr-2" /> */}
            Add Review
          </ButtonComponent>
        </Link>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search by Description..."
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => searchHandler(e)}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <div className="action ml-3">
              <div className="action_header">
                Action
                <img src={arrowDown} alt="image" />
              </div>
              <div className="action_contents">
                {bulkActionFilter.length > 0 &&
                  bulkActionFilter.map((item, indx) => (
                    <div
                      className="action_contents_header"
                      key={item._id}
                      onClick={() => bulkStatusClickHandler(item)}
                    >
                      <h2
                        className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                          }`}
                      >
                        {item.name}
                      </h2>
                      {item.isChecked && (
                        <span
                          className="action_contents_btn"
                          onClick={() => applyBulkActionHandler(item)}
                        >
                          Apply
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Column <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading.list === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching review details..." />
                  </td>
                </tr>
              </div>
            ) : Array.isArray(ratingsForm) && ratingsForm.length > 0 ? (
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`OfferList for ${new Date().getFullYear()}`}
                author="Damro Team"
              >
                <div>
                  <input
                    type="checkbox"
                    className={`${commonstyles.parentCheckbox}`}
                    onChange={parentCheckbox}
                  ></input>
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: ratingsForm,
                      pagination: false,
                      // onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </div>
              </PDFExport>
            ) : (
              <tr className="text-info">
                <span colSpan="3" style={{ color: "black", fontSize: "12px" }}>
                  No data found!
                </span>
              </tr>
            )}
          </div>
        )}
      {!IsLoading.list && Array.isArray(ratingsForm) && ratingsForm.length > 0
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <Pagination
            pageSizes={pagesizes}
            pageSize={initialFilterState.pagesize}
            nextPage={initialFilterState.nextpage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
    </div>
  );
}
