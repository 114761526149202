import React, { useState, useEffect } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";

export default function PermissionTable(props) {
    const columns = [
        {
            title: "Entry",
            visible: false,
            key: "entry",
            render: (text, record, index) => (
                <span>{record.name}</span>
            ),
        },
        {
            title: "Add",
            visible: false,
            key: "add",
            render: (text, record, index) => (
                <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    checked={record.add}
                    onChange={(e) => props.addcheckbox(e, record, "add")}
                ></input>
            ),
        },
        {
            title: "Edit",
            visible: false,
            key: "edit",
            render: (text, record, index) => (
                <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    checked={record.edit}
                    onChange={(e) => props.addcheckbox(e, record, "edit")}
                ></input>
            ),
        },
        {
            title: "View",
            visible: false,
            key: "view",
            render: (text, record, index) => (
                <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    checked={record.view}
                    onChange={(e) => props.addcheckbox(e, record, "view")}
                ></input>
            ),
        }
    ];

    return (
        <div>
            <div>
                {props.btns.length > 0 && props.btns.map((btn) => (
                    <button 
                        key={btn.id} 
                        className={`${commonstyles.sidebarButton} 
                            ${btn.active&&commonstyles.sidebarButton_active} 
                            d-inline-block`}
                        onClick={() => props.selectedBtn(btn)}
                    >
                        {btn.name}
                    </button>
                ))}
            </div>
            
            <AntTableComponent
                className="order_table"
                tableProps={{
                    columns: columns,
                    dataSource: props?.tableList,
                    pagination: false,
                }}
            />
        </div>
    )
}